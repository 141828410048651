import Cookies from "js-cookie";

export const storeUserdata = (data) => {
  localStorage.setItem("user", JSON.stringify(data));
};

export const signIn = (loginData) => {
  if (loginData.keepMeSignedIn) {
    Cookies.set("user", JSON.stringify(loginData), { expires: 1 });
  } else {
    // sessionStorage.setItem("user", JSON.stringify(loginData));
    Cookies.set("user", JSON.stringify(loginData), { expires: 1 });
  }
};

/**
 * Token is available in Cookie or not
 */
export const isUserLoggedIn = () => {
  let userLoggedInData = [];
  userLoggedInData = Cookies.getJSON("user");
  return userLoggedInData ? (userLoggedInData.token ? true : false) : false;
};

export const getToken = () => {
  let userLoggedInData = [];
  userLoggedInData =
    JSON.parse(sessionStorage.getItem("user")) || Cookies.getJSON("user");
  if (userLoggedInData) {
    return userLoggedInData.token;
  } else {
    return false;
  }
};

// Redirects to login page when we get 401 unauthorized error or when token is expired

export const unAuthorizedRedirection = (response) => {
  if (response.error.status === 401) {
    window.location = "login";
    userLogOut();
  }
};

/**
 * Keep Me signed is true or false
 */
export const isKeepMeLoggedIn = () => {
  let userLoggedInData = [];
  userLoggedInData = Cookies.getJSON("user");
  return (
    userLoggedInData &&
    userLoggedInData.keepMeSignedIn &&
    userLoggedInData.keepMeSignedIn
  );
};

/**
 * Removing token on user logout
 */
export const userLogOut = () => {
  sessionStorage.removeItem("user");
  Cookies.remove("user");
  localStorage.removeItem("retailer");
  // done by mani
  localStorage.removeItem("itemIdArr");
  localStorage.removeItem("firstView");
  // localStorage.removeItem("tabs");
};

/**
 * Removing token on bowser close
//  */
export const closeTab = () => {
  const data = Cookies.getJSON("user");
  if (!data.keepMeSignedIn) {
    Cookies.remove("user");
  }
};
/**
 * Error boundary
 */
export const errorFallback = () => {
  return <p>Something went wrong</p>;
};

/**
 * Email pattern
 */
export const emailRex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Email valid or not
 */

export const isValidEmail = (email) => {
  return emailRex.test(String(email).trim().toLowerCase());
};

/**
 * Number Pattern
 */
export const numberRex = /^[789]\d{9}$/;

/**
 * Number valid or not
 */
export const isValidMobileNumber = (number) => {
  return numberRex.test(Number(number));
};
/**
 * Sort array in ascending array by name
 */

export const getSortedArrayData = (data) => {
  return data.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    } else if (b.name > a.name) {
      return -1;
    } else {
      return 0;
    }
  });
};

/**
 * Is mobile view screen
 */
export const isMobileViewVisible = () => {
  if (window.innerWidth <= 440) {
    return true;
  }
  return false;
};

/**
 * Is IGST
 */
export const calucalteIGST = (price, quantity, IGSTRate) => {
  return parseInt((quantity * price * IGSTRate) / (100 + IGSTRate));
};

export const calucalteCGST = (price, quantity, CGSTRate, SGSTRate) => {
  return parseInt(
    (((quantity * price * 100) / (100 + CGSTRate + SGSTRate)) * CGSTRate) / 100
  );
};

export const calucalteSGST = (price, quantity, CGSTRate, SGSTRate) => {
  return parseInt(
    (((quantity * price * 100) / (100 + CGSTRate + SGSTRate)) * SGSTRate) / 100
  );
};

/**
 * Is tablet view screen
 */

export const isTabViewVisible = () => {
  if (window.innerWidth <= 1024) {
    return true;
  }
  return false;
};

/**
 * Config settngs
 */

export const setConfig = (data) => {
  if (data) {
    Cookies.set("config", JSON.stringify(data), { expires: 1 });
  }
};

/**
 * If Key available then return value
 */
export const isKeyAvailable = (key) => {
  let userLoggedInData = [];
  userLoggedInData = Cookies.getJSON("config");
  let data = userLoggedInData?.find((data) => data.ConfigKey == key);
  return data?.ConfigValue || 0;
};
