import React, { useState, useEffect } from "react";
import { Form, FormGroup, Input, FormFeedback } from "reactstrap";
import { useTranslation } from "react-multi-lang";
import PropTypes from "prop-types";
import PlusIcon from "./../../../assets/Images/plus_icon.png";
import MinusIcon from "./../../../assets/Images/minus_icon.png";
import Button from "reactstrap-button-loader";
import { useSelector } from "react-redux";
// import { isKeyAvailable } from "../../../helpers/Utility";

function productQuantity({
  quantityNumber,
  handleProductQuntity,
  cartProductDetailId,
  isUpdateQuantityRequesting,
  costPriceWithoutGST,
  IGSTRate,
  CGSTRate,
  SGSTRate,
  quantityData,
  index,
  errorHandler,
  availableStockQuantity,
  maxLimitPerVariant,
  isPlaceOrderRequesting,
  isFree,
}) {
  const t = useTranslation();
  const [newQuantityNumber, setNewQuantityNumber] = useState(0);
  const [updateQuanity, setUpdateQuanity] = useState(false);
  const [dcreaseButton, setDcreaseButton] = useState(true);
  const [increaseButton, setIncreaseButton] = useState(true);
  const [invalidQuantity, setInvalidQuantity] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [cartUpdateId, setCartUpdateId] = useState(0);
  const [IGSTRateData, setIGSTRateData] = useState();
  const [CGSTRateData, setCGSTRateData] = useState();
  const [SGSTRateData, setSGSTRateData] = useState();
  const [updateRequesting, setUpdateRequesting] = useState(false);
  const [prodId, setProdId] = useState();
  const [updateButtunEnable, setUpdateButtonEnable] = useState();
  const [maximumQuantity, setMaximumQuantity] = useState();
  const [defaultQunatityValue, setDefaultQunatityValue] = useState(0);

  const [availabileQuantity] = useState(availableStockQuantity);

  const nextProps = useSelector((state) => ({
    cartItemsData: state.Cart || null,
  }));
  const { isDeleteCartItemsRequesting } = nextProps.cartItemsData;
  useEffect(() => {
    errorHandler(index, invalidQuantity);
  }, [invalidQuantity]);
  useEffect(() => {
    // setMaximumQuantity(parseInt(isKeyAvailable("MaxQuantityPerProduct")));
    setMaximumQuantity(maxLimitPerVariant);
    if (newQuantityNumber > 1 && newQuantityNumber <= maximumQuantity) {
      setDcreaseButton(true);
      setIncreaseButton(true);
    }
  }, []);
  useEffect(() => {
    setUpdateButtonEnable(quantityData);
  }, [quantityData]);

  useEffect(() => {
    // setUpdateButtonEnable([]);
    setUpdateQuanity(false);
    setInvalidQuantity(false);
  }, [isDeleteCartItemsRequesting]);

  useEffect(() => {
    if (isPlaceOrderRequesting) {
      setDcreaseButton(!isPlaceOrderRequesting);
      setIncreaseButton(!isPlaceOrderRequesting);
    }
  }, [isPlaceOrderRequesting]);

  useEffect(() => {
    setProdId(cartProductDetailId);
  }, [cartProductDetailId]);
  useEffect(() => {
    setUpdateRequesting(isUpdateQuantityRequesting);
  }, [isUpdateQuantityRequesting]);

  useEffect(() => {
    setIGSTRateData(IGSTRate);
  }, [IGSTRate]);

  useEffect(() => {
    setCGSTRateData(IGSTRate);
    setSGSTRateData(SGSTRate);
  }, [CGSTRate, SGSTRate]);

  useEffect(() => {
    setNewQuantityNumber(quantityNumber);
    setDefaultQunatityValue(quantityNumber);
    if (quantityNumber > 1 && quantityNumber <= maximumQuantity) {
      setDcreaseButton(true);
      setIncreaseButton(true);
    }
  }, [quantityNumber]);

  useEffect(() => {
    if (updateRequesting && prodId == cartUpdateId) {
      setUpdateLoading(true);
    }
    if (!updateRequesting && prodId == cartUpdateId) {
      setUpdateLoading(false);
    }
  }, [updateRequesting]);

  const handleInputQuantity = (e) => {
    if (e <= 0) {
      setDcreaseButton(false);
      setInvalidQuantity(true);
      setUpdateQuanity(false);
      setIncreaseButton(true);
    } else if (e == 1) {
      setDcreaseButton(false);
      setInvalidQuantity(false);
      setUpdateQuanity(true);
      setIncreaseButton(true);
    } else {
      setDcreaseButton(true);
      setInvalidQuantity(false);
      setIncreaseButton(true);
      setUpdateQuanity(true);
    }
    if (e == 100) {
      setDcreaseButton(true);
      setInvalidQuantity(false);
      setIncreaseButton(false);
      setUpdateQuanity(true);
    }
    if (e > availabileQuantity || e > maximumQuantity) {
      setDcreaseButton(true);
      setInvalidQuantity(true);
      setIncreaseButton(false);
    }
    if (e > availabileQuantity || e > maximumQuantity) {
      setInvalidQuantity(true);
      setUpdateQuanity(false);
      setIncreaseButton(false);
      setDcreaseButton(true);
      setNewQuantityNumber(e);
      return;
    }
    setNewQuantityNumber(parseInt(e));
  };

  const validateQuantity = (calculate) => {
    if (calculate == "decrease" && newQuantityNumber > 2) {
      setDcreaseButton(true);
      setIncreaseButton(true);
      if (
        newQuantityNumber > availabileQuantity + 1 ||
        newQuantityNumber > maximumQuantity + 1
      ) {
        setInvalidQuantity(true);
      } else if (
        newQuantityNumber <= availabileQuantity ||
        newQuantityNumber <= maximumQuantity
      ) {
        setInvalidQuantity(false);
      }
    } else if (calculate == "decrease" && newQuantityNumber == 2) {
      setDcreaseButton(false);
      setInvalidQuantity(false);
    }

    if (calculate == "increase" && newQuantityNumber < maximumQuantity) {
      setDcreaseButton(true);
      setIncreaseButton(true);
      if (newQuantityNumber >= availabileQuantity) {
        setInvalidQuantity(true);
      } else if (newQuantityNumber >= 0) {
        setInvalidQuantity(false);
      }
    } else if (
      calculate == "increase" &&
      newQuantityNumber >= maximumQuantity
    ) {
      setDcreaseButton(true);
      setIncreaseButton(false);
      if (
        newQuantityNumber >= availabileQuantity ||
        newQuantityNumber >= maximumQuantity
      ) {
        setInvalidQuantity(true);
      }
    }
  };
  const handleQuantity = (calcualte) => {
    if (calcualte == "decrease" && newQuantityNumber <= 1) {
      setDcreaseButton(false);
      return;
    }
    if (
      calcualte == "increase" &&
      (newQuantityNumber > maximumQuantity ||
        newQuantityNumber > availabileQuantity)
    ) {
      setIncreaseButton(false);
      setInvalidQuantity(true);
      return;
    }
    validateQuantity(calcualte);
    let newQuantity;
    if ("decrease" === calcualte) {
      newQuantity = parseInt(parseInt(newQuantityNumber) - 1);
    }
    if ("increase" === calcualte) {
      newQuantity = parseInt(newQuantityNumber) + 1;
    }
    if (newQuantity != quantityNumber || newQuantity != defaultQunatityValue) {
      setUpdateQuanity(true);
    } else {
      setUpdateQuanity(false);
    }
    setNewQuantityNumber(parseInt(newQuantity));
  };

  const handleUpdate = (data) => {
    if (
      newQuantityNumber == 0 ||
      newQuantityNumber == undefined ||
      newQuantityNumber == null
    ) {
      return;
    }
    if (
      newQuantityNumber > availabileQuantity ||
      newQuantityNumber > maximumQuantity
    ) {
      setInvalidQuantity(true);
      return;
    } else {
      setInvalidQuantity(false);
    }

    setDefaultQunatityValue(newQuantityNumber);
    setCartUpdateId(data);
    handleProductQuntity(
      newQuantityNumber,
      prodId,
      costPriceWithoutGST,
      IGSTRateData,
      CGSTRateData,
      SGSTRateData
    );
  };

  //checking quntity is updated ??
  const isUpdateQuantity = (cartId) => {
    let tempData = updateButtunEnable?.find(
      (item) => cartId == item?.detailsId
    );
    if (
      tempData != undefined &&
      tempData?.productQuantitity == newQuantityNumber
    ) {
      return false;
    } else {
      return true;
    }
  };
  document.addEventListener("keypress", function (e) {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  });
  return (
    <>
      <div className="product-quantity">
        <span className="product-quantity-box">
          <Form>
            <FormGroup>
              <div className="d-flex flex-column">
                <div className=" direction-ltr d-flex justify-content-end">
                  <div className="mt-1">
                    <span
                      onClick={() => {
                        handleQuantity("decrease");
                      }}
                      className={
                        dcreaseButton && !isFree
                          ? "minus-icon"
                          : "minus-icon-diabled"
                      }
                    >
                      <img src={MinusIcon} />
                    </span>
                    <Input
                      type="number"
                      value={newQuantityNumber}
                      className="purchase-product-quantity-number"
                      onChange={(e) => handleInputQuantity(e.target.value)}
                      invalid={invalidQuantity}
                      disabled={isPlaceOrderRequesting || isFree}
                    />
                    <span
                      onClick={() => {
                        handleQuantity("increase");
                      }}
                      className={
                        increaseButton && !isFree
                          ? "plus-icon"
                          : "plus-icon-diabled"
                      }
                    >
                      <img src={PlusIcon} />
                    </span>
                  </div>

                  <span className="update-order-button-span">
                    {updateQuanity &&
                    isUpdateQuantity(prodId) &&
                    !invalidQuantity &&
                    !isPlaceOrderRequesting ? (
                      <Button
                        className="update-order"
                        id={prodId}
                        // loading={updateLoading}
                        onClick={() => {
                          handleUpdate(prodId);
                        }}
                      >
                        {t("ORDER.UPDATE")}
                      </Button>
                    ) : (
                      <Button className="update-order" disabled>
                        {t("ORDER.UPDATE")}
                      </Button>
                    )}
                  </span>
                </div>
                <div className="formdata-error">
                  <FormFeedback>
                    {invalidQuantity && newQuantityNumber > 0
                      ? newQuantityNumber > maximumQuantity
                        ? t("ORDER.COLOR_QUANTITY_LIMIT_ERROR_MESSAGE")
                        : t("ORDER.WE_ARE_SORRY") +
                          ` ${availableStockQuantity}` +
                          t("ORDER.ITEMS_LEFT")
                      : ""}
                  </FormFeedback>
                </div>
              </div>
            </FormGroup>
          </Form>
        </span>
      </div>
      {console.log(updateLoading, updateQuanity)}
    </>
  );
}

export default productQuantity;
productQuantity.propTypes = {
  quantityNumber: PropTypes.number,
  inputQuantity: PropTypes.func,
  cartProductDetailId: PropTypes.number,
  isUpdateQuantityRequesting: PropTypes.bool,
  costPriceWithoutGST: PropTypes.any,
  IGSTRate: PropTypes.any,
  CGSTRate: PropTypes.any,
  SGSTRate: PropTypes.any,
  availableStockQuantity: PropTypes.number,
  maxLimitPerVariant: PropTypes.number,
  isPlaceOrderRequesting: PropTypes.bool,
  isFree: PropTypes.bool,
};
