import { AXIOS_INSTANCE, BASE_URL } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { ADDRESS_DETAILS } from "./adressDetailsConst";
import {
  getAddressDetailsSuccess,
  getAddressDetailsFailed,
} from "./addressDetailsActions";
import { HomeClient } from "../apibenfranklin";

const client = new HomeClient(`${BASE_URL}`, AXIOS_INSTANCE);

const agetAddressDetailsApiRequest = async () => {
  let response = {};
  try {
    const data = await client.getCorporateDetails();
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getAddressDetails(action) {
  try {
    const apiResponse = yield call(
      agetAddressDetailsApiRequest,
      action.payload
    );
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getAddressDetailsSuccess(responseData));
    } else {
      // unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getAddressDetailsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAddressDetailsFailed(errorData));
  }
}

export function* watchAddressDetails() {
  yield takeEvery(
    ADDRESS_DETAILS.ADDRESS_DETAILS_REQUESTING,
    getAddressDetails
  );
}

function* addressDetailsSaga() {
  yield all([fork(watchAddressDetails)]);
}

export default addressDetailsSaga;
