import React, { useEffect } from "react";
import { Container } from "reactstrap";
import BreadCrumbs from "../../components/BreadCrumbs";
import ProductList from "./ProductList";
import { useHistory } from "react-router-dom";
import { getToken } from "../../helpers/Utility";
import { getCartItemsCount } from "../../redux/cartCount/cartCountActions";
import { useDispatch, useSelector } from "react-redux";
import { getAllProduct } from "../../redux/expressCheckout/expressCheckoutActions";
import { getCartItems } from "../../redux/cart/cartActions";

function ExpressCheckout() {
  // done by mani
  document.title = `Eyegear Express-Checkout`;
  const history = useHistory();
  const dispatch = useDispatch();

  const nextProps = useSelector((state) => ({
    UserProfileData: state.UserProfile.profileData?.data?.userProfile || null,
  }));
  const salesExecutive = "salesexecutive";

  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    if (!getToken()) {
      history.push("/login");
    }
    dispatch(getAllProduct());
  }, []);

  useEffect(() => {
    let data = {
      pageNumber: 1,
    };
    if (nextProps.UserProfileData) {
      if (
        nextProps.UserProfileData?.roleName?.toLowerCase().replace(/ /g, "") ===
          salesExecutive &&
        localStorage.getItem("retailer")
      ) {
        const email = JSON.parse(localStorage.getItem("retailer"))?.Email;
        data.email = email;
        dispatch(getCartItemsCount(email));
      } else {
        dispatch(getCartItemsCount());
      }
      dispatch(getCartItems(data));
    }
  }, [nextProps.UserProfileData]);

  let breadCrumbsdata = [
    {
      name: "Express Checkout",
      value: "/express-checkout",
    },
  ];

  return (
    <>
      <div className="express-checkout-body">
        <Container
          fluid={true}
          className="footer-section-container pb-4 container-fluid "
        >
          <div className="pt-4 exprees-checkout-breadcrumb">
            <BreadCrumbs defaultBreadCrumbsdata={breadCrumbsdata} />
          </div>
          <div className="express-checkout-product-list">
            <ProductList />
          </div>
        </Container>
      </div>
    </>
  );
}

export default ExpressCheckout;
