/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
// cr-change: created new component for the heading and content UI
import React from "react";
import "./HeadingAndContentComponent.scss";

const HeadingAndContentComponent = (props) => {
  return (
    <>
      <div className="heading">{props.title}</div>
      {props.paras.map((para) => {
        return <p>{para}</p>;
      })}
    </>
  );
};

export default HeadingAndContentComponent;
