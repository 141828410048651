import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import PropTypes from "prop-types";

import { useTranslation } from "react-multi-lang";

const SortModal = ({
  sortPopUp,
  isSortPopUp,
  sortTypesData,
  sortModaSelectedIndex,
  handleSelectedSortType,
  getSortModalSelectedIndex,
}) => {
  const t = useTranslation();
  const [modal, setModal] = useState(false);
  const [sortTypeList, setSortTypeList] = useState([]);
  const [defaultOption, setDefaultOption] = useState();
  const toggle = () => {
    isSortPopUp(!modal);
    setModal(!modal);
  };
  useEffect(() => {
    setDefaultOption(sortModaSelectedIndex);
  }, []);
  useEffect(() => {
    setModal(sortPopUp);
  }, [sortPopUp]);

  useEffect(() => {
    if (sortTypesData) {
      setSortTypeList(sortTypesData);
    }
  }, [sortTypesData]);

  const handleSortOption = (e) => {
    setDefaultOption(e.target.name);
    getSortModalSelectedIndex(e.target.name);
    toggle();
    handleSelectedSortType(e.target.value);
  };

  const sortingOption = () => {
    return sortTypeList?.map((sortData, index) => {
      return (
        <>
          <div className="sort-price-checkbox" key={index}>
            <p>{sortData.sortTypeName}</p>
            <Input
              type="radio"
              className="sort-checkbox"
              name={index}
              value={sortData.sortKey}
              checked={defaultOption == index ? true : false}
              onChange={handleSortOption}
            />
          </div>
        </>
      );
    });
  };

  return (
    <div className="filter-sort-section">
      <Modal isOpen={modal} toggle={toggle} className="sort-modal d-none">
        <ModalHeader toggle={toggle} className="sort-header">
          {t("SORT.SORT_BY")}
        </ModalHeader>
        <ModalBody className="sort-body">{sortingOption()}</ModalBody>
        <ModalFooter className="sort-footer">
          <Button className="sort-footer-button" onClick={toggle}>
            {t("SORT.CLOSE")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SortModal;

SortModal.propTypes = {
  sortPopUp: PropTypes.bool.isRequired,
  isSortPopUp: PropTypes.func.isRequired,
  sortTypesData: PropTypes.array.isRequired,
  handleSelectedSortType: PropTypes.func.isRequired,
  sortModaSelectedIndex: PropTypes.any,
  getSortModalSelectedIndex: PropTypes.func.isRequired,
};
