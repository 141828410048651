import "./FooterComponent.scss";
import "../../assets/CSS/preLoginStyles.scss";

import { useDispatch, useSelector } from "react-redux";

import Logo from "../../assets/Images/S-logo-footer.png";
import React from "react";
import { useHistory } from "react-router-dom";
import { windowTabSave } from "../../redux/loginTabDuplicate/loginTabAction";

//import { handleLoginDuplicate } from "../../common/alertNotifications";

function FooterComponent() {
  const history = useHistory();
  const windowTab = useSelector((state) => state.loginTabReducer);
  const dispatch = useDispatch();
  return (
    <div className="footer font_white">
      <div className="footer_about just-space-btw">
        <ul className="footer_list">
          <li onClick={() => history.push("/about")} className="footerLinks">
            About Eyegear
          </li>
          {/* <li onClick={() => navigate("/brands")}>Eyegear Platform</li> */}
          <li onClick={() => history.push("/careers")} className="footerLinks">
            Careers
          </li>
          <li
            onClick={() => history.push("/news-room")}
            className="footerLinks"
          >
            News Room
          </li>
          <li
            style={{ display: "none" }}
            onClick={() => {
              history.push("/blogs");
              window.scrollTo(0, 0);
            }}
            className="footerLinks"
          >
            Blogs
          </li>
          <li className="footerLinks">
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="#"
              onClick={(event) => {
                event.preventDefault();
                if (Object.keys(windowTab).length > 0) {
                  windowTab.focus();
                } else {
                  window.localStorage.setItem("isTab", "true");
                  let windowtab = window.open(
                    `${window.location.origin}/login`
                  );
                  dispatch(windowTabSave(windowtab));
                }
                //handleLoginDuplicate("login");
              }}
              target="_blank"
            >
              Partner Log-in
            </a>{" "}
          </li>
          <li
            onClick={() => {
              history.push("/contact");
            }}
            className="footerLinks"
          >
            Contact Us
          </li>
        </ul>
        <div className="address_block">
          <img
            src={Logo}
            className="header_logo footer__logo"
            onClick={() => history.push("/")}
            alt="Logo"
            style={{ width: "100%", height: "100%" }}
          />
          <div className="address_para">
            {/* cr-change: footer address alignment */}
            <p> Eyegear Optics India Pvt. Ltd.,</p>
            <p>No 7-1-69/1/25/11 & 13,</p>
            <p>Above Urbankisaan store, </p>
            <p>Dharam Karan Road,</p>
            <p> Hyderabad - 500082, Telangana.</p>
            <p>Tel: 04046461111, 04042071111</p>
          </div>
        </div>
      </div>
      <div className="just-space-btw footer_cc">
        <div className="policy_links_section">
          <p
            className="policy_link"
            onClick={() => history.push("/privacy-policy")}
          >
            Privacy Policy
          </p>
          <p className="pipe"> | </p>
          <p
            className="policy_link"
            onClick={() => history.push("/cookie-policy")}
          >
            Cookie Policy
          </p>
        </div>
        <p style={{ marginRight: "5px" }}>
          © Eyegear Optics India Pvt Ltd. {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
}

export default FooterComponent;
