import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-multi-lang";
import PropTypes from "prop-types";

const SwitchUserModal = ({
  switchModal,
  user,
  confirmSwitchUser,
  isSalesExecutive,
}) => {
  const [modal, setModal] = useState(false);
  const t = useTranslation();
  useEffect(() => {
    setModal(setModal);
  }, [switchModal]);

  const toggle = () => setModal(!modal);
  const okToSwitch = () => {
    if (isSalesExecutive) {
      confirmSwitchUser();
    } else {
      confirmSwitchUser(user);
    }
    setModal(!modal);
  };
  const cancelSwitch = () => {
    setModal(!modal);
  };

  return (
    <div>
      <div>
        {user.id === JSON.parse(localStorage.getItem("retailer"))?.Id ? (
          <Button className="switch-user-btn disabled">
            {" "}
            {t("PROFILE.CURRENT")}
          </Button>
        ) : (
          <Button
            className="switch-user-btn"
            onClick={() => {
              toggle();
            }}
          >
            {" "}
            {t("PROFILE.SWITCH")}
          </Button>
        )}
      </div>
      <Modal isOpen={modal} toggle={toggle} className="">
        <ModalHeader
          toggle={toggle}
          className="p-0"
          style={{ border: "none" }}
        />
        <ModalBody className="order-delete">
          <h5>
            {t("PROFILE.SWITCH_USER_TEXT")}
            {`"${user.businessName}"`}
          </h5>
        </ModalBody>
        <ModalFooter style={{ border: "none" }}>
          <Button
            className="ok-button"
            onClick={() => {
              okToSwitch();
            }}
          >
            {t("PRODUCT_DETAILS.OK")}
          </Button>{" "}
          <Button className="cancel-button" onClick={() => cancelSwitch()}>
            {t("PRODUCT_DETAILS.CANCEL")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SwitchUserModal;

SwitchUserModal.propTypes = {
  confirmSwitchUser: PropTypes.any,
  user: PropTypes.object,
  switchModal: PropTypes.bool,
  isSalesExecutive: PropTypes.bool,
};
