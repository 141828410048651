import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import ViewAllIcon from "../../assets/Images/view_all_icon.png";
export default function CarouselHeader({ data, viewAll }) {
  const { title, view_all } = data;
  const history = useHistory();
  return (
    <>
      <div>
        <Container fluid={true} className="p-0 ">
          <Row>
            <Col xs="8" sm="9" md="9">
              <p className="material-caption">{title}</p>
            </Col>
            <Col xs="4" sm="3" md="3">
              <span
                className="view_all_col"
                onClick={() => history.push(`/products?${viewAll || ""}`)}
              >
                <img src={ViewAllIcon} className="view_icon" />
                <p className="view_all">{view_all}</p>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

CarouselHeader.propTypes = {
  data: PropTypes.object,
  viewAll: PropTypes.string,
};
