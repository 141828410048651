import { SHAPES_CONST } from "./shapesConst";

const shapes = (state = {}, action) => {
  switch (action.type) {
    case SHAPES_CONST.SHAPES_CONST_REQUEST:
      return {
        ...state,
        isShapesRequesting: true,
        shapesData: false,
        shapesDataError: false,
      };
    case SHAPES_CONST.SHAPES_CONST_SUCCESS:
      return {
        ...state,
        isShapesRequesting: false,
        shapesData: action.payload.response,
        shapesDataError: false,
      };
    case SHAPES_CONST.SHAPES_CONST_FAILED:
      return {
        ...state,
        isShapesRequesting: false,
        shapesData: false,
        shapesDataError: action.payload.error ? false : action.payload.response,
      };

    default:
      return { ...state };
  }
};

export default shapes;
