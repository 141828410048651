import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BreadCrumbs from "../../components/BreadCrumbs";
import { Container } from "reactstrap";
import Loader from "../../common/Loader";
import ProductInfo from "../../components/ProductInfo";
import classnames from "classnames";
import { getCartItems } from "../../redux/cart/cartActions";
import { getCartItemsCount } from "./../../redux/cartCount/cartCountActions";
import { getInventory } from "../../redux/inventory/inventoryAction";
import { getProductById } from "../../redux/filters/filterActions";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-multi-lang";

export default function ProductDetails() {
  const [activeTab, setActiveTab] = useState("1");
  const [productCardData, setProductCardData] = useState();
  const [activeColorIndex, setActiveColorIndex] = useState(0);
  const dispatch = useDispatch();
  const t = useTranslation();
  const salesExecutive = "salesexecutive";
  let breadCrumbsdata = [
    {
      name: "All Frames & Sunglasses",
      value: "/products",
    },
    {
      name: productCardData && productCardData.productName,
      value: "/product-details",
    },
  ];
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  let paramsData = window.location.search
    .slice(1, window.location.search.length)
    .split("=")[1];
  const nextProps = useSelector((state) => ({
    ProductsData: state.FilterOption.getProductByIdData || null,
    ProductsLoader: state.FilterOption.isGetProductByRequesting,
    UserProfileData: state.UserProfile.profileData?.data?.userProfile || null,
  }));

  useEffect(() => {
    dispatch(getProductById({ id: paramsData }));
    dispatch(getInventory({ productId: paramsData }));
  }, []);

  useEffect(() => {
    let data = {
      pageNumber: 1,
    };
    if (nextProps.UserProfileData) {
      if (
        nextProps.UserProfileData?.roleName?.toLowerCase().replace(/ /g, "") ===
          salesExecutive &&
        localStorage.getItem("retailer")
      ) {
        const email = JSON.parse(localStorage.getItem("retailer"))?.Email;
        data.email = email;
        dispatch(getCartItemsCount(email));
      } else {
        dispatch(getCartItemsCount());
      }
      dispatch(getCartItems(data));
    }
  }, [nextProps.UserProfileData]);

  useEffect(() => {
    if (
      nextProps.ProductsData &&
      nextProps.ProductsData.data &&
      nextProps.ProductsData.data.searchResults &&
      nextProps.ProductsData.data.searchResults
    ) {
      let productItem = nextProps.ProductsData.data.searchResults;
      if (typeof productItem.productColor !== "string")
        productItem.productColor = JSON.stringify(productItem.productColor);
      //  done by mani
      document.title = `Eyegear - ${productItem.productName}`;
      setProductCardData(productItem);
    }
  }, [nextProps.ProductsData]);

  const handleActiveColorIndex = (value) => {
    setActiveColorIndex(value);
  };
  // done by mani
  document.addEventListener("visibilitychange", (event) => {
    event.preventDefault();
    if (!document.hidden) {
      let data = {
        pageNumber: 1,
      };
      const email = JSON.parse(localStorage.getItem("retailer"))?.Email;
      data.email = email;
      dispatch(getCartItemsCount());
      dispatch(getCartItems(data));
    }
    event.stopImmediatePropagation();
  });
  //done by mani
  let url = window.location.href;
  const history = useHistory();
  const duplicateTabEvent = () => {
    let index = url.indexOf("=");
    let itemId = url.substring(index + 1);
    let itemIdArr = [];
    itemIdArr = localStorage.getItem("itemIdArr").split(",");
    itemIdArr = itemIdArr.filter((x) => {
      return x != "";
    });
    if (itemIdArr.includes(itemId)) {
      let newIndex = itemIdArr.indexOf(itemId);
      itemIdArr.splice(newIndex, 1);
      localStorage.setItem("itemIdArr", itemIdArr);
    } else {
      return true;
    }
  };
  //mani
  useEffect(() => {
    const unlisten = history.listen(() => {
      duplicateTabEvent();
    });
    // stop the listener when component unmounts
    return unlisten;
  }, []);
  // done by mani
  window.addEventListener("beforeunload", function (e) {
    e.preventDefault();
    duplicateTabEvent();
    e.stopImmediatePropagation();
  });
  return (
    <>
      {nextProps.ProductsLoader ? (
        <div className="custom-loader">
          <Loader width="50" height="50" />
        </div>
      ) : (
        <>
          <Container>
            <div className="pt-4">
              <BreadCrumbs defaultBreadCrumbsdata={breadCrumbsdata} />
            </div>
            {nextProps.ProductsData ? (
              <ProductInfo
                productData={productCardData}
                fromDetails={true}
                activeColorProductIndex={handleActiveColorIndex}
              />
            ) : (
              ""
            )}
          </Container>
          <div className="product-details-tabs">
            <Container>
              <Row>
                <div className="tab-label">
                  <Nav tabs>
                    <NavItem className="product-nav-item">
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        <label className="inDetail-info-label">
                          {t("PRODUCT_DETAILS.TECHNICAL_INFO")}
                        </label>
                        <hr
                          className={
                            activeTab === "1" ? "hr-tag-active" : "hr-tag"
                          }
                        />
                      </NavLink>
                    </NavItem>
                    <NavItem className="product-nav-item">
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        <label className="inDetail-info-label">
                          {t("PRODUCT_DETAILS.ABOUT_PRODUCT")}
                        </label>
                        <hr
                          className={
                            activeTab === "2" ? "hr-tag-active" : "hr-tag"
                          }
                        />
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row className="tab-row">
                        <Col sm="6" md="6" lg="6">
                          <div>
                            <ul>
                              <li>
                                <span className="product-detail-label">
                                  {t("PRODUCT_DETAILS.BRAND_NAME")}
                                </span>
                                <span className="inDetail-info-value">
                                  {" "}
                                  {productCardData &&
                                    (productCardData.brandName || "N/A")}
                                </span>
                              </li>
                              <li>
                                <span className="product-detail-label">
                                  {t("PRODUCT_DETAILS.PRODUCT_TYPE")}
                                </span>
                                <span className="inDetail-info-value">
                                  {productCardData &&
                                    (productCardData.productType || "N/A")}
                                </span>
                              </li>
                              {productCardData &&
                              productCardData.productTypeId === 4 ? (
                                ""
                              ) : (
                                <li>
                                  <span className="product-detail-label">
                                    {t("PRODUCT_DETAILS.FRAME_TYPE")}
                                  </span>
                                  <span className="inDetail-info-value">
                                    {productCardData &&
                                      (productCardData.frameType || "N/A")}
                                  </span>
                                </li>
                              )}
                              {productCardData &&
                              productCardData.productTypeId === 4 ? (
                                ""
                              ) : (
                                <li>
                                  <span className="product-detail-label">
                                    {t("PRODUCT_DETAILS.FRAME_SHAPE")}
                                  </span>
                                  <span className="inDetail-info-value">
                                    {productCardData &&
                                      (productCardData.frameShape || "N/A")}
                                  </span>
                                </li>
                              )}
                              <li>
                                <span className="product-detail-label">
                                  {t("PRODUCT_DETAILS.MANUFACTURER_DETAILS")}
                                </span>
                                <span className="inDetail-info-value">
                                  {productCardData &&
                                    (productCardData.manufacturerDetails ||
                                      "N/A")}
                                </span>
                              </li>
                              <li>
                                <span className="product-detail-label">
                                  {t("PRODUCT_DETAILS.MODEL_NO")}
                                </span>
                                <span className="inDetail-info-value">
                                  {productCardData &&
                                    (productCardData.modelCode || "N/A")}
                                </span>
                              </li>
                              {productCardData &&
                              productCardData.productTypeId === 4 ? (
                                ""
                              ) : (
                                <li>
                                  <span className="product-detail-label">
                                    {t("PRODUCT_DETAILS.FRAME_WIDTH")}
                                  </span>
                                  <span className="inDetail-info-value">
                                    {productCardData &&
                                      (productCardData.frameWidth || "N/A")}
                                  </span>
                                </li>
                              )}
                              {productCardData &&
                              productCardData.productTypeId === 4 ? (
                                ""
                              ) : (
                                <li>
                                  <span className="product-detail-label">
                                    {t("PRODUCT_DETAILS.FRAME_DIMENSIONS")}
                                  </span>
                                  <span className="inDetail-info-value">
                                    {productCardData &&
                                      `${productCardData.eyeSize + "-"}` +
                                        `${
                                          productCardData.noseBridgeSize + "-"
                                        }` +
                                        `${productCardData.armLength}`}
                                  </span>
                                </li>
                              )}
                              {productCardData &&
                              productCardData.productTypeId === 4 ? (
                                ""
                              ) : (
                                <li>
                                  <span className="product-detail-label">
                                    {t("PRODUCT_DETAILS.FRAME_COLOR")}
                                  </span>
                                  <span className="inDetail-info-value">
                                    {productCardData &&
                                      (productCardData.frameColor[
                                        activeColorIndex
                                      ] ||
                                        "N/A")}
                                  </span>
                                </li>
                              )}
                            </ul>
                          </div>
                        </Col>
                        <Col sm="6" md="6" lg="6">
                          <div>
                            <ul>
                              {productCardData &&
                              productCardData.productTypeId === 4 ? (
                                ""
                              ) : (
                                <li>
                                  <span className="product-detail-label">
                                    {t("PRODUCT_DETAILS.WEIGHT")}
                                  </span>
                                  <span className="inDetail-info-value">
                                    {productCardData &&
                                      (productCardData.weight || "N/A")}
                                  </span>
                                </li>
                              )}
                              {productCardData &&
                              productCardData.productTypeId === 4 ? (
                                ""
                              ) : (
                                <li>
                                  <span className="product-detail-label">
                                    {t("PRODUCT_DETAILS.WEIGHT_GROUP")}
                                  </span>
                                  <span className="inDetail-info-value">
                                    {productCardData &&
                                      (productCardData.weightGroup || "N/A")}
                                  </span>
                                </li>
                              )}
                              {productCardData &&
                              productCardData.productTypeId === 4 ? (
                                ""
                              ) : (
                                <li>
                                  <span className="product-detail-label">
                                    {t("PRODUCT_DETAILS.FRAME_MATERIAL")}
                                  </span>
                                  <span className="inDetail-info-value">
                                    {productCardData &&
                                      (productCardData.frameMaterialName ||
                                        "N/A")}
                                  </span>
                                </li>
                              )}
                              {productCardData &&
                              productCardData.productTypeId === 4 ? (
                                ""
                              ) : (
                                <li>
                                  <span className="product-detail-label">
                                    {t("PRODUCT_DETAILS.TEMPLE_MATERIAL")}
                                  </span>
                                  <span className="inDetail-info-value">
                                    {productCardData &&
                                      (productCardData.frameMaterialName ||
                                        "N/A")}
                                  </span>
                                </li>
                              )}
                              {productCardData &&
                              productCardData.productTypeId === 4 ? (
                                ""
                              ) : (
                                <li>
                                  <span className="product-detail-label">
                                    {t("PRODUCT_DETAILS.COLLECTION")}
                                  </span>
                                  <span className="inDetail-info-value">
                                    {productCardData &&
                                      ((productCardData.tags &&
                                        JSON.parse(productCardData.tags)[0]) ||
                                        "N/A")}
                                  </span>
                                </li>
                              )}
                              {productCardData &&
                              productCardData.productTypeId === 4 ? (
                                ""
                              ) : (
                                <li>
                                  <span className="product-detail-label">
                                    {t("PRODUCT_DETAILS.PRODUCT_WARRANTY")}
                                  </span>
                                  <span className="inDetail-info-value">
                                    {productCardData &&
                                      (productCardData.productWarranty ||
                                        "N/A")}
                                  </span>
                                </li>
                              )}
                              {productCardData &&
                              productCardData.productTypeId === 4 ? (
                                ""
                              ) : (
                                <li>
                                  <span className="product-detail-label">
                                    {t("PRODUCT_DETAILS.GENDER")}
                                  </span>
                                  <span className="inDetail-info-value">
                                    {productCardData &&
                                      (productCardData.gender[0] || "N/A")}
                                  </span>
                                </li>
                              )}
                            </ul>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row className="tab-row">
                        <Col>
                          <span className="product-detail-label">
                            {t("PRODUCT_DETAILS.DESCRIPTION")}
                          </span>
                          <span className="inDetail-info-value">
                            {productCardData &&
                              (productCardData.description || "N/A")}
                          </span>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              </Row>
            </Container>
          </div>
        </>
      )}
    </>
  );
}
