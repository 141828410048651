import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import CarouselHeader from "../../../components/CategoryHeader";
import Frames from "../../../components/Frames";
import PropTypes from "prop-types";
import Slider from "../../../components/CarouselWrapper";
import { t } from "react-multi-lang";

export default function Price({ pricesData, priceRangeBannerData, priceKey }) {
  const [range, setRange] = useState();
  const data = {
    title: t("HOME.PRICE_TEXT"),
    view_all: t("HOME.VIEW_ALL"),
  };
  useEffect(() => {
    let priceRange = [];
    if (pricesData !== undefined) {
      pricesData.map((items) => {
        priceRange.push({
          frameId: items.id,
          frameMinPrice: items.min,
          frameMaxPrice: items.max,
          price: items.price,
          images: items.priceRangeImages,
        });
      });
    }
    setRange(priceRange);
  }, [pricesData]);

  const handleImage = (item, index) => {
    return item && item.length > 0 && (item[index]?.priceRangeUrl || "");
  };

  return (
    <div>
      <Container
        fluid={true}
        className="footer-section-container material-section d-none"
      >
        <CarouselHeader data={data} />
        <Row className="price-section-mb">
          <Col xs="6" sm="6" md="12" xl="6" lg="12" className="ta-section">
            <Row className="shape-section">
              {range && range.length > 0
                ? range.map((item) => (
                    <Col
                      xs="6"
                      sm="6"
                      md="6"
                      className="mb-section"
                      key={item.frameId}
                    >
                      <Frames
                        frameMinPrice={item.frameMinPrice}
                        frameMaxPrice={item.frameMaxPrice}
                        label={item.price || ""}
                        imageOne={handleImage(item.images, 0)}
                        imageTwo={handleImage(item.images, 1)}
                        frameKey={priceKey}
                        id={item.frameId}
                      />
                    </Col>
                  ))
                : ""}
            </Row>
          </Col>
          <Col xl="6" lg="12" md="12">
            <Slider data={priceRangeBannerData} className="catalog-section-3" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
Price.propTypes = {
  priceRangeBannerData: PropTypes.array,
  pricesData: PropTypes.object,
  priceKey: PropTypes.string,
};
