import { MY_ORDER_CONST } from "./orderConst";

/**
 *  User my Orders
 */
export const getMyOrders = (formProps) => ({
  type: MY_ORDER_CONST.MY_ORDER_REQUESTING,
  payload: formProps || null,
});

export const getMyOrdersSuccess = (response) => ({
  type: MY_ORDER_CONST.MY_ORDER_SUCCESS,
  payload: response,
});

export const getMyOrdersFailed = (error) => ({
  type: MY_ORDER_CONST.MY_ORDER_FAILED,
  payload: error,
});

/**
 *  User my Orders details
 */
export const getOrderDetails = (formProps) => ({
  type: MY_ORDER_CONST.MY_ORDER_DETAILS_REQUESTING,
  payload: formProps || null,
});

export const getOrderDetailsSuccess = (response) => ({
  type: MY_ORDER_CONST.MY_ORDER_DETAILS_SUCCESS,
  payload: response,
});

export const getOrderDetailsFailed = (error) => ({
  type: MY_ORDER_CONST.MY_ORDER_DETAILS_FAILED,
  payload: error,
});
