import { INVENTORY } from "./inventoryConst";

export const getInventory = (payload) => ({
  type: INVENTORY.GET_INVENTORY_REQUESTING,
  payload: payload || null,
});

export const getInventorySuccess = (response) => ({
  type: INVENTORY.GET_INVENTORY_SUCCESS,
  payload: response,
});

export const getInventoryFailed = (error) => ({
  type: INVENTORY.GET_INVENTORY_FAILED,
  payload: error,
});

export const getInventoryByUser = (payload) => ({
  type: INVENTORY.GET_INVENTORY_BY_USER_REQUESTING,
  payload: payload || null,
});

export const getInventoryByUserSuccess = (response) => ({
  type: INVENTORY.GET_INVENTORY_BY_USER_SUCCESS,
  payload: response,
});

export const getInventoryByUserFailed = (error) => ({
  type: INVENTORY.GET_INVENTORY_BY_USER_FAILED,
  payload: error,
});
