import { Col, Container, Row } from "reactstrap";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LocationIcon from "../../assets/Images/location_icon.png";
import Logo from "../../assets/Images/footer_logo.png";
import MailIcon from "../../assets/Images/mail_icon.png";
import { PAGES_TYPE } from "../../helpers/Constants";
import PhoneIcon from "../../assets/Images/phone_icon.png";
import { getAddressDetails } from "../../redux/addressDetails/addressDetailsActions";
import { getPages } from "../../redux/getPages/getPagesActions";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-multi-lang";

const Footer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTranslation();
  const [genderList, setGenderList] = useState([]);
  const nextProps = useSelector((state) => ({
    isFilterData: state.FilterOption || null,
    pages: state.Pages || null,
    addressDetails: state.AddressDetails || null,
  }));

  const { filterOptionData } = nextProps.isFilterData;
  const { getPagesData } = nextProps.pages;
  const { addressDetailsData } = nextProps?.addressDetails;
  useEffect(() => {
    dispatch(getPages(PAGES_TYPE.YEAR));
    dispatch(getAddressDetails());
  }, []);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (filterOptionData) {
      filterOptionData?.filters?.map((filter) => {
        if (
          filter.type.length > 0 &&
          filter.key.length > 0 &&
          "In" === filter.type &&
          "genderIdList" === filter.key
        ) {
          setGenderList({
            key: filter.key,
            name: filter.name,
            data: filter.data,
          });
        }
      });
    }
  }, [filterOptionData]);
  const handlenavigation = (e, id) => {
    e.preventDefault();
    history.push(`/products?genderIdList=${id}`);
  };

  const generItems = () => {
    return genderList?.data?.map((item) => {
      return (
        <>
          <li key={item.id}>
            <a
              onClick={(e) => handlenavigation(e, item.id)}
              className="footerLinks"
            >
              {item.name}
            </a>
          </li>
        </>
      );
    });
  };
  return (
    <div className="footer">
      <Container fluid={true} className="footer-section-container">
        <Row className="footerBlock">
          <Col
            sm="2"
            md="2"
            className="footer-section"
            style={{ lineHeight: "2rem" }}
          >
            <Row>
              <p className="footerTitle">{t("FOOTER.PRODUCTS")}</p>
              <ul>{generItems()}</ul>
            </Row>
          </Col>
          <Col sm="2" md="2" className="footer-section d-none">
            <Row>
              <p className="footerTitle">{t("FOOTER.SUPPORT")}</p>
              <ul>
                <li>
                  <a
                    onClick={() => history.push("/support?pageName=ABOUTUS")}
                    className="footerLinks"
                  >
                    {t("FOOTER.ABOUT_US")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => history.push("/support?pageName=CONTACTUS")}
                    className="footerLinks"
                  >
                    {t("FOOTER.CONTACT_US")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      history.push("/support?pageName=ORDERTRACKING")
                    }
                    className="footerLinks"
                  >
                    {t("FOOTER.ORDER_TRACKING")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => history.push("/support?pageName=FAQS")}
                    className="footerLinks"
                  >
                    {t("FOOTER.FAQS")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      history.push("/support?pageName=AFTERSALESERVICES")
                    }
                    className="footerLinks"
                  >
                    {t("FOOTER.AFTER_SALE_SERVICES")}
                  </a>
                </li>
              </ul>
            </Row>
          </Col>
          <Col sm="2" md="2"></Col>
          <Col sm="2" md="2" className="footer-section d-none">
            <Row>
              <p className="footerTitle">{t("FOOTER.LEGAL")}</p>
              <ul>
                <li>
                  <a
                    onClick={() =>
                      history.push("/support?pageName=TERMSANDCONDITIONS")
                    }
                    className="footerLinks"
                  >
                    {t("FOOTER.TERMS_CONDITIONS")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      history.push("/support?pageName=SHIPPINGANDRETURNS")
                    }
                    className="footerLinks"
                  >
                    {t("FOOTER.SHIPPING_RETURNS")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      history.push("/support?pageName=PRODUCTWARRANTY")
                    }
                    className="footerLinks"
                  >
                    {t("FOOTER.PRODUCT_WARRANTY")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      history.push("/support?pageName=CODEOFBUSINESSCONDUCT")
                    }
                    className="footerLinks"
                  >
                    {t("FOOTER.CODE_OF_BUSSINESS")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      history.push("/support?pageName=BUSINESSETHICS")
                    }
                    className="footerLinks"
                  >
                    {t("FOOTER.BUSINESS_ETHICS")}
                  </a>
                </li>
              </ul>
            </Row>
          </Col>
          <Col sm="5" md="5">
            <Row>
              <span className="footerLogo">
                <img src={Logo} />
              </span>
              <ul className="first_col_icons">
                <li>
                  <span>
                    <img src={LocationIcon} />
                  </span>
                  <a className="footerLinks">
                    {addressDetailsData?.data?.corporateDetails?.address}
                  </a>
                </li>
                <li className="mt-3">
                  <span className="first_col_icons">
                    <img src={MailIcon} />
                  </span>
                  <a href="mailto: support@eyegear.com" className="footerLinks">
                    {addressDetailsData?.data?.corporateDetails?.emailId}
                  </a>
                </li>
                <li className="mt-2">
                  <span className="first_col_icons">
                    <img src={PhoneIcon} />
                  </span>
                  <a href="#" className="footerLinks">
                    {addressDetailsData?.data?.corporateDetails?.contactNumber}
                  </a>
                </li>
                <li className="mt-5">
                  <p>
                    © {getPagesData?.data?.year} All right reserved by Eyegear
                    Optics India Pvt. Ltd
                  </p>
                </li>
              </ul>
              {/* <p className="footerTitle">{t("FOOTER.COMPANY_NAME")}</p> */}
            </Row>
          </Col>
        </Row>
        {/* <Row className="footerBottomLine">
          <p>
            © {getPagesData?.data?.year} All right reserved by Eyegear Optics
            India Pvt. Ltd
          </p>
        </Row> */}
      </Container>
    </div>
  );
};

export default Footer;
