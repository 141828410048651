import { GET_PAGES } from "./getPagesConst";

export const getPages = (payload) => ({
  type: GET_PAGES.GET_PAGES_REQUESTING,
  payload: payload || null,
});

export const getPagesSuccess = (response) => ({
  type: GET_PAGES.GET_PAGES_SUCCESS,
  payload: response,
});

export const getPagesFailed = (error) => ({
  type: GET_PAGES.GET_PAGES_FAILED,
  payload: error,
});
