import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-multi-lang";

function DeliveryTo({ deliveryToData }) {
  const t = useTranslation();
  console.log(deliveryToData);
  return (
    <div>
      {deliveryToData?.address ? (
        <div className="delivery-section">
          <h6>{t("ORDER.DELIVER_TO")}</h6>
          <p>{deliveryToData?.address}</p>
          <p>{deliveryToData?.city}</p>
          <p>{deliveryToData?.pincode}</p>
          <p>{deliveryToData?.state}</p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default DeliveryTo;
DeliveryTo.propTypes = {
  deliveryToData: PropTypes.object,
};
