import { PLACE_ORDER } from "./placeOrderConst";

const PlaceOrder = (state = {}, action) => {
  switch (action.type) {
    case PLACE_ORDER.PLACE_ORDERR_EQUESTING:
      return {
        ...state,
        isPlaceOrderRequesting: true,
        placeOrderData: false,
        placeOrderDataError: false,
      };
    case PLACE_ORDER.PLACE_ORDER_SUCCESS:
      return {
        ...state,
        isPlaceOrderRequesting: false,
        placeOrderData: action.payload.response.data,
        placeOrderDataError: false,
      };
    case PLACE_ORDER.PLACE_ORDER_FAILED:
      return {
        ...state,
        isPlaceOrderRequesting: false,
        placeOrderData: false,
        placeOrderDataError: action.payload.error
          ? false
          : action.payload.response,
      };

    default:
      return { ...state };
  }
};

export default PlaceOrder;
