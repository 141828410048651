import { Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import {
  resetPasswordRequest,
  validateTokenRequest,
} from "../../redux/login/loginActions";
import { useDispatch, useSelector } from "react-redux";

import Button from "reactstrap-button-loader";
import Loader from "../../common/Loader";
import LocationIcon from "../../assets/Images/location_icon.png";
import Logo from "../../assets/Images/footer_logo.png";
import MailIcon from "../../assets/Images/mail_icon.png";
import PhoneIcon from "../../assets/Images/phone_icon.png";
import { getAddressDetails } from "../../redux/addressDetails/addressDetailsActions";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-multi-lang";

const Resetpassword = () => {
  // done by mani
  document.title = `Eyegear Resetpassword`;
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [isNewPasswordValidLen, setIsNewPasswordValidLen] = useState(false);
  const [isNewPasswordwithNum, setIsNewPasswordwithNum] = useState(false);
  const [isNewPasswordwithSpecialChr, setIsNewPasswordwithSpecialChr] =
    useState(false);
  const [isInvalidNewPassword, setIsInvalidNewPassword] = useState(false);
  const [isInvalidRepeatNewPassword, setInvalidRepeatNewPassword] =
    useState(false);
  const [token, setToken] = useState("");

  const t = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    setToken(params.token);
  }, []);

  useEffect(() => {
    dispatch(getAddressDetails());
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(validateTokenRequest({ Token: token }));
    }
  }, [token]);

  useEffect(() => {
    if (
      isNewPasswordValidLen &&
      isNewPasswordwithNum &&
      isNewPasswordwithSpecialChr
    ) {
      setIsInvalidNewPassword(false);
    }
  }, [
    isNewPasswordValidLen,
    isNewPasswordwithNum,
    isNewPasswordwithSpecialChr,
  ]);

  const nextProps = useSelector((state) => ({
    addressDetails: state.AddressDetails || null,
    validateTokenRequest: state.Login.validateTokenRequesting,
    tokenData: state.Login.validateTokenData?.result,
    tokenError: state.Login.validateTokenDataError?.data?.message,
    resetPasswordRequest: state.Login.resetPasswordRequesesting,
    resetPasswordData: state.Login.resetPasswordData?.data?.result,
    resetPasswordError: state.Login.resetPasswordError?.data?.message,
  }));

  const { addressDetailsData } = nextProps?.addressDetails;

  const onChangeNewPassword = (e) => {
    if (e.target.value) {
      setNewPassword(e.target.value);
    }
    if (e.target.value.length >= 8) {
      setIsNewPasswordValidLen(true);
    }
    if (e.target.value.length < 8) {
      setIsNewPasswordValidLen(false);
    }
    if (e.target.value.match("^(?=.*[0-9])") !== null) {
      setIsNewPasswordwithNum(true);
    }
    if (e.target.value.match("^(?=.*[0-9])") === null) {
      setIsNewPasswordwithNum(false);
    }
    if (e.target.value.match("(?=.*[!@#$%^&*])") !== null) {
      setIsNewPasswordwithSpecialChr(true);
    }
    if (e.target.value.match("(?=.*[!@#$%^&*])") === null) {
      setIsNewPasswordwithSpecialChr(false);
    }
  };

  const isValidNewPassword = () => {
    if (
      !isNewPasswordValidLen ||
      !isNewPasswordwithNum ||
      !isNewPasswordwithSpecialChr
    ) {
      setIsInvalidNewPassword(true);
    } else {
      setIsInvalidNewPassword(false);
    }
  };

  const onChangeRepeatPassword = (e) => {
    setRepeatNewPassword(e.target.value);
    if (newPassword === e.target.value) {
      setInvalidRepeatNewPassword(false);
    }
  };
  const handleOnBlurRepeatPassword = () => {
    if (newPassword !== repeatNewPassword) {
      setInvalidRepeatNewPassword(true);
    } else {
      setInvalidRepeatNewPassword(false);
    }
  };
  const submitPasswordForm = () => {
    isValidNewPassword();
    if (newPassword !== repeatNewPassword) {
      setInvalidRepeatNewPassword(true);
    }
    let formProps = {};
    formProps = {
      token: token,
      newPassword: newPassword,
    };
    if (
      !isInvalidNewPassword &&
      !isInvalidRepeatNewPassword &&
      newPassword &&
      repeatNewPassword
    ) {
      dispatch(resetPasswordRequest(formProps));
    }
  };
  return (
    <div>
      <div className="header">
        <Container
          fluid={true}
          className="footer-section-container header_container"
        >
          <Row>
            <Col xs="2" sm="2" md="2" className="header-logo-section">
              <div className="logo header-logo-image">
                <a>
                  <img src={Logo} />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Row className="tab-label ">
        <Col lg={2} className="text-center p-2 px-4">
          <label className="reset_password_heading">Reset Password</label>
          <hr className="reset-hr-tag" />
        </Col>
      </Row>
      <Row className="resetpassword_section mx-4">
        {nextProps.validateTokenRequest ? (
          <div className="custom-loader">
            <Loader width="50" height="50" />
          </div>
        ) : (
          ""
        )}
        <Col
          xl={6}
          lg={8}
          className={
            nextProps.tokenData &&
            !nextProps.resetPasswordData &&
            !nextProps.resetPasswordError
              ? ""
              : "d-none"
          }
        >
          <Container>
            <Form className="mt-4">
              <FormGroup className="mb-3">
                <div>
                  <h1 className="change-password-label">New Password</h1>
                </div>
                <div className="d-flex">
                  <Input
                    type="text"
                    className="form-control change-password-input"
                    name="newPassword"
                    onChange={onChangeNewPassword}
                    onBlur={isValidNewPassword}
                    autoFocus
                  />
                  {isInvalidNewPassword ? (
                    <div className="invalid-feedback change-password-error-box">
                      <ul className="mb-0">
                        <li>Password must have</li>
                        {!isNewPasswordValidLen ? (
                          <li className="change-password-error-list">
                            <span>{"At least 8 characters"}</span>
                          </li>
                        ) : (
                          ""
                        )}
                        {!isNewPasswordwithNum ? (
                          <li className="change-password-error-list">
                            <span>{"At least 1 number"}</span>
                          </li>
                        ) : (
                          ""
                        )}
                        {!isNewPasswordwithSpecialChr ? (
                          <li className="change-password-error-list">
                            <span>{"At least 1 special character"}</span>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </FormGroup>
              <FormGroup className="mb-3">
                <div>
                  <h1 className="change-password-label">Repeat New Password</h1>
                </div>
                <div className="d-flex">
                  <Input
                    type="text"
                    className="form-control change-password-input"
                    name="repeatPassword"
                    onChange={(e) => onChangeRepeatPassword(e)}
                    onBlur={() => handleOnBlurRepeatPassword()}
                  />
                  {isInvalidRepeatNewPassword && (
                    <div className="invalid-feedback change-password-error-box">
                      <span>New Passwords do not match</span>
                    </div>
                  )}
                </div>
              </FormGroup>
              <div className="reset-password-button-box d-flex">
                <Button
                  loading={nextProps.resetPasswordRequest}
                  className="btn resetpassword-btn"
                  onClick={() => submitPasswordForm()}
                >
                  {t("RESETPASSWORD.RESET_PASSWORD")}
                </Button>
                <Button
                  className="btn resetpassword-cancel-btn"
                  onClick={() => history.push("/login")}
                >
                  CANCEL
                </Button>
              </div>
            </Form>
          </Container>
        </Col>
        <Col
          className={`p-5 ${
            nextProps.tokenError ||
            !token ||
            nextProps.resetPasswordData ||
            nextProps.resetPasswordError
              ? ""
              : "d-none"
          }`}
        >
          <h1 className="change-password-success">
            {nextProps.tokenError && (
              <span>{t("RESETPASSWORD.INVALID_TOKEN_MESSAGE")}</span>
            )}
            {!token && <span>{t("RESET PASSWORD.INVALID_LINK_MESSAGE")}</span>}
            {nextProps.resetPasswordData && (
              <>
                <p>Your Password has been reset successfully.</p>
                <span>
                  {" "}
                  Please click <a href="/login"> here </a>to login with new
                  password.{" "}
                </span>
              </>
            )}
            {nextProps.resetPasswordError && (
              <span>{this("RESETPASSWORD.RESETPASSWORD_FAILED")}</span>
            )}
          </h1>
        </Col>
      </Row>
      <div className="footer">
        <Container fluid={true} className="footer-section-container">
          <Row className="footerBlock">
            <Col sm="3" md="3">
              <Row>
                <span className="footerLogo">
                  <img src={Logo} />
                </span>
                <ul className="first_col_icons">
                  <li>
                    <span>
                      <img src={LocationIcon} />
                    </span>
                    <a className="footerLinks">
                      {addressDetailsData?.data?.corporateDetails?.address}
                    </a>
                  </li>
                  <li className="mt-3">
                    <span className="first_col_icons">
                      <img src={MailIcon} />
                    </span>
                    <a
                      href="mailto: support@eyegear.com"
                      className="footerLinks"
                    >
                      {addressDetailsData?.data?.corporateDetails?.emailId}
                    </a>
                  </li>
                  <li className="mt-2">
                    <span className="first_col_icons">
                      <img src={PhoneIcon} />
                    </span>
                    <a href="#" className="footerLinks">
                      {
                        addressDetailsData?.data?.corporateDetails
                          ?.contactNumber
                      }
                    </a>
                  </li>
                </ul>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Resetpassword;
