import React, { useEffect, useState } from "react";
import InputRange from "react-input-range";
import PropTypes from "prop-types";
import "../../../../node_modules/react-input-range/lib/css/index.css";
import { CATALOG } from "./../../../helpers/Constants";

function index({ productPrice, getPriceRangeData, priceFilterData }) {
  const maximumLimitPrice =
    productPrice &&
    productPrice.data &&
    productPrice.data[productPrice.data.length - 1].max;
  const [priceRange, setPriceRange] = useState({
    min: 0,
    max: 0,
  });

  useEffect(() => {
    if (!priceFilterData.min && !priceFilterData.max) {
      setPriceRange({
        min: 0,
        max:
          productPrice &&
          productPrice.data &&
          productPrice.data[productPrice.data.length - 1].max,
      });
    }
  }, [productPrice]);

  const getPriceRange = (range) => {
    setPriceRange(range);
    getPriceRangeData(range);
  };

  useEffect(() => {
    if (priceFilterData.min >= 0 && priceFilterData.max > 0) {
      setPriceRange(priceFilterData);
    } else {
      setPriceRange({ min: 0, max: maximumLimitPrice });
    }
  }, [priceFilterData]);

  return (
    <div className="fliter-price-mbv">
      <p className="price-text">{productPrice && productPrice.name} </p>
      <div className="price_range_min_Max">
        <span>
          {`Min: ${
            priceRange.min >= maximumLimitPrice
              ? maximumLimitPrice
              : priceRange.min
          }`}{" "}
        </span>
        <span>
          {" "}
          {`Max: ${
            priceRange.max >= maximumLimitPrice
              ? maximumLimitPrice
              : priceRange.max
          }`}
        </span>
      </div>
      <div className="price-range-slider">
        <InputRange
          step={CATALOG.PRICE_FILTER_STEP}
          maxValue={maximumLimitPrice}
          minValue={0}
          value={priceRange}
          onChange={(value) => {
            getPriceRange(value);
          }}
        />
      </div>
      <p></p>
    </div>
  );
}

export default index;
index.propTypes = {
  productPrice: PropTypes.object,
  getPriceRangeData: PropTypes.any,
  priceFilterData: PropTypes.object,
};
