import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Form,
  FormGroup,
} from "reactstrap";
import PropTypes from "prop-types";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import PriceFilter from "./../PriceFilter";
import { useTranslation } from "react-multi-lang";
import { getSortedArrayData } from "./../../../helpers/Utility";

const FilterModal = ({
  isFilterPopup,
  filterModal,
  filterOptionData,
  getFilterdSelectedQuery,
  priceFilterDatas,
  genderCheckedData,
  materilaCheckedData,
  shapeCheckedData,
  clearFilterData,
  filterCheckedData,
  getPriceFiltered,
}) => {
  const filterId = {
    frameMaterialId: "frameMaterialId",
    frameShapeId: "frameShapeId",
    genderIdList: "genderIdList",
    sizeId: "sizeId",
    frameColorIdList: "frameColorIdList",
    brandId: "brandId",
    productTypeId: "productTypeId",
    availablity: "availablity",
  };
  const t = useTranslation();
  const [activeTab, setActiveTab] = useState("0");
  const tabtoggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [modal, setModal] = useState(false);

  const [productSize, setProductSize] = useState([]);
  const [productMaterial, setproductMaterial] = useState([]);
  const [productGender, setProductGender] = useState([]);
  const [productShape, setProductShape] = useState([]);
  const [productColor, setProductColor] = useState([]);
  const [productBrand, setProductBrand] = useState([]);
  const [productCatagory, setProductCatagory] = useState([]);
  const [productPrice, setProductPrice] = useState([]);
  const [checkData, setCheckData] = useState({});
  const [query, setQuery] = useState("");
  const [priceFilter, setPriceFilter] = useState("");

  const IsAvailblity = {
    key: "availablity",
    name: "Availability",
    type: "In",
    data: [
      {
        id: "1",
        name: "Don’t show out of stock items, Show available items only",
      },
    ],
  };

  useEffect(() => {
    setModal(filterModal);
  }, [filterModal]);
  const toggle = () => {
    setModal(!modal);
    isFilterPopup(!modal);
  };

  useEffect(() => {
    setCheckData({});
  }, [materilaCheckedData, genderCheckedData, shapeCheckedData]);

  useEffect(() => {
    if (filterCheckedData) {
      if (Object.keys(filterCheckedData).length) {
        setCheckData(filterCheckedData);

        let search =
          `${frameShapeIdData(
            filterCheckedData?.frameShapeId?.length
              ? filterCheckedData?.frameShapeId.toString()
              : ""
          )}` +
          `${frameColorIdListData(
            filterCheckedData?.frameColorIdList?.length
              ? filterCheckedData?.frameColorIdList.toString()
              : ""
          )}` +
          `${sizeIdData(
            filterCheckedData?.sizeId?.length
              ? filterCheckedData?.sizeId.toString()
              : ""
          )}` +
          `${brandIdData(
            filterCheckedData?.brandId?.length
              ? filterCheckedData?.brandId.toString()
              : ""
          )}` +
          `${catagoriesIdData(
            filterCheckedData?.productTypeId?.length
              ? filterCheckedData?.productTypeId.toString()
              : ""
          )}` +
          `${frameMaterialIdData(
            filterCheckedData?.frameMaterialId?.length
              ? filterCheckedData?.frameMaterialId.toString()
              : ""
          )}` +
          `${genderIdListData(
            filterCheckedData?.genderIdList?.length
              ? filterCheckedData?.genderIdList.toString()
              : ""
          )}` +
          `${availablityData(
            filterCheckedData?.availablity?.length
              ? filterCheckedData?.availablity.toString()
              : ""
          )}` +
          `${priceFilterData(priceFilter)}`;
        setQuery(search);
      }
    }
  }, [filterCheckedData]);

  useEffect(() => {
    if (
      shapeCheckedData &&
      shapeCheckedData.frameShapeId &&
      shapeCheckedData.frameShapeId.length
    ) {
      let checkedObject = {};
      shapeCheckedData.frameShapeId.map((item) => {
        if (checkedObject[Object.keys(shapeCheckedData)]) {
          checkedObject[Object.keys(shapeCheckedData)].push(item.toString());
        } else {
          checkedObject[Object.keys(shapeCheckedData)] = [item.toString()];
        }
      });
      setCheckData(
        Object.keys(filterCheckedData)?.length
          ? filterCheckedData
          : checkedObject
      );

      setQuery(
        frameShapeIdData(shapeCheckedData?.frameShapeIdData?.toString())
      );
    }
    if (
      materilaCheckedData &&
      materilaCheckedData.frameMaterialId &&
      materilaCheckedData.frameMaterialId.length
    ) {
      let checkedObject = {};
      materilaCheckedData.frameMaterialId.map((item) => {
        if (checkedObject[Object.keys(materilaCheckedData)]) {
          checkedObject[Object.keys(materilaCheckedData)].push(item.toString());
        } else {
          checkedObject[Object.keys(materilaCheckedData)] = [item.toString()];
        }
      });
      setCheckData(
        Object.keys(filterCheckedData)?.length
          ? filterCheckedData
          : checkedObject
      );
      setQuery(
        frameMaterialIdData(materilaCheckedData?.frameMaterialId?.toString())
      );
    }
    if (
      genderCheckedData &&
      genderCheckedData.genderIdList &&
      genderCheckedData.genderIdList.length
    ) {
      let genderObject = {};
      genderCheckedData.genderIdList.map((item) => {
        if (genderObject[Object.keys(genderCheckedData)]) {
          genderObject[Object.keys(genderCheckedData)].push(item.toString());
        } else {
          genderObject[Object.keys(genderCheckedData)] = [item.toString()];
        }
      });
      setCheckData(
        Object.keys(filterCheckedData)?.length
          ? filterCheckedData
          : genderObject
      );
      setQuery(genderIdListData(genderCheckedData?.frameShapeId?.toString()));
    }
  }, [shapeCheckedData, materilaCheckedData, genderCheckedData]);

  useEffect(() => {
    filterOptionData &&
      filterOptionData.filters.map((filter) => {
        switch (filter.type.length > 0 && filter.key.length > 0) {
          case "In" === filter.type && "sizeId" === filter.key:
            setProductSize({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "In" === filter.type && "frameMaterialId" === filter.key:
            setproductMaterial({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "In" === filter.type && "genderIdList" === filter.key:
            setProductGender({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "In" === filter.type && "frameShapeId" === filter.key:
            setProductShape({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "In" === filter.type && "frameColorIdList" === filter.key:
            setProductColor({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "In" === filter.type && "brandId" === filter.key:
            setProductBrand({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "In" === filter.type && "productTypeId" === filter.key:
            setProductCatagory({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "Between" === filter.type && "netPrice" === filter.key:
            setProductPrice({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          default:
        }
      });
  }, [filterOptionData]);

  const displayFiltererOption = () => {
    return filterOptionData?.filters?.map((item, index) => {
      return (
        <>
          <NavItem className="filter-modal-item" key={index}>
            <NavLink
              className={classnames({ active: activeTab == index.toString() })}
              onClick={() => {
                tabtoggle(index.toString());
              }}
            >
              {item.name}
            </NavLink>
          </NavItem>
        </>
      );
    });
  };

  const getPriceRangeData = (data) => {
    getPriceFiltered(data);
    let filterQuery = `netPrice_From=${data.min}&netPrice_To=${data.max}`;
    setPriceFilter(filterQuery);
  };

  const frameShapeIdData = (frameShapeId) => {
    if (frameShapeId) {
      return `frameShapeId=${frameShapeId}&`;
    } else {
      return "";
    }
  };
  const frameColorIdListData = (frameColorIdList) => {
    if (frameColorIdList) {
      return `frameColorIdList=${frameColorIdList}&`;
    } else {
      return "";
    }
  };
  const sizeIdData = (sizeId) => {
    if (sizeId) {
      return `sizeId=${sizeId}&`;
    } else {
      return "";
    }
  };
  const brandIdData = (brandId) => {
    if (brandId) {
      return `brandId=${brandId}&`;
    } else {
      return "";
    }
  };
  const catagoriesIdData = (productTypeId) => {
    if (productTypeId) {
      return `productTypeId=${productTypeId}&`;
    } else {
      return "";
    }
  };
  const frameMaterialIdData = (frameMaterialId) => {
    if (frameMaterialId) {
      return `frameMaterialId=${frameMaterialId}&`;
    } else {
      return "";
    }
  };
  const genderIdListData = (genderIdList) => {
    if (genderIdList) {
      return `genderIdList=${genderIdList}&`;
    } else {
      return "";
    }
  };
  const priceFilterData = (priceString) => {
    if (priceString) {
      return `${priceFilter}&`;
    } else {
      return "";
    }
  };
  const availablityData = (availablity) => {
    if (availablity) {
      return `isAvailable=true&`;
    } else {
      return "";
    }
  };

  let queryData = checkData;
  const handleCheckBox = (e) => {
    if (!e.target.checked) {
      let arr = queryData[e.target.value];
      arr = arr && arr.length && arr.filter((item) => item !== e.target.name);
      if (!arr?.length) {
        delete queryData[e.target.value];
      } else {
        queryData[e.target.value] = arr;
      }
    } else {
      if (queryData[e.target.value]) {
        queryData[e.target.value].push(e.target.name);
      } else {
        queryData[e.target.value] = [e.target.name];
      }
    }
    setCheckData(queryData);
    let frameShapeId =
      queryData && queryData.frameShapeId && queryData.frameShapeId.toString();
    let frameColorIdList =
      queryData &&
      queryData.frameColorIdList &&
      queryData.frameColorIdList.toString();
    let sizeId = queryData && queryData.sizeId && queryData.sizeId.toString();
    let brandId =
      queryData && queryData.brandId && queryData.brandId.toString();
    let productTypeId =
      queryData &&
      queryData.productTypeId &&
      queryData.productTypeId.toString();
    let frameMaterialId =
      queryData &&
      queryData.frameMaterialId &&
      queryData.frameMaterialId.toString();
    let genderIdList =
      queryData && queryData.genderIdList && queryData.genderIdList.toString();
    let availablity =
      queryData && queryData.availablity && queryData.availablity.toString();
    let search =
      `${frameShapeIdData(frameShapeId)}` +
      `${frameColorIdListData(frameColorIdList)}` +
      `${sizeIdData(sizeId)}` +
      `${brandIdData(brandId)}` +
      `${catagoriesIdData(productTypeId)}` +
      `${frameMaterialIdData(frameMaterialId)}` +
      `${genderIdListData(genderIdList)}` +
      `${availablityData(availablity)}` +
      `${priceFilterData(priceFilter)}`;

    let filter = search.trim();
    setQuery(filter.substring(0, filter.length - 1));
  };

  const getFilter = (product) => {
    let data = product && product.data;
    data && getSortedArrayData(data);
    return (
      data &&
      data.sort().map((item) => {
        return (
          <>
            <Form id="filter">
              <FormGroup check>
                <Label check className="product-filter-option">
                  <Input
                    type="checkbox"
                    className="product-checkbox"
                    name={item.id}
                    value={product.key}
                    checked={
                      (filterId?.frameMaterialId == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.frameMaterialId?.includes(
                          item.id.toString()
                        )) ||
                      (filterId?.frameShapeId == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.frameShapeId?.includes(
                          item.id.toString()
                        )) ||
                      (filterId?.genderIdList == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.genderIdList?.includes(
                          item.id.toString()
                        )) ||
                      (filterId?.sizeId == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.sizeId?.includes(item.id.toString())) ||
                      (filterId?.frameColorIdList == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.frameColorIdList?.includes(
                          item.id.toString()
                        )) ||
                      (filterId?.brandId == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.brandId?.includes(item.id.toString())) ||
                      (filterId?.productTypeId == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.productTypeId?.includes(
                          item.id.toString()
                        )) ||
                      (filterId.availablity == product.key &&
                        Object.keys(checkData)?.includes(product.key) &&
                        checkData?.availablity?.includes(item.id))
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      handleCheckBox(e);
                    }}
                  />{" "}
                  {item.name.split(" ").map((item) => {
                    return (
                      item.charAt(0).toUpperCase() +
                      item.slice(1).toLowerCase() +
                      " "
                    );
                  })}
                </Label>
              </FormGroup>
            </Form>
          </>
        );
      })
    );
  };

  const appllyFilter = () => {
    let filterQuery = `${query}&${priceFilter || ""}`;
    getFilterdSelectedQuery(filterQuery, checkData);
  };

  const clearFilter = () => {
    clearFilterData();
    setModal(false);
  };

  return (
    <div className="filter-sort-section">
      <Modal isOpen={modal} toggle={toggle} className="filter-modal d-none">
        <ModalHeader toggle={toggle} className="filter-modal-header">
          {t("SORT.FILTER")}
        </ModalHeader>
        <ModalBody className="py-0 filter-modal-body">
          <div className="filter-modal-section">
            <Nav tabs className="filter-modal-tab">
              {displayFiltererOption()}
            </Nav>
            <div className="filter-modal-tabcontent">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="0">
                  {priceFilterDatas ? (
                    <PriceFilter
                      priceFilterData={priceFilterDatas}
                      productPrice={productPrice}
                      getPriceRangeData={getPriceRangeData}
                    />
                  ) : (
                    ""
                  )}
                </TabPane>
              </TabContent>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">{getFilter(productSize)}</TabPane>
              </TabContent>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="2"> {getFilter(productMaterial)}</TabPane>
              </TabContent>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="3">{getFilter(productGender)}</TabPane>
              </TabContent>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="4">{getFilter(productShape)}</TabPane>
              </TabContent>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="5">{getFilter(productColor)}</TabPane>
              </TabContent>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="6">{getFilter(productBrand)}</TabPane>
              </TabContent>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="7">{getFilter(productCatagory)}</TabPane>
              </TabContent>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="8"> {getFilter(IsAvailblity)}</TabPane>
              </TabContent>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="filter-modal-footer">
          <Button
            className="apply-button"
            onClick={() => {
              appllyFilter();
            }}
          >
            {t("SORT.APPLY")}
          </Button>{" "}
          <Button
            className="clear-button"
            onClick={() => {
              clearFilter();
            }}
          >
            {t("SORT.CLEAR")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FilterModal;
FilterModal.propTypes = {
  isFilterPopup: PropTypes.func.isRequired,
  filterModal: PropTypes.bool.isRequired,
  filterOptionData: PropTypes.object.isRequired,
  genderCheckedData: PropTypes.array,
  getFilterdSelectedQuery: PropTypes.function,
  priceFilterDatas: PropTypes.object,
  materilaCheckedData: PropTypes.array,
  shapeCheckedData: PropTypes.array,
  clearFilterData: PropTypes.function,
  changeFilter: PropTypes.bool,
  filterCheckedData: PropTypes.object,
  getPriceFiltered: PropTypes.func,
};
