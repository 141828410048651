import React, { useEffect, useState } from "react";
import { FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import Select from "react-select";
import { CART } from "./../../../helpers/Constants";

function ProductSort(props) {
  const { sortTypesData, sortKey } = props;
  const [sort, setSort] = useState([]);

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: CART.COLOR_CODE,
      padding: 5,
      paddingLeft: 10,
      backgroundColor: CART.WHITE_BACKGROUND,
      fontSize: "12px",
      cursor: "pointer",
    }),
  };

  useEffect(() => {
    if (sortTypesData) {
      let sortOption = [];
      sortTypesData.map((item) => {
        sortOption.push({ value: item.sortKey, label: item.sortTypeName });
      });
      setSort(sortOption);
    }
  }, [sortTypesData]);

  const handleSortType = (data) => {
    sortKey(data.value);
  };
  return (
    <>
      {sort.length ? (
        <FormGroup className="sort-product mt-2">
          <Select
            styles={customStyles}
            defaultValue={sort.length && sort[0]}
            options={sort}
            onChange={(e) => handleSortType(e)}
          />
        </FormGroup>
      ) : (
        ""
      )}
    </>
  );
}

export default ProductSort;
ProductSort.propTypes = {
  sortTypesData: PropTypes.array,
  sortKey: PropTypes.func,
};
