import React from "react";
// import AwesomeSlider from "react-awesome-slider";
// import AwesomeSliderStyles from "react-awesome-slider/dist/styles.css";
// import withAutoplay from "react-awesome-slider/dist/autoplay";

function LoginForm() {
  //   const AutoplaySlider = withAutoplay(AwesomeSlider);

  return (
    <div>
      {/* <AutoplaySlider
        cssModule={AwesomeSliderStyles}
        play={true}
        interval={5000}
      >
        <div data-src="https://bencatalogstorage.blob.core.windows.net/banners/Login1.svg" />
        <div data-src="https://bencatalogstorage.blob.core.windows.net/banners/Login2.svg" />
        <div data-src="https://bencatalogstorage.blob.core.windows.net/banners/Login3.svg" />
        {/* <div data-src="https://i.ytimg.com/vi/PCwL3-hkKrg/maxresdefault.jpg" />
        <div data-src="https://demoseal.cloudimg.io/v7/sample.li/birds.jpg?ci_eqs=d2F0PTEmd2F0X3VybD1odHRwOi8vc2FtcGxlLmxpL2xvdWlzLXZ1aXR0b24tbG9nby13aGl0ZS5wbmcmd2F0X3NjYWxlPTQ1JndhdF9ncmF2aXR5PXNvdXRod2VzdCZ3YXRfcGFkPTE1&ci_seal=a355cce069fbfb18a4" />
        <div data-src="https://res.cloudinary.com/demo/image/upload/o_50/sample.jpg" /> */}
      {/* </AutoplaySlider> */}
    </div>
  );
}

export default LoginForm;
