import "./BrandsPage.scss";
import "../../assets/CSS/preLoginStyles.scss";

import { useDispatch, useSelector } from "react-redux";

import BrandImageComponent from "./BrandImageComponent";
import ButtonComponent from "../../common/button/ButtonComponent";
import Cadiz from "../../assets/Images/cadiz.png";
import Elementz from "../../assets/Images/elementz.png";
import FoldedBrownSpec from "../../assets/Images/foldedBrownSpecs.jpg";
import GirlInBlueShirt from "../../assets/Images/girlinBlueShirt.jpg";
import Moonshine from "../../assets/Images/moonshine.png";
import React from "react";
import Riverstone from "../../assets/Images/riverstone.png";
import Siena from "../../assets/Images/siena.png";
import { windowTabSave } from "../../redux/loginTabDuplicate/loginTabAction";

// import { handleLoginDuplicate } from "../../common/alertNotifications";

const BrandsPage = () => {
  const windowTab = useSelector((state) => state.loginTabReducer);
  const dispatch = useDispatch();
  return (
    <>
      <div>
        <div className="image-section">
          <img
            style={{ width: "100%" }}
            src={GirlInBlueShirt}
            alt="Girl In Blue Shirt"
            className="banner_image"
          />
        </div>
        <div className="section__padding">
          <div className="content-section ">
            <div className="content-section-desc">
              <div className="heading">Eyegear B2B Platform</div>
              <p>
                The Eyegear B2B platform was started in 2020 with the intention
                of transforming independent mom & pop optical retailers through
                technology and brands that cater to a young and aspirational
                India. This Business-to-Business tech platform offers
                world-class spectacle frames, sunglasses, contact lenses and
                more, with 2,000+ retailers as part of an ever-growing customer
                base across the country. The platform provides an extensive
                range of 2,500+ styles that are not just fashionable and of high
                quality, but also very affordable.
              </p>
              <p>
                In a very short period of time, the Eyegear B2B tech platform
                has onboarded 2,000+ retail partners across Tier 1, 2 and 3
                cities, with plans of adding an additional 8,000 retail partners
                by the end of 2023.
              </p>
              <p>
                Eyegear aims to continue to revolutionize the optical industry
                and customer experiences, by partnering with independent mom &
                pop optical retailers, and providing customers with high-quality
                branded products, the latest designs and styles at affordable
                prices. Eyegear offers retailers a vast choice of 2,500+ styles,
                by way of in-house brands that are as fashionable as they are
                comfortable on customers’ wallets.
              </p>
              <p>
                Eyegear is helping independent mom-and-pop stores with
                proprietary tech solutions, branding and advertising, retail
                expertise, data capabilities and a robust supply chain to aid in
                their business growth. Currently Eyegear supports independent
                mom & pop optical retailer stores in Andhra Pradesh, Bihar,
                Chandigarh, Chhattisgarh, Delhi, Goa, Haryana, Jammu & Kashmir,
                Madhya Pradesh, Maharashtra, Odisha, Punjab, Tamil Nadu,
                Telangana and Uttar Pradesh.
              </p>
              <p>
                The Company plans on reaching every part of the country by being
                easily accessible, and becoming India’s first eyewear brand that
                provides quality, fashion and affordability to millions of
                customers.
              </p>
            </div>
            <div className="content-section-img">
              <img
                style={{ width: "100%" }}
                src={FoldedBrownSpec}
                alt="Brown Specs"
                className="content_right_image"
              />
            </div>
          </div>
          <div className="brands_section ">
            <p className="portfolio_title">Portfolio Brands</p>
            <div className="logos_section">
              <BrandImageComponent image={Cadiz} name="Cadiz" />
              <BrandImageComponent image={Elementz} name="Elementz" />
              <BrandImageComponent
                image={Moonshine}
                name="Moonshine"
                height="16px"
              />
              <BrandImageComponent
                image={Riverstone}
                name="Riverstone"
                height="17px"
              />
              <BrandImageComponent
                image={Siena}
                name="Siena"
                isSmallImage={true}
                height="20px"
              />
            </div>
          </div>
          <div className="button-section">
            <a
              href="#"
              style={{ textDecoration: "none", color: "white" }}
              onClick={(event) => {
                event.preventDefault();
                if (Object.keys(windowTab).length > 0) {
                  windowTab.focus();
                } else {
                  window.localStorage.setItem("isTab", "true");
                  let windowtab = window.open(
                    `${window.location.origin}/login`
                  );
                  dispatch(windowTabSave(windowtab));
                }
                //handleLoginDuplicate("login");
              }}
              target="_blank"
            >
              <ButtonComponent title="B2B Partner Log-in" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandsPage;
