import { MY_ORDER_CONST } from "./orderConst";

const Order = (state = {}, action) => {
  switch (action.type) {
    case MY_ORDER_CONST.MY_ORDER_REQUESTING:
      return {
        ...state,
        isOrderRequesting: true,
        isOrderData: false,
        isOrderDataError: false,
      };
    case MY_ORDER_CONST.MY_ORDER_SUCCESS:
      return {
        ...state,
        isOrderRequesting: false,
        isOrderData: action.payload.response,
        isOrderDataError: false,
      };
    case MY_ORDER_CONST.MY_ORDER_FAILED:
      return {
        ...state,
        isOrderRequesting: false,
        isOrderData: false,
        isOrderDataError: action.payload.error
          ? false
          : action.payload.response,
      };

    case MY_ORDER_CONST.MY_ORDER_DETAILS_REQUESTING:
      return {
        ...state,
        isOrderDetailsRequesting: true,
        isOrderDetailsData: false,
        isOrderDetailsDataError: false,
      };
    case MY_ORDER_CONST.MY_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        isOrderDetailsRequesting: false,
        isOrderDetailsData: action.payload.response,
        isOrderDetailsDataError: false,
      };
    case MY_ORDER_CONST.MY_ORDER_DETAILS_FAILED:
      return {
        ...state,
        isOrderDetailsRequesting: false,
        isOrderDetailsData: false,
        isOrderDetailsDataError: action.payload.error
          ? false
          : action.payload.response,
      };

    default:
      return { ...state };
  }
};

export default Order;
