import { AXIOS_INSTANCE, BASE_URL } from "../../api/apiEndPoint";

import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { CART_CONST } from "./cartConst";

import {
  getCartItemsSuccess,
  getCartItemsFailed,
  deleteCartItemsSuccess,
  deleteCartItemsFailed,
  updateItemsToCartSuccess,
  updateItemsToCartFailed,
  clearCartItemsSuccess,
  clearCartItemsFailed,
} from "./cartActions";
import { ProductClient } from "../apibenfranklin";
import { getToken, unAuthorizedRedirection } from "./../../helpers/Utility";

const client = new ProductClient(`${BASE_URL}`, AXIOS_INSTANCE);

/**
 *  Login
 */
const getCartItemsApiRequest = async (payload) => {
  let response = {};
  let token = `bearer  ${getToken()}`;
  try {
    const data = await client.getCartItems(
      payload.pageNumber,
      payload.pageSize,
      payload.email,
      token
    );
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getCartItems(action) {
  try {
    const apiResponse = yield call(getCartItemsApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getCartItemsSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getCartItemsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getCartItemsFailed(errorData));
  }
}

/**
 *  Delete cart items Saga
 */
const deleteCartItemsApiRequest = async (payload) => {
  let response = {};
  let token = `bearer  ${getToken()}`;
  try {
    const data = await client.deleteCartItems(
      payload.id,
      payload.email || null,
      token
    );
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* deleteCartItems(action) {
  try {
    const apiResponse = yield call(deleteCartItemsApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(deleteCartItemsSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(deleteCartItemsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteCartItemsFailed(errorData));
  }
}
/**
 *  Update quantity of cart Items
 */
const updateItemsToCartApiRequest = async (payload) => {
  let response = {};
  let token = `bearer  ${getToken()}`;
  try {
    const data = await client.updateItemsToCart(payload, token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* updateItemsToCart(action) {
  try {
    const apiResponse = yield call(updateItemsToCartApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(updateItemsToCartSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(updateItemsToCartFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(updateItemsToCartFailed(errorData));
  }
}

/**
 *  Clear Cart Saga
 */
const clearCartItemsApiRequest = async (payload) => {
  let response = {};
  let token = `bearer  ${getToken()}`;
  try {
    const data = await client.clearCart(payload.email || null, token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* clearCartItems(action) {
  try {
    const apiResponse = yield call(clearCartItemsApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(clearCartItemsSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(clearCartItemsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(clearCartItemsFailed(errorData));
  }
}

export function* watchLoginUser() {
  yield takeEvery(CART_CONST.DELETE_CART_ITEMS_REQUEST, deleteCartItems);
}

export function* watchCart() {
  yield takeEvery(CART_CONST.GET_CART_ITEMS_REQUEST, getCartItems);
  yield takeEvery(CART_CONST.DELETE_CART_ITEMS_REQUEST, deleteCartItems);
  yield takeEvery(CART_CONST.UPDATE_QUANTITY_REQUEST, updateItemsToCart);
  yield takeEvery(CART_CONST.CLEAR_CART_ITEMS_REQUEST, clearCartItems);
}

function* cartSaga() {
  yield all([fork(watchCart)]);
}

export default cartSaga;
