import "./HomePage.scss";
import "../../assets/CSS/preLoginStyles.scss";

import { useDispatch, useSelector } from "react-redux";

import Banner from "../../assets/Images/banner1.jpg";
import HomeAbout from "../../assets/Images/homeAbout1.jpg";
import HomeBannerMobile from "../../assets/Images/HomeBannerMobile1.jpg";
import HomeCareers from "../../assets/Images/homeCareers1.jpg";
import HomeContact from "../../assets/Images/homeContact1.jpg";
import HomePartner from "../../assets/Images/homePartner1.jpg";
import { useHistory } from "react-router-dom";
import { useScreenSize } from "../../hooks/useScreenSize";
import { windowTabSave } from "../../redux/loginTabDuplicate/loginTabAction";

//import { handleLoginDuplicate } from "../../common/alertNotifications";

//import ButtonComponent from "../../common/button/ButtonComponent";

//import About from "../../assets/images/about.jpg";
//import Career from "../../assets/images/careers.jpg";
//import Platform from "../../assets/images/platform.jpg";
//import Contactus from "../../assets/images/contactus.jpg";

export const HomePage = () => {
  const history = useHistory();
  document.title = "Eyegear";
  const windowTab = useSelector((state) => state.loginTabReducer);
  const dispatch = useDispatch();

  const onImageClick = (path) => {
    if (path) {
      history.push(path);
    } else {
      if (Object.keys(windowTab).length > 0) {
        windowTab.focus();
      } else {
        window.localStorage.setItem("isTab", "true");
        let windowtab = window.open(`${window.location.origin}/login`);
        dispatch(windowTabSave(windowtab));
      }
      //handleLoginDuplicate("login");
    }
  };
  const screenSize = useScreenSize();
  return (
    <>
      <div className="home_banner align-center">
        <div>
          <img
            style={{ width: "100%" }}
            src={
              screenSize.currentScreenConfig.width > 767
                ? Banner
                : HomeBannerMobile
            }
            alt="BannerImage"
          />
        </div>
        <div className="banner_text font_white flex-column">
          <div className="banner_heading">A NEW WORLD OF EYEWEAR!</div>
          <p className="banner_sub-text">
            Welcome to Eyegear. Everything about eyewear, reimagined!
          </p>
        </div>
      </div>
      <div className="section__padding card_container">
        <div className="card">
          <img
            src={HomeAbout}
            className="card_image"
            alt="AboutImage"
            onClick={() => onImageClick("/about", "")}
          />
        </div>
        <div className="card">
          <img
            src={HomePartner}
            className="card_image"
            alt="PartnerLoginImage"
            onClick={(event) => {
              event.preventDefault();
              onImageClick("");
            }}
          />
        </div>
        <div className="card">
          <img
            src={HomeCareers}
            className="card_image"
            alt="CareerImage"
            onClick={() => onImageClick("/careers", "")}
          />
        </div>
        <div className="card">
          <img
            src={HomeContact}
            className="card_image"
            alt="ContactImage"
            onClick={() => onImageClick("/contact", "")}
          />
        </div>
        {/* <div className="card">
          <img
            src={About}
            className="card_image"
            alt="AboutImage"
            onClick={() => onImageClick("/about", "")}
          />
          <ButtonComponent title="ABOUT EYEGEAR" link="/about" />
        </div>
        <div className="card">
          <img
            src={Platform}
            className="card_image"
            alt="PlatformImage"
            onClick={() => onImageClick("", "https://eyegear.com/login")}
          />
          <ButtonComponent
            title="PARTNER LOG-IN"
            url="https://eyegear.com/login"
          />
        </div>
        <div className="card">
          <img
            src={Career}
            className="card_image"
            alt="CareerImage"
            onClick={() => onImageClick("/careers", "")}
          />
          <ButtonComponent title="CAREERS" link="/careers" />
        </div>
        <div className="card">
          <img
            src={Contactus}
            className="card_image"
            alt="ContactImage"
            onClick={() => onImageClick("/contact", "")}
          />
          <ButtonComponent title="CONTACT US" link="/contact" />
        </div> */}
      </div>
    </>
  );
};
