import { ADD_TO_CART } from "./addToCartConst";

export const addItemsToCart = (payload) => ({
  type: ADD_TO_CART.ADD_TO_CART_REQUESTING,
  payload: payload || null,
});

export const addItemsToCartSuccess = (response) => ({
  type: ADD_TO_CART.ADD_TO_CART_SUCCESS,
  payload: response,
});

export const addItemsToCartFailed = (error) => ({
  type: ADD_TO_CART.ADD_TO_CART_FAILED,
  payload: error,
});
