import "../../assets/CSS/preLoginStyles.scss";
import "./ContactPage.scss";

import ContactImage from "./../../assets/Images/contact-us-img1.jpg";
import ContactusPageBanner from "../../assets/Images/ContactusPageBanner1.jpg";
import { useScreenSize } from "../../hooks/useScreenSize";

export const ContactPage = () => {
  document.title = "Eyegear";
  const sendMailTo = (receiver) => {
    const mailToText = `mailto:${receiver}`;
    window.location = `${mailToText}`;
  };
  const openDialPad = (number) => {
    const callTo = `tel:${number}`;
    document.location.href = callTo;
  };
  const screenSize = useScreenSize();
  // const locationUrl =
  //   "https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=UrbanKissan Farm Ameerpet&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed";
  return (
    <>
      <div className="containerdummy">
        <div className="image-section">
          <img
            src={
              screenSize.currentScreenConfig.width > 767
                ? ContactImage
                : ContactusPageBanner
            }
            style={{ width: "100%" }}
            alt="contactimage"
            className="image"
          />
        </div>
        <div className="content-section section__padding">
          <div className="content-page-section-desc">
            <div className="heading">Contact Us</div>

            <div className="heading light-heading">Sales Office :</div>
            <div className="heading-content">
              <div>Eyegear Optics India Pvt. Ltd.,</div>{" "}
              <div>No 7-1-69/1/25/11 & 13, Above Urbankisaan store,</div>
              <div>Dharam Karan Road, Ameerpet,</div>{" "}
              <div>Hyderabad - 500082, Telangana.</div>
              <div>
                Tel:{" "}
                <span
                  className="email-text"
                  onClick={() => openDialPad("040 - 46461111")}
                >
                  040 - 46461111
                </span>{" "}
                ,{" "}
                <span
                  className="email-text"
                  onClick={() => openDialPad("040 - 42071111")}
                >
                  040 - 42071111
                </span>{" "}
              </div>
            </div>
            <div className="heading light-heading">Customer Service:</div>
            <div className="heading-content">
              <div
                className="email-text"
                onClick={() => sendMailTo("feedback@eyegear.com")}
              >
                Email: feedback@eyegear.com{" "}
              </div>{" "}
              <div
                className="email-text"
                onClick={() => openDialPad("040 - 40381111")}
              >
                Phone No : 040 - 40381111{" "}
              </div>
              <div
                className="email-text"
                onClick={() => openDialPad("+91 - 8886807010 ")}
              >
                Mobile No : +91 - 8886807010{" "}
              </div>
              <div>Timing : 10.00 AM to 6.00 PM (Mon - Sat)</div>
            </div>
            <div className="heading light-heading">Business Enquiries:</div>
            <div className="heading-content">
              <div
                className="email-text"
                onClick={() => sendMailTo("corporate@eyegear.com")}
              >
                Email: corporate@eyegear.com
              </div>{" "}
              <div
                className="email-text"
                onClick={() => openDialPad("+91 - 7799777830 ")}
              >
                Contact No: +91 - 7799777830
              </div>
            </div>
            <div className="heading light-heading">Careers</div>
            <div
              className="heading-content email-text"
              onClick={() => sendMailTo("careers@eyegear.com")}
            >
              Email: careers@eyegear.com
            </div>
            <div className="button-section">
              {/* include button component */}
            </div>
          </div>
          <div className="content-page-section">
            <iframe
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              title="EYEGEAR"
              width="600"
              height="400"
              className="map-block"
              src="https://www.google.com/maps/d/u/0/embed?mid=1ooWkObACKOTql4wWnpvaHyxWgthI9uQ&ehbc=2E312F"
            ></iframe>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};
