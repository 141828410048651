import React, { useState, useEffect } from "react";
import { Col, Row, Table, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useTranslation } from "react-multi-lang";
import moment from "moment";
import PropTypes from "prop-types";
import RupeeIcon from "../../assets/Images/rupee_icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Loader from "./../../common/Loader";
import DefaultImage from "./../../assets/Images/spec.png";

function OrderStatus({
  viewOrderModal,
  handleViewModel,
  isOrderDetailsRequesting,
  orderDetails,
  orderDetailsHeadline,
}) {
  const t = useTranslation();
  const [modal, setModal] = useState(false);
  useEffect(() => {
    setModal(viewOrderModal);
  }, [viewOrderModal]);
  const toggle = () => {
    setModal(!modal);
    handleViewModel(!modal);
  };

  const renderDetails = () => {
    return (
      orderDetails &&
      orderDetails.orderDetails &&
      orderDetails.orderDetails.map((item) => {
        return (
          <>
            <tr>
              <td className="myorder-image-section">
                <object
                  data={item.image || DefaultImage}
                  className="myorder-image"
                />
              </td>
              <td colSpan="1" className="myorder-text">
                <p className="product-brand-name p-0 m-0">{item.productName}</p>
                {item.productTypeID === 4 ? (
                  <p className="myorder-item p-0 m-0">
                    {t("CATALOG.COLOR")} : {item.colorVariant}
                  </p>
                ) : (
                  <p className="myorder-item p-0 m-0">
                    {item.sizeName
                      ? t("CATALOG.SIZE") + " : " + item.sizeName + " | "
                      : ""}{" "}
                    {t("CATALOG.COLOR")} : {item.colorVariant + " : "}
                    {item.colorName}
                    {item.colorName !== item.templeColorName &&
                    item.templeColorName
                      ? "-" + item.templeColorName
                      : ""}
                  </p>
                )}
              </td>
              <td className="payment-value myorder-text-1 break-text">
                <img src={RupeeIcon} className="p-0" /> {item.productListPrice}
              </td>
              <td className="payment-value myorder-text-1 break-text mobile-view-width">
                {item?.discount?.discountAmount > 0 &&
                item.productTypeID !== 4 ? (
                  <img src={RupeeIcon} className="p-0" />
                ) : (
                  ""
                )}{" "}
                {item?.discount?.discountAmount > 0 && item.productTypeID !== 4
                  ? item?.discount?.discountAmount
                  : "N/A"}
                {item?.discount?.discountAmount > 0 &&
                item.productTypeID !== 4 ? (
                  <span className="discount-view-mobile-order"> Off</span>
                ) : (
                  ""
                )}
              </td>
              <td className="payment-value myorder-text-1 break-text">
                <FontAwesomeIcon icon={faTimes} className="cross-icon" />
                {item.productQty}
              </td>
              <td className="payment-value myorder-text-2 break-text">
                <img src={RupeeIcon} className="p-0" /> {item.productTotAmt}
              </td>
            </tr>
          </>
        );
      })
    );
  };

  const isIGST = () => {
    if (parseInt(orderDetails.totalIGSTValue) > 0) {
      return (
        <>
          <tr>
            <td
              className="payment-label payment-mbv px-5 pb-3"
              style={{ fontWeight: "500" }}
            >
              {t("ORDER.IGST")}
            </td>
            <td className="payment-value payment-value-mbv pb-3">
              <img src={RupeeIcon} />
              {orderDetails.totalIGSTValue}
            </td>
          </tr>
        </>
      );
    }

    return (
      <>
        <tr>
          <td
            className="payment-label payment-mbv px-5 pb-3"
            style={{ fontWeight: "500" }}
          >
            {t("ORDER.CGST")}
          </td>
          <td className="payment-value payment-value-mbv pb-3">
            <img src={RupeeIcon} />
            {orderDetails.totalCGSTValue}
          </td>
        </tr>
        <tr>
          <td
            className="payment-label payment-mbv px-5 pb-3"
            style={{ fontWeight: "500" }}
          >
            {t("ORDER.SGST")}
          </td>
          <td className="payment-value payment-value-mbv pb-3">
            <img src={RupeeIcon} />
            {orderDetails.totalSGSTValue}
          </td>
        </tr>
      </>
    );
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-xl order-modal myorder-view-modal"
      >
        <ModalHeader
          toggle={toggle}
          className="p-0"
          style={{ border: "none" }}
        />
        {!isOrderDetailsRequesting ? (
          <ModalBody className="myorder-mbv myorder-dt">
            <p className="myorder-item">
              {orderDetailsHeadline.orderId} |{" "}
              {moment(orderDetailsHeadline.orderDate).format(" D MMM YYYY")} |{" "}
              <span className="">{orderDetailsHeadline.orderStatus}</span> |{" "}
              <span>{`${t("ORDER.TOTAL_UNITS")} : ${
                orderDetailsHeadline.orderItemTotal
              }`}</span>
            </p>
            <Table className="myorder-table">
              <tr className="view-mobile-order">
                <td className="myorder-image-section"></td>
                <td colSpan="1" className="myorder-text"></td>
                <td className="payment-value myorder-text-1 break-text">
                  {t("CATALOG.PRICE")}
                </td>
                <td className="payment-value myorder-text-1 break-text">
                  {t("CATALOG.DISCOUNT")}
                </td>
                <td className="payment-value myorder-text-1 break-text">
                  {t("CATALOG.QUANTITY")}
                </td>
                <td className="payment-value myorder-text-2 break-text">
                  {t("CATALOG.TOTALPRICE")}
                </td>
              </tr>
              {renderDetails()}
            </Table>
            <Row>
              <Col></Col>
              <Col lg={6} className="myorder-payment-table">
                <Table>
                  <tr className="order-total-section-1">
                    <td
                      className="payment-label payment-mbv px-5 py-3"
                      style={{ fontWeight: "500" }}
                    >
                      {t("ORDER.SUBTOTAL_SMALL_LETTER")}
                    </td>
                    <td className="payment-value payment-value-mbv pt-3 pb-3">
                      <img src={RupeeIcon} />
                      {orderDetails.cartTotPrice}
                    </td>
                  </tr>
                  {isIGST()}
                  <tr>
                    <td
                      className="payment-label payment-mbv px-5"
                      style={{ fontWeight: "500" }}
                    >
                      {t("ORDER.TOTAL_AMOUNT")}
                    </td>
                    <td className="payment-value payment-value-mbv">
                      <img src={RupeeIcon} />
                      {orderDetails.cartProductTotPricewithGST}
                    </td>
                  </tr>
                </Table>
              </Col>
            </Row>
          </ModalBody>
        ) : (
          <div className="custom-loader">
            <Loader width={50} height={50} />
          </div>
        )}
      </Modal>
    </>
  );
}
export default OrderStatus;
OrderStatus.propTypes = {
  viewOrderModal: PropTypes.boolean,
  handleViewModel: PropTypes.func,
  isOrderDetailsRequesting: PropTypes.bool,
  orderDetails: PropTypes.object,
  orderDetailsHeadline: PropTypes.object,
};
