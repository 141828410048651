import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Delete from "../../assets/Images/delete.svg";
import { useTranslation } from "react-multi-lang";
import PropTypes from "prop-types";
const ConfirmationModal = ({
  confirmationId,
  confirmationModalStatus,
  confirmationHandle,
}) => {
  const [modal, setModal] = useState(false);
  //   const [status, setStatus] = useState(false);
  const t = useTranslation();
  useEffect(() => {
    setModal(confirmationModalStatus);
  }, [confirmationModalStatus]);
  const toggle = () => {
    confirmationHandle(false, false, confirmationId);
    setModal(!modal);
  };
  const okTodelete = () => {
    confirmationHandle(true, false, confirmationId);
    setModal(!modal);
  };

  const cancelDelete = () => {
    confirmationHandle(false, false, confirmationId);
    setModal(!modal);
  };

  return (
    <div>
      <div className="delete-icon" onClick={toggle}>
        <img src={Delete} />
      </div>
      <Modal isOpen={modal} toggle={toggle} className="">
        <ModalHeader
          toggle={toggle}
          className="p-0"
          style={{ border: "none" }}
        />
        <ModalBody className="order-delete">
          <h5>{t("PRODUCT_DETAILS.DELETE_TEXT")}</h5>
        </ModalBody>
        <ModalFooter style={{ border: "none" }}>
          <Button
            className="ok-button"
            onClick={() => {
              okTodelete();
            }}
          >
            {t("PRODUCT_DETAILS.OK")}
          </Button>{" "}
          <Button className="cancel-button" onClick={() => cancelDelete()}>
            {t("PRODUCT_DETAILS.CANCEL")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;

ConfirmationModal.propTypes = {
  confirmationId: PropTypes.any,
  confirmationModalStatus: PropTypes.bool,
  confirmationHandle: PropTypes.any,
};
