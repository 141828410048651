import React, { useState } from "react";
import CaretIcon from "../../assets/Images/dropdown_icon.png";
import { Dropdown, DropdownToggle } from "reactstrap";
import PropTypes from "prop-types";

const CustomDropdown = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle tag="span" aria-expanded={dropdownOpen}>
        {props.label}
        <span className="angleDownIcon">
          <img src={CaretIcon} className="caretIcon" />
        </span>
      </DropdownToggle>
      {/* <DropdownMenu></DropdownMenu> */}
    </Dropdown>
  );
};

export default CustomDropdown;

CustomDropdown.propTypes = {
  label: PropTypes.string,
};
