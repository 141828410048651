import CarouselCaption from "../../../components/CarouselCaption";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";
import Slider from "../../../components/CarouselWrapper";

// import { BANNER } from "./../../../helpers/Constants";

export default function NewArrivals({ newArrivalBannerData }) {
  // const buttonText = BANNER.NEW_ARRIVAL_BUTTON_TEXT;
  const data = {
    text: newArrivalBannerData.length && newArrivalBannerData[0].title,
    // buttonText: buttonText,
    captionClassName: "catalogSectionTitle-1",
    buttonClassName: "catalogSectionButton-1",
  };
  return (
    <>
      <Container
        fluid={true}
        className="footer-section-container banner-section"
      >
        <Slider data={newArrivalBannerData} className="catalog-section-2" />
        <div className="bannerText3 bannerText col-3">
          <CarouselCaption data={data} />
        </div>
      </Container>
    </>
  );
}
NewArrivals.propTypes = {
  newArrivalBannerData: PropTypes.array,
};
