import "bootstrap/dist/css/bootstrap.min.css";

import { Card, Label } from "reactstrap";
import React, { useEffect, useState } from "react";
import { showError, showSuccess } from "../../common/alertNotifications";

import DataTable from "react-data-table-component";
import MyInvoiceStyles from "./MyInvoices.module.css";
import PropTypes from "prop-types";
import SortIcon from "@material-ui/icons/ArrowDownward";
import SpinnerBomb from "./spinner";
import axios from "axios";
import downloadArrow from "../../assets/Images/download.svg";

const MyInvoices = ({ RetailerID, MobileNO, ShopName, Email }) => {
  console.log(ShopName);
  console.log(Email);
  console.log(RetailerID);
  console.log(MobileNO);
  const [filterInvoiceList, setFilterInvoiceList] = useState([{}]);
  const [balanceAmt, setBalanceAmt] = useState("0");
  const intialstate = "";
  const [amountToPay, setAmountToPay] = useState(intialstate);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [invoiceList, setInvoiceList] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [disablePyStatus, setDisablePyStatus] = useState(true);
  const [disableProcToPay, setDisableProcToPay] = useState(false);
  const [paymentLinkID, setPaymentLinkID] = useState("");
  const [razorRequest, setRazorRequest] = useState({
    Type: "Frames",
    TransactionAmount: "",
    MobileNO: "",
    RetailerID: "",
  });

  const GetInvoicesOfRetailer = () => {
    var data = JSON.stringify({
      Type: "For Stock Invoice Report Website",
      FromDate: "2021-07-01",
      ToDate: new Date().toJSON().slice(0, 10),
      RetailerID: RetailerID,
    });

    var config = {
      method: "post",
      url: "https://workspace.benfranklin.in/EyegearBackofficeWebAPI_Production/api/LensMaster/LensInvoiceReport",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        if (res?.data?.result) {
          const data = JSON.parse(res.data.stores);
          let balanceAmount = "";
          setInvoiceList(data);
          setFilterInvoiceList(data);
          data.forEach((value) => {
            if (
              value.PaymentStatus === "Unpaid" ||
              value.PaymentStatus === "Partially Paid"
            ) {
              balanceAmount = (
                Number(balanceAmount) + Number(value.BalanceAmount)
              ).toString();
            }
          });
          balanceAmount = (
            Math.round(Number(balanceAmount) * 100) / 100
          ).toFixed(2);
          setBalanceAmt(balanceAmount);
        } else {
          showError("Invoices not found.");
        }
      })
      .catch((err) => {
        showError(err);
      });
  };
  useEffect(() => {
    if (RetailerID != undefined) {
      GetInvoicesOfRetailer();
    }
  }, [RetailerID]);

  const conditionalRowStyles = [
    {
      when: (row) => row.PaymentStatus === "Paid",
      style: {
        backgroundColor: "#66FF99",
        fontWeight: 400,
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row.PaymentStatus === "Partially Paid",
      style: {
        backgroundColor: "#FFFFE0",
        fontWeight: 400,
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row.PaymentStatus === "Unpaid",
      style: {
        backgroundColor: "#FFCCCB",
        fontWeight: 400,
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];
  useEffect(() => {
    if (
      invoiceList != undefined &&
      invoiceList.length > 0 &&
      Object.keys(invoiceList[0]).length != 0
    ) {
      const result = invoiceList.filter((invoice) => {
        return (
          invoice.InvoiceNo.toLowerCase().match(search.toLowerCase()) ||
          invoice.PaymentStatus.toLowerCase().match(search.toLowerCase()) ||
          invoice.InvoiceDate.toLowerCase().match(search.toLowerCase())
        );
      });
      setFilterInvoiceList(result);
    }
  }, [search]);

  const cmbStatusChangeEvent = (e) => {
    if (e.target.value === "--Status--") {
      setFilterInvoiceList(invoiceList);
      setStatus(e.target.value);
      return;
    }
    const result = invoiceList.filter((invoice) => {
      return (
        invoice.PaymentStatus.toLowerCase() === e.target.value.toLowerCase()
      );
    });
    setFilterInvoiceList(result);
    setStatus(e.target.value);
  };

  const amountToPayOnchangeEvent = (e) => {
    if (Number(balanceAmt) < Number(e.target.value)) {
      showError(
        "Your amout to pay not greater than your total due amount.",
        "error"
      );
      return;
    }
    setAmountToPay(e.target.value);
    setRazorRequest((razorRequest) => {
      return {
        ...razorRequest,
        MobileNO: MobileNO,
        RetailerID: RetailerID,
        Email: Email,
        ShopName: ShopName,
        TransactionAmount: e.target.value,
      };
    });
  };

  const columns = [
    {
      name: "S.No",
      cell: (row, index) => index + 1,
      width: "80px",
      sortable: true,
    },
    {
      name: "Invoice No",
      selector: (row) => row.InvoiceNo,
      sortable: true,
      width: "180px",
    },
    {
      name: "Invoice Date",
      selector: (row) => row.InvoiceDate,
      sortable: true,
      width: "150px",
    },
    {
      name: "Invoice Amt",
      selector: (row) => row.InvoiceAmount,
      sortable: true,
      width: "150px",
    },
    {
      name: "Paid Amt",
      selector: (row) => row.PaymentAmount,
      sortable: true,
      width: "150px",
    },
    {
      name: "Balance Amt",
      selector: (row) => row.BalanceAmount,
      sortable: true,
      width: "150px",
    },
    {
      name: "Payment Status",
      selector: (row) => row.PaymentStatus,
      sortable: true,
      width: "200px",
    },
    {
      name: "Download Invoice",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row) => (
        <a
          style={{ display: "table-cell" }}
          href={row.DocumentURL}
          target="_blank"
          rel="noreferrer"
        >
          <img src={downloadArrow} alt="Download" title="Download" />
        </a>
      ),
      ignoreRowClick: true,
      button: true,
      width: "120px",
    },
  ];
  const btnContinueClickEvent = (e) => {
    e.preventDefault();
    GetInvoicesOfRetailer();
    setSearch("");
    setStatus("--Status--");
    // GetInvoicesOfRetailer();
    // setFilterInvoiceList(invoiceList);
    setAmountToPay(intialstate);
    setDisableProcToPay(false);
    setDisablePyStatus(true);
  };

  const btnStatusClickEvent = (e) => {
    e.preventDefault();
    const data = JSON.stringify({
      PaymentLinkID: paymentLinkID,
    });
    const razorStatusConfig = {
      method: "post",
      url: "https://workspace.benfranklin.in/EyegearBackofficeWebAPI_Production/api/LensMaster/GetRazorpaymentStatus",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(razorStatusConfig)
      .then((response) => {
        if (response?.data?.result) {
          showSuccess(`${response?.data?.message}`);
        } else {
          showError(`${response?.data?.message}`);
        }
      })
      .catch((err) => {
        showError(err);
      });
  };

  const btnProceedClickEvent = (e) => {
    e.preventDefault();
    if (!amountToPay) {
      showError("Please enter amount to pay.");
      return;
    }
    setLoading(true);
    const config = {
      method: "post",
      url: "https://workspace.benfranklin.in/EyegearBackofficeWebAPI_Production/api/LensMaster/RazorpaymentRequest",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      data: JSON.stringify(razorRequest),
    };
    const responseOut = axios(config);
    setTimeout(() => {
      responseOut
        .then((response) => {
          if (response?.data?.result) {
            setPaymentLinkID(response?.data?.paymentLinkId);
            showSuccess(
              // eslint-disable-next-line max-len
              `${response?.data?.message} For this transactionNo ${response?.data?.transactionNo}`
            );
            setDisableProcToPay(true);
            setDisablePyStatus(false);
          } else {
            showError(`${response?.data?.message}`);
          }
        })
        .catch((err) => {
          showError(err);
        });
      setLoading(false);
    }, 20000);
  };

  return (
    <div className="container">
      <h2 className={MyInvoiceStyles.h2}>My Invoices</h2>
      <div className="container" style={{ textAlign: "center" }}>
        {typeof invoiceList != undefined &&
        invoiceList != null &&
        invoiceList.length > 0 &&
        Object.keys(invoiceList[0]).length != 0 ? (
          <div className="container">
            <Card>
              <DataTable
                columns={columns}
                data={filterInvoiceList}
                paginationRowsPerPageOptions={[10, 20, 30, 50, 100, 200]}
                paginationComponentOptions={{
                  rowsPerPageText: "Invoices per page",
                }}
                sortIcon={<SortIcon />}
                className={MyInvoiceStyles.gwNKnB}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="450px"
                highlightOnHover
                subHeader
                subHeaderComponent={
                  <>
                    <select
                      value={status}
                      className="form-select"
                      style={{
                        width: "150px",
                        marginRight: "10px",
                      }}
                      aria-label="Default select example"
                      id="cmbStatus"
                      onChange={cmbStatusChangeEvent}
                    >
                      <option>--Status--</option>
                      <option>Paid</option>
                      <option>Partially Paid</option>
                      <option>Unpaid</option>
                    </select>
                    <input
                      type="text"
                      placeholder="Search here"
                      className="w-25 form-control"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </>
                }
                conditionalRowStyles={conditionalRowStyles}
              />
            </Card>
          </div>
        ) : (
          "No Data Found"
        )}
      </div>
      {loading ? (
        <div className="container">
          <SpinnerBomb />
        </div>
      ) : (
        ""
      )}
      <form className="mt-3 ps-5">
        <div className="row ">
          <div
            className="col"
            style={{
              marginTop: "6px",
              textAlign: "right",
              color: "green",
              fontSize: "18px",
              fontFamily: "bold",
            }}
          >
            <Label htmlFor="txtBalanceAmt">Total Due Amount:</Label>{" "}
          </div>
          <div className="col">
            <input
              type="text"
              readOnly
              value={balanceAmt}
              className="form-control-plaintext"
              id="txtBalanceAmt"
              style={{ fontFamily: "bold", fontSize: "18px" }}
            />
          </div>
        </div>
        <div className="row ">
          <div
            className="col"
            style={{
              marginTop: "6px",
              textAlign: "right",
              color: "green",
              fontSize: "18px",
              fontFamily: "bold",
            }}
          >
            <Label htmlFor="txtProceedAmount">Amount to Pay:</Label>{" "}
          </div>
          <div className="col">
            <input
              type="text"
              value={amountToPay}
              onChange={(e) => {
                amountToPayOnchangeEvent(e);
              }}
              placeholder="amount to pay"
              className="form-control"
              id="txtProceedAmount"
              style={{ width: "150px" }}
              disabled={loading || disableProcToPay}
            />
          </div>
        </div>
        <div className="row">
          <div
            className="col"
            style={{
              marginTop: "6px",
              textAlign: "center",
            }}
          >
            <input
              type="button"
              className="btn btn-primary"
              value="Proceed To Pay"
              onClick={(e) => {
                btnProceedClickEvent(e);
              }}
              disabled={loading || disableProcToPay}
            />
            <input
              type="button"
              style={{ marginLeft: "10px" }}
              className="btn btn-primary"
              value="Payment Status"
              onClick={(e) => {
                btnStatusClickEvent(e);
              }}
              disabled={loading || disablePyStatus}
            />
            <input
              type="button"
              style={{ marginLeft: "10px" }}
              // eslint-disable-next-line max-len
              className={`btn btn-primary ${MyInvoiceStyles.ctnbutton}`}
              value="Continue"
              onClick={(e) => {
                btnContinueClickEvent(e);
              }}
              disabled={loading}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default MyInvoices;

MyInvoices.propTypes = {
  RetailerID: PropTypes.any,
  MobileNO: PropTypes.any,
  ShopName: PropTypes.any,
  Email: PropTypes.any,
};
