import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ProductInfo from "../../../components/ProductInfo";
import PropTypes from "prop-types";
export default function AddToOrderPopUp(props) {
  const { isToggle, productModalData, handleToggle } = props;
  const [modal, setModal] = useState(isToggle);
  const toggle = () => {
    setModal(!modal);
    handleToggle(!modal);
  };
  return (
    <>
      <div>
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="modal-xl order-modal order-modal-mbv"
        >
          <ModalHeader toggle={toggle} />
          <ModalBody>
            <ProductInfo isFromModal={true} productData={productModalData} />
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}
AddToOrderPopUp.propTypes = {
  isToggle: PropTypes.boolean,
  productModalData: PropTypes.object,
  handleToggle: PropTypes.func,
};
