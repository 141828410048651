import { LOGIN_CONST } from "./loginConst";

/**
 *  User Login actions
 */
export const loginUser = (formProps) => ({
  type: LOGIN_CONST.LOGIN_REQUEST,
  payload: formProps,
});

export const loginUserSuccess = (response) => ({
  type: LOGIN_CONST.LOGIN_SUCCESS,
  payload: response,
});

export const loginUserFailed = (error) => ({
  type: LOGIN_CONST.LOGIN_FAILED,
  payload: error,
});

/**
 *  User carousel actions
 */
export const loginCarousel = () => ({
  type: LOGIN_CONST.LOGIN_CAROUSEL_REQUEST,
  payload: null,
});

export const loginCarouselSuccess = (response) => ({
  type: LOGIN_CONST.LOGIN_CAROUSEL_SUCCESS,
  payload: response,
});

export const loginCarouselFailed = (error) => ({
  type: LOGIN_CONST.LOGIN_CAROUSEL_FAILED,
  payload: error,
});

/**
 *  forgot password actions
 */
export const forgotPasswordRequest = (payload) => ({
  type: LOGIN_CONST.FORGOT_PASSWORD_REQUEST,
  payload: payload || null,
});

export const forgotPasswordSuccess = (response) => ({
  type: LOGIN_CONST.FORGOT_PASSWORD_SUCCESS,
  payload: response,
});

export const forgotPasswordFailed = (error) => ({
  type: LOGIN_CONST.FORGOT_PASSWORD_FAILED,
  payload: error,
});

export const validateTokenRequest = (payload) => ({
  type: LOGIN_CONST.VALIDATE_RESET_TOKEN_REQUEST,
  payload: payload || null,
});

export const validateTokenSuccess = (response) => ({
  type: LOGIN_CONST.VALIDATE_RESET_TOKEN_SUCCESS,
  payload: response,
});

export const validateTokenFailed = (error) => ({
  type: LOGIN_CONST.VALIDATE_RESET_TOKEN_FAILED,
  payload: error,
});

export const resetPasswordRequest = (payload) => ({
  type: LOGIN_CONST.RESET_PASSWORD_REQUESTS,
  payload: payload,
});

export const resetPasswordSuccess = (response) => ({
  type: LOGIN_CONST.RESET_PASSWORD_SUCCESS,
  payload: response,
});

export const resetPasswordFailed = (error) => ({
  type: LOGIN_CONST.RESET_PASSWORD_FAILED,
  payload: error,
});
