import React, { useEffect, useState } from "react";
import { Form, FormGroup, Input } from "reactstrap";
import PropTypes from "prop-types";
import PlusIcon from "../../assets/Images/plus_icon.png";
import { PRODUCTINFO } from "../../helpers/Constants";
import MinusIcon from "../../assets/Images/minus_icon.png";
import { useTranslation } from "react-multi-lang";
// import { isKeyAvailable } from "../../helpers/Utility";

function ProductQuantity({
  quantityNumber,
  inputQuantity,
  index,
  rSelected,
  // fromPurchaseOrder,
  errorHandler,
  availabileStockQuantity,
  maxLimitPerVariant,
  colorVariant,
  loading,
}) {
  const t = useTranslation();
  const [quantityError, setQuantityError] = useState(false);
  const [newQuantityNumber, setQuantityNumber] = useState(quantityNumber);
  const [isInputChanged, setIsInputChanged] = useState(false);
  const [maximumQuantity, setMaximumQuantity] = useState();
  const [enableMinus, setEnableMinus] = useState(false);
  const [enablePlus, setEnablePlus] = useState(true);
  const [disabledInput, setDisabledInput] = useState(false);
  useEffect(() => {
    setDisabledInput(loading);
    if (loading) {
      setEnableMinus(!loading);
      setEnablePlus(!loading);
    } else {
      setEnableMinus(!loading);
      setEnablePlus(!loading);
    }
  }, [loading]);
  useEffect(() => {
    setMaximumQuantity(maxLimitPerVariant);
  }, []);
  useEffect(() => {
    if (index === rSelected) {
      setQuantityNumber(quantityNumber);
    }
  }, [quantityNumber]);
  useEffect(() => {
    if (
      newQuantityNumber < PRODUCTINFO.MIN_CART_SIZE - 1 ||
      (newQuantityNumber > availabileStockQuantity &&
        availabileStockQuantity > 0) ||
      newQuantityNumber > maximumQuantity
    ) {
      setQuantityError(true);
      if (!disabledInput) {
        if (newQuantityNumber - 1 < 0) {
          setEnableMinus(false);
          setEnablePlus(true);
        }

        if (
          newQuantityNumber + 1 > maximumQuantity + 1 ||
          newQuantityNumber + 1 > availabileStockQuantity + 1
        ) {
          setEnableMinus(true);
          setEnablePlus(false);
        }
        errorHandler(index, true);
      }
    } else if (isInputChanged === true) {
      setQuantityError(false);
      if (
        newQuantityNumber - 1 >= 0 &&
        newQuantityNumber < maximumQuantity + 1
      ) {
        setEnableMinus(true);
        setEnablePlus(true);
        errorHandler(index, false);
      }

      if (!disabledInput) {
        setIsInputChanged(false);
        inputQuantity(parseInt(newQuantityNumber), index, colorVariant);
      }
    }
  }, [newQuantityNumber]);
  const handleMinusQuantity = () => {
    if (newQuantityNumber - 1 >= 0) {
      setIsInputChanged(true);
      setQuantityNumber(newQuantityNumber - 1);
    } else {
      setEnableMinus(false);
    }

    if (newQuantityNumber >= 0 && newQuantityNumber <= maximumQuantity) {
      setQuantityError(false);
    }
  };
  const handlePlusQuantity = () => {
    if (newQuantityNumber + 1 <= maximumQuantity + 1) {
      setIsInputChanged(true);
      setEnableMinus(true);
      setQuantityNumber(newQuantityNumber + 1);
    } else {
      setEnablePlus(false);
    }
  };
  const handleInputQuantity = (e) => {
    if (
      e.target.value > maximumQuantity ||
      e.target.value < PRODUCTINFO.MIN_CART_SIZE - 1
    ) {
      setQuantityError(true);
      let num = parseInt(e.target.value);
      setQuantityNumber(num);
    } else {
      setIsInputChanged(true);
      setQuantityError(false);
      let num = parseInt(e.target.value);
      setQuantityNumber(num);
    }
    if (e.target.value == 0) {
      setEnableMinus(false);
      setQuantityError(false);
    }
    if (e.target.value > 0 && e.target.value <= maximumQuantity) {
      setQuantityError(false);
      setEnableMinus(true);
    }
  };

  document.addEventListener("keypress", function (e) {
    if (e.key === "Enter") {
      e.preventDefault();
      return false;
    }
  });
  return (
    <>
      <div className="product-quantity">
        {availabileStockQuantity <= 0 ? (
          <span className="quantity-error-message outOfStock-error-message">
            {t("CATALOG.OUT_OF_STOCK")}
          </span>
        ) : (
          <span className="product-quantity-box">
            <Form>
              <FormGroup>
                {newQuantityNumber > availabileStockQuantity ||
                newQuantityNumber > maximumQuantity ? (
                  newQuantityNumber > maximumQuantity ? (
                    <span className="quantity-error-message">
                      {" "}
                      {t("ORDER.COLOR_QUANTITY_LIMIT_ERROR_MESSAGE")}
                    </span>
                  ) : (
                    <span className="quantity-error-message">
                      {" "}
                      {`${`Only ${availabileStockQuantity} available in stock!`}`}
                    </span>
                  )
                ) : (
                  ""
                )}
                <span
                  onClick={handleMinusQuantity}
                  className={
                    !enableMinus
                      ? "minus-icon-diabled"
                      : index === rSelected
                      ? "minus-icon"
                      : "minus-icon-diabled"
                  }
                >
                  <img src={MinusIcon} />
                </span>
                <Input
                  type="number"
                  value={newQuantityNumber}
                  className={
                    disabledInput
                      ? "disabled-product-quantity-number"
                      : index === rSelected
                      ? "product-quantity-number"
                      : "disabled-product-quantity-number"
                  }
                  onChange={(e) => handleInputQuantity(e)}
                  invalid={quantityError}
                  id={index}
                  disabled={
                    disabledInput ? true : index === rSelected ? false : true
                  }
                />
                <span
                  onClick={handlePlusQuantity}
                  className={
                    !enablePlus
                      ? "plus-icon-diabled"
                      : index === rSelected
                      ? "plus-icon"
                      : "plus-icon-diabled"
                  }
                >
                  <img src={PlusIcon} />
                </span>
              </FormGroup>
            </Form>
          </span>
        )}
      </div>
    </>
  );
}

export default ProductQuantity;
ProductQuantity.propTypes = {
  quantityNumber: PropTypes.quantityNumber,
  inputQuantity: PropTypes.func,
  index: PropTypes.number,
  rSelected: PropTypes.number,
  // fromPurchaseOrder: PropTypes.bool,
  errorHandler: PropTypes.func,
  availabileStockQuantity: PropTypes.number,
  maxLimitPerVariant: PropTypes.number,
  colorVariant: PropTypes.string,
  loading: PropTypes.bool,
};
