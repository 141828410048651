import { LOGIN_CONST } from "./loginConst";

const Login = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_CONST.LOGIN_REQUEST:
      return {
        ...state,
        isLoginRequesting: true,
        loginData: false,
        loginDataError: false,
        loginDataNetworkError: false,
      };
    case LOGIN_CONST.LOGIN_SUCCESS:
      return {
        ...state,
        isLoginRequesting: false,
        loginData: action.payload.response,
        loginDataError: false,
        loginDataNetworkError: false,
      };
    case LOGIN_CONST.LOGIN_FAILED:
      return {
        ...state,
        isLoginRequesting: false,
        loginData: false,
        loginDataError: action.payload.error ? false : action.payload.response,
        loginDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
    case LOGIN_CONST.LOGIN_CAROUSEL_REQUEST:
      return {
        ...state,
        isLoginCarouselRequesting: true,
        loginCarouselData: false,
        loginCarouselError: false,
        loginCarouselNetworkError: false,
      };
    case LOGIN_CONST.LOGIN_CAROUSEL_SUCCESS:
      return {
        ...state,
        isLoginCarouselRequesting: false,
        loginCarouselData: action.payload.response,
        loginCarouselError: false,
        loginCarouselNetworkError: false,
      };
    case LOGIN_CONST.LOGIN_CAROUSEL_FAILED:
      return {
        ...state,
        isLoginCarouselRequesting: false,
        loginCarouselData: false,
        loginCarouselError: action.payload.error
          ? false
          : action.payload.response,
        loginCarouselNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };

    case LOGIN_CONST.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isForgotPasswordRequesting: true,
        forgotPasswordData: false,
        forgotPasswordDataError: false,
      };
    case LOGIN_CONST.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isForgotPasswordRequesting: false,
        forgotPasswordData: action.payload.response,
        forgotPasswordDataError: false,
      };
    case LOGIN_CONST.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        isForgotPasswordRequesting: false,
        forgotPasswordData: false,
        forgotPasswordError: action.payload.error
          ? false
          : action.payload.response,
      };
    case LOGIN_CONST.VALIDATE_RESET_TOKEN_REQUEST:
      return {
        ...state,
        validateTokenRequesting: true,
        validateTokenData: false,
        validateTokenDataError: false,
      };
    case LOGIN_CONST.VALIDATE_RESET_TOKEN_SUCCESS:
      return {
        ...state,
        validateTokenRequesting: false,
        validateTokenData: action.payload.response.data,
        validateTokenDataError: false,
      };
    case LOGIN_CONST.VALIDATE_RESET_TOKEN_FAILED:
      return {
        ...state,
        validateTokenRequesting: false,
        validateTokenData: false,
        validateTokenDataError: action.payload.response.error
          ? false
          : action.payload.response,
      };
    case LOGIN_CONST.RESET_PASSWORD_REQUESTS:
      return {
        ...state,
        resetPasswordRequesesting: true,
        resetPasswordData: false,
        resetPasswordError: false,
      };
    case LOGIN_CONST.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordRequesesting: false,
        resetPasswordData: action.payload.response,
        resetPasswordError: false,
      };
    case LOGIN_CONST.RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetPasswordRequesesting: false,
        resetPasswordData: null,
        resetPasswordError: action.payload.error
          ? false
          : action.payload.response,
      };
    default:
      return { ...state };
  }
};

export default Login;
