import { AXIOS_INSTANCE, BASE_URL } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  changePasswordFailed,
  changePasswordSuccess,
  getCitiesFailed,
  getCitiesSuccess,
  getCountriesFailed,
  getCountriesSuccess,
  getRetailersListFailed,
  getRetailersListSuccess,
  getStatesFailed,
  getStatesSuccess,
  getUserProfileFailed,
  getUserProfileSuccess,
  updateUserProfileFailed,
  updateUserProfileSuccess,
} from "./profileAction";
import { getToken, unAuthorizedRedirection } from "./../../helpers/Utility";

import { AccountClient } from "../apibenfranklin";
import { PROFILE_CONST } from "./profileConst";

const client = new AccountClient(`${BASE_URL}`, AXIOS_INSTANCE);
/**
 *  UserProfile Saga
 */
const getUserProfileApiRequest = async () => {
  let response = {};
  let token = `bearer  ${getToken()}`;
  try {
    const data = await client.getUserProfile(token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const getCountriesApiRequest = async () => {
  let response = {};
  let token = `bearer  ${getToken()}`;
  try {
    const data = await client.getCountries(token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const getStatesApiRequest = async () => {
  let response = {};
  let token = `bearer  ${getToken()}`;
  try {
    const data = await client.getStates(null, token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const getCitiesApiRequest = async () => {
  let response = {};
  let token = `bearer  ${getToken()}`;
  try {
    const data = await client.getCities(null, token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const updateUserProfileApiRequest = async (payload) => {
  let response = {};
  let token = `bearer  ${getToken()}`;
  try {
    const data = await client.updateUserProfile(
      payload.UpdateUserProfile || null,
      token
    );
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getUserProfile(action) {
  try {
    const apiResponse = yield call(getUserProfileApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getUserProfileSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getUserProfileFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getUserProfileFailed(errorData));
  }
}

function* getCountries(action) {
  try {
    const apiResponse = yield call(getCountriesApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getCountriesSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getCountriesFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getCountriesFailed(errorData));
  }
}

function* getStates(action) {
  try {
    const apiResponse = yield call(getStatesApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getStatesSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getStatesFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getStatesFailed(errorData));
  }
}

function* getCities(action) {
  try {
    const apiResponse = yield call(getCitiesApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getCitiesSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getCitiesFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getStatesFailed(errorData));
  }
}

function* updateUserProfile(action) {
  try {
    const apiResponse = yield call(updateUserProfileApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(updateUserProfileSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(updateUserProfileFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(updateUserProfileFailed(errorData));
  }
}

const changePasswordApiRequest = async (payload) => {
  let response = {};
  let token = `bearer  ${getToken()}`;
  try {
    const data = await client.changePassword(
      payload.changePassword || null,
      token
    );
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* changePassword(action) {
  try {
    const apiResponse = yield call(changePasswordApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(changePasswordSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(changePasswordFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(changePasswordFailed(errorData));
  }
}

const getRetailersListApiRequest = async () => {
  let response = {};
  let token = `bearer  ${getToken()}`;
  try {
    const data = await client.retailersList(token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getRetailersList(action) {
  try {
    const apiResponse = yield call(getRetailersListApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getRetailersListSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getRetailersListFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getRetailersListFailed(errorData));
  }
}

export function* watchLoginUser() {
  yield takeEvery(PROFILE_CONST.GET_USER_PROFILE_REQUEST, getUserProfile);
  yield takeEvery(PROFILE_CONST.GET_COUNTRIES_REQUEST, getCountries);
  yield takeEvery(PROFILE_CONST.GET_STATES_REQUEST, getStates);
  yield takeEvery(PROFILE_CONST.GET_CITIES_REQUEST, getCities);
  yield takeEvery(PROFILE_CONST.UPDATE_USER_PROFILE_REQUEST, updateUserProfile);
  yield takeEvery(PROFILE_CONST.CHANGE_PASSWORD_REQUEST, changePassword);
  yield takeEvery(PROFILE_CONST.GET_RETAILERS_LISTS_REQUEST, getRetailersList);
}

function* userProfileSaga() {
  yield all([fork(watchLoginUser)]);
}

export default userProfileSaga;
