import "./BlogsPage.scss";

import React, { useEffect, useMemo, useState } from "react";

import { BlogsDetails } from "./BlogsDetails";
import { Link } from "react-router-dom";
import blogBanner from "./../../assets/Images/BlogImages/Blog-Header.webp";

const BlogsPage = () => {
  document.title = "Eyegear";
  const blogsPerPage = 12;
  const [visibleBlogs, setVisibleBlogs] = useState(
    parseInt(localStorage.getItem("visibleBlogs")) || blogsPerPage
  );

  useEffect(() => {
    localStorage.setItem("visibleBlogs", visibleBlogs.toString());
  }, [visibleBlogs]);

  const handleLoadMore = () => {
    setVisibleBlogs((prevBlogs) => prevBlogs + blogsPerPage);
  };

  const memoizedBlogs = useMemo(() => {
    const reversed = [...BlogsDetails].reverse();
    return reversed.slice(0, visibleBlogs).map((blog, index) => {
      const { imageData, title } = blog;
      const createSlug = (title) => {
        return title
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, "-")
          .replace(/(^-|-$)/g, "");
      };
      const slug = createSlug(title);
      return (
        <div className="blog__page__container" key={index}>
          <Link
            to={{
              pathname: `/blog/${slug}`,
            }}
          >
            <div className="blog-img-container">
              <div className="img-container">
                <img src={imageData} alt={title} />
              </div>
              <h4>{title}</h4>
            </div>
          </Link>
        </div>
      );
    });
  }, [BlogsDetails, visibleBlogs]);

  window.onload = () => {
    localStorage.setItem("visibleBlogs", blogsPerPage);
    setVisibleBlogs(blogsPerPage);
  };

  return (
    <>
      <header>
        <img src={blogBanner} alt="blog banner" />
      </header>
      <section className="section__padding blog__page">{memoizedBlogs}</section>
      {visibleBlogs < BlogsDetails.length && (
        <div className="loadmore__container section__padding">
          <button onClick={handleLoadMore} className="loadmore__btn">
            Load More
          </button>
        </div>
      )}
    </>
  );
};

export default BlogsPage;
