import { AXIOS_INSTANCE, BASE_URL } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { PRICES_CONST } from "./pricesConst";
import { getPricesSuccess, getPricesFailed } from "./pricesAction";
import { HomeClient } from "../apibenfranklin";
import { getToken, unAuthorizedRedirection } from "./../../helpers/Utility";

const client = new HomeClient(`${BASE_URL}`, AXIOS_INSTANCE);

/**
 *  Prices Saga
 */
const getPricesApiRequest = async () => {
  let response = {};
  let token = `bearer  ${getToken()}`;
  try {
    const data = await client.getPriceRanges(token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getPrices(action) {
  try {
    const apiResponse = yield call(getPricesApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getPricesSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getPricesFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getPricesFailed(errorData));
  }
}

export function* watchLoginUser() {
  yield takeEvery(PRICES_CONST.PRICES_CONST_REQUEST, getPrices);
}

function* pricesSaga() {
  yield all([fork(watchLoginUser)]);
}

export default pricesSaga;
