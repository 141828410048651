import { INVENTORY } from "./inventoryConst";

const GetInventory = (state = {}, action) => {
  switch (action.type) {
    case INVENTORY.GET_INVENTORY_REQUESTING:
      return {
        ...state,
        isgetInventoryRequesting: true,
        getInventoryData: false,
        getInventoryError: false,
      };
    case INVENTORY.GET_INVENTORY_SUCCESS:
      return {
        ...state,
        isgetInventoryRequesting: false,
        getInventoryData: action.payload.response,
        getInventoryError: false,
      };
    case INVENTORY.GET_INVENTORY_FAILED:
      return {
        ...state,
        isgetInventoryRequesting: false,
        getInventoryData: false,
        getInventoryError: action.payload.error
          ? false
          : action.payload.response,
      };
    case INVENTORY.GET_INVENTORY_BY_USER_REQUESTING:
      return {
        ...state,
        isgetInventoryByUserRequesting: true,
        getInventoryByUserData: false,
        getInventoryByUserError: false,
      };
    case INVENTORY.GET_INVENTORY_BY_USER_SUCCESS:
      return {
        ...state,
        isgetInventoryByUserRequesting: false,
        getInventoryByUserData: action.payload.response,
        getInventoryByUserError: false,
      };
    case INVENTORY.GET_INVENTORY_BY_USER_FAILED:
      return {
        ...state,
        isgetInventoryByUserRequesting: false,
        getInventoryByUserData: false,
        getInventoryByUserError: action.payload.error
          ? false
          : action.payload.response,
      };

    default:
      return { ...state };
  }
};

export default GetInventory;
