import React from "react";
import { useTranslation } from "react-multi-lang";
import { Table } from "reactstrap";
import PropTypes from "prop-types";
import Button from "reactstrap-button-loader";
import RupeeIcon from "../../../assets/Images/rupee_icon.png";

function PaymentSummary({
  paymentSummaryData,
  getPlaceOrder,
  isPlaceOrderRequesting,
  // updateQuantity,
  validateQuantity,
}) {
  const t = useTranslation();
  // const [quantityData, setQuantityData] = useState(updateQuantity);
  const handlePlaceOrder = () => {
    getPlaceOrder();
  };

  // useEffect(() => {
  //   setQuantityData(updateQuantity);
  // }, [updateQuantity]);
  return (
    <div className="payment-section mb-4">
      <p className="mb-2">{t("ORDER.PAYMENT_SUMMARY")}</p>
      <Table>
        <tr>
          <td className="payment-label">{t("ORDER.SUBTOTAL")}</td>
          <td className="payment-value">
            <img src={RupeeIcon} />
            {paymentSummaryData.subTotalPrice}
          </td>
        </tr>
        {parseInt(paymentSummaryData.totalIGSTValue) > 0 ? (
          <tr>
            <td className="payment-label pb-4">{t("ORDER.IGST")}</td>
            <td className="payment-value pb-4">
              <img src={RupeeIcon} />
              {paymentSummaryData.totalIGSTValue}
            </td>
          </tr>
        ) : (
          <>
            <tr>
              <td className="payment-label">{t("ORDER.CGST")}</td>
              <td className="payment-value">
                <img src={RupeeIcon} />
                {paymentSummaryData.totalCGSTValue}
              </td>
            </tr>
            <tr>
              <td className="payment-label pb-2">{t("ORDER.SGST")}</td>
              <td className="payment-value pb-2">
                <img src={RupeeIcon} />
                {paymentSummaryData.totalSGSTValue}
              </td>
            </tr>
          </>
        )}

        <tr className="order-total-section">
          <td className="order-total pt-2">{t("ORDER.ORDER_TOTAL")}</td>
          <td className="payment-value pt-2">
            <img src={RupeeIcon} />
            {paymentSummaryData.totalPrice}
          </td>
        </tr>
      </Table>
      <Button
        className="place-order-btn"
        loading={isPlaceOrderRequesting}
        onClick={() => {
          handlePlaceOrder();
        }}
        disabled={validateQuantity}
      >
        {t("ORDER.PLACE_ORDER")}
      </Button>
    </div>
  );
}

export default PaymentSummary;
PaymentSummary.propTypes = {
  paymentSummaryData: PropTypes.object.isRequired,
  getPlaceOrder: PropTypes.func.isRequired,
  isPlaceOrderRequesting: PropTypes.bool.isRequired,
  updateQuantity: PropTypes.object.isRequired,
  validateQuantity: PropTypes.bool.isRequired,
};
