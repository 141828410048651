export const LOGIN_CONST = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  LOGIN_CAROUSEL_REQUEST: "LOGIN_CAROUSEL_REQUEST",
  LOGIN_CAROUSEL_SUCCESS: "LOGIN_CAROUSEL_SUCCESS",
  LOGIN_CAROUSEL_FAILED: "LOGIN_CAROUSEL_FAILED",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILED: "FORGOT_PASSWORD_FAILED",
  VALIDATE_RESET_TOKEN_REQUEST: "VALIDATE_RESET_TOKEN_REQUEST",
  VALIDATE_RESET_TOKEN_SUCCESS: "VALIDATE_RESET_TOKEN_SUCCESS",
  VALIDATE_RESET_TOKEN_FAILED: "VALIDATE_RESET_TOKEN_FAILED",
  RESET_PASSWORD_REQUESTS: "RESET PASSWORD REQUESTS",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",
};
