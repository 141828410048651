import { PRICES_CONST } from "./pricesConst";

const prices = (state = {}, action) => {
  switch (action.type) {
    case PRICES_CONST.PRICES_CONST_REQUEST:
      return {
        ...state,
        isPricesRequesting: true,
        pricesData: false,
        pricesDataError: false,
      };
    case PRICES_CONST.PRICES_CONST_SUCCESS:
      return {
        ...state,
        isPricesRequesting: false,
        pricesData: action.payload.response,
        pricesDataError: false,
      };
    case PRICES_CONST.PRICES_CONST_FAILED:
      return {
        ...state,
        isPricesRequesting: false,
        pricesData: false,
        pricesDataError: action.payload.error ? false : action.payload.response,
      };

    default:
      return { ...state };
  }
};

export default prices;
