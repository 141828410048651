import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import en from "./translations/en.json";
import reportWebVitals from "./reportWebVitals";
import ViewsLayout from "./layouts";
import { setTranslations, setDefaultLanguage } from "react-multi-lang";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "../src/assets/CSS/Custom.css";
import "../src/assets/CSS/Common.css";
import "../src/assets/CSS/Custom.css";
import "react-toastify/dist/ReactToastify.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

setTranslations({ en });
setDefaultLanguage(process.env.REACT_APP_DEFAULT_LANGUAGE);
ReactDOM.render(
  <Provider store={configureStore()}>
    <BrowserRouter>
      <Route path="/" component={ViewsLayout} />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
