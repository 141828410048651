import { AXIOS_INSTANCE, BASE_URL } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { LOGIN_CONST } from "./loginConst";
import {
  loginUserSuccess,
  loginUserFailed,
  loginCarouselSuccess,
  loginCarouselFailed,
  forgotPasswordSuccess,
  forgotPasswordFailed,
  validateTokenSuccess,
  validateTokenFailed,
  resetPasswordSuccess,
  resetPasswordFailed,
} from "./loginActions";
import { AccountClient, Client } from "../apibenfranklin";

const client = new Client(`${BASE_URL}`, AXIOS_INSTANCE);

/**
 *  Login
 */
const loginUserApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await client.token(
      "password",
      payload.email,
      payload.password
    );
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* loginUser(action) {
  try {
    const apiResponse = yield call(loginUserApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(loginUserSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(loginUserFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(loginUserFailed(errorData));
  }
}

/**
 *  Login carousel
 */
const accountClient = new AccountClient(`${BASE_URL}`, AXIOS_INSTANCE);
const loginCarouselApiRequest = async () => {
  let response = {};
  try {
    const data = await accountClient.getLoginBanners();
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* loginCarousel(action) {
  try {
    const apiResponse = yield call(loginCarouselApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(loginCarouselSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(loginCarouselFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(loginCarouselFailed(errorData));
  }
}

const forgotPasswordApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await accountClient.forgotPassword(payload);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* forgotPasswordRequest(action) {
  console.log(action);
  try {
    const apiResponse = yield call(forgotPasswordApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(forgotPasswordSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(forgotPasswordFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(forgotPasswordFailed(errorData));
  }
}

const validateTokenApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await accountClient.validateToken(payload);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* validateTokenRequest(action) {
  try {
    const apiResponse = yield call(validateTokenApiRequest, action.payload);
    console.log(apiResponse);
    if (apiResponse.status === 200 && apiResponse.data.result) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(validateTokenSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data.error,
        },
      };
      yield put(validateTokenFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(validateTokenFailed(errorData));
  }
}

const resetPasswordApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await accountClient.resetPassword(payload);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* resetPassword(action) {
  try {
    const apiResponse = yield call(resetPasswordApiRequest, action.payload);
    if (apiResponse.status === 200 && apiResponse.data.result) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(resetPasswordSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data.error,
        },
      };
      yield put(resetPasswordFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(resetPasswordFailed(errorData));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_CONST.LOGIN_REQUEST, loginUser);
  yield takeEvery(LOGIN_CONST.LOGIN_CAROUSEL_REQUEST, loginCarousel);
  yield takeEvery(LOGIN_CONST.FORGOT_PASSWORD_REQUEST, forgotPasswordRequest);
  yield takeEvery(
    LOGIN_CONST.VALIDATE_RESET_TOKEN_REQUEST,
    validateTokenRequest
  );
  yield takeEvery(LOGIN_CONST.RESET_PASSWORD_REQUESTS, resetPassword);
}

function* loginSaga() {
  yield all([fork(watchLoginUser)]);
}

export default loginSaga;
