import { Col, Row } from "reactstrap";

import React from "react";
import Smile from "../assets/Images/smile.png";
import Suggestion from "../assets/Images/suggestion.png";
import Warning from "../assets/Images/warning.png";
import { toast } from "react-toastify";

export const showError = (message) => {
  return toast.error(
    <Row className="alert-row">
      <Col sm="3" md="2" lg="2" xs="3" className="image-background">
        <img src={Warning} />
      </Col>
      <Col sm="9" md="10" lg="10" xs="9" className="alert-message-col">
        <h6>Error</h6>
        <h4>{message}</h4>
      </Col>
    </Row>,
    {
      hideProgressBar: false,
      autoClose: 3000,
    }
  );
};

export const showSuccess = (message) => {
  return toast.success(
    <Row className="alert-row">
      <Col sm="3" md="2" lg="2" xs="3" className="image-background">
        <img src={Smile} />
      </Col>
      <Col sm="9" md="10" lg="10" xs="9" className="alert-message-col">
        <h6>Success</h6>
        <h4>{message}</h4>
      </Col>
    </Row>,
    {
      hideProgressBar: false,
      autoClose: 3000,
    }
  );
};
// done by mani
export const tabAlert = (message) => {
  return toast.error(
    <Row className="alert-row">
      <Col sm="3" md="2" lg="2" xs="3" className="image-background">
        <img src={Suggestion} />
      </Col>
      <Col sm="9" md="10" lg="10" xs="9" className="alert-message-col">
        <h7>Suggestion</h7>
        <h4>{message}</h4>
      </Col>
    </Row>,
    {
      hideProgressBar: false,
      autoClose: 2000,
    }
  );
};

export const handleLoginDuplicate = (item) => {
  let loginParams = "";
  let condition =
    JSON.parse(localStorage.getItem("firstLogin")) == null
      ? false
      : JSON.parse(localStorage.getItem("firstLogin"));

  if (condition) {
    loginParams = localStorage.getItem("loginTab");
    if (loginParams === item) {
      tabAlert(`Eyegear Partner Log-in - tab already opened.`);
    } else {
      loginParams = item;
      localStorage.setItem("loginTab", loginParams);
      window.open(`${window.location.origin}/login`);
    }
  } else {
    loginParams = item;
    localStorage.setItem("firstLogin", "true");
    localStorage.setItem("loginTab", loginParams);
    window.open(`${window.location.origin}/login`);
  }
};
