import React from "react";
import spinner from "../../assets/Images/spinner.gif";

const SpinnerBomb = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <img
        src={spinner}
        style={{ width: "50px", margin: "auto", display: "block" }}
        alt=""
      />
      <strong>Processing . Please wait.....</strong>
    </div>
  );
};

export default SpinnerBomb;
