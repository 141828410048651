import {
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
} from "reactstrap";
import React, { useEffect, useRef, useState } from "react";
import { faBars, faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { setConfig, userLogOut } from "../../helpers/Utility";
import { useDispatch, useSelector } from "react-redux";

import CartIcon from "../../assets/Images/cart_icon.png";
import CustomDropdown from "../Dropdown";
import ExpressOrder from "../../assets/Images/express-checkout.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FramesDropdown from "./FramesDropdown";
import Logo from "../../assets/Images/footer_logo.png";
import ReactTooltip from "react-tooltip";
import SearchIcon from "../../assets/Images/search_icon.png";
import defaultProfileImage from "../../assets/Images/profile-placeholder.png";
import { getConfig } from "../../redux/config/configActions";
import { getUserProfile } from "../../redux/profile/profileAction";
import { isMobileViewVisible } from "./../../helpers/Utility";
import leftarrow from "../../assets/Images/left-arrow.png";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-multi-lang";

const Header = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const inputElement = useRef(null);
  const history = useHistory();
  const [cartItemsCount, setCartItemsCount] = useState("");
  const [image, setImage] = useState(defaultProfileImage);
  const [searchData, setSearchData] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputVisible, setSearchInputVisible] = useState(false);
  const [totalLines, setTotalLines] = useState("");
  const [totalOrderValue, setTotalOrderValue] = useState("");
  const [isSalesExecutive, setIsSalesExecutive] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const salesExecutive = "salesexecutive";

  const nextProps = useSelector((state) => ({
    UserProfileData: state.UserProfile.profileData || null,
    isCartItemsCount: state.GetCartItemsCount,
    configData: state.ConfigData || null,
    cartItemsData: state.Cart || null,
  }));
  const { cartItemsCountData } = nextProps.isCartItemsCount;
  const { cartItemsData, updateQuantityData, deleteCartItemsData } =
    nextProps.cartItemsData;
  const { configData } = nextProps.configData;
  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getConfig());
  }, []);

  useEffect(() => {
    if (configData) {
      setConfig(configData?.data?.configs);
    }
  }, [configData]);
  useEffect(() => {
    if (cartItemsCountData) {
      setCartItemsCount(cartItemsCountData.cartCount);
    }
  }, [cartItemsCountData]);
  useEffect(() => {
    if (cartItemsData) {
      setTotalLines(cartItemsData.cartItemsCount);
      setTotalOrderValue(cartItemsData.cartProductTotPricewithGST);
    }
  }, [cartItemsData]);
  useEffect(() => {
    if (updateQuantityData?.data) {
      setTotalOrderValue(updateQuantityData?.data.cartProductTotPricewithGST);
    }
  }, [updateQuantityData]);
  useEffect(() => {
    if (deleteCartItemsData?.data) {
      setTotalLines(deleteCartItemsData?.data.productCount);
      setTotalOrderValue(deleteCartItemsData?.data.cartProductTotPricewithGST);
    }
  }, [deleteCartItemsData]);
  useEffect(() => {
    let profileData = nextProps.UserProfileData;
    if (profileData && profileData.data && profileData.data.userProfile) {
      if (
        profileData.data.userProfile.profilePictureUrl === null ||
        profileData.data.userProfile.profilePictureUrl === ""
      ) {
        setImage(defaultProfileImage);
      } else {
        setImage(profileData.data.userProfile.profilePictureUrl);
      }
    }
    if (profileData && profileData.data && profileData.data.userProfile) {
      if (
        profileData.data.userProfile.roleName
          ?.toLowerCase()
          .replace(/ /g, "") === salesExecutive
      ) {
        setIsSalesExecutive(true);
      } else {
        setIsSalesExecutive(false);
      }
    }
  }, [nextProps.UserProfileData]);

  const handleToggle = () => {
    if (inputElement.current.style.display === "block") {
      inputElement.current.style.display = "none";
    } else {
      inputElement.current.style.display = "block";
    }
  };

  const handleSearch = (e) => {
    setSearchData(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      history.push(`/products?searchText=${e.target.value}`);
    }
  };

  const submitSearch = () => {
    if (searchData !== "") {
      history.push(`/products?searchText=${searchData}`);
    }
  };

  let tempSearch;
  let url_string = window.location.href;
  let url = new URL(url_string);

  if (url.searchParams.get("searchText")) {
    tempSearch = url.searchParams.get("searchText");
  }
  useEffect(() => {
    if (url.searchParams.get("searchText")?.length) {
      setSearchData(url.searchParams.get("searchText"));
    } else {
      setSearchData("");
      setSearchInputVisible(false);
    }
  }, [tempSearch]);

  const handleLogout = () => {
    userLogOut();
    history.push("/login");
  };
  const gotToHome = (e) => {
    e.preventDefault();
    history.push("/home");
  };

  const isSearchInputVisible = () => {
    setSearchInputVisible(!searchInputVisible);
  };

  const isSerchFieldEnable = () => {
    if (isMobileViewVisible()) {
      if (searchInputVisible) {
        return false;
      }
      return true;
    } else {
      return true;
    }
  };

  const closeSearch = () => {
    setSearchInputVisible(false);
  };

  const expressOrderNavigation = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/express-checkout");
  };
  return (
    <div className="header">
      <Container
        fluid={true}
        className="footer-section-container header_container"
      >
        <Row>
          {isSerchFieldEnable() ? (
            <>
              <Col xs="2" sm="2" md="2" className="header-logo-section">
                <div className="logo header-logo-image">
                  <a onClick={(e) => gotToHome(e)}>
                    <img src={Logo} />
                  </a>
                </div>
              </Col>
              <Col xs="3" sm="3" md="3" className="frames-sunglasses">
                <div className="dropdowns">
                  <span className="frameDropdown">
                    <FramesDropdown label="Frames" />
                  </span>
                  <span className="sunglassesDropdown">
                    <CustomDropdown label="Sunglasses" />
                  </span>
                </div>
                <div className="searchIcon">
                  <img src={SearchIcon} />
                </div>
              </Col>
            </>
          ) : (
            ""
          )}
          {isMobileViewVisible() ? (
            ""
          ) : (
            <Col
              xs="1"
              sm="1"
              md="1"
              style={{ paddingTop: "15px", fontSize: "18px" }}
            >
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  window.open(`https://eyegearlens.azurewebsites.net/login`);
                }}
              >
                <span className="badge">Eyegear Lens</span>
              </a>
            </Col>
          )}
          <Col
            xs={searchInputVisible && isMobileViewVisible() ? "12" : "6"}
            md="6"
          >
            <div className="iconSection">
              <div className="cart-count-section">
                {searchInputVisible ? (
                  <>
                    <Input
                      className="seacrh-input"
                      placeholder="Search"
                      value={searchData}
                      onKeyDown={(e) => {
                        handleKeyDown(e);
                      }}
                      onChange={(e) => {
                        handleSearch(e);
                      }}
                    ></Input>
                    <img
                      src={SearchIcon}
                      className="searchIcons"
                      onClick={submitSearch}
                    />
                  </>
                ) : (
                  <img
                    src={SearchIcon}
                    className="searchIcons-default"
                    onClick={() => {
                      isSearchInputVisible();
                    }}
                  />
                )}
              </div>
              <div
                className={`cart-count-section ${
                  isSalesExecutive && !localStorage.getItem("retailer")
                    ? "d-none"
                    : ""
                }`}
              >
                <span className="headerIcons">
                  <img
                    src={CartIcon}
                    onClick={() => history.push("/purchase-order")}
                    id="cart_view_popup"
                    data-tip
                    data-for="cart_view_popup"
                  />
                </span>
                <ReactTooltip
                  id="cart_view_popup"
                  effect="solid"
                  place="bottom"
                  type="light"
                  className="tool-tip-card"
                >
                  <div className="d-flex flex-row mb-2">
                    <Col lg="7">
                      <span className="cart-popup-text">
                        {t("HEADER.TOTAL_ORDER-QUANTITY")}
                      </span>
                    </Col>
                    <Col lg="1">:</Col>
                    <Col lg="4">
                      <span className="cart-popup-text">
                        {cartItemsCount > 0 ? `${cartItemsCount}` : 0}
                      </span>
                    </Col>
                  </div>
                  <div className="d-flex flex-row mb-2">
                    <Col lg="7">
                      <span className="cart-popup-text">
                        {t("HEADER.TOTAL_LINES")}
                      </span>
                    </Col>
                    <Col lg="1">:</Col>
                    <Col lg="4">
                      <span className="cart-popup-text">{`${totalLines}`}</span>
                    </Col>
                  </div>
                  <div className="d-flex flex-row">
                    <Col lg="7">
                      <span className="cart-popup-text">
                        {t("HEADER.TOTAL_ORDER_VALUE")}
                      </span>
                    </Col>
                    <Col lg="1">:</Col>
                    <Col lg="4">
                      <span className="cart-popup-text">
                        <span>
                          {" "}
                          <FontAwesomeIcon
                            icon={faRupeeSign}
                            className="repeeIcon-style"
                          />{" "}
                        </span>
                        {`${totalOrderValue}`}
                      </span>
                    </Col>
                  </div>
                </ReactTooltip>
                {cartItemsCount > 0 ? (
                  <span
                    className="cart-count"
                    onClick={() => history.push("/purchase-order")}
                  >
                    {` `}
                    {cartItemsCount || 0}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div
                className={`cart-count-section ${
                  isSalesExecutive && !localStorage.getItem("retailer")
                    ? "d-none"
                    : ""
                }`}
              >
                <span className="headerIcons">
                  <img
                    src={ExpressOrder}
                    onClick={() => history.push("/express-checkout")}
                    width={25}
                  />
                </span>
              </div>
              <span className="headerProfileIcon">
                {/* <img src={image} onClick={() => history.push("/profile")} /> */}
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggle}
                  className="profile-dropdown"
                >
                  <DropdownToggle>
                    <img src={image} />
                  </DropdownToggle>
                  <DropdownMenu className="profile-dropdown-menu">
                    <DropdownItem
                      className="profile-dropdown-item"
                      onClick={() => history.push("/profile")}
                    >
                      {t("PROFILE.MY_PROFILE")}
                    </DropdownItem>
                    {isSalesExecutive && !localStorage.getItem("retailer") ? (
                      ""
                    ) : (
                      <DropdownItem
                        className="profile-dropdown-item"
                        onClick={() => history.push("/profile?order=true")}
                      >
                        {t("PROFILE.MY_ORDERS")}
                      </DropdownItem>
                    )}
                    {isSalesExecutive ? (
                      <DropdownItem
                        className="profile-dropdown-item"
                        onClick={() =>
                          history.push("/profile?switch-user=true")
                        }
                      >
                        {t("PROFILE.SWITCH_USER")}
                      </DropdownItem>
                    ) : (
                      ""
                    )}
                    <DropdownItem
                      className="profile-dropdown-item"
                      onClick={() =>
                        history.push("/profile?change-password=true")
                      }
                    >
                      {t("PROFILE.CHANGE_PASSWORD")}
                    </DropdownItem>
                    {isSalesExecutive && !localStorage.getItem("retailer") ? (
                      ""
                    ) : (
                      <DropdownItem
                        className="profile-dropdown-item"
                        onClick={() =>
                          history.push("/profile?my-invoices=true")
                        }
                      >
                        My Invoices
                      </DropdownItem>
                    )}
                    <DropdownItem
                      className="profile-dropdown-item"
                      onClick={handleLogout}
                    >
                      {t("HEADER.LOGOUT")}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </span>
            </div>
            {/* right sidebar */}
            <div
              className={
                !isSerchFieldEnable()
                  ? "iconSection_menu_Input_true"
                  : "iconSection_menu"
              }
            >
              {!searchInputVisible ? (
                <FontAwesomeIcon
                  icon={faBars}
                  onClick={handleToggle}
                  className="menu"
                />
              ) : (
                ""
              )}

              {searchInputVisible ? (
                <>
                  <img
                    src={leftarrow}
                    className="searchIc searchIcons-default-mbv"
                    onClick={closeSearch}
                  />
                  <Input
                    className="seacrh-input searchIcons-default-mbv seacrh-input-mbv"
                    placeholder="Search"
                    value={searchData}
                    onKeyDown={(e) => {
                      handleKeyDown(e);
                    }}
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  ></Input>
                  <img
                    src={SearchIcon}
                    className="searchIcons searchIcons-default-mbv"
                    onClick={submitSearch}
                  />
                </>
              ) : (
                <img
                  src={SearchIcon}
                  className="searchIcons-default searchIcons-default-mbv"
                  onClick={() => {
                    isSearchInputVisible();
                  }}
                />
              )}
              {!searchInputVisible ? (
                <div className="cart-count-section">
                  <img
                    src={CartIcon}
                    className="sidebar-cart-icon"
                    onClick={() => history.push("/purchase-order")}
                  />
                  {cartItemsCount > 0 ? (
                    <>
                      <span
                        className="cart-count sidebar-cart-count"
                        onClick={() => history.push("/purchase-order")}
                      >
                        {` `}
                        {cartItemsCount || 0}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                  <span>
                    <img
                      src={ExpressOrder}
                      onClick={(e) => {
                        expressOrderNavigation(e);
                      }}
                      width={25}
                      className="sidebar-express-icon"
                    />
                  </span>
                </div>
              ) : (
                ""
              )}
              <div
                className="header-sidebar sidebar-animate-right"
                style={{ display: "none" }}
                id="myLinks"
                ref={inputElement}
              >
                <button
                  onClick={handleToggle}
                  className="header-sidebar-item header-sidebar-close"
                >
                  &times;
                </button>
                <ul>
                  <li>
                    <span className="headerMenuProfileIcon header-sidebar-item">
                      <img
                        src={image}
                        onClick={() => {
                          handleToggle();
                          history.push("/profile");
                        }}
                      />
                    </span>
                  </li>
                  <li
                    onClick={(event) => {
                      event.preventDefault();
                      window.open(
                        `https://eyegearlens.azurewebsites.net/login`
                      );
                    }}
                    className="header-sidebar-item"
                  >
                    <p>Eyegear Lens</p>
                  </li>
                  <li
                    onClick={() => {
                      handleToggle();
                      history.push("/home");
                    }}
                    className="header-sidebar-item"
                  >
                    <p>{t("HEADER.HOME")}</p>
                  </li>
                  {/* <li className="header-sidebar-item">
                    <p>{t("HEADER.SEARCH")}</p>
                  </li> */}
                  <li className="header-sidebar-item">
                    <p>
                      <FramesDropdown
                        handleToggle={handleToggle}
                        label="Frames"
                      />
                    </p>
                  </li>
                  {/* <li className="header-sidebar-item">
                    <p>{t("FOOTER.SUNGLASSES")}</p>
                  </li> */}
                  <li
                    onClick={() => {
                      handleToggle();
                      history.push("/profile");
                    }}
                    className="header-sidebar-item"
                  >
                    <p>{t("PROFILE.MY_PROFILE")}</p>
                  </li>
                  <li
                    className="header-sidebar-item"
                    onClick={() => {
                      handleToggle();
                      history.push("/profile?order=true");
                    }}
                  >
                    <p>{t("PROFILE.MY_ORDERS")}</p>
                  </li>
                  <li
                    className="header-sidebar-item"
                    onClick={() => {
                      handleToggle();
                      history.push("/profile?change-password=true");
                    }}
                  >
                    <p>{t("PROFILE.CHANGE_PASSWORD")}</p>
                  </li>
                  <li
                    className="header-sidebar-item"
                    onClick={() => {
                      handleToggle();
                      history.push("/profile?my-invoices=true");
                    }}
                  >
                    <p>My Invoices</p>
                  </li>
                  <li className="header-sidebar-item" onClick={handleLogout}>
                    <p>{t("HEADER.LOGOUT")}</p>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Header;
