import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Label } from "reactstrap";
import Button from "reactstrap-button-loader";
import ModalProductFrame from "../../assets/Images/Popup Images/Popup image.png";
import ProductFrame from "../../assets/Images/Mask Group.png";
import PropTypes from "prop-types";
import RupeeIcon from "../../assets/Images/rupee_icon.png";
import { PRODUCTINFO } from "../../helpers/Constants";
import ProductQuantity from "../ProductQuantity";
import ProductColors from "../ProductColors";
import { useTranslation } from "react-multi-lang";
import { addItemsToCart } from "../../redux/addToCart/addToCartActions";
import { getCartItemsCount } from "../../redux/cartCount/cartCountActions";
import { getCartItems } from "../../redux/cart/cartActions";
import { getInventory } from "../../redux/inventory/inventoryAction";
import { showSuccess, showError } from "./../../common/alertNotifications";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import _ from "lodash";

export default function ProductInfo(props) {
  const dispatch = useDispatch();
  const t = useTranslation();
  const { isFromModal, productData, fromDetails, activeColorProductIndex } =
    props;
  const [rSelected, setRSelected] = useState();
  const [colorQuantityData, setColorQuantityData] = useState([]);
  const imageColumnSize = isFromModal ? "5" : "6";
  const imageDetailsColumnSize = isFromModal ? "7" : "6";
  const colorLabelColumn = isFromModal ? "5" : "5";
  const quantityLabelColumn = isFromModal ? "7" : "7";
  const [thumbNailImages, setThumbNailImages] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [activeColorIndex, setActiveColorIndex] = useState(0);
  const [coloredImageIndex, setColoredImageIndex] = useState(0);
  const [error, setError] = useState(false);
  const [productObj, setProductObj] = useState({});
  const [firstImage, setFirstImage] = useState("");
  const [secondImage, setSecondImage] = useState("");
  const [thrirdImage, setThrirdImage] = useState("");
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [productStockData, setProductStockData] = useState({});
  const [quantityErrorData, setQuantityErrorData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [maxLimitPerVariant, setMaxLimitPerVariant] = useState(0);
  const [quantityNumber, setQuantityNumber] = useState(
    PRODUCTINFO.MIN_CART_SIZE - 1
  );
  const [image, setImage] = useState(
    isFromModal ? ModalProductFrame : ProductFrame
  );
  // const [selectedQuantityData, setSelectedQuantityData] = useState([]);
  const [availabileColorList, setAvailabileColorList] = useState([]);
  const [isSalesExecutive, setIsSalesExecutive] = useState(false);
  const salesExecutive = "salesexecutive";
  const nextProps = useSelector((state) => ({
    UserProfileData: state.UserProfile.profileData?.data?.userProfile || null,
    addToCart: state.AddToCart || null,
    availabileQuantity:
      state.GetInventory?.getInventoryData?.data?.inventories || null,
  }));
  const { addToCartData, isAddToCartRequesting } = nextProps.addToCart;
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (addToCartData) {
      let data = {
        pageNumber: 1,
      };
      if (isSalesExecutive && localStorage.getItem("retailer")) {
        const email = JSON.parse(localStorage.getItem("retailer"))?.Email;
        data.email = email;
        dispatch(getCartItemsCount(email));
      } else {
        dispatch(getCartItemsCount());
      }
      dispatch(getCartItems(data));
      if (addToCartData.data.message) {
        showSuccess(addToCartData.data.message, {
          position: "bottom-left",
        });
      }

      if (addToCartData.data.error) {
        showError(addToCartData.data.error.message);
      }
    }
  }, [addToCartData]);

  const isSecondRun = useRef(true);
  useEffect(() => {
    if (isSecondRun.current) {
      isSecondRun.current = false;
      return;
    }
    if (nextProps.availabileQuantity) {
      let data = colorQuantityData;
      let reqData = data.filter((dt) => {
        return dt.Quantity !== 0 && !isNaN(dt.Quantity);
      });
      const temp = [];
      for (let i = 0; i <= reqData.length; i++) {
        for (let j = 0; j <= nextProps.availabileQuantity?.length; j++) {
          if (
            reqData[i]?.ColorVariant ==
              nextProps.availabileQuantity[j]?.colorVariant &&
            nextProps.availabileQuantity[j]?.colorVariant != undefined
          ) {
            if (
              reqData[i].Quantity <=
              nextProps.availabileQuantity[j].availableStockQuantity
            ) {
              temp.push(reqData[i]);
            }
          }
        }
      }
      if (temp.length > 0) {
        if (isSalesExecutive && localStorage.getItem("retailer")) {
          // setSelectedQuantityData(reqData);
          const email = JSON.parse(localStorage.getItem("retailer"))?.Email;
          dispatch(addItemsToCart({ Products: reqData, Email: email }));
        } else {
          dispatch(addItemsToCart({ Products: reqData }));
        }
      } else {
        setLoading(false);
        setError(true);
      }
    }
  }, [nextProps.availabileQuantity]);

  useEffect(() => {
    if (!isAddToCartRequesting) {
      setLoading(false);
    }
  }, [isAddToCartRequesting]);

  useEffect(() => {
    if (
      nextProps.UserProfileData &&
      nextProps.UserProfileData.maxLimitPerVariant
    ) {
      setMaxLimitPerVariant(nextProps.UserProfileData.maxLimitPerVariant);
    }
    if (
      nextProps.UserProfileData?.roleName?.toLowerCase().replace(/ /g, "") ===
      salesExecutive
    ) {
      setIsSalesExecutive(true);
    }
  }, [nextProps.UserProfileData]);

  useEffect(() => {
    setProductStockData(nextProps.availabileQuantity);
    if (nextProps.availabileQuantity) {
      let availabileProducts = colorList.map((item) => {
        let availableProductQuantity = _.find(nextProps.availabileQuantity, {
          colorVariant: item.colorVariant,
        });
        item.availableStockQuantity =
          availableProductQuantity?.availableStockQuantity;
        return item;
      });
      setAvailabileColorList(availabileProducts);
    }
  }, [nextProps.availabileQuantity, colorList]);
  useEffect(() => {
    if (productData !== undefined) {
      if (
        productData.productColor &&
        productData.productColor.length > 0 &&
        (JSON.parse(productData.productColor)[activeColorIndex].productImage[
          coloredImageIndex
        ] ||
          JSON.parse(productData.productColor)[activeColorIndex].thumbnailImage)
      ) {
        let productColorImages = JSON.parse(productData.productColor);
        setImage(
          productColorImages[activeColorIndex].productImage[coloredImageIndex]
        );
        setFirstImage(productColorImages[activeColorIndex].productImage[0]);
        setSecondImage(productColorImages[activeColorIndex].productImage[1]);
        setThrirdImage(productColorImages[activeColorIndex].productImage[2]);
        setThumbNailImages(productColorImages[activeColorIndex].thumbnailImage);
        setColorList(productColorImages);
      }
    }
  }, [activeColorIndex, productData]);

  const handleError = (index, value) => {
    const presentindex = _.findIndex(quantityErrorData, {
      Id: index,
    });
    let temp = quantityErrorData;
    if (presentindex >= 0) {
      quantityErrorData[presentindex].Error = value;
    } else {
      temp.push({
        Id: index,
        Error: value,
      });
    }
    setQuantityErrorData(temp);
    const quantityError = _.find(quantityErrorData, {
      Error: true,
    });
    if (quantityError) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const minusQuantity = (decreasedQuantity) => {
    setQuantityNumber(decreasedQuantity);
  };
  const plusQuantity = (increasedQuantity) => {
    setQuantityNumber(increasedQuantity);
  };
  const inputQuantity = (quantity, index, colorVariant) => {
    setRSelected(index);
    setQuantityNumber(quantity);
    let temp = colorQuantityData;
    if (index === rSelected && quantity !== "") {
      const selectedProduct = _.findIndex(colorQuantityData, {
        ColorVariant: colorVariant,
      });
      if (selectedProduct >= 0) {
        productObj[selectedProduct].Quantity = quantity;
        setColorQuantityData(productObj);
      } else {
        temp.push({
          ProductID: productData.productId,
          Quantity: quantity,
          ColorVariant: colorVariant,
        });
        setProductObj(temp);
        setColorQuantityData(productObj);
      }
      setColorQuantityData(temp);
    }
    const total = colorQuantityData.reduce((accumulator, colorQuantity) => {
      if (!isNaN(colorQuantity.Quantity)) {
        return accumulator + colorQuantity.Quantity;
      } else {
        return accumulator + 0;
      }
    }, 0);
    setTotalQuantity(total);
  };

  const handleActiveColor = (index) => {
    setActiveColorIndex(index);
    if (fromDetails) {
      activeColorProductIndex(index);
    }
    setColoredImageIndex(0);
    setRSelected(index);
  };

  const handelAddToOrder = () => {
    setLoading(true);
    dispatch(getInventory({ productId: colorQuantityData[0].ProductID }));
  };
  console.log("thumbnail assigned", image, thumbNailImages);

  const isThirdRun = useRef(true);
  const handleDefaulltSelect = () => {
    if (isThirdRun.current) {
      isThirdRun.current = false;
      const availabileVariantIndex = [];
      availabileColorList.map((color, index) => {
        if (color.availableStockQuantity > 0) {
          availabileVariantIndex.push(index);
        }
      });
      if (availabileVariantIndex.length > 0) {
        const random = Math.floor(
          Math.random() * availabileVariantIndex.length
        );
        const selectedNum = availabileVariantIndex[random];
        setRSelected(selectedNum);
        setActiveColorIndex(selectedNum);
      }
    }
  };

  if (productData) {
    let discounts =
      isFromModal && productData.discounts
        ? JSON.parse(productData.discounts)
        : productData.discounts;
    let availableDiscountList = _.filter(discounts, (discount) => {
      return discount.StateId === nextProps.UserProfileData?.stateId.toString();
    });

    if (!availableDiscountList.length) {
      availableDiscountList = _.filter(discounts, (discount) => {
        return discount.StateId === "";
      });
    }

    let availableDiscount = {};
    let tempDiscountValue = 0;
    availableDiscountList?.length &&
      availableDiscountList.map((discount) => {
        if (
          discount.UsePercentage.toString() === "1" &&
          parseInt(discount?.DiscountPercentage) > 0
        ) {
          let discountValue =
            (productData.netPrice * discount.DiscountPercentage) / 100;
          if (
            parseInt(tempDiscountValue) < parseInt(discountValue) &&
            parseInt(discountValue) < parseInt(productData.netPrice) &&
            (discount.PriceFrom
              ? parseInt(discount.PriceFrom) <= parseInt(productData.netPrice)
              : true) &&
            (discount.PriceTo
              ? parseInt(discount.PriceTo) >= parseInt(productData.netPrice)
              : true)
          ) {
            tempDiscountValue = discountValue;
            availableDiscount = discount;
          }
        } else {
          let discountValue = discount.DiscountAmount;
          if (
            parseInt(tempDiscountValue) < parseInt(discountValue) &&
            parseInt(discountValue) < parseInt(productData.netPrice) &&
            (discount.PriceFrom
              ? parseInt(discount.PriceFrom) <= parseInt(productData.netPrice)
              : true) &&
            (discount.PriceTo
              ? parseInt(discount.PriceTo) >= parseInt(productData.netPrice)
              : true)
          ) {
            tempDiscountValue = discountValue;
            availableDiscount = discount;
          }
        }
      });
    return (
      <Container className="mob-1">
        <Row className="card-product justify-content-center">
          <Col sm={imageColumnSize} md="12" lg={imageColumnSize}>
            <div className="d-flex">
              <span
                className={
                  availableDiscount && productData.productTypeId !== 4
                    ? availableDiscount?.UsePercentage?.toString() === "1" &&
                      parseInt(availableDiscount?.DiscountPercentage) > 0
                      ? "product-offer  product-offer-padding"
                      : availableDiscount?.UsePercentage?.toString() === "0" &&
                        parseInt(availableDiscount?.DiscountAmount) > 0
                      ? "product-offer  product-offer-padding"
                      : "product-no-offer"
                    : "product-no-offer"
                }
              >
                {availableDiscount && productData.productTypeId !== 4
                  ? availableDiscount?.UsePercentage?.toString() === "1" &&
                    parseInt(availableDiscount?.DiscountPercentage) > 0
                    ? `${parseInt(availableDiscount.DiscountPercentage)}% off`
                    : availableDiscount?.UsePercentage?.toString() === "0" &&
                      parseInt(availableDiscount?.DiscountAmount) > 0
                    ? `₹${parseInt(availableDiscount.DiscountAmount)} off`
                    : ""
                  : ""}
              </span>
            </div>
            {productData.productTypeId === 4 ? (
              <div>
                <img src={firstImage} className="d-block w-100" />
              </div>
            ) : (
              <Carousel
                className="carousel-product"
                showThumbs={true}
                showArrows={true}
                showIndicators={true}
                useKeyboardArrows={true}
              >
                <div>
                  <img
                    src={firstImage}
                    className="d-block w-100"
                    alt="First slide"
                  />
                </div>

                <div>
                  <img
                    className="d-block w-100"
                    src={secondImage}
                    alt="Second slide"
                  />
                </div>
                <div>
                  <img
                    className="d-block w-100"
                    src={thrirdImage}
                    alt="Third slide"
                  />
                </div>
              </Carousel>
            )}
          </Col>

          <Col sm="12" md="12" xl={imageDetailsColumnSize} lg="12" xs="12">
            <div className={isFromModal ? "modal-product-info" : ""}>
              <Row>
                <p className="product-code d-none">
                  Code: {` ${productData.productCode}` || ""}
                </p>
                <p
                  className={
                    isFromModal ? "modal-product-name" : "product-name"
                  }
                >
                  {productData.productName}
                </p>
                <Row noGutters style={{ paddingLeft: 0, paddingRight: 0 }}>
                  {/* <Col xs="3" sm="3"> done by mani*/}
                  <Col xs="4" sm="4">
                    <div className="d-flex ml-0">
                      <Label>
                        <img src={RupeeIcon} width="auto" height="15px" />{" "}
                        <span className="price-text">
                          {productData.netPrice.toFixed(2)}{" "}
                        </span>
                      </Label>
                    </div>
                  </Col>
                  <Col sm="5" xs="5">
                    {productData.productTypeId === 4 ? (
                      ""
                    ) : (
                      <span className="product-info-value">
                        {`${productData.eyeSize + "-"}` +
                          `${productData.noseBridgeSize + "-"}` +
                          `${productData.armLength}`}
                      </span>
                    )}
                  </Col>
                  {/* <Col sm="4" xs="4"> done by mani*/}
                  <Col xs="3" sm="3">
                    {productData.productTypeId === 4 ? (
                      ""
                    ) : (
                      <span className="product-info-value">
                        {productData.frameMaterialName}
                      </span>
                    )}
                  </Col>
                </Row>
              </Row>
              <Row
                className={
                  isFromModal ? " modal-product-details" : "product-details"
                }
              >
                <Col xs="12" sm="12" lg="12" md="12">
                  <Row>
                    <Col
                      xs={colorLabelColumn}
                      sm={colorLabelColumn}
                      lg={colorLabelColumn}
                      md={colorLabelColumn}
                    >
                      {productData.productTypeId === 4 ? (
                        <p className="product-color-label">
                          {t("CATALOG.SELECT_ACCESSORY")}
                        </p>
                      ) : (
                        <p className="product-color-label">
                          {t("CATALOG.COLOR")}
                        </p>
                      )}
                    </Col>
                    <Col
                      xs={quantityLabelColumn}
                      sm={quantityLabelColumn}
                      lg={quantityLabelColumn}
                      md={quantityLabelColumn}
                      className="d-flex justify-content-center"
                      //done by mani className="d-flex justify-content-end"
                    >
                      <p className="product-quantity-label">
                        {`${t("CATALOG.QUANTITY")} : ${totalQuantity}`}
                      </p>
                    </Col>
                  </Row>
                  {/* <p className="product-info-label">{t("CATALOG.COLOR")}</p> */}
                  {/* <p className="product-info-quantity-label">
                    {t("CATALOG.QUANTITY")}
                  </p> */}
                  <Row>
                    <ul className="frame-colors">
                      {availabileColorList.map((color_data, index) => {
                        let frame_color = color_data.frameColorCode;
                        let frameColorName = color_data.frameColor;
                        let templeColorName = color_data.templeColor;
                        let temple_Color = color_data.templeColorCode;
                        let temple_ColorID = color_data.templeColorID;
                        let colorVariant = color_data.colorVariant;
                        let availabileQuantityData = _.find(productStockData, {
                          colorVariant: color_data.colorVariant,
                        });
                        let availabileStockQuantity =
                          availabileQuantityData?.availableStockQuantity;
                        let productColorId = color_data.productColorId;
                        if (color_data.availableStockQuantity > 0) {
                          handleDefaulltSelect();
                        }
                        return (
                          <>
                            <Row className="color-quantity">
                              <Col
                                xs={colorLabelColumn}
                                sm={colorLabelColumn}
                                lg={colorLabelColumn}
                                md={colorLabelColumn}
                              >
                                {/* <p className="product-color-label">
                                  {t("CATALOG.COLOR")}
                                </p> */}
                                <ProductColors
                                  frameColor={frame_color}
                                  templeColor={temple_Color}
                                  index={index}
                                  rSelected={rSelected}
                                  frameColorId={color_data.frameColorID}
                                  handleActiveColorButton={handleActiveColor}
                                  colorVariant={colorVariant}
                                  frameColorName={frameColorName}
                                  templeColorName={templeColorName}
                                  activeColorIndex={activeColorIndex}
                                  error={error}
                                  productData={productData}
                                />
                              </Col>
                              <Col
                                xs={quantityLabelColumn}
                                sm={quantityLabelColumn}
                                lg={quantityLabelColumn}
                                md={quantityLabelColumn}
                                // done by mani className="d-flex justify-content-end quantity-col-padding-left"
                                className="d-flex justify-content-center quantity-col-padding-left"
                              >
                                {/* <p className="product-quantity-label">
                                  {t("CATALOG.QUANTITY")}
                                </p> */}

                                <ProductQuantity
                                  quantityNumber={quantityNumber}
                                  minusQuantity={minusQuantity}
                                  plusQuantity={plusQuantity}
                                  inputQuantity={inputQuantity}
                                  index={index}
                                  rSelected={rSelected}
                                  frameColorId={color_data.frameColorID}
                                  templeColor={temple_ColorID}
                                  colorVariant={colorVariant}
                                  errorHandler={handleError}
                                  availabileStockQuantity={
                                    availabileStockQuantity
                                  }
                                  maxLimitPerVariant={maxLimitPerVariant}
                                  productColorId={productColorId}
                                  loading={loading}
                                />
                              </Col>
                            </Row>
                          </>
                        );
                      })}
                    </ul>
                  </Row>
                </Col>
              </Row>
              <div
                className={
                  isFromModal
                    ? "modal-error-msg-button"
                    : "details-error-msg-button"
                }
              >
                <span className="quantity-error-message">
                  {isSalesExecutive && !localStorage.getItem("retailer") ? (
                    ""
                  ) : isFromModal ? (
                    <Button
                      loading={loading}
                      className="modal-add-to-order-btn"
                      disabled={
                        !colorQuantityData.some((a) => a.Quantity !== 0) ||
                        (isNaN(quantityNumber) && totalQuantity === 0) ||
                        error
                          ? true
                          : false
                      }
                      onClick={() => {
                        handelAddToOrder();
                      }}
                    >
                      {t("CATALOG.ADD_TO_ORDER")}
                    </Button>
                  ) : (
                    <Button
                      loading={loading}
                      className="modal-add-to-order-btn "
                      disabled={
                        !colorQuantityData.some((a) => a.Quantity !== 0) ||
                        (isNaN(quantityNumber) && totalQuantity === 0) ||
                        error
                          ? true
                          : false
                      }
                      onClick={() => {
                        handelAddToOrder();
                      }}
                    >
                      {t("CATALOG.ADD_TO_ORDER")}
                    </Button>
                  )}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return <></>;
  }
}
ProductInfo.propTypes = {
  isFromModal: PropTypes.boolean,
  fromDetails: PropTypes.boolean,
  productData: PropTypes.object,
  activeColorProductIndex: PropTypes.func,
};
