import React, { Fragment } from "react";
import PropTypes from "prop-types";
import canvasImage from "../../assets/Images/canvasImage.png";

export default function ProductColors(props) {
  const {
    frameColor,
    templeColor,
    index,
    rSelected,
    handleActiveColorButton,
    frameColorName,
    templeColorName,
    colorVariant,
    productData,
  } = props;
  const handleActiveColor = (index) => {
    handleActiveColorButton(index);
  };
  let frame_color = frameColor;
  return (
    <>
      <span
        id="colored-frames_span"
        onClick={() => handleActiveColor(index)}
        style={
          index == rSelected
            ? {
                border:
                  frameColor === "#FFFFFF"
                    ? "2px solid #dad6d0"
                    : `2px solid ${frameColor}`,
                padding: "1px",
                paddingBottom: frame_color === "" ? "2px" : "",
                borderRadius: "50%",
              }
            : { border: "transparent", padding: "3px", borderRadius: "50%" }
        }
      >
        {frame_color == "" ? (
          <img src={canvasImage} width="auto" height="18.7" />
        ) : templeColor == "" && frame_color == "" ? (
          <img
            src={canvasImage}
            width="auto"
            height="18.7"
            className="img_canvas"
          />
        ) : (
          <Fragment>
            <span
              className="color-dot-first-half-product"
              style={{
                border:
                  frame_color === "#FFFFFF"
                    ? "2px solid #dad6d0"
                    : "2px solid white",
                backgroundColor: `${frame_color}`,
              }}
            ></span>
            <span
              className="color-dot-second-half-product"
              style={{
                border:
                  frame_color === "#FFFFFF"
                    ? "2px solid #dad6d0"
                    : "2px solid white",
                backgroundColor: `${
                  frame_color != templeColor && templeColor != ""
                    ? templeColor
                    : frame_color
                }`,
              }}
            ></span>
          </Fragment>
        )}
      </span>
      {productData.productTypeId === 4 ? (
        <label
          className="frame-color-name"
          onClick={() => handleActiveColor(index)}
        >
          {colorVariant ? colorVariant : ""}
        </label>
      ) : (
        <label
          className="frame-color-name"
          onClick={() => handleActiveColor(index)}
        >
          {colorVariant ? colorVariant + " : " : ""}
          {frameColorName}
          {templeColorName !== frameColorName ? "-" + templeColorName : ""}
        </label>
      )}
    </>
  );
}
ProductColors.propTypes = {
  frameColor: PropTypes.string,
  templeColor: PropTypes.string,
  index: PropTypes.number,
  rSelected: PropTypes.number,
  handleActiveColorButton: PropTypes.func,
  error: PropTypes.bool,
  frameColorName: PropTypes.string.isRequired,
  templeColorName: PropTypes.string.isRequired,
  colorVariant: PropTypes.string,
  productData: PropTypes.object,
};
