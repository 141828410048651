export const FILTER_CONST = {
  GET_FILTER_OPTION_REQUEST: "GET_FILTER_OPTION_REQUEST",
  GET_FILTER_OPTION_SUCCESS: "GET_FILTER_OPTION_SUCCESS",
  GET_FILTER_OPTION_FAILED: "GET_FILTER_OPTION_FAILED",
  GET_ALL_REQUESTING: "GET_ALL_REQUESTING",
  GET_ALL_SUCCESS: "GET_ALL_SUCCESS",
  GET_ALL_FAILED: "GET_ALL_FAILED",
  GET_PRODUCT_BY_ID_REQUESTING: "GET_PRODUCT_BY_ID_REQUESTING",
  GET_PRODUCT_BY_ID_SUCCESS: "GET_PRODUCT_BY_ID_SUCCESS",
  GET_PRODUCT_BY_ID_FAILED: "GET_PRODUCT_BY_ID_FAILED",
  GET_SORT_TYPE_REQUESTING: "GET_SORT_TYPE_REQUESTING",
  GET_SORT_TYPE_SUCCESS: "GET_SORT_TYPE_SUCCESS",
  GET_SORT_TYPE_FAILED: "GET_SORT_TYPE_FAILED",
};
