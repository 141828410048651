import { MATERIAL_CONST } from "./categoryConst";

const Login = (state = {}, action) => {
  switch (action.type) {
    case MATERIAL_CONST.MATERIAL_REQUESTING:
      return {
        ...state,
        isMaterialRequesting: true,
        materialData: false,
        materilaDataError: false,
      };
    case MATERIAL_CONST.MATERIAL_SUCCESS:
      return {
        ...state,
        isMaterialRequesting: false,
        materialData: action.payload.response,
        materilaDataError: false,
      };
    case MATERIAL_CONST.MATERIAL_FAILED:
      return {
        ...state,
        isMaterialRequesting: false,
        materialData: false,
        materilaDataError: action.payload.error
          ? false
          : action.payload.response,
      };

    default:
      return { ...state };
  }
};

export default Login;
