import { PLACE_ORDER } from "./placeOrderConst";

export const placeOrder = (payload) => ({
  type: PLACE_ORDER.PLACE_ORDERR_EQUESTING,
  payload: payload || null,
});

export const placeOrderSuccess = (response) => ({
  type: PLACE_ORDER.PLACE_ORDER_SUCCESS,
  payload: response,
});

export const placeOrderFailed = (error) => ({
  type: PLACE_ORDER.PLACE_ORDER_FAILED,
  payload: error,
});
