import { GET_CARD_ITEMS_COUNT } from "./cartCountConst";

export const getCartItemsCount = (payload) => ({
  type: GET_CARD_ITEMS_COUNT.GET_CARD_ITEMS_COUNT_REQUESTING,
  payload: payload || null,
});

export const getCartItemsCountSuccess = (response) => ({
  type: GET_CARD_ITEMS_COUNT.GET_CARD_ITEMS_COUNT_SUCCESS,
  payload: response,
});

export const getCartItemsCountFailed = (error) => ({
  type: GET_CARD_ITEMS_COUNT.GET_CARD_ITEMS_COUNT_FAILED,
  payload: error,
});
