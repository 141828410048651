import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Input, Label, FormGroup } from "reactstrap";
import { useTranslation } from "react-multi-lang";
import BreadCrumbs from "./../../components/BreadCrumbs";
import Button from "reactstrap-button-loader";
import { getToken } from "./../../helpers/Utility";
import DeliveryTo from "./DeliverTo";
import PaymentSummary from "./PaymentSummary";
import ProductCard from "./ProductsCard";
// import TermsAndConditions from "./TermsAndConditions";
import {
  getCartItems,
  deleteCartItems,
  updateItemsToCart,
  clearCartItems,
} from "./../../redux/cart/cartActions";
import Loader from "./../../common/Loader";
import { getCartItemsCount } from "./../../redux/cartCount/cartCountActions";
import { PURCHASE_ORDER } from "./../../helpers/Constants";
import { showSuccess, showError } from "./../../common/alertNotifications";
import { placeOrder } from "./../../redux/placeOrder/placeOrderActions";
import SuccesModal from "./../../components/SuccessfullOrdered";
import { getInventoryByUser } from "../../redux/inventory/inventoryAction";
import ClearCartModal from "../../components/ClearCartpopup";
import _ from "lodash";

export default function index() {
  const t = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();
  const [productData, setProductData] = useState([]);
  const [paymentSummary, setPaymentSummary] = useState({});
  const [deliveryToData, setDeliveryToData] = useState({});
  const [lodaingProduct, setLoadingProduct] = useState(false);
  const [pageNumber, setPageNumber] = useState(
    PURCHASE_ORDER.DEFAULT_PAGE_NUMBER
  );
  const [pageSize, setPageSize] = useState(PURCHASE_ORDER.DEFAUILT_PAGE_SIZE);
  const [succesfullModal, setSuccesfullModal] = useState(false);
  const [validateQuantity, setValidQuantity] = useState(true);
  const [itemsCount, setItemsCount] = useState("");
  const [orderNote, setOrderNote] = useState("");
  const [isSalesExecutive, setIsSalesExecutive] = useState(false);
  const [clearCart, setClearCartModal] = useState(false);

  const nextProps = useSelector((state) => ({
    cartItemsData: state.Cart || null,
    cartCountData: state.GetCartItemsCount || null,
    placedOrderData: state.PlaceOrder || null,
    UserProfileData: state.UserProfile.profileData?.data?.userProfile || null,
  }));
  const salesExecutive = "salesexecutive";

  let breadCrumbsdata = [
    {
      name: "Purchase Order",
      value: "/purchase-order",
    },
  ];
  const { cartItemsCountData, isCartItemsCountRequesting } =
    nextProps.cartCountData;
  const {
    cartItemsData,
    isGetCartItemsRequesting,
    // isDeleteCartItemsRequesting,
    deleteCartItemsData,
    clearCartItemsData,
    isClearCartItemsRequesting,
  } = nextProps.cartItemsData;
  const { placeOrderData, isPlaceOrderRequesting } = nextProps.placedOrderData;
  const { isUpdateQuantityRequesting, updateQuantityData } =
    nextProps.cartItemsData;

  const isRun = useRef(true);
  useEffect(() => {
    // done by mani.
    document.title = `Eyegear Shopping Cart`;
    if (isRun.current) {
      isRun.current = false;
      return;
    }
    if (updateQuantityData) {
      if (updateQuantityData?.data?.error?.message) {
        showError(updateQuantityData?.data?.error?.message);
      }
      if (updateQuantityData?.data?.message?.length) {
        showSuccess(updateQuantityData?.data?.message);
        setPaymentSummary({
          totalPrice: updateQuantityData?.data?.cartProductTotPricewithGST,
          subTotalPrice: updateQuantityData?.data?.cartTotPrice,
          totalCGSTValue: updateQuantityData?.data?.totalCGSTValue,
          totalIGSTValue: updateQuantityData?.data?.totalIGSTValue,
          totalSGSTValue: updateQuantityData?.data?.totalSGSTValue,
        });
      }
      let data = {
        pageNumber: pageNumber,
        isLoading: true,
      };
      if (isSalesExecutive && localStorage.getItem("retailer")) {
        const email = JSON.parse(localStorage.getItem("retailer"))?.Email;
        data.email = email;
        dispatch(getCartItemsCount(email));
      } else {
        dispatch(getCartItemsCount());
      }
      dispatch(getCartItems(data));
    }
  }, [updateQuantityData]);

  useEffect(() => {
    if (cartItemsCountData) {
      setItemsCount(cartItemsCountData.cartCount);
    }
  }, [cartItemsCountData]);

  useEffect(() => {
    if (
      nextProps.UserProfileData?.roleName?.toLowerCase().replace(/ /g, "") ===
      salesExecutive
    ) {
      setIsSalesExecutive(true);
    }
    let data = {
      pageNumber: pageNumber,
      // pageSize: pageSize,
    };
    if (nextProps.UserProfileData) {
      if (
        nextProps.UserProfileData?.roleName?.toLowerCase().replace(/ /g, "") ===
          salesExecutive &&
        localStorage.getItem("retailer")
      ) {
        const email = JSON.parse(localStorage.getItem("retailer"))?.Email;
        data.email = email;
        dispatch(getCartItemsCount(email));
      } else {
        dispatch(getCartItemsCount());
      }
      dispatch(getCartItems(data));
    }
  }, [nextProps.UserProfileData]);

  useEffect(() => {
    if (!getToken()) {
      history.push("/login");
    }
    dispatch(getInventoryByUser());
    setProductData([]);
    setLoadingProduct(true);
  }, []);

  const isSuccesfullyOrdered = (status) => {
    setSuccesfullModal(status);
  };

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (deleteCartItemsData?.data?.message?.length) {
      showSuccess(deleteCartItemsData?.data?.message, {
        position: "top-right",
      });
      setPaymentSummary({
        totalPrice: deleteCartItemsData?.data?.cartProductTotPricewithGST,
        subTotalPrice: deleteCartItemsData?.data?.cartTotPrice,
        totalCGSTValue: deleteCartItemsData?.data?.totalCGSTValue,
        totalIGSTValue: deleteCartItemsData?.data?.totalIGSTValue,
        totalSGSTValue: deleteCartItemsData?.data?.totalSGSTValue,
      });
      let data = {
        pageNumber: pageNumber,
        isLoading: true,
      };
      if (isSalesExecutive && localStorage.getItem("retailer")) {
        const email = JSON.parse(localStorage.getItem("retailer"))?.Email;
        data.email = email;
        dispatch(getCartItemsCount(email));
      } else {
        dispatch(getCartItemsCount());
      }
      dispatch(getCartItems(data));
      dispatch(getInventoryByUser());
    }
  }, [deleteCartItemsData]);
  const isSecondRun = useRef(true);
  useEffect(() => {
    if (isSecondRun.current) {
      isSecondRun.current = false;
      return;
    }
    if (clearCartItemsData?.data?.message?.length) {
      showSuccess(clearCartItemsData?.data?.message, {
        position: "top-right",
      });
      let data = {
        pageNumber: pageNumber,
        isLoading: true,
      };
      if (isSalesExecutive && localStorage.getItem("retailer")) {
        const email = JSON.parse(localStorage.getItem("retailer"))?.Email;
        data.email = email;
        dispatch(getCartItemsCount(email));
      } else {
        dispatch(getCartItemsCount());
      }
      dispatch(getCartItems(data));
      dispatch(getInventoryByUser());
    }
    if (clearCartItemsData?.data?.error?.message) {
      showError(clearCartItemsData?.data?.error?.message);
    }
  }, [clearCartItemsData]);

  let productCardData = [];

  useEffect(() => {
    if (cartItemsData) {
      productCardData.push(cartItemsData.cartItems);
      setProductData(cartItemsData.cartItems);
      setLoadingProduct(false);
      setPaymentSummary({
        totalPrice: cartItemsData.cartProductTotPricewithGST,
        gstPrice: cartItemsData.cartTotalGSTAmt,
        subTotalPrice: cartItemsData.cartTotPrice,
        totalCGSTValue: cartItemsData.totalCGSTValue,
        totalIGSTValue: cartItemsData.totalIGSTValue,
        totalSGSTValue: cartItemsData.totalSGSTValue,
        cGSTRate: cartItemsData.cGSTRate,
        iGSTRate: cartItemsData.iGSTRate,
        sGSTRate: cartItemsData.sGSTRate,
      });
      setDeliveryToData({
        address: cartItemsData?.billToInfo?.billToAddress,
        businessName: cartItemsData?.billToInfo?.businessName,
        city: cartItemsData?.billToInfo?.city,
        pincode: cartItemsData?.billToInfo?.pinCode,
        state: cartItemsData?.billToInfo?.state,
      });
    }
  }, [cartItemsData]);

  const run = useRef(true);
  useEffect(() => {
    if (run.current) {
      run.current = false;
      return;
    }
    if (placeOrderData) {
      if (placeOrderData?.error?.message) {
        showError(placeOrderData?.error?.message);
        return;
      }
      let data = {
        pageNumber: pageNumber,
        // pageSize: pageSize,
      };
      if (isSalesExecutive && localStorage.getItem("retailer")) {
        const email = JSON.parse(localStorage.getItem("retailer"))?.Email;
        data.email = email;
        dispatch(getCartItemsCount(email));
      } else {
        dispatch(getCartItemsCount());
      }
      dispatch(getCartItems(data));
      dispatch(getInventoryByUser());
      history.push("/profile?order=true&success=true");
    }
  }, [placeOrderData]);

  const getPlaceOrder = () => {
    let email = "";
    if (isSalesExecutive && localStorage.getItem("retailer")) {
      email = JSON.parse(localStorage.getItem("retailer"))?.Email;
    }
    dispatch(
      placeOrder({
        CartId: productData?.length && productData[0].cartID,
        Note: orderNote,
        Email: email ? email : null,
      })
    );
  };

  const [updateQuantity, setUpdateQuantity] = useState([]);
  let priceData = [];
  priceData = updateQuantity;
  const handleProductQuntity = (
    productQuantitity,
    detailsId,
    costPriceWithoutGST,
    IGSTRate,
    CGSTRate,
    SGSTRate
  ) => {
    let tempData = updateQuantity?.find((item) => detailsId == item?.detailsId);
    if (tempData != undefined) {
      let newData = updateQuantity?.filter(
        (item) => detailsId != item?.detailsId
      );
      priceData = newData;
    }
    priceData.push({
      productQuantitity: productQuantitity,
      detailsId: detailsId,
      costPriceWithoutGST: costPriceWithoutGST,
      IGSTRate,
      CGSTRate,
      SGSTRate,
    });
    setUpdateQuantity(priceData);
    const updateQuantityProduct = _.findIndex(productData, {
      cartProductDetailId: detailsId,
    });
    productData[updateQuantityProduct].cartProductTotQuantity =
      productQuantitity;
    if (isSalesExecutive && localStorage.getItem("retailer")) {
      const email = JSON.parse(localStorage.getItem("retailer"))?.Email;
      dispatch(
        updateItemsToCart({
          CartProductDetailID: detailsId,
          Quantity: productQuantitity,
          Email: email,
        })
      );
    } else {
      dispatch(
        updateItemsToCart({
          CartProductDetailID: detailsId,
          Quantity: productQuantitity,
        })
      );
    }
  };

  const callCartAPI = (data) => {
    dispatch(getCartItems(data));
  };

  const getDeleteRequestedProductId = (id) => {
    let data = {
      id: id,
    };
    if (isSalesExecutive && localStorage.getItem("retailer")) {
      data.email = JSON.parse(localStorage.getItem("retailer"))?.Email;
    }
    dispatch(deleteCartItems(data));
    setPageNumber(PURCHASE_ORDER.DEFAULT_PAGE_NUMBER);
  };

  const clearCartItemsApiRequest = () => {
    let data = {};
    if (isSalesExecutive && localStorage.getItem("retailer")) {
      data.email = JSON.parse(localStorage.getItem("retailer"))?.Email;
    }
    dispatch(clearCartItems(data));
    setPageNumber(PURCHASE_ORDER.DEFAULT_PAGE_NUMBER);
  };
  const clearCartModal = () => {
    setClearCartModal(true);
  };

  const handleLoadMore = () => {
    if (pageNumber >= 1) {
      setPageSize(PURCHASE_ORDER.DEFAUILT_PAGE_SIZE + 5);
      let data = {
        pageNumber: pageNumber,
        pageSize: pageSize + 5,
      };
      if (isSalesExecutive && localStorage.getItem("retailer")) {
        data.email = JSON.parse(localStorage.getItem("retailer"))?.Email;
      }
      callCartAPI(data);
    }
  };
  const isLoadMore = () => {
    if (
      cartItemsData?.cartItemsCount > 0 &&
      cartItemsData?.cartItemsCount < productData?.length // if needed change to !=
    ) {
      return (
        <>
          <div className="d-flex justify-content-center">
            <Button
              className="myorder-loadmore"
              loading={isGetCartItemsRequesting}
              onClick={() => {
                handleLoadMore();
              }}
            >
              {t("ORDER.LOAD_MORE")}
            </Button>
          </div>
        </>
      );
    }
  };

  const displayOrderDetails = () => {
    if (
      !isGetCartItemsRequesting &&
      // !isCartItemsCountRequesting &&
      !lodaingProduct
    ) {
      return (
        <>
          {productData?.length ? (
            <>
              <Row>
                <Col xl="12" lg="12" md="12" sm="8">
                  <ProductCard
                    getDeleteRequestedProductId={getDeleteRequestedProductId}
                    productData={productData}
                    handleProductQuntity={handleProductQuntity}
                    isUpdateQuantityRequesting={isUpdateQuantityRequesting}
                    updateQuantity={updateQuantity}
                    setValidQuantity={setValidQuantity}
                  />
                </Col>
              </Row>
              <Row className="justify-content-between">
                <Col lg="4">
                  <div className="product-order-notes d-none">
                    <FormGroup>
                      <Label for="orderNotes" className="product-order-count-1">
                        {t("ORDER.ORDER_NOTES")}
                      </Label>
                      <Input
                        type="textarea"
                        name="text"
                        id="orderNotes"
                        className="order-notes-text"
                        value={orderNote}
                        onChange={(e) => setOrderNote(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                </Col>
                <Col lg="7">
                  <Row>
                    <Col lg="6" md="12" sm="4">
                      <DeliveryTo deliveryToData={deliveryToData} />
                    </Col>
                    <Col lg="6">
                      <PaymentSummary
                        paymentSummaryData={paymentSummary}
                        getPlaceOrder={getPlaceOrder}
                        isPlaceOrderRequesting={isPlaceOrderRequesting}
                        updateQuantity={updateQuantity}
                        validateQuantity={validateQuantity}
                      />
                      {/* <TermsAndConditions /> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <p className="product-order-count">{t("ORDER.EXPIRE_MESSAGE")}</p>
            </>
          ) : (
            <></>
          )}
          {isLoadMore()}
        </>
      );
    } else {
      return (
        <div className="custom-loader">
          <Loader width={50} height={50} />
        </div>
      );
    }
  };

  return (
    <>
      <div className="product-page">
        <Container
          fluid={true}
          className="section-container pl-0 clear-cart-align-card"
        >
          <div className="pl-0 pt-4">
            <BreadCrumbs defaultBreadCrumbsdata={breadCrumbsdata} />
            {productData?.length ? (
              <>
                {!isGetCartItemsRequesting && !isCartItemsCountRequesting ? (
                  <div>
                    {succesfullModal ? (
                      <SuccesModal
                        isSuccesfullyOrdered={isSuccesfullyOrdered}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                {!lodaingProduct && (
                  <div>
                    <p
                      className={
                        !isGetCartItemsRequesting && !lodaingProduct
                          ? "product-order-count"
                          : "d-none"
                      }
                    >
                      {t("ORDER.YOU_HAVE")}
                      <span className="product-order-count-1">
                        {" "}
                        {itemsCount ? itemsCount : 0} {t("ORDER.ITEMS")}
                      </span>{" "}
                      {t("ORDER.IN_YOUR_ORDER")}
                    </p>
                  </div>
                )}
              </>
            ) : (
              ""
            )}
          </div>
          {itemsCount ? (
            <div
              className={
                !isGetCartItemsRequesting && !lodaingProduct ? "" : "d-none"
              }
            >
              <div
                onClick={() => {
                  clearCartModal();
                }}
              ></div>
              <ClearCartModal
                clearCartModal={clearCart}
                confirmClearCart={clearCartItemsApiRequest}
                isClearCartItemsRequesting={isClearCartItemsRequesting}
              />
            </div>
          ) : (
            ""
          )}
        </Container>
        <Container
          fluid={true}
          className={
            productData?.length ? "section-container pb-4" : "center-text"
          }
        >
          {productData?.length ? (
            ""
          ) : (
            <>
              {!isGetCartItemsRequesting && !isCartItemsCountRequesting ? (
                <div>
                  {succesfullModal ? (
                    <SuccesModal isSuccesfullyOrdered={isSuccesfullyOrdered} />
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {!lodaingProduct && (
                <p
                  className={
                    !isGetCartItemsRequesting && !lodaingProduct
                      ? "product-order-count"
                      : "d-none"
                  }
                >
                  {t("ORDER.YOU_HAVE")}
                  <span className="product-order-count-1">
                    {" "}
                    {itemsCount ? itemsCount : 0} {t("ORDER.ITEMS")}
                  </span>{" "}
                  {t("ORDER.IN_YOUR_ORDER")}
                </p>
              )}
            </>
          )}

          {displayOrderDetails()}
        </Container>
      </div>
    </>
  );
}
