import React from "react";
import "./Button.scss";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

const ButtonComponent = ({ title, link, url }) => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const history = useHistory();
  return (
    <div>
      <button
        onClick={() => {
          if (link) {
            history.push(link);
          } else {
            openInNewTab(url);
          }
        }}
        className="button__container"
      >
        {title}
      </button>
    </div>
  );
};

export default ButtonComponent;

ButtonComponent.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  url: PropTypes.string,
};
