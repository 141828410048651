import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import CarouselHeader from "../../../components/CategoryHeader";
import Frames from "../../../components/Frames";
import PropTypes from "prop-types";
import Slider from "../../../components/CarouselWrapper";
import { t } from "react-multi-lang";
export default function Material({
  materialData,
  materialBannerData,
  materialKey,
}) {
  const [materials, setMaterials] = useState();
  const [materialViewAll, setMaterialViewAll] = useState("");
  const data = {
    title: t("HOME.MATERIAL_TEXT"),
    view_all: t("HOME.VIEW_ALL"),
  };

  useEffect(() => {
    let materialViewAllData = [];
    if (materialData !== undefined) {
      materialData.map((items) => {
        materialViewAllData.push(items.materialId.toString());
      });
    }
    setMaterialViewAll(`frameMaterialId=${materialViewAllData.toString()}`);

    let shape_Material = [];
    if (materialData !== undefined) {
      const materialResult = materialData.filter(
        (item) => item.displayOnHome === true
      );
      materialResult.map((items) => {
        shape_Material.push({
          frameId: items.materialId,
          frameMaterial: items.description,
          images: items.materialImages,
        });
      });
    }
    setMaterials(shape_Material);
  }, [materialData]);

  const handleImage = (item, index) => {
    return item && item.length > 0 && (item[index]?.materialUrl || "");
  };

  return (
    <div>
      <Container
        fluid={true}
        className="footer-section-container material-section d-none"
      >
        <CarouselHeader data={data} viewAll={materialViewAll} />
        <Row>
          <Col xl="6" lg="12" md="12">
            <Slider data={materialBannerData} className="catalog-section-3" />
          </Col>
          <Col xs="6" sm="6" md="6" xl="6" lg="12" className="ta-section">
            <Row className="shape-section">
              {materials && materials.length > 0
                ? materials.map((item) => {
                    return (
                      <Col
                        xs="6"
                        sm="6"
                        md="6"
                        className="mb-section"
                        key={item.frameId}
                      >
                        <Frames
                          label={item.frameMaterial || ""}
                          imageOne={handleImage(item.images, 0)}
                          imageTwo={handleImage(item.images, 1)}
                          frameKey={materialKey}
                          id={item.frameId}
                        />
                      </Col>
                    );
                  })
                : ""}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
Material.propTypes = {
  materialBannerData: PropTypes.array,
  materialData: PropTypes.object,
  materialKey: PropTypes.string,
};
