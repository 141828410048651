import { Row, Col, Modal, ModalBody } from "reactstrap";
import React, { useEffect, useState, useRef } from "react";
import { FormGroup, Label, Input, Form, TabPane, TabContent } from "reactstrap";
import PropTypes from "prop-types";
import PriceFilter from "./../PriceFilter";
import { getSortedArrayData } from "./../../../helpers/Utility";
import Close from "../../../assets/Images/close_24.svg";
const ProductWebFilter = ({
  productWebFilterEnableHandle,
  productWebFilterEnable,
  genderCheckedData,
  materilaCheckedData,
  shapeCheckedData,
  priceFilterDatas,
  filterOptionData,
  getFilterdSelectedQuery,
  getPriceFiltered,
  getSelectedFilter,
  selectedButton,
}) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    productWebFilterEnableHandle(!modal);
    setModal(!modal);
  };

  useEffect(() => {
    setModal(productWebFilterEnable);
  }, [productWebFilterEnable]);

  const filterId = {
    frameMaterialId: "frameMaterialId",
    frameShapeId: "frameShapeId",
    genderIdList: "genderIdList",
    sizeId: "sizeId",
    frameColorIdList: "frameColorIdList",
    brandId: "brandId",
    productTypeId: "productTypeId",
    availablity: "availablity",
  };
  const [productSize, setProductSize] = useState([]);
  const [productMaterial, setproductMaterial] = useState([]);
  const [productGender, setProductGender] = useState([]);
  const [productShape, setProductShape] = useState([]);
  const [productColor, setProductColor] = useState([]);
  const [productBrand, setProductBrand] = useState([]);
  const [productPrice, setProductPrice] = useState([]);
  const [productCatagory, setProductCatagory] = useState([]);
  const [checkData, setCheckData] = useState({});
  const isFirstRun = useRef(true);
  const [query, setQuery] = useState("");
  const [priceFilter, setPriceFilter] = useState("");

  const IsAvailblity = {
    key: "availablity",
    name: "Availability",
    type: "In",
    data: [
      {
        id: "1",
        name: "Don’t show out of stock items, Show available items only",
      },
    ],
  };

  const [activeTab, setActiveTab] = useState("0");
  useEffect(() => {
    setCheckData({});
  }, [materilaCheckedData, genderCheckedData, shapeCheckedData]);

  useEffect(() => {
    let data = JSON.parse(JSON.stringify(selectedButton));
    setCheckData(data);
    let frameShapeId = data?.frameShapeId?.toString();
    let frameColorIdList = data?.frameColorIdList?.toString();
    let sizeId = data?.sizeId?.toString();
    let brandId = data?.brandId?.toString();
    let productTypeId = data?.productTypeId?.toString();
    let frameMaterialId = data?.frameMaterialId?.toString();
    let genderIdList = data?.genderIdList?.toString();
    let availablity = data?.availablity?.toString();
    let search =
      `${frameShapeIdData(frameShapeId)}` +
      `${frameColorIdListData(frameColorIdList)}` +
      `${sizeIdData(sizeId)}` +
      `${brandIdData(brandId)}` +
      `${categoriesIdData(productTypeId)}` +
      `${frameMaterialIdData(frameMaterialId)}` +
      `${genderIdListData(genderIdList)}` +
      `${availablityData(availablity)}`;
    let filter = search.trim();
    setQuery(filter.substring(0, filter.length - 1));
    getFilterdSelectedQuery(filter.substring(0, filter.length - 1));
  }, [selectedButton]);

  useEffect(() => {
    if (
      shapeCheckedData &&
      shapeCheckedData.frameShapeId &&
      shapeCheckedData.frameShapeId.length
    ) {
      let checkedObject = {};
      shapeCheckedData.frameShapeId.map((item) => {
        if (checkedObject[Object.keys(shapeCheckedData)]) {
          checkedObject[Object.keys(shapeCheckedData)].push(item.toString());
        } else {
          checkedObject[Object.keys(shapeCheckedData)] = [item.toString()];
        }
      });
      setCheckData(checkedObject);

      setQuery(
        frameShapeIdData(shapeCheckedData?.frameShapeIdData?.toString())
      );
    }
    if (
      materilaCheckedData &&
      materilaCheckedData.frameMaterialId &&
      materilaCheckedData.frameMaterialId.length
    ) {
      let checkedObject = {};
      materilaCheckedData.frameMaterialId.map((item) => {
        if (checkedObject[Object.keys(materilaCheckedData)]) {
          checkedObject[Object.keys(materilaCheckedData)].push(item.toString());
        } else {
          checkedObject[Object.keys(materilaCheckedData)] = [item.toString()];
        }
      });
      setCheckData(checkedObject);

      setQuery(
        frameMaterialIdData(materilaCheckedData?.frameMaterialId?.toString())
      );
    }
    if (
      genderCheckedData &&
      genderCheckedData.genderIdList &&
      genderCheckedData.genderIdList.length
    ) {
      let genderObject = {};
      genderCheckedData.genderIdList.map((item) => {
        if (genderObject[Object.keys(genderCheckedData)]) {
          genderObject[Object.keys(genderCheckedData)].push(item.toString());
        } else {
          genderObject[Object.keys(genderCheckedData)] = [item.toString()];
        }
      });
      setCheckData(genderObject);
      setQuery(genderIdListData(genderCheckedData?.frameShapeId?.toString()));
    }
  }, [shapeCheckedData, materilaCheckedData, genderCheckedData]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    filterOptionData &&
      filterOptionData.filters.map((filter) => {
        switch (filter.type.length > 0 && filter.key.length > 0) {
          case "In" === filter.type && "sizeId" === filter.key:
            setProductSize({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "In" === filter.type && "frameMaterialId" === filter.key:
            setproductMaterial({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "In" === filter.type && "genderIdList" === filter.key:
            setProductGender({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "In" === filter.type && "frameShapeId" === filter.key:
            setProductShape({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "In" === filter.type && "frameColorIdList" === filter.key:
            setProductColor({
              key: filter.key,
              name: filter.name,
              data: JSON.parse(JSON.stringify(filter.data)),
            });
            break;
          case "In" === filter.type && "brandId" === filter.key:
            setProductBrand({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "In" === filter.type && "productTypeId" === filter.key:
            setProductCatagory({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "Between" === filter.type && "netPrice" === filter.key:
            setProductPrice({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;

          default:
        }
      });
  }, [filterOptionData]);

  const getPriceRangeData = (data) => {
    getPriceFiltered(data);
    let filterQuery = `netPrice_From=${data.min}&netPrice_To=${data.max}`;
    setPriceFilter(filterQuery);
    getFilterdSelectedQuery(`${query}&${filterQuery}`);
  };

  const frameShapeIdData = (frameShapeId) => {
    if (frameShapeId) {
      return `frameShapeId=${frameShapeId}&`;
    } else {
      return "";
    }
  };
  const frameColorIdListData = (frameColorIdList) => {
    if (frameColorIdList) {
      return `frameColorIdList=${frameColorIdList}&`;
    } else {
      return "";
    }
  };
  const sizeIdData = (sizeId) => {
    if (sizeId) {
      return `sizeId=${sizeId}&`;
    } else {
      return "";
    }
  };
  const brandIdData = (brandId) => {
    if (brandId) {
      return `brandId=${brandId}&`;
    } else {
      return "";
    }
  };
  const categoriesIdData = (productTypeId) => {
    if (productTypeId) {
      return `productTypeId=${productTypeId}&`;
    } else {
      return "";
    }
  };
  const frameMaterialIdData = (frameMaterialId) => {
    if (frameMaterialId) {
      return `frameMaterialId=${frameMaterialId}&`;
    } else {
      return "";
    }
  };
  const genderIdListData = (genderIdList) => {
    if (genderIdList) {
      return `genderIdList=${genderIdList}&`;
    } else {
      return "";
    }
  };
  const priceFilterData = (priceString) => {
    if (priceString) {
      return `${priceFilter}&`;
    } else {
      return "";
    }
  };
  const availablityData = (availablity) => {
    if (availablity) {
      return `isAvailable=true&`;
    } else {
      return "";
    }
  };

  let queryData = checkData;

  const handleCheckBox = (e) => {
    if (!e.target.checked) {
      let arr = queryData[e.target.value];
      arr = arr && arr.length && arr.filter((item) => item !== e.target.name);
      queryData[e.target.value] = arr;
    } else {
      if (queryData[e.target.value]) {
        queryData[e.target.value].push(e.target.name);
      } else {
        queryData[e.target.value] = [e.target.name];
      }
    }
    getSelectedFilter(queryData);
    setCheckData(queryData);
    let frameShapeId =
      queryData && queryData.frameShapeId && queryData.frameShapeId.toString();
    let frameColorIdList =
      queryData &&
      queryData.frameColorIdList &&
      queryData.frameColorIdList.toString();
    let sizeId = queryData && queryData.sizeId && queryData.sizeId.toString();
    let brandId =
      queryData && queryData.brandId && queryData.brandId.toString();
    let productTypeId =
      queryData &&
      queryData.productTypeId &&
      queryData.productTypeId.toString();
    let frameMaterialId =
      queryData &&
      queryData.frameMaterialId &&
      queryData.frameMaterialId.toString();
    let genderIdList =
      queryData && queryData.genderIdList && queryData.genderIdList.toString();
    let availablity =
      queryData && queryData.availablity && queryData.availablity.toString();
    let search =
      `${frameShapeIdData(frameShapeId)}` +
      `${frameColorIdListData(frameColorIdList)}` +
      `${sizeIdData(sizeId)}` +
      `${brandIdData(brandId)}` +
      `${categoriesIdData(productTypeId)}` +
      `${frameMaterialIdData(frameMaterialId)}` +
      `${genderIdListData(genderIdList)}` +
      `${availablityData(availablity)}` +
      `${priceFilterData(priceFilter)}`;

    let filter = search.trim();
    setQuery(filter.substring(0, filter.length - 1));
    getFilterdSelectedQuery(filter.substring(0, filter.length - 1));
  };

  const getFilter = (product) => {
    let data = product && product.data;
    if (filterId.genderIdList !== product.key) {
      data && getSortedArrayData(data);
    }
    return (
      data &&
      data.sort().map((item) => {
        return (
          <>
            <Form
              id={item.name}
              className={
                data.length > 7 ? "d-inline-block option-more-tna-five" : ""
              }
            >
              <FormGroup check className="mt-2">
                <Label check className="product-filter-option ">
                  <Input
                    type="checkbox"
                    className="product-checkbox"
                    name={item.id}
                    value={product.key}
                    checked={
                      (filterId.frameMaterialId == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.frameMaterialId?.includes(
                          item.id.toString()
                        )) ||
                      (filterId.frameShapeId == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.frameShapeId?.includes(
                          item.id.toString()
                        )) ||
                      (filterId.genderIdList == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.genderIdList?.includes(
                          item.id.toString()
                        )) ||
                      (filterId.sizeId == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.sizeId?.includes(item.id.toString())) ||
                      (filterId.frameColorIdList == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.frameColorIdList?.includes(
                          item.id.toString()
                        )) ||
                      (filterId.brandId == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.brandId?.includes(item.id.toString())) ||
                      (filterId.productTypeId == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.productTypeId?.includes(
                          item.id.toString()
                        )) ||
                      (filterId.availablity == product.key &&
                        Object.keys(checkData)?.includes(product.key) &&
                        checkData?.availablity?.includes(item.id))
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      handleCheckBox(e);
                    }}
                  />{" "}
                  {
                    /* mani {item?.name?.split(" ").map((item) => {
                    return item == "TR"
                      ? item + " "
                      : item.charAt(0).toUpperCase() +
                          item.slice(1).toLowerCase() +
                          " ";
                  })} */
                    item?.name
                  }
                </Label>
              </FormGroup>
            </Form>
          </>
        );
      })
    );
  };

  const handleTab = (params) => {
    setActiveTab(`${params}`);
  };

  const showActiveTabColor = (params) => {
    if (`${params}` == activeTab) {
      return true;
    }
    return false;
  };

  const handleSelectedColorFilter = (id) => {
    // let arrayDataNew = queryData["frameColorIdList"]);
    let colorData = queryData["frameColorIdList"];
    if (colorData?.filter((item) => item == id).length) {
      let arr = queryData["frameColorIdList"];
      arr = arr && arr.length && arr.filter((item) => item !== id);
      queryData["frameColorIdList"] = arr;
    } else {
      if (queryData["frameColorIdList"]) {
        queryData["frameColorIdList"].push(id);
      } else {
        queryData["frameColorIdList"] = [id];
      }
    }
    getSelectedFilter(queryData);
    setCheckData(queryData);
    let frameShapeId =
      queryData && queryData.frameShapeId && queryData.frameShapeId.toString();
    let frameColorIdList =
      queryData &&
      queryData.frameColorIdList &&
      queryData.frameColorIdList.toString();
    let sizeId = queryData && queryData.sizeId && queryData.sizeId.toString();
    let brandId =
      queryData && queryData.brandId && queryData.brandId.toString();
    let productTypeId =
      queryData &&
      queryData.productTypeId &&
      queryData.productTypeId.toString();
    let frameMaterialId =
      queryData &&
      queryData.frameMaterialId &&
      queryData.frameMaterialId.toString();
    let genderIdList =
      queryData && queryData.genderIdList && queryData.genderIdList.toString();
    let availablity =
      queryData && queryData.availablity && queryData.availablity.toString();
    let search =
      `${frameShapeIdData(frameShapeId)}` +
      `${frameColorIdListData(frameColorIdList)}` +
      `${sizeIdData(sizeId)}` +
      `${brandIdData(brandId)}` +
      `${categoriesIdData(productTypeId)}` +
      `${frameMaterialIdData(frameMaterialId)}` +
      `${genderIdListData(genderIdList)}` +
      `${availablityData(availablity)}` +
      `${priceFilterData(priceFilter)}`;

    let filter = search.trim();
    setQuery(filter.substring(0, filter.length - 1));
    getFilterdSelectedQuery(filter.substring(0, filter.length - 1));
  };

  function getColorRender() {
    return productColor?.data?.map((color) => {
      return (
        <div key={color.Id} className="color-filter-option">
          <li
            key={color.Id}
            onClick={() => {
              handleSelectedColorFilter(color.Id);
            }}
            style={
              // index ==
              queryData["frameColorIdList"]?.filter((item) => item == color.Id)
                .length > 0
                ? {
                    border:
                      color.colorCode === "#FFFFFF"
                        ? "2px solid #dad6d0"
                        : `2px solid ${color.colorCode}`,
                    // border: "2px solid ",
                    borderRadius: "50%",
                  }
                : {}
            }
          >
            <span
              className="color-dot"
              style={{
                // border: "2px solid white",
                border:
                  color.colorCode === "#FFFFFF"
                    ? "2px solid #dad6d0"
                    : "2px solid white",
                backgroundColor: `${color.colorCode}`,
              }}
            ></span>
          </li>
          <span
            onClick={() => {
              handleSelectedColorFilter(color.Id);
            }}
          >
            {color.Name}
          </span>
        </div>
      );
    });
  }

  return (
    <Modal isOpen={modal} toggle={toggle} className="web-filter-model">
      <ModalBody className="modal-body-padding model-border-shadow">
        <div className="web-filter">
          {/* {productWebFilterEnable ? ( */}
          <div className="web-filter-options model-border-shadow">
            <div className="">
              <span className="web-filter-close-button" onClick={toggle}>
                <img src={Close} className="close-buttton-filter-options" />
              </span>
            </div>

            <Row className="padding-filter-ipad">
              <Col sm="3" md="3" lg="3" className="web-filter-option-col">
                <div
                  onClick={() => handleTab(0)}
                  className={
                    showActiveTabColor(0)
                      ? "filter-option-list-active-color"
                      : "filter-option-list"
                  }
                >
                  <p> {`${productPrice.name} & ${productGender.name}`}</p>
                </div>
                <div
                  onClick={() => handleTab(1)}
                  className={
                    showActiveTabColor(1)
                      ? "filter-option-list-active-color"
                      : "filter-option-list"
                  }
                >
                  <p>{productSize.name}</p>
                </div>
                <div
                  onClick={() => handleTab(2)}
                  className={
                    showActiveTabColor(2)
                      ? "filter-option-list-active-color"
                      : "filter-option-list"
                  }
                >
                  <p>{productMaterial.name}</p>
                </div>

                <div
                  onClick={() => handleTab(4)}
                  className={
                    showActiveTabColor(4)
                      ? "filter-option-list-active-color"
                      : "filter-option-list"
                  }
                >
                  <p>{productShape.name}</p>
                </div>
                <div
                  onClick={() => handleTab(5)}
                  className={
                    showActiveTabColor(5)
                      ? "filter-option-list-active-color"
                      : "filter-option-list"
                  }
                >
                  <p>{productColor.name}</p>
                </div>
                <div
                  onClick={() => handleTab(6)}
                  className={
                    showActiveTabColor(6)
                      ? "filter-option-list-active-color"
                      : "filter-option-list"
                  }
                >
                  <p>{productBrand.name}</p>
                </div>
                <div
                  onClick={() => handleTab(7)}
                  className={
                    showActiveTabColor(7)
                      ? "filter-option-list-active-color"
                      : "filter-option-list"
                  }
                >
                  <p>{productCatagory.name}</p>
                </div>
                <div
                  onClick={() => handleTab(8)}
                  className={
                    showActiveTabColor(8)
                      ? "filter-option-list-active-color"
                      : "filter-option-list"
                  }
                >
                  <p>Availability</p>
                </div>
              </Col>
              <Col sm="9" md="9" lg="9" className="filter-padding">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="0">
                    <div className="tab-content-color-price-padding tab-content-padding">
                      <PriceFilter
                        priceFilterData={priceFilterDatas}
                        productPrice={productPrice}
                        getPriceRangeData={getPriceRangeData}
                      />
                      <div className="price-filter-spacing">
                        {productGender.name}
                      </div>
                      <div className="mt-2">{getFilter(productGender)}</div>
                    </div>
                    {/* </div> */}
                  </TabPane>
                </TabContent>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <div className="tab-content-padding">
                      <div className="filter-header-spacing ">
                        {productSize.name}
                      </div>

                      {getFilter(productSize)}
                    </div>
                  </TabPane>
                </TabContent>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="2">
                    <div className="tab-content-padding">
                      <div className="filter-header-spacing ">
                        {productMaterial.name}
                      </div>

                      {getFilter(productMaterial)}
                    </div>
                  </TabPane>
                </TabContent>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="3">
                    <div className="tab-content-padding">
                      <div className="filter-header-spacing ">
                        {productGender.name}
                      </div>

                      {getFilter(productGender)}
                    </div>
                  </TabPane>
                </TabContent>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="4">
                    <div className="tab-content-padding">
                      <div className="filter-header-spacing ">
                        {productShape.name}
                      </div>

                      {getFilter(productShape)}
                    </div>
                  </TabPane>
                </TabContent>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="5">
                    <div className="tab-content-color-price-padding tab-content-padding">
                      <div className="filter-header-spacing ">
                        {productColor.name}
                      </div>
                      <div className="tab-content-color-overflow">
                        <ul className="frame-colors-list d-inline  ">
                          {getColorRender()}
                        </ul>
                      </div>

                      {/* {getFilter(productColor)} */}
                    </div>
                  </TabPane>
                </TabContent>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="6">
                    <div className="tab-content-padding">
                      <div className="filter-header-spacing">
                        {productBrand.name}
                      </div>

                      {getFilter(productBrand)}
                    </div>
                  </TabPane>
                </TabContent>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="7">
                    <div className="tab-content-padding">
                      <div className="filter-header-spacing">
                        {productCatagory.name}
                      </div>

                      {getFilter(productCatagory)}
                    </div>
                  </TabPane>
                </TabContent>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="8">
                    <div className="tab-content-padding">
                      <div className="filter-header-spacing">Availability</div>
                      {getFilter(IsAvailblity)}
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </div>
          {/* ) : (
            ""
          )} */}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ProductWebFilter;
ProductWebFilter.propTypes = {
  productWebFilterEnableHandle: PropTypes.func,
  productWebFilterEnable: PropTypes.bool,
  filterOptionData: PropTypes.object,
  getFilterdSelectedQuery: PropTypes.func,
  priceFilterDatas: PropTypes.object,
  materilaCheckedData: PropTypes.object,
  shapeCheckedData: PropTypes.object,
  genderCheckedData: PropTypes.object,
  clearFilterData: PropTypes.any,
  changeFilter: PropTypes.bool,
  getPriceFiltered: PropTypes.func,
  getSelectedFilter: PropTypes.any,
  selectedButton: PropTypes.any,
};
