import { AXIOS_INSTANCE, BASE_URL } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_PAGES } from "./getPagesConst";
import { getPagesSuccess, getPagesFailed } from "./getPagesActions";
import { HomeClient } from "../apibenfranklin";
import { unAuthorizedRedirection } from "./../../helpers/Utility";

const client = new HomeClient(`${BASE_URL}`, AXIOS_INSTANCE);

/**
 *  getPages for  footer
 */
const getPagesApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await client.getPages(payload || {});
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getPages(action) {
  try {
    const apiResponse = yield call(getPagesApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getPagesSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getPagesFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getPagesFailed(errorData));
  }
}

export function* watchGetPages() {
  yield takeEvery(GET_PAGES.GET_PAGES_REQUESTING, getPages);
}

function* getPagesSaga() {
  yield all([fork(watchGetPages)]);
}

export default getPagesSaga;
