import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getPages } from "./../../redux/getPages/getPagesActions";
import Loader from "./../../common/Loader";
import { getToken } from "./../../helpers/Utility";

function Support() {
  let history = useHistory();
  const dispatch = useDispatch();
  const [pageContent, setPageContent] = useState("");

  const nextProps = useSelector((state) => ({
    pagesData: state.Pages || null,
  }));
  const { isGetPagesRequesting, getPagesData } = nextProps.pagesData;
  useEffect(() => {
    if (!getToken()) {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    if (getPagesData) {
      setPageContent(getPagesData?.data?.content);
    }
  }, [getPagesData]);

  let url_string = window.location.href;
  let url = new URL(url_string);
  let newPageName = url.searchParams.get("pageName");
  useEffect(() => {
    if (url.searchParams.get("pageName")) {
      dispatch(getPages(url.searchParams.get("pageName")));
    }
  }, [newPageName]);

  return (
    <>
      <div className="div-alignment">
        {isGetPagesRequesting ? (
          <div className="support-loader">
            <Loader width="50" height="50" />
          </div>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: pageContent }}></div>
        )}
      </div>
    </>
  );
}

export default Support;
