import { AXIOS_INSTANCE, BASE_URL } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { EXPRESS_CHECKOUT_CONST } from "./expressCheckoutConst";

import {
  getAllProductSuccess,
  getAllProductFailed,
  getInventoryByMultipleProductIdSuccess,
  getInventoryByMultipleProductIdFailed,
} from "./expressCheckoutActions";
import { ProductClient } from "../apibenfranklin";
import { getToken, unAuthorizedRedirection } from "../../helpers/Utility";

const client = new ProductClient(`${BASE_URL}`, AXIOS_INSTANCE);

/**
 *  Placed order
 */
const placeOrderApiRequest = async () => {
  let token = `Bearer ${getToken()}`;
  let response = {};
  try {
    const data = await client.getAllSelectedFields(token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getAllProduct(action) {
  try {
    const apiResponse = yield call(placeOrderApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getAllProductSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getAllProductFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllProductFailed(errorData));
  }
}
/**
 *  get Inventory By Multiple Product Ids
 */
const getInventoryByMultipleProductIdApiRequest = async (payload) => {
  let token = `Bearer ${getToken()}`;
  let response = {};
  try {
    const data = await client.getInventoryByMultipleProductId(
      payload.productIds,
      token
    );
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getInventoryByMultipleProducts(action) {
  try {
    const apiResponse = yield call(
      getInventoryByMultipleProductIdApiRequest,
      action.payload
    );
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getInventoryByMultipleProductIdSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getInventoryByMultipleProductIdFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getInventoryByMultipleProductIdFailed(errorData));
  }
}

export function* watchGetAllProduct() {
  yield takeEvery(
    EXPRESS_CHECKOUT_CONST.EXPRESS_CHECKOUT_PRODUCT_LIST_REQUESTING,
    getAllProduct
  );
  yield takeEvery(
    EXPRESS_CHECKOUT_CONST.EXPRESS_CHECKOUT_MULTIPLE_PRODUCT_ID_REQUESTING,
    getInventoryByMultipleProducts
  );
}

function* watchGetAllProductSaga() {
  yield all([fork(watchGetAllProduct)]);
}

export default watchGetAllProductSaga;
