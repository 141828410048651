import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getRetailersListRequest } from "../../redux/profile/profileAction";
import { getCartItemsCount } from "../../redux/cartCount/cartCountActions";
import { getCartItems } from "../../redux/cart/cartActions";
import { getMyOrders } from "../../redux/myOrders/orderActions";
import SwitchUserModal from "../../components/SwitchUserpopup";
import SwitchUserSuccess from "../../components/SwitchUserSuccessPopup";
import Loader from "./../../common/Loader";
import { useTranslation } from "react-multi-lang";
import PropTypes from "prop-types";

const SwitchUser = ({ changeCurrentRetailer }) => {
  const [retailersListData, setRetailersListData] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [switchModal, setSwitchModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const dispatch = useDispatch();
  const t = useTranslation();
  const salesExecutive = "salesexecutive";
  const nextProps = useSelector((state) => ({
    RetailesListData: state.UserProfile.isGetretailersListData || null,
    RetailerListRequest: state.UserProfile.isGetretailersListRequesting || null,
    UserProfileData: state.UserProfile.profileData || null,
  }));

  useEffect(() => {
    dispatch(getRetailersListRequest());
  }, []);
  useEffect(() => {
    if (nextProps.RetailesListData && nextProps.RetailesListData?.data) {
      setRetailersListData(nextProps.RetailesListData.data.retailersList);
    }
    if (nextProps.RetailerListRequest) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [nextProps.RetailesListData, nextProps.RetailerListRequest]);
  useEffect(() => {
    if (
      nextProps.UserProfileData &&
      nextProps.UserProfileData?.data.userProfile
    ) {
      setProfileData(nextProps.UserProfileData?.data.userProfile);
    }
  }, [nextProps.UserProfileData]);

  const handleSwitch = () => {
    setSwitchModal(true);
  };
  const changeCurrentUser = (user) => {
    if (user) {
      setCurrentUser(user);
      localStorage.setItem("retailer", JSON.stringify(user));
    } else {
      setCurrentUser({});
      localStorage.removeItem("retailer");
    }
    setSuccessModal(true);
  };

  useEffect(() => {
    changeCurrentRetailer(currentUser);
    let data = {
      pageNumber: 1,
    };
    if (
      nextProps.UserProfileData?.data.userProfile?.roleName
        ?.toLowerCase()
        .replace(/ /g, "") === salesExecutive &&
      localStorage.getItem("retailer")
    ) {
      const email = JSON.parse(localStorage.getItem("retailer"))?.Email;
      data.email = email;
      dispatch(getCartItemsCount(email));
      dispatch(getMyOrders({ email }));
    } else {
      dispatch(getCartItemsCount());
      dispatch(getMyOrders());
    }
    dispatch(getCartItems(data));
  }, [currentUser]);

  const isSwitchedSuccessfully = (value) => {
    setSuccessModal(value);
  };

  return (
    <>
      {loading ? (
        <div className="custom-loader">
          <Loader width={50} height={50} />
        </div>
      ) : (
        <div>
          <div className="d-none d-lg-block ">
            <Row className="text-center purchase-product-heading">
              <Col lg="3">
                <p className="switch-user-label">{t("PROFILE.NAME")}</p>
              </Col>
              <Col lg="3">
                <p className="switch-user-label">
                  {t("PROFILE.BUSINESS_NAME")}
                </p>
              </Col>
              <Col lg="3">
                <p className="switch-user-label">{t("PROFILE.EMAIL")}</p>
              </Col>
            </Row>
          </div>
          <div>
            <Row className="switch-user-row mb-4 align-items-center text-center">
              <Col>
                <p className="switch-user-info">{profileData.name}</p>
              </Col>
              <Col>
                <p className="switch-user-info">{profileData.businessName}</p>
              </Col>
              <Col>
                <p className="switch-user-info">{profileData.email}</p>
              </Col>
              <Col>
                <div
                  onClick={() => {
                    handleSwitch();
                  }}
                ></div>
                <SwitchUserModal
                  switchModal={switchModal}
                  user={profileData}
                  confirmSwitchUser={changeCurrentUser}
                  isSalesExecutive
                />
              </Col>
            </Row>
            {retailersListData.map((retailer, index) => {
              return (
                <Row
                  key={index}
                  className="switch-user-row mb-4 align-items-center text-center"
                >
                  <Col>
                    <p className="switch-user-info">
                      {retailer.firstName} {retailer.lastName}
                    </p>
                  </Col>
                  <Col>
                    <p className="switch-user-info">{retailer.businessName}</p>
                  </Col>
                  <Col>
                    <p className="switch-user-info">{retailer.email}</p>
                  </Col>
                  <Col>
                    <div
                      onClick={() => {
                        handleSwitch();
                      }}
                    ></div>
                    <SwitchUserModal
                      switchModal={switchModal}
                      user={retailer}
                      confirmSwitchUser={changeCurrentUser}
                    />
                  </Col>
                </Row>
              );
            })}
          </div>
        </div>
      )}
      {successModal && (
        <SwitchUserSuccess
          isSwitched={isSwitchedSuccessfully}
          successFullModal={successModal}
          currentUser={currentUser?.businessName ? currentUser : profileData}
        />
      )}
    </>
  );
};

export default SwitchUser;

SwitchUser.propTypes = {
  changeCurrentRetailer: PropTypes.any,
};
