import { FILTER_CONST } from "./filterConst";

const FilterOption = (state = {}, action) => {
  switch (action.type) {
    case FILTER_CONST.GET_FILTER_OPTION_REQUEST:
      return {
        ...state,
        isFilterOptionRequesting: true,
        filterOptionData: false,
        filterOptionDataError: false,
      };
    case FILTER_CONST.GET_FILTER_OPTION_SUCCESS:
      return {
        ...state,
        isFilterOptionRequesting: false,
        filterOptionData: action.payload.response.data,
        filterOptionDataError: false,
      };
    case FILTER_CONST.GET_FILTER_OPTION_FAILED:
      return {
        ...state,
        isFilterOptionRequesting: false,
        filterOptionData: false,
        filterOptionDataError: action.payload.error
          ? false
          : action.payload.response,
      };
    case FILTER_CONST.GET_ALL_REQUESTING:
      return {
        ...state,
        isGetAllRequesting: true,
        getAllData: false,
        getAllDataError: false,
      };
    case FILTER_CONST.GET_ALL_SUCCESS:
      return {
        ...state,
        isGetAllRequesting: false,
        getAllData: action.payload.response,
        getAllDataError: false,
      };
    case FILTER_CONST.GET_ALL_FAILED:
      return {
        ...state,
        isGetAllRequesting: false,
        getAllData: false,
        getAllDataError: action.payload.error ? false : action.payload.response,
      };

    case FILTER_CONST.GET_PRODUCT_BY_ID_REQUESTING:
      return {
        ...state,
        isGetProductByRequesting: true,
        getProductByIdData: false,
        getProductByIdDataError: false,
      };
    case FILTER_CONST.GET_PRODUCT_BY_ID_SUCCESS:
      return {
        ...state,
        isGetProductByRequesting: false,
        getProductByIdData: action.payload.response,
        getProductByIdDataError: false,
      };
    case FILTER_CONST.GET_PRODUCT_BY_ID_FAILED:
      return {
        ...state,
        isGetProductByRequesting: false,
        getProductByIdData: false,
        getProductByIdDataError: action.payload.error
          ? false
          : action.payload.response,
      };

    case FILTER_CONST.GET_SORT_TYPE_REQUESTING:
      return {
        ...state,
        isSortTypeRequesting: true,
        getSortTypeData: false,
        getSortTypeError: false,
      };
    case FILTER_CONST.GET_SORT_TYPE_SUCCESS:
      return {
        ...state,
        isSortTypeRequesting: false,
        getSortTypeData: action.payload.response,
        getSortTypeError: false,
      };
    case FILTER_CONST.GET_SORT_TYPE_FAILED:
      return {
        ...state,
        isSortTypeRequesting: false,
        getSortTypeData: false,
        getSortTypeError: action.payload.error
          ? false
          : action.payload.response,
      };

    default:
      return { ...state };
  }
};

export default FilterOption;
