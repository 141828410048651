// @flow
// import { AXIOS_INSTANCE, EMPLOYEE_API, ROLE_API, PROJECT_API, REINVITE_API } from "../../api/apiEndPoint";
import { AXIOS_INSTANCE, BASE_URL } from "../../api/apiEndPoint";

// import { checkHttpStatusResult } from "../../api/apiUtils";

import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { USER_CONST } from "./usersConst";

import { addUserSuccess, addUserFailed } from "./usersActions";
import { CitiesClient } from "../ACG.AutoHall.VO-api";

// import Axios from "axios";

const client = new CitiesClient(BASE_URL, AXIOS_INSTANCE);

const addUserApiRequest = async () => {
  // const response = await AXIOS_INSTANCE.post(`${EMPLOYEE_API}/add`, payload);
  // const response = await AXIOS_INSTANCE.get(`${BASE_URL}`);
  // const response = await client
  return await client.get().then((response) => {
    if (!response.status) {
      return {
        data: response,
      };
    } else {
      return {
        error: response,
      };
    }
  });
};

function* addUser(action) {
  try {
    const response = yield call(addUserApiRequest, action.payload);
    // const response = yield call(checkHttpStatusResult, apiResponse);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(addUserSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(addUserFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(addUserFailed(errorData));
  }
}

export function* watchAddUser() {
  yield takeEvery(USER_CONST.ADD_USER_REQUEST, addUser);
}

function* userSaga() {
  yield all([fork(watchAddUser)]);
}

export default userSaga;
