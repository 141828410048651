import { CART_CONST } from "./cartConst";

/**
 *  Get products Items from cart
 */

export const getCartItems = (payload) => ({
  type: CART_CONST.GET_CART_ITEMS_REQUEST,
  payload: payload || null,
});

export const getCartItemsSuccess = (response) => ({
  type: CART_CONST.GET_CART_ITEMS_SUCCESS,
  payload: response,
});

export const getCartItemsFailed = (error) => ({
  type: CART_CONST.GET_CART_ITEMS_FAILED,
  payload: error,
});

/**
 *  Delete product from cart
 */

export const deleteCartItems = (payload) => ({
  type: CART_CONST.DELETE_CART_ITEMS_REQUEST,
  payload: payload || null,
});

export const deleteCartItemsSuccess = (response) => ({
  type: CART_CONST.DELETE_CART_ITEMS_SUCCESS,
  payload: response,
});

export const deleteCartItemsFailed = (error) => ({
  type: CART_CONST.DELETE_CART_ITEMS_FAILED,
  payload: error,
});

/**
 *  Update  product quantity in cart
 */

export const updateItemsToCart = (payload) => ({
  type: CART_CONST.UPDATE_QUANTITY_REQUEST,
  payload: payload || null,
});

export const updateItemsToCartSuccess = (response) => ({
  type: CART_CONST.UPDATE_QUANTITY_SUCCESS,
  payload: response,
});

export const updateItemsToCartFailed = (error) => ({
  type: CART_CONST.UPDATE_QUANTITY_FAILED,
  payload: error,
});

/**
 *  Clear Cart
 */

export const clearCartItems = (payload) => ({
  type: CART_CONST.CLEAR_CART_ITEMS_REQUEST,
  payload: payload || null,
});

export const clearCartItemsSuccess = (response) => ({
  type: CART_CONST.CLEAR_CART_ITEMS_SUCCESS,
  payload: response,
});

export const clearCartItemsFailed = (error) => ({
  type: CART_CONST.CLEAR_CART_ITEMS_FAILED,
  payload: error,
});
