import { GET_PAGES } from "./getPagesConst";

const Pages = (state = {}, action) => {
  switch (action.type) {
    case GET_PAGES.GET_PAGES_REQUESTING:
      return {
        ...state,
        isGetPagesRequesting: true,
        getPagesData: false,
        getPagesDataError: false,
      };
    case GET_PAGES.GET_PAGES_SUCCESS:
      return {
        ...state,
        isGetPagesRequesting: false,
        getPagesData: action.payload.response,
        getPagesDataError: false,
      };
    case GET_PAGES.GET_PAGES_FAILED:
      return {
        ...state,
        isGetPagesRequesting: false,
        getPagesData: false,
        getPagesDataError: action.payload.error
          ? false
          : action.payload.response,
      };

    default:
      return { ...state };
  }
};

export default Pages;
