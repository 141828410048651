import "../../assets/CSS/preLoginStyles.scss";

import React from "react";
import leftArrow from "../../assets/Images/S-left-arrow.png";
import { useHistory } from "react-router-dom";

const PrivacyPolicyPage = () => {
  const history = useHistory();
  document.title = "Eyegear";
  return (
    <div className="section__padding policy__content__section">
      <div onClick={() => history.goBack()} className="back__section">
        <img src={leftArrow} alt="backButton" className="back_arrow" />
        <h3 className="policy__content__section_heading back__text">Back</h3>
      </div>
      <h3 className="policy__content__section_heading">Privacy Policy</h3>
      <p className="policy__content__section__para">
        Welcome to eyegear.com This section pertains to the Privacy Policy of
        the website. Please note that our privacy policy is subject to change
        without intimation. You are requested to review it regularly.
      </p>
      <p className="policy__content__section__para">
        The protection and security of your personal information are among
        Eyegear.com’s top priorities. This Privacy Policy discloses
        Eyegear.com’s practice concerning the information collected from the
        users of the website. Eyegear Optics India Private Limited is authorised
        to use the website Eyegear.com for commercial purposes. This Privacy
        Policy extends to both users who visit the website but do not transact
        business on the website, as well as users who are registered on the
        website. By accessing or using the website, you agree to accept the
        terms of this Privacy Policy as well as the website&#39;s Terms of Use.
        You also expressly consent to our use and disclosure of your personal
        information in any manner described in this Privacy Policy.
        &quot;Personal Information,&quot; in this context, refers to any
        information that identifies or can be used to identify, contact or
        locate the person, to whom such information pertains including, but not
        limited to, name, address, phone number, fax number, email address,
        financial profiles, identification number, credit card information, etc.
      </p>
      <p className="policy__content__section__para">
        PLEASE READ THE FOLLOWING TERMS OF OUR PRIVACY POLICY
      </p>
      <p className="policy__content__section__para">
        By accepting this privacy policy, you authorise Eyegear.com to collect,
        store and use any information that you provide on our website. The
        information collected by us includes: All information entered by you on
        our website, such as your name, address, contact number, email ID, chat
        history and such other information sent by you via email to our email
        ID. Information collected using cookies that are installed on your hard
        drive. Information, such as the IP address of your computer, the server
        from which you are accessing our website, and details of the web browser
        and operating system used to access our website and or date, time and
        place of accessing of our website, etc.
      </p>
      <p className="policy__content__section__para">
        USE OF INFORMATION COLLECTED
      </p>
      <p className="policy__content__section__para">
        Eyegear.com owns all the information collected via the website or
        applications installed on the website. The information collected by
        Eyegear.com shall be used to contact you about the website and related
        news and services available on the website; to monitor and improve the
        website; to calculate the number of visitors to the website and to know
        the geographical locations of the visitors; to update you on all the
        special offers available on the website and to provide you with a better
        experience. It includes sending emails to you about the various offers
        on the website. You may, at any time, choose to unsubscribe from such
        emails. Some of your information may be shared with and used by third
        parties who shall need to have access to information to enable them and
        Eyegear.com to perform their duties and fulfil your order requirements.
        These include courier companies, credit card processing companies,
        vendors, etc. Eyegear.com does not allow any unauthorised persons or
        organisations to use any information that it may collect from you
        through the website. However, eyegear.com is not responsible for any
        information collected or shared or used by any other third-party website
        due to your browser settings.
      </p>
      <p className="policy__content__section__para">
        Eyegear.com reserves the right to share any of your personal information
        to comply with court orders or other legal processes. Your personal
        information may be disclosed under such court order or legal process,
        which shall be without notice to you.
      </p>
      <p className="policy__content__section__para">
        Eyegear.com may share collective information, such as demographics and
        website or mobile application usage statistics with our sponsors,
        advertisers or other third parties. Such third parties do not include
        Eyegear.com’s marketing partners and network providers. When this type
        of information is shared, such parties do not have access to your
        personal information. When you contact Eyegear.com through any means,
        such as chat/email, Eyegear.com reserves the right to include your email
        ID for marketing communications. You can unsubscribe from such
        communications any time you wish to do so.
      </p>
      <p className="policy__content__section__para">
        The website may contain links, which may lead you to other websites.
        Please note that once you leave our website, you will be subjected to
        the Privacy Policy of the other website, and this our Privacy Policy
        will no longer apply.
      </p>
      <p className="policy__content__section__para">
        BY USING THE WEBSITE, YOU SIGNIFY YOUR AGREEMENT TO THE TERMS OF THIS
        PRIVACY POLICY. EYEGEAR.COM RESERVES THE RIGHT, IN OUR SOLE DISCRETION,
        TO CHANGE, MODIFY, ADD OR DELETE PORTIONS OF THE TERMS OF THIS PRIVACY
        POLICY AT ANY TIME.
      </p>
      <p className="policy__content__section__para">
        If you have any questions about this Privacy Policy, please feel free to
        contact us through our website.
      </p>
    </div>
  );
};

export default PrivacyPolicyPage;
