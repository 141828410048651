import { AXIOS_INSTANCE, BASE_URL } from "../../api/apiEndPoint";

import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { BANNER_CONST } from "./bannerConst";

import { getBannersSuccess, getBannersFailed } from "./bannerActions";
import { HomeClient } from "../apibenfranklin";
import { getToken, unAuthorizedRedirection } from "./../../helpers/Utility";

const client = new HomeClient(`${BASE_URL}`, AXIOS_INSTANCE);

/**
 *  Banner Saga
 */
const getBannersApiRequest = async () => {
  let token = `Bearer ${getToken()}`;
  let response = {};
  try {
    const data = await client.getBanners("", token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getBanners(action) {
  try {
    const apiResponse = yield call(getBannersApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getBannersSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getBannersFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getBannersFailed(errorData));
  }
}

export function* watchLoginUser() {
  yield takeEvery(BANNER_CONST.BANNER_REQUEST, getBanners);
}

function* bannerSaga() {
  yield all([fork(watchLoginUser)]);
}

export default bannerSaga;
