import React from "react";
import Loader from "react-loader-spinner";
import PropTypes from "prop-types";

function LoaderSpinner(props) {
  return (
    <>
      <Loader
        type={props.type || "Oval"}
        color={props.color || "#14467C"}
        height={props.height || 100}
        width={props.width || 100}
      />
    </>
  );
}

export default LoaderSpinner;

LoaderSpinner.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
