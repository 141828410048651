/* eslint-disable max-len */
//import ButtonComponent from "../../common/button/ButtonComponent";

import "../../assets/CSS/preLoginStyles.scss";

import ButtonMailTo from "../../common/button/ButtonMailTo";
import CareersPageBannerMobile from "../../assets/Images/CareersPageBannerMobile1.jpg";
import HeadingAndContentComponent from "../../common/HeaderHeadingAndContentComponent/HeadingAndContentComponent";
import careersbanner from "./../../assets/Images/careersbanner1.jpg";
import careerssection from "./../../assets/Images/careerssection.jpg";
import { useScreenSize } from "../../hooks/useScreenSize";

export const CareersPage = () => {
  document.title = "Eyegear";
  // cr-change: made all the content as constants
  const explorePara =
    "Eyegear is a dynamic company that understands quality eyewear as per the needs of customers everywhere. We are always looking to hire talented professionals who speak the language of our customers. We pride ourselves on our committed and diverse work culture that believes in nurturing client relationships that go beyond optical sales. Moreover, our multi-branded retail outlets boast of a pan-India presence, making it a convenient shopping experience for our customers. By joining the company’s diversified workforce, come and make a difference to the future of our business—and your career!";
  const valuesPara1 =
    "We are proud of our caring, dynamic and responsive team who put customers first. Our team members work together to embrace the company’s ethical practices that are aligned with our business goals. Our mission is to empower employees to know they are part of something unique in the retail optical industry. That’s why at Eyegear, our team values workplace morale, growth and loyalty to drive the business forward.";
  const valuesPara2 =
    "We strive to create a safe and supportive workplace and encourage visionary thinking and leadership development at all levels. The most rewarding thing is to see our employees derive job enrichment and grow their career with us. By fostering an inclusive and collaborative atmosphere, we are paving the way for a positive work culture.";
  const culturePara1 =
    "We seek to hire smart and talented professionals who pursue their goals passionately and responsibly. Our leadership team supports each employee’s professional and personal aspirations and provides adequate resources, on-job training, coaching and mentoring to help them stand apart from the crowd. Eyegear offers employees ample opportunities to contribute their best life at work by aligning their career goals with the company’s strategic mission. We champion our employees and help them flourish in a competitive environment by enhancing their skill-sets and product knowledge. It is why we seek team members who are driven and passionate about self-improvement.";
  const culturePara2 =
    "Our company thrives on a culture that encourages job rotation and cross-functionality upskilling. We offer continuous learning in terms of career progression by providing real-time feedback, succession planning and performance improvement plans that empower individuals to reach their optimum as professionals in a modern-day workplace.";
  const culturePara3 =
    "Most important, the company prides itself in recognizing and rewarding employees for great work done.";

  const screenSize = useScreenSize();
  return (
    <>
      <div>
        <div className="image-section">
          {/* cr-change: Banner image was changed depending on the screen width */}
          <img
            src={
              screenSize.currentScreenConfig.width > 767
                ? careersbanner
                : CareersPageBannerMobile
            }
            style={{ width: "100%" }}
            alt="careers banner"
            className="image"
          />
        </div>
        {/* cr-change: UI in the mobile resolution placed image between the content */}
        {screenSize.currentScreenConfig.width > 1024 ? (
          <div className="content-section section__padding">
            <div className="content-section-desc">
              <HeadingAndContentComponent
                title="Explore Life at Eyegear"
                paras={[explorePara]}
              />
              <HeadingAndContentComponent
                title="Our Values: Why we are Unique"
                paras={[valuesPara1, valuesPara2]}
              />
              <HeadingAndContentComponent
                title="A Culture of Learning and Rewards"
                paras={[culturePara1, culturePara2, culturePara3]}
              />
            </div>
            <div className="content-section-img">
              <img
                src={careerssection}
                style={{ width: "100%" }}
                alt="careerssection"
                className="content-section-img"
              />
            </div>
          </div>
        ) : (
          <div className="content-section section__padding">
            <div className="content-section-desc">
              <HeadingAndContentComponent
                title="Explore Life at Eyegear"
                paras={[explorePara]}
              />
              <HeadingAndContentComponent
                title="Our Values: Why we are Unique"
                paras={[valuesPara1, valuesPara2]}
              />
            </div>
            <div className="content-section-img margin__bottom__20">
              <img
                style={{ width: "100%" }}
                src={careerssection}
                alt="careerssection"
                className="content-section-img"
              />
            </div>
            <div className="content-section-desc">
              <HeadingAndContentComponent
                title="A Culture of Learning and Rewards"
                paras={[culturePara1, culturePara2, culturePara3]}
              />
            </div>
          </div>
        )}
        <div className="button-section section__padding">
          <ButtonMailTo title="Email Your CV" receiver="careers@eyegear.com" />
        </div>
      </div>
    </>
  );
};
export default CareersPage;
