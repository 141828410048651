import {
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from "reactstrap";
import { Link, useHistory, withRouter } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import {
  forgotPasswordRequest,
  loginCarousel,
  loginUser,
} from "../../redux/login/loginActions";
import { getToken, isValidEmail } from "./../../helpers/Utility";
import { useDispatch, useSelector } from "react-redux";

import Button from "reactstrap-button-loader";
import Email from "../../assets/Images/email.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LOGIN } from "./../../helpers/Constants";
import Loader from "../../common/Loader";
import Lock from "../../assets/Images/lock.png";
import Loginlogo from "../../assets/Images/Logo-Blue.png";
import Slider from "../../components/CarouselWrapper/index.js";
import _ from "lodash";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { showError } from "../../common/alertNotifications";
import { signIn } from "../../helpers/Utility";
import { useTranslation } from "react-multi-lang";

function Login() {
  //done by mani
  document.title = `Eyegear Partner Log-in`;
  const t = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("t");
  const [isPasswordValid, setIsNotPasswordValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [passwortType, setPasswordType] = useState(true);
  const [forgotButtonLoading, setForgotButtonLoading] = useState(false);
  const [isEmailDisable, setIsEmailDisable] = useState("");
  const [isPasswordDisable, setIsPasswordDisable] = useState("");
  const [keepMeSignedIn, setKeepMeSignedIn] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false);
  const [forgotPasswordSubmitClicked, setForgotPasswordSubmitClicked] =
    useState(false);
  const [message, setMessage] = useState(false);
  const [enteringEmail, setEnteringEmail] = useState(false);
  const [enteringPassword, setEnteringPassword] = useState(false);

  const nextProps = useSelector((state) => ({
    isLogged: state.Login,
  }));
  const {
    loginData,
    loginDataError,
    isLoginRequesting,
    loginCarouselData,
    isLoginCarouselRequesting,
    forgotPasswordData,
    isForgotPasswordRequesting,
  } = nextProps.isLogged;

  useEffect(() => {
    if (getToken()) {
      history.push("/home");
    } else {
      history.push("/login");
    }
    dispatch(loginCarousel());
  }, []);

  console.log("object", loginCarouselData);
  const isFirstRun = useRef(true);

  // done by mani
  window.addEventListener("beforeunload", function (e) {
    e.preventDefault();
    window.localStorage.setItem("isTab", "false");
    e.stopImmediatePropagation();
  });

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (loginData) {
      let tokenData = {};

      tokenData = {
        keepMeSignedIn: keepMeSignedIn,
        token: loginData.data.access_token,
      };
      signIn(tokenData);
      history.push("/home");
    }
    if (loginDataError) {
      setIsEmailDisable(false);
      setIsPasswordDisable(false);
      setButtonLoader(false);
      showError(loginDataError.data.error_description);
    }
  }, [loginData, loginDataError]);

  useEffect(() => {
    if (forgotPasswordData && forgotPasswordData.data) {
      if (forgotPasswordData.data.message) {
        setMessage(true);
        setForgotButtonLoading(false);
      } else if (
        forgotPasswordData &&
        forgotPasswordData.data &&
        forgotPasswordData.data.status === 1
      ) {
        showError(forgotPasswordData.data.error.message);
        setForgotButtonLoading(false);
      }
    }
  }, [forgotPasswordData]);

  const signInPassword = (e) => {
    console.log(e.target.value.length, "iyeihuieh");
    e.target.value.length >= 1
      ? setEnteringPassword(true)
      : setEnteringPassword(false);

    if (e.target.value) {
      setPassword(e.target.value.trim());
    }
    if (
      e.target.value.length < LOGIN.PASSWORD_MINIMUM_LENGTH ||
      e.target.value.length > LOGIN.PASSWORD_MAXIMUM_LENGTH
    ) {
      setIsNotPasswordValid(true);
    } else {
      setIsNotPasswordValid(false);
    }
  };

  //this function is used when we click on forgot password button
  const handleForgotPassword = () => {
    setForgotPasswordClicked(true);
  };

  const signInEmail = (e) => {
    e.target.value.length >= 1
      ? setEnteringEmail(true)
      : setEnteringEmail(false);
    // setEmail(e.target.value.trim());

    // if (e.target.value.length <= 0) {
    //   setIsEmailValid(true);
    // } else {
    //   setIsEmailValid(false);
    // }
    if (!_.isEmpty(e?.target?.value)) {
      if (isValidEmail(e.target.value)) {
        setEmail(e.target.value.trim());
        setIsEmailValid(false);
      } else {
        setIsEmailValid(true);
      }
    }
  };

  const handleForgotPasswordSubmit = () => {
    setForgotPasswordSubmitClicked(true);

    if (email.length <= 1) {
      showError(t("LOGIN.PLEASE_PROVIDE_EMAIL_ID"));
    } else {
      dispatch(forgotPasswordRequest({ email: email }));
      setForgotButtonLoading(true);
      // setEmail("");
    }
    // setForgotButtonLoading(true);
  };
  const isValidPassword = () => {
    if (
      password.length < LOGIN.PASSWORD_MINIMUM_LENGTH ||
      password.length > LOGIN.PASSWORD_MAXIMUM_LENGTH
    ) {
      setIsNotPasswordValid(true);
      return false;
    } else {
      return true;
    }
  };
  const emailIsValid = () => {
    if (email.length <= 1) {
      setIsEmailValid(true);
      return false;
    } else {
      return true;
    }
  };
  const submitSignInForm = () => {
    let formProps = {};
    formProps = {
      email: email,
      password: password,
    };

    if (emailIsValid() && isValidPassword()) {
      setIsEmailDisable(true);
      setIsPasswordDisable(true);
      setButtonLoader(true);
      dispatch(loginUser(formProps));
    }
  };
  const showPassword = () => {
    setPasswordType(!passwortType);
  };
  const inputKeepMeSignedIn = () => {
    setKeepMeSignedIn(!keepMeSignedIn);
  };

  const handleBackButton = () => {
    window.location.reload();
  };

  return (
    <div className={isLoginCarouselRequesting ? "login-loader" : ""}>
      {isLoginCarouselRequesting ? (
        <Loader height={50} width={50} />
      ) : (
        <div className="login-mb-background">
          <div className="login-background">
            <div className="img-configuration">
              <Slider
                data={
                  loginCarouselData &&
                  loginCarouselData.data &&
                  loginCarouselData.data.banners &&
                  loginCarouselData.data.banners
                }
                className="login-background"
              />
              <Container>
                <Row className="justify-content-center">
                  <Col xl={3} lg={10} md={10} sm={3} className="form-box">
                    {forgotPasswordClicked ? (
                      <div
                        className="back-button-section"
                        onClick={handleBackButton}
                      >
                        <FontAwesomeIcon
                          icon={faArrowLeft}
                          className="back-button-icon"
                        />
                        <span className="back-button-text">Back to Login</span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text-box">
                      <img src={Loginlogo} />
                      {!forgotPasswordClicked ? (
                        <h3>{t("LOGIN.LOGIN_PAGE")}</h3>
                      ) : (
                        ""
                      )}
                    </div>
                    <Form className="body-form mt-4">
                      <FormGroup>
                        <InputGroup className="form-login from-login1">
                          <InputGroupAddon addonType="prepend">
                            <span className="input-group-text login-icon">
                              <img src={Email} />
                            </span>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            className="form-control input-login"
                            placeholder="Email"
                            onChange={signInEmail}
                            invalid={isEmailValid}
                            disabled={isEmailDisable ? "disabled" : ""}
                          />
                          <FormFeedback>
                            {isEmailValid && !enteringEmail
                              ? t("LOGIN.EMAIL_REQUIRED")
                              : ""}
                          </FormFeedback>
                          <FormFeedback>
                            {isEmailValid &&
                              enteringEmail &&
                              !forgotPasswordClicked &&
                              t("LOGIN.EMAIL_VALID")}
                          </FormFeedback>
                        </InputGroup>
                        {!forgotPasswordClicked ? (
                          <InputGroup className="form-login">
                            <InputGroupAddon addonType="prepend">
                              <span className="input-group-text login-icon-2">
                                <img src={Lock} width="22px" height="22px" />
                              </span>
                            </InputGroupAddon>
                            <Input
                              type={passwortType ? "password" : "text"}
                              className="form-control input-login"
                              placeholder="Password"
                              onChange={signInPassword}
                              invalid={isPasswordValid}
                              disabled={isPasswordDisable ? "disabled" : ""}
                            />
                            <FormFeedback>
                              {isPasswordValid && !enteringPassword
                                ? t("LOGIN.PASSWORD_REQUIRED")
                                : ""}
                            </FormFeedback>
                            {password ? (
                              <InputGroupAddon addonType="prepend">
                                <span
                                  className="input-group-text login-icon-1"
                                  onClick={showPassword}
                                >
                                  {passwortType
                                    ? t("LOGIN.SHOW")
                                    : t("LOGIN.HIDE")}
                                </span>
                              </InputGroupAddon>
                            ) : (
                              ""
                            )}
                          </InputGroup>
                        ) : (
                          ""
                        )}
                        {!forgotPasswordClicked ? (
                          <div className="message mt-4">
                            <Input
                              type="checkbox"
                              className="checkbox-login"
                              onClick={inputKeepMeSignedIn}
                            />{" "}
                            <p>{t("LOGIN.REMEMBER")}</p>
                          </div>
                        ) : (
                          ""
                        )}
                        {console.log("button loader", buttonLoader)}
                        {!forgotPasswordClicked ? (
                          <Button
                            loading={isLoginRequesting || false}
                            onClick={submitSignInForm}
                            className="btn-block login-button mb-4"
                          >
                            {t("LOGIN.LOGIN")}
                          </Button>
                        ) : (
                          <>
                            <>
                              <FormFeedback>
                                {isEmailValid &&
                                  enteringEmail &&
                                  t("LOGIN.EMAIL_VALID")}
                              </FormFeedback>
                              <Button
                                loading={isForgotPasswordRequesting || false}
                                onClick={handleForgotPasswordSubmit}
                                className="btn-block login-button mb-4"
                                disabled={forgotButtonLoading ? true : false}
                              >
                                {t("LOGIN.FORGOT_PASSWORD")}
                              </Button>
                            </>
                          </>
                        )}
                        {!forgotPasswordClicked ? (
                          <Link
                            to="#"
                            className="forget"
                            onClick={handleForgotPassword}
                          >
                            {t("LOGIN.FORGET_PASSWORD")}
                          </Link>
                        ) : (
                          ""
                        )}
                        {forgotPasswordSubmitClicked && message ? (
                          <h3 className="reset-message">
                            {t("LOGIN.FORGOT_PASSWORD_SUCCESS_MESSAGE")}
                          </h3>
                        ) : (
                          ""
                        )}
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default withRouter(Login);
