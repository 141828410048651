import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { Row, Col, Form, FormFeedback, FormGroup, Input } from "reactstrap";
import Button from "reactstrap-button-loader";
import { changePasswordRequest } from "../../redux/profile/profileAction";
import { showError } from "../../common/alertNotifications";

const ChangePassword = () => {
  const t = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [isNewPasswordValidLen, setIsNewPasswordValidLen] = useState(false);
  const [isNewPasswordwithNum, setIsNewPasswordwithNum] = useState(false);
  const [isNewPasswordwithSpecialChr, setIsNewPasswordwithSpecialChr] =
    useState(false);
  const [isInvalidNewPassword, setIsInvalidNewPassword] = useState(false);
  const [isInvalidRepeatNewPassword, setInvalidRepeatNewPassword] =
    useState(false);
  const [requiredPassword, setRequiredPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);

  const nextProps = useSelector((state) => ({
    ChangePasswordData: state.UserProfile.changePasswordData || null,
    isChangePasswordRequesting:
      state.UserProfile.isChangePasswordRequesting || false,
  }));
  useEffect(() => {
    if (nextProps.ChangePasswordData?.data) {
      setPasswordChanged(nextProps.ChangePasswordData?.data.result);
      if (!nextProps.ChangePasswordData?.data.result && showErrorMsg) {
        if (nextProps.ChangePasswordData?.data?.error) {
          showError(t(nextProps.ChangePasswordData?.data?.error?.message));
        } else {
          showError(t("PROFILE.CURRENT_PASSWORD_ERROR_MESSAGE"));
        }
      }
    }
  }, [nextProps.ChangePasswordData]);

  useEffect(() => {
    setPasswordChanged(false);
    setShowErrorMsg(false);
  }, []);
  const onChangecurrentPassword = (e) => {
    setCurrentPassword(e.target.value);

    if (e.target.value !== "") {
      setRequiredPassword(false);
    }
  };
  const onChangeNewPassword = (e) => {
    setNewPassword(e.target.value);

    if (e.target.value.length >= 8) {
      setIsNewPasswordValidLen(true);
    }
    if (e.target.value.length < 8) {
      setIsNewPasswordValidLen(false);
    }
    if (e.target.value.match("^(?=.*[0-9])") !== null) {
      setIsNewPasswordwithNum(true);
    }
    if (e.target.value.match("^(?=.*[0-9])") === null) {
      setIsNewPasswordwithNum(false);
    }
    if (e.target.value.match("(?=.*[!@#$%^&*])") !== null) {
      setIsNewPasswordwithSpecialChr(true);
    }
    if (e.target.value.match("(?=.*[!@#$%^&*])") === null) {
      setIsNewPasswordwithSpecialChr(false);
    }
  };
  const onChangeRepeatNewPassword = (e) => {
    setRepeatNewPassword(e.target.value);

    if (e.target.value === newPassword) {
      setInvalidRepeatNewPassword(false);
    }
  };
  useEffect(() => {
    if (
      isNewPasswordValidLen &&
      isNewPasswordwithNum &&
      isNewPasswordwithSpecialChr
    ) {
      setIsInvalidNewPassword(false);
    }
  }, [
    isNewPasswordValidLen,
    isNewPasswordwithNum,
    isNewPasswordwithSpecialChr,
  ]);
  const isRequired = () => {
    if (currentPassword !== "") {
      setRequiredPassword(false);
    } else {
      setRequiredPassword(true);
    }
  };
  const isValidNewPassword = () => {
    if (
      !isNewPasswordValidLen ||
      !isNewPasswordwithNum ||
      !isNewPasswordwithSpecialChr
    ) {
      setIsInvalidNewPassword(true);
    } else {
      setIsInvalidNewPassword(false);
    }
  };
  const isValidateRepeatNewPassword = () => {
    if (newPassword !== repeatNewPassword) {
      setInvalidRepeatNewPassword(true);
    } else {
      setInvalidRepeatNewPassword(false);
    }
  };
  const submitChangePasswordForm = () => {
    isRequired();
    isValidNewPassword();
    isValidateRepeatNewPassword();
    let formProps = {};
    formProps = {
      CurrentPassword: currentPassword,
      NewPassword: newPassword,
    };
    if (
      !isInvalidNewPassword &&
      !isInvalidRepeatNewPassword &&
      !requiredPassword &&
      currentPassword &&
      newPassword &&
      repeatNewPassword
    ) {
      dispatch(changePasswordRequest({ changePassword: formProps }));
      setShowErrorMsg(true);
    }
  };

  return (
    <Row className="justify-content-start change-password-card">
      <Col xl={6} lg={8} className={`${passwordChanged ? "d-none" : ""}`}>
        <Form>
          <FormGroup>
            <FormGroup className="mb-3">
              <div>
                <h1 className="change-password-label">
                  {t("PROFILE.CURRENT_PASSWORD")}
                  <span className="mandatory">{"*"}</span>
                </h1>
              </div>
              <div className="d-flex">
                <Input
                  type="text"
                  className="form-control  change-password-input"
                  name="currentPassword"
                  onChange={onChangecurrentPassword}
                  selected
                  onBlur={isRequired}
                  autoFocus
                />
                {requiredPassword ? (
                  <FormFeedback className="change-password-error-box">
                    <span>{"Required"}</span>
                  </FormFeedback>
                ) : (
                  ""
                )}
              </div>
            </FormGroup>
            <FormGroup className="mb-3">
              <div>
                <h1 className="change-password-label">
                  {t("PROFILE.NEW_PASSWORD")}
                  <span className="mandatory">{"*"}</span>
                </h1>
              </div>
              <div className="d-flex">
                <Input
                  type="text"
                  className="form-control change-password-input"
                  name="newPassword"
                  onChange={onChangeNewPassword}
                  onBlur={isValidNewPassword}
                />
                {isInvalidNewPassword ? (
                  <FormFeedback className="invalid-feedback change-password-error-box">
                    <ul className="mb-0">
                      <li>{"Password must have"}</li>
                      {!isNewPasswordValidLen ? (
                        <li className="change-password-error-list">
                          <span>{"At least 8 characters"}</span>
                        </li>
                      ) : (
                        ""
                      )}
                      {!isNewPasswordwithNum ? (
                        <li className="change-password-error-list">
                          <span>{"At least 1 number"}</span>
                        </li>
                      ) : (
                        ""
                      )}
                      {!isNewPasswordwithSpecialChr ? (
                        <li className="change-password-error-list">
                          <span>{"At least 1 special character"}</span>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </FormFeedback>
                ) : (
                  ""
                )}
              </div>
            </FormGroup>
            <FormGroup className="mb-3">
              <div>
                <h1 className="change-password-label">
                  {t("PROFILE.REPEAT_NEW_PASSWORD")}
                  <span className="mandatory">{"*"}</span>
                </h1>
              </div>
              <div className="d-flex">
                <Input
                  type="text"
                  className="form-control change-password-input"
                  name="repeatNewPassword"
                  onChange={onChangeRepeatNewPassword}
                  onBlur={isValidateRepeatNewPassword}
                />
                {isInvalidRepeatNewPassword ? (
                  <FormFeedback className="change-password-error-box">
                    <span>{"New Passwords do not match"}</span>
                  </FormFeedback>
                ) : (
                  ""
                )}
              </div>
            </FormGroup>
            <div className="change-password-button-box ">
              <Button
                loading={nextProps.isChangePasswordRequesting || false}
                className="change-password-button mb-3"
                onClick={submitChangePasswordForm}
              >
                {t("PROFILE.CHANGE_PASSWORD")}
              </Button>
              <Button
                onClick={() => history.push("/profile")}
                className="change-password-cancel-button mb-3"
              >
                {t("PROFILE.CANCEL")}
              </Button>
            </div>
          </FormGroup>
        </Form>
      </Col>
      <Col className={`p-5 ${passwordChanged ? "" : "d-none"}`}>
        <h1 className="change-password-success">
          {t("PROFILE.CURRENT_PASSWORD_SUCCESS_MESSAGE")}
        </h1>
      </Col>
    </Row>
  );
};

export default ChangePassword;
