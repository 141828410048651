/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Footer from "../components/Footer";
import FooterPreLogin from "../common/footer/FooterComponent.js";
import Header from "../components/Header";
import HeaderPreLogin from "../common/header/HeaderComponent.js";
import Loader from "../common/Loader";
import Routes from "../routes";
import { ToastContainer } from "react-toastify";
import { getToken } from "./../helpers/Utility";

//import { getToken } from "./../helpers/Utility";

export default function Layout() {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  useEffect(() => {
    setIsLoaderVisible(false);
    setLoader();
  }, []);

  const setLoader = (isLoading) => {
    setIsLoaderVisible(isLoading);
  };
  const getRoutes = (routeItems) => {
    const routes = routeItems.map((prop, key) => {
      window.scrollTo(0, 0);
      return (
        <Route
          path={prop.path}
          // render={() => <prop.component loader={setLoader} />}
          component={prop.component}
          key={key}
        />
      );
    });
    return routes;
  };
  return (
    <div>
      {getToken() && window.location.pathname !== "/resetpassword" ? (
        window.location.pathname === "/homepage" ||
        window.location.pathname === "/contact" ||
        window.location.pathname === "/careers" ||
        window.location.pathname === "/news-room" ||
        window.location.pathname === "/blogs" ||
        window.location.pathname === "/blog/:slug" ||
        window.location.pathname === "/about" ||
        window.location.pathname === "/brands" ||
        window.location.pathname === "/privacy-policy" ||
        window.location.pathname === "/cookie-policy" ? (
          <HeaderPreLogin />
        ) : (
          <Header />
        )
      ) : window.location.pathname !== "/login" ? (
        <HeaderPreLogin />
      ) : (
        " "
      )}
      {/* /* <Header /> */}
      {isLoaderVisible ? <Loader /> : ""}
      <div className="container_class">
        <Switch>
          {getRoutes(Routes)}
          <Redirect from="/" to="/homepage" />
          {/* {isLoggedIn ? <Redirect from="/" to="/home" /> : <Redirect from="/" to="/login" />} */}
        </Switch>
      </div>
      {getToken() && window.location.pathname !== "/resetpassword" ? (
        window.location.pathname === "/homepage" ||
        window.location.pathname === "/contact" ||
        window.location.pathname === "/careers" ||
        window.location.pathname === "/news-room" ||
        window.location.pathname === "/blogs" ||
        window.location.pathname === "/blog/:slug" ||
        window.location.pathname === "/about" ||
        window.location.pathname === "/brands" ||
        window.location.pathname === "/privacy-policy" ||
        window.location.pathname === "/cookie-policy" ? (
          <FooterPreLogin />
        ) : (
          <Footer />
        )
      ) : window.location.pathname !== "/login" ? (
        <FooterPreLogin />
      ) : (
        ""
      )}
      <ToastContainer />
    </div>
  );
}
