import { CART_CONST } from "./cartConst";

const Cart = (state = {}, action) => {
  switch (action.type) {
    case CART_CONST.GET_CART_ITEMS_REQUEST:
      return {
        ...state,
        isGetCartItemsRequesting: action.payload?.isLoading ? false : true,
        cartItemsData: false,
        cartItemsDataError: false,
      };
    case CART_CONST.GET_CART_ITEMS_SUCCESS:
      return {
        ...state,
        isGetCartItemsRequesting: false,
        cartItemsData: action.payload.response.data,
        cartItemsDataError: false,
      };
    case CART_CONST.GET_CART_ITEMS_FAILED:
      return {
        ...state,
        isGetCartItemsRequesting: false,
        cartItemsData: false,
        cartItemsDataError: action.payload.error
          ? false
          : action.payload.response,
      };
    case CART_CONST.DELETE_CART_ITEMS_REQUEST:
      return {
        ...state,
        isDeleteCartItemsRequesting: true,
        deleteCartItemsData: false,
        deleteCartItemsDataError: false,
      };
    case CART_CONST.DELETE_CART_ITEMS_SUCCESS:
      return {
        ...state,
        isDeleteCartItemsRequesting: false,
        deleteCartItemsData: action.payload.response,
        deleteCartItemsDataError: false,
      };
    case CART_CONST.DELETE_CART_ITEMS_FAILED:
      return {
        ...state,
        isDeleteCartItemsRequesting: false,
        deleteCartItemsData: false,
        deleteCartItemsDataError: action.payload.error
          ? false
          : action.payload.response,
      };
    case CART_CONST.UPDATE_QUANTITY_REQUEST:
      return {
        ...state,
        isUpdateQuantityRequesting: true,
        updateQuantityData: false,
        updateQuantityDataError: false,
      };
    case CART_CONST.UPDATE_QUANTITY_SUCCESS:
      return {
        ...state,
        isUpdateQuantityRequesting: false,
        updateQuantityData: action.payload.response,
        updateQuantityDataError: false,
      };
    case CART_CONST.UPDATE_QUANTITY_FAILED:
      return {
        ...state,
        isUpdateQuantityRequesting: false,
        updateQuantityData: false,
        updateQuantityDataError: action.payload.error
          ? false
          : action.payload.response,
      };
    case CART_CONST.CLEAR_CART_ITEMS_REQUEST:
      return {
        ...state,
        isClearCartItemsRequesting: true,
        clearCartItemsData: false,
        clearCartItemsDataError: false,
      };
    case CART_CONST.CLEAR_CART_ITEMS_SUCCESS:
      return {
        ...state,
        isClearCartItemsRequesting: false,
        clearCartItemsData: action.payload.response,
        clearCartItemsDataError: false,
      };
    case CART_CONST.CLEAR_CART_ITEMS_FAILED:
      return {
        ...state,
        isClearCartItemsRequesting: false,
        clearCartItemsData: false,
        clearCartItemsDataError: action.payload.error
          ? false
          : action.payload.response,
      };

    default:
      return { ...state };
  }
};

export default Cart;
