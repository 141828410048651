import blogImage1 from "./../../assets/Images/BlogImages/blog1.jpg";
import blogImage2 from "./../../assets/Images/BlogImages/blog2.webp";
import blogImage3 from "./../../assets/Images/BlogImages/blog3.jpg";
import blogImage4 from "./../../assets/Images/BlogImages/blog4.webp";
import blogImage5 from "./../../assets/Images/BlogImages/blog5.webp";
import blogImage6 from "./../../assets/Images/BlogImages/blog6.webp";
import blogImage7 from "./../../assets/Images/BlogImages/blog7.webp";
import blogImage8 from "./../../assets/Images/BlogImages/blog8.webp";
import blogImage9 from "./../../assets/Images/BlogImages/blog9.webp";
import blogImage10 from "./../../assets/Images/BlogImages/blog10.webp";
import blogImage11 from "./../../assets/Images/BlogImages/blog11.webp";
import blogImage12 from "./../../assets/Images/BlogImages/blog12.jpg";
import blogImage13 from "./../../assets/Images/BlogImages/blog13.webp";
import blogImage14 from "./../../assets/Images/BlogImages/blog14.webp";
import blogImage15 from "./../../assets/Images/BlogImages/blog15.webp";
import blogImage16 from "./../../assets/Images/BlogImages/blog16.webp";
import blogImage17 from "./../../assets/Images/BlogImages/blog17.webp";
import blogImage18 from "./../../assets/Images/BlogImages/blog18.webp";
import blogImage19 from "./../../assets/Images/BlogImages/blog19.webp";
import blogImage20 from "./../../assets/Images/BlogImages/blog20.webp";
import blogImage21 from "./../../assets/Images/BlogImages/blog21.jpg";
import blogImage22 from "./../../assets/Images/BlogImages/blog22.jpg";
import blogImage23 from "./../../assets/Images/BlogImages/blog23.jpg";
import blogImage24 from "./../../assets/Images/BlogImages/blog24.jpg";
import blogImage25 from "./../../assets/Images/BlogImages/blog25.webp";
import blogImage26 from "./../../assets/Images/BlogImages/blog26.jpg";
import blogImage27 from "./../../assets/Images/BlogImages/blog27.jpg";
import blogImage28 from "./../../assets/Images/BlogImages/blog28.jpg";
import blogImage29 from "./../../assets/Images/BlogImages/blog29.jpg";
import blogImage30 from "./../../assets/Images/BlogImages/blog30.jpg";
import blogImage31 from "./../../assets/Images/BlogImages/blog31.jpg";
import blogImage32 from "./../../assets/Images/BlogImages/blog32.jpg";
import blogImage33 from "./../../assets/Images/BlogImages/blog33.jpg";
import blogImage34 from "./../../assets/Images/BlogImages/blog34.jpg";
import blogImage35 from "./../../assets/Images/BlogImages/blog35.jpg";
import blogImage36 from "./../../assets/Images/BlogImages/blog36.png";
import blogImage37 from "./../../assets/Images/BlogImages/blog37.jpg";
import blogImage38 from "./../../assets/Images/BlogImages/blog38.jpg";
import blogImage39 from "./../../assets/Images/BlogImages/blog39.png";
import blogImage40 from "./../../assets/Images/BlogImages/blog40.png";
import blogImage41 from "./../../assets/Images/BlogImages/blog41.jpg";
import blogImage42 from "./../../assets/Images/BlogImages/blog42.jpg";
import blogImage43 from "./../../assets/Images/BlogImages/blog43.jpg";
import blogImage44 from "./../../assets/Images/BlogImages/blog44.jpg";
import blogImage45 from "./../../assets/Images/BlogImages/blog45.png";
import blogImage46 from "./../../assets/Images/BlogImages/blog46.jpg";
import blogImage47 from "./../../assets/Images/BlogImages/blog47.jpg";
import blogImage48 from "./../../assets/Images/BlogImages/blog48.png";
import blogImage49 from "./../../assets/Images/BlogImages/blog49.png";
import blogImage50 from "./../../assets/Images/BlogImages/blog50.jpg";
import blogImage51 from "./../../assets/Images/BlogImages/blog51.jpg";
import blogImage52 from "./../../assets/Images/BlogImages/blog52.jpg";
import blogImage53 from "./../../assets/Images/BlogImages/blog53.jpg";
import blogImage54 from "./../../assets/Images/BlogImages/blog54.jpg";
import blogImage55 from "./../../assets/Images/BlogImages/blog55.jpg";
import blogImage56 from "./../../assets/Images/BlogImages/blog56.jpg";
import blogImage57 from "./../../assets/Images/BlogImages/blog57.jpg";
import blogImage58 from "./../../assets/Images/BlogImages/blog58.jpg";
import blogImage59 from "./../../assets/Images/BlogImages/blog59.png";
import blogImage60 from "./../../assets/Images/BlogImages/blog60.jpg";
import blogImage61 from "./../../assets/Images/BlogImages/blog61.png";
import blogImage62 from "./../../assets/Images/BlogImages/blog62.jpg";
import blogImage63 from "./../../assets/Images/BlogImages/blog63.jpg";
import blogImage64 from "./../../assets/Images/BlogImages/blog64.jpg";
import blogImage65 from "./../../assets/Images/BlogImages/blog65.jpg";
import blogImage66 from "./../../assets/Images/BlogImages/blog66.jpg";
import blogImage67 from "./../../assets/Images/BlogImages/blog67.jpg";
import blogImage68 from "./../../assets/Images/BlogImages/blog68.jpg";
import blogImage69 from "./../../assets/Images/BlogImages/blog69.jpg";
import blogImage70 from "./../../assets/Images/BlogImages/blog70.jpg";
import blogImage71 from "./../../assets/Images/BlogImages/blog71.jpg";

export const BlogsDetails = [
  {
    id: 1,
    imageData: blogImage1,
    title: "Eyewear Fashion: The Modern Trends",
  },
  {
    id: 2,
    imageData: blogImage2,
    title: "Eyeglasses Through History's Lens",
  },
  {
    id: 3,
    imageData: blogImage3,
    title:
      "Does your eyewear suit your face shape? Ten top tips to ensure it does!",
  },
  {
    id: 4,
    imageData: blogImage4,
    title: "Need to Know: How blue light can impact eye health.",
  },
  {
    id: 5,
    imageData: blogImage5,
    title:
      "How to Naturally Enhance Your Vision: Top 5 Exercises for Your Eyes",
  },
  {
    id: 6,
    imageData: blogImage6,
    title: "Maintaining eye health: The essential role of nutrition",
  },
  {
    id: 7,
    imageData: blogImage7,
    title: "Early Detection of Eye Issues: The Value of Regular Exams",
  },
  {
    id: 8,
    imageData: blogImage8,
    title: "Computer Vision Syndrome in Children: Prevention Guidelines",
  },
  {
    id: 9,
    imageData: blogImage9,
    title: "Lens Coatings: What You Need To Know",
  },
  {
    id: 10,
    imageData: blogImage10,
    title: "Prescription Safety Glasses: Work Safe, Play Safe",
  },
  {
    id: 11,
    imageData: blogImage11,
    title: "Fashionable Sports Eyewear: An Eye-opener",
  },
  {
    id: 12,
    imageData: blogImage12,
    title: "Choosing the Right Frames for Your Personality and Lifestyle",
  },
  {
    id: 13,
    imageData: blogImage13,
    title: "Cleaning and Caring for Eyewear: A Comprehensive Guide",
  },
  {
    id: 14,
    imageData: blogImage14,
    title: "Anti-Reflective Coating: The Clear Benefits",
  },
  {
    id: 15,
    imageData: blogImage15,
    title: "Polarized Sunglasses: Some Amazing Advantages",
  },
  {
    id: 16,
    imageData: blogImage16,
    title: "Prescription Sunglasses: A Comprehensive Guide",
  },
  {
    id: 17,
    imageData: blogImage17,
    title: "Designer Eyewear: When Style Meets Luxury!",
  },
  {
    id: 18,
    imageData: blogImage18,
    title: "Your Guide to Choosing the Right Contact Lenses",
  },
  {
    id: 19,
    imageData: blogImage19,
    title: "Computer Vision Syndrome: Tips on Prevention",
  },
  {
    id: 20,
    imageData: blogImage20,
    title: "UV Protection for Better Eye Health",
  },
  {
    id: 21,
    imageData: blogImage21,
    title: "Vintage Eyewear: Evergreen Styles for You",
  },
  {
    id: 22,
    imageData: blogImage22,
    title: "Eco-Friendly Eyewear: Stylish and Sustainable Choices",
  },
  {
    id: 23,
    imageData: blogImage23,
    title: "Hottest Eyewear Trends: Fashion Forward",
  },
  {
    id: 24,
    imageData: blogImage24,
    title: "How Outdoor Enthusiasts can Protecting Their Eyes",
  },
  {
    id: 25,
    imageData: blogImage25,
    title: "Comfort, Durability, Style: The Perfect Eyewear for Kids",
  },
  {
    id: 26,
    imageData: blogImage26,
    title: "Affordable, Stylish Eyewear on a Budget!",
  },
  {
    id: 27,
    imageData: blogImage27,
    title: "Different Strokes for Different Folks",
  },
  {
    id: 28,
    imageData: blogImage28,
    title: "Unique and Unconventional Frames",
  },
  {
    id: 29,
    imageData: blogImage29,
    title: "International Fashion Idols: Signature Eyewear Styles",
  },
  {
    id: 30,
    imageData: blogImage30,
    title: "Bollywood Fashion Idols: Signature Eyewear Styles",
  },
  {
    id: 31,
    imageData: blogImage31,
    title: "Matching Frames to Outfits: An Art",
  },
  {
    id: 32,
    imageData: blogImage32,
    title: "Casual? Formal? Eyewear for Various Occasions",
  },
  {
    id: 33,
    imageData: blogImage33,
    title: "Perfect Frames on a Budget!",
  },
  {
    id: 34,
    imageData: blogImage34,
    title: "Retro-Inspired Eyewear: Old-world Allure in the Modern Age",
  },
  {
    id: 35,
    imageData: blogImage35,
    title: "The Fashion Lover’s Handbook to Sustainable Eyewear",
  },
  {
    id: 36,
    imageData: blogImage36,
    title: "Eyewear that Matches Your Hair Type: A Guide",
  },
  {
    id: 37,
    imageData: blogImage37,
    title: "Minimalism: Sleek and Elegant Eyewear",
  },
  {
    id: 38,
    imageData: blogImage38,
    title: "Versatile Eyewear on a Budget",
  },
  {
    id: 39,
    imageData: blogImage39,
    title: "Geometric Frame Designs: The Fashion-Forward Choice",
  },
  {
    id: 40,
    imageData: blogImage40,
    title: "Statement Eyewear: Inspiration from Celebrities",
  },
  {
    id: 41,
    imageData: blogImage41,
    title: "Eyewear and Your Distinctive Personality: Make a Statement",
  },
  {
    id: 42,
    imageData: blogImage42,
    title: "Eyewear and the Seasons: Different Trends for Different Times",
  },
  {
    id: 43,
    imageData: blogImage43,
    title: "Style plus Durability: Affordable Eyewear for Children",
  },
  {
    id: 44,
    imageData: blogImage44,
    title: "The Future of Vision: A Blending of Fashion and Technology",
  },
  {
    id: 45,
    imageData: blogImage45,
    title: "Eyewear + Celebrity Collaborations: Look like a Star!",
  },
  {
    id: 46,
    imageData: blogImage46,
    title: "Affordable Prescription Eyewear: Accessible Vision Correction",
  },
  {
    id: 47,
    imageData: blogImage47,
    title: "Clear Frames: A Fashion-forward Preference",
  },
  {
    id: 48,
    imageData: blogImage48,
    title: "Eyewear for Various Skin Tones: Your Natural Beauty, Enhanced!",
  },
  {
    id: 49,
    imageData: blogImage49,
    title: "Cat Eye Frames: A Retro Spin to Timeless Elegance",
  },
  {
    id: 50,
    imageData: blogImage50,
    title: "Comfort and Style – Affordable Eyewear for Active Lifestyles!",
  },
  {
    id: 51,
    imageData: blogImage51,
    title: "Layering Your Eyewear: The Personalized Look",
  },
  {
    id: 52,
    imageData: blogImage52,
    title: "Unisex Eyewear: Smashing Gender Stereotypes",
  },
  {
    id: 53,
    imageData: blogImage53,
    title: "Owning the Oversized: Spectacular Frame Styles",
  },
  {
    id: 54,
    imageData: blogImage54,
    title: "Art and Architecture: Their Influence on Eyewear Design",
  },
  {
    id: 55,
    imageData: blogImage55,
    title: "Transitional Eyewear: Smooth Switching between Dim and Bright",
  },
  {
    id: 56,
    imageData: blogImage56,
    title:
      "Eyewear for Seniors: Affordability, Functionality, Comfort and Style",
  },
  {
    id: 57,
    imageData: blogImage57,
    title: "Perfect Lenses for Your Sunglasses",
  },
  {
    id: 58,
    imageData: blogImage58,
    title: "Different Personalities, Different Frames!",
  },
  {
    id: 59,
    imageData: blogImage59,
    title: "The Evolution of Eyewear: From Functionality to Fashion Forward",
  },
  {
    id: 60,
    imageData: blogImage60,
    title: "Cultural Celebrations: Festive Styles in Eyewear",
  },
  {
    id: 61,
    imageData: blogImage61,
    title: "Indian Cultural Festivals: Celebratory Eyewear Style",
  },
  {
    id: 62,
    imageData: blogImage62,
    title: "Cat Eye Frames: Set Free your Inner Felin",
  },
  {
    id: 63,
    imageData: blogImage63,
    title: "The Perfect Sunglasses for your Face Shape!",
  },
  {
    id: 64,
    imageData: blogImage64,
    title: "Layering Eyewear: Displaying Endlessly Different Looks!",
  },
  {
    id: 65,
    imageData: blogImage65,
    title: "Year-Round Eyewear Fashion: The Seasons of Style",
  },
  {
    id: 66,
    imageData: blogImage66,
    title: "Sustainable Eyewear Brands: Eco-Conscious Fashion",
  },
  {
    id: 67,
    imageData: blogImage67,
    title: "Innovations in Lens Materials",
  },
  {
    id: 68,
    imageData: blogImage68,
    title: "Fashionable Eyewear for Music Lovers",
  },
  {
    id: 69,
    imageData: blogImage69,
    title: "Timeless Elegance in Black and White: Monochrome Eyewear Frames",
  },
  {
    id: 70,
    imageData: blogImage70,
    title: "The Endless Interplay of Art Movements and Eyewear Design",
  },
  {
    id: 71,
    imageData: blogImage71,
    title: "Vintage-Inspired Eyewear: Timeless Grace Meets Modern Flair",
  },
];
