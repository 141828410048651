import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import DefaultImage from "../../assets/Images/frame1.png";
import LazyLoad from "react-lazyload";

export default function Frames({
  // imageTwo,
  label,
  imageOne,
  frameKey,
  id,
  frameMinPrice,
  frameMaxPrice,
}) {
  const [image, setImage] = useState(DefaultImage);
  const history = useHistory();
  useEffect(() => {
    setImage(imageOne);
  }, [imageOne]);
  // const handleMouseOver = () => {
  //   setImage(imageTwo);
  // };
  // const handleMouseOut = () => {
  //   setImage(imageOne);
  // };
  const handleFrameClick = () => {
    history.push({
      pathname: "/products",
      search:
        "netPrice" === frameKey
          ? `${frameKey}_From=${frameMinPrice}&${frameKey}_To=${frameMaxPrice}`
          : `${frameKey}=${id}`,
    });
  };

  return (
    <>
      <div className="frames-section" onClick={handleFrameClick}>
        <div className="frame-image-section">
          <LazyLoad>
            <img
              src={image}
              alt="frame"
              // onMouseOver={handleMouseOver}
              // onMouseOut={handleMouseOut}
              className="frame-image"
            />
          </LazyLoad>
        </div>
        <p className="frame-label">{label ? label : ""}</p>
      </div>
    </>
  );
}
Frames.propTypes = {
  imageOne: PropTypes.string,
  // imageTwo: PropTypes.string,
  label: PropTypes.string,
  HandleFunction: PropTypes.func,
  frameKey: PropTypes.string,
  id: PropTypes.string,
  frameMaxPrice: PropTypes.string,
  frameMinPrice: PropTypes.string,
};
