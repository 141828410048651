// @flow

import AddToCart from "./addToCart/addToCartReducer";
import AddressDetails from "./addressDetails/addressDetailsReducer";
import Banner from "./banner/bannerReducer";
import Cart from "./cart/cartReducers";
import Category from "./category/categoryReducers";
import ConfigData from "./config/configReduer";
import FilterOption from "./filters/filterReducers";
import GetCartItemsCount from "./cartCount/cartCountReducer";
import GetInventory from "./inventory/inventoryReducer";
import Login from "./login/loginReducer";
import Order from "./../redux/myOrders/orderReducer";
import Pages from "./getPages/getPagesReducer";
import PlaceOrder from "./placeOrder/placeOrderReducer";
import Prices from "./prices/pricesReducer";
import Shapes from "./shapes/shapesReducer";
import UserProfile from "./profile/profileReducer";
import { combineReducers } from "redux";
import getAllProduct from "../redux/expressCheckout/expressCheckoutReducer";
import loginTabReducer from "../redux/loginTabDuplicate/loginTabReducer";

export default combineReducers({
  AddressDetails,
  AddToCart,
  Banner,
  Cart,
  Category,
  ConfigData,
  FilterOption,
  GetCartItemsCount,
  getAllProduct,
  Login,
  Order,
  Pages,
  PlaceOrder,
  Prices,
  Shapes,
  UserProfile,
  GetInventory,
  loginTabReducer,
});
