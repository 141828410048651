import "../../assets/CSS/preLoginStyles.scss";

import AboutPageBannerMobile from "../../assets/Images/AboutPageBannerMobile1.jpg";
import BrandImageComponent from "../brands/BrandImageComponent";
import Cadiz from "../../assets/Images/cadiz.png";
import Elementz from "../../assets/Images/elementz.png";
import HeadingAndContentComponent from "../../common/HeaderHeadingAndContentComponent/HeadingAndContentComponent";
import Moonshine from "../../assets/Images/moonshine.png";
import Riverstone from "../../assets/Images/riverstone.png";
import Siena from "../../assets/Images/siena.png";
import aboutContentImage from "./../../assets/Images/about-content-img.jpg";
import aboutImage from "./../../assets/Images/about-image1.jpg";
import { useScreenSize } from "../../hooks/useScreenSize";

export const AboutPage = () => {
  document.title = "Eyegear";
  const aboutPara1 =
    // eslint-disable-next-line max-len
    "Indian customers spend a lot of their time online which has resulted in the growing prevalence of various eye sight related disorders. A growing elderly population are more susceptible to serious eye related disorders. Apart from the sight related disorders, there is a new and growing generation of technology enabled customers, with high disposable incomes and aspirational lifestyles, seeking sleek and stylish frames, spectacles and sunglasses. India has a considerably high percentage of eyewear customers in the value segment, with budgets ranging from ₹500 - ₹1200 who are in need for good quality, fashionable and affordable eyewear.";
  const aboutPara2 =
    "Based out of Hyderabad, Eyegear Optics India Pvt. Ltd. brings to the market Internationally-inspired styles, from the classical to the contemporary to the futuristic, sourced from quality manufacturers all over the world.";

  const platformPara1 =
    "The Eyegear B2B tech platform was started in 2020 with the intention of transforming independent mom & pop optical retailers through technology and brands that cater to a young and aspirational India.";
  const platformPara2 =
    "Eyegear helps independent mom-and-pop stores with proprietary tech solutions, branding and advertising, retail expertise, data capabilities and a robust supply chain to aid in their business growth.";
  const platformPara3 =
    "The Eyegear B2B tech platform offers world-class spectacle frames, sunglasses, contact lenses and more and has onboarded 2,400+ retail partners across Tier 1, 2 and 3 cities, selling a half million units per annum.";
  const platformPara4 =
    "Currently Eyegear supports independent mom & pop optical retailer stores in Andhra Pradesh, Bihar, Chandigarh, Chhattisgarh, Delhi, Goa, Haryana, Jammu & Kashmir, Madhya Pradesh, Maharashtra, Odisha, Punjab, Tamil Nadu, Telangana and Uttar Pradesh.";
  const platformPara5 =
    "Eyegear aims to continue to revolutionize the optical industry and customer experiences, by partnering with plans of adding an additional 8,000 retail partners by the end of 2023 and provide customers with high-quality branded products, the latest designs and styles at affordable prices.";
  const platformPara6 =
    "The Company plans on reaching every part of the country by being easily accessible, and becoming India’s first eyewear brand that provides quality, fashion and affordability to millions of customers.";
  const screenSize = useScreenSize();
  return (
    <>
      <div className="containerdummy">
        <div className="image-section">
          <img
            src={
              screenSize.currentScreenConfig.width > 767
                ? aboutImage
                : AboutPageBannerMobile
            }
            style={{ width: "100%" }}
            alt="aboutimage"
            className="image"
          />
        </div>
        {screenSize.currentScreenConfig.width > 1024 ? (
          <div className="content-section section__padding">
            <div className="content-section-desc">
              <HeadingAndContentComponent
                title="About Eyegear"
                paras={[aboutPara1, aboutPara2]}
              />
              <HeadingAndContentComponent
                title="Eyegear B2B Platform"
                paras={[
                  platformPara1,
                  platformPara2,
                  platformPara3,
                  platformPara4,
                  platformPara5,
                  platformPara6,
                ]}
              />
            </div>
            <div className="content-section-image">
              <img
                src={aboutContentImage}
                style={{ width: "100%" }}
                alt="contentimage"
                className="content-section-img"
              />
            </div>
          </div>
        ) : (
          <div className="content-section section__padding">
            <div className="content-section-desc">
              <HeadingAndContentComponent
                title="About Eyegear"
                paras={[aboutPara1, aboutPara2]}
              />
            </div>
            <div className="content-section-image margin__bottom__20">
              <img
                src={aboutContentImage}
                style={{ width: "100%" }}
                alt="contentimage"
                className="content-section-img"
              />
            </div>
            <div className="content-section-desc">
              <HeadingAndContentComponent
                title="Eyegear B2B Platform"
                paras={[
                  platformPara1,
                  platformPara2,
                  platformPara3,
                  platformPara4,
                  platformPara5,
                  platformPara6,
                ]}
              />
            </div>
          </div>
        )}
        <div className="brands_section section__padding">
          <p className="heading padding__top__20 ">Portfolio Brands</p>
          <div className="logos_section">
            <BrandImageComponent image={Cadiz} name="Cadiz" />
            <BrandImageComponent
              image={Elementz}
              name="Elementz"
              height="15px"
            />
            <BrandImageComponent
              image={Moonshine}
              name="Moonshine"
              height="17px"
            />
            <BrandImageComponent
              image={Riverstone}
              name="Riverstone"
              height="18px"
            />
            <BrandImageComponent
              image={Siena}
              name="Siena"
              isSmallImage={true}
              height="20px"
            />
          </div>
        </div>
        {/* <div className="button-section section__padding">
          <ButtonComponent title="Eyegear B2B Platform" link="/brands" />
        </div> */}
      </div>
    </>
  );
};
export default AboutPage;
