import { AXIOS_INSTANCE, BASE_URL } from "../../api/apiEndPoint";

import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { MATERIAL_CONST } from "./categoryConst";

import { getMaterialsSuccess, getMaterialsFailed } from "./categoryActions";
import { HomeClient } from "../apibenfranklin";
import { getToken, unAuthorizedRedirection } from "./../../helpers/Utility";

const client = new HomeClient(`${BASE_URL}`, AXIOS_INSTANCE);

/**
 *  Material Saga
 */
const getMaterialsApiRequest = async () => {
  let response = {};
  let token = `bearer  ${getToken()}`;
  try {
    const data = await client.getMaterials(token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getMaterials(action) {
  try {
    const apiResponse = yield call(getMaterialsApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getMaterialsSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getMaterialsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getMaterialsFailed(errorData));
  }
}

export function* watchLoginUser() {
  yield takeEvery(MATERIAL_CONST.MATERIAL_REQUESTING, getMaterials);
}

function* materialsSaga() {
  yield all([fork(watchLoginUser)]);
}

export default materialsSaga;
