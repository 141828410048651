import React from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

export default function CarouselCaption({ data }) {
  const history = useHistory();
  const { text, buttonText, captionClassName, buttonClassName } = data;
  return (
    <>
      <p className={captionClassName ? captionClassName : ""}>{text}</p>
      {buttonText && (
        <div className="buttonClass">
          <Button
            className={
              buttonClassName ? buttonClassName : "catalogSectionButton"
            }
            onClick={() => history.push("/products")}
          >
            {buttonText}
          </Button>
        </div>
      )}
    </>
  );
}

CarouselCaption.propTypes = {
  data: PropTypes.object,
};
