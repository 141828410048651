import React from "react";
// import CarouselCaption from "../../../components/CarouselCaption";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import Slider from "../../../components/CarouselWrapper";
// import { BANNER } from "./../../../helpers/Constants";

export default function Color({ colorCarouselData }) {
  // const buttonText = BANNER.CATALOG_BUTTON_TEXT;
  // const data = {
  //   text: colorCarouselData.length && colorCarouselData[0].title,
  //   buttonText: buttonText,
  //   captionClassName: "catalogSectionTitle-1",
  //   buttonClassName: "",
  // };
  return (
    <>
      <Container
        fluid={true}
        className="footer-section-container banner-section"
      >
        <Slider data={colorCarouselData} className="catalog-section-2" />
        {/* <div className="bannerText col-5">
          <CarouselCaption data={data} />
        </div> */}
      </Container>
    </>
  );
}

Color.propTypes = {
  colorCarouselData: PropTypes.array,
};
