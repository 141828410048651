import React, { useEffect, useState, useRef } from "react";
import { FormGroup, Label, Input, Button, Form } from "reactstrap";
import { useTranslation } from "react-multi-lang";
import PropTypes from "prop-types";
import PriceFilter from "./../PriceFilter";
import { CATALOG } from "./../../../helpers/Constants";
import { getSortedArrayData } from "./../../../helpers/Utility";

function filter({
  filterOptionData,
  genderCheckedData,
  getFilterdSelectedQuery,
  priceFilterDatas,
  materilaCheckedData,
  shapeCheckedData,
  getPriceFiltered,
  getSelectedFilter,
  selectedButton,
}) {
  const filterId = {
    frameMaterialId: "frameMaterialId",
    frameShapeId: "frameShapeId",
    genderIdList: "genderIdList",
    sizeId: "sizeId",
    frameColorIdList: "frameColorIdList",
    brandId: "brandId",
    productTypeId: "productTypeId",
    availablity: "availablity",
  };
  const t = useTranslation();
  const [productSize, setProductSize] = useState([]);
  const [productMaterial, setproductMaterial] = useState([]);
  const [productGender, setProductGender] = useState([]);
  const [productShape, setProductShape] = useState([]);
  const [productColor, setProductColor] = useState([]);
  const [productBrand, setProductBrand] = useState([]);
  const [productCatagory, setProductCatagory] = useState([]);
  const [productPrice, setProductPrice] = useState([]);
  const [checkData, setCheckData] = useState({});
  const isFirstRun = useRef(true);
  const [query, setQuery] = useState("");
  const [priceFilter, setPriceFilter] = useState("");
  const [sizeAll, setSizeAll] = useState(false);
  const [materialAll, setMaterialAll] = useState(false);
  const [genderAll, setGenderAll] = useState(false);
  const [shapeAll, setshapeAlll] = useState(false);
  const [colorAll, setcolorAll] = useState(false);
  const [brandAll, setBrandAll] = useState(false);
  const [categoriesAll, setCategoriesAll] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);

  const IsAvailblity = {
    key: "availablity",
    name: "Availability",
    type: "In",
    data: [
      {
        id: "1",
        name: "Don’t show out of stock items, Show available items only",
      },
    ],
  };

  useEffect(() => {
    setCheckData({});
  }, [materilaCheckedData, genderCheckedData, shapeCheckedData]);

  useEffect(() => {
    let data = JSON.parse(JSON.stringify(selectedButton));
    setCheckData(data);
    let frameShapeId = data?.frameShapeId?.toString();
    let frameColorIdList = data?.frameColorIdList?.toString();
    let sizeId = data?.sizeId?.toString();
    let brandId = data?.brandId?.toString();
    let productTypeId = data?.productTypeId?.toString();
    let frameMaterialId = data?.frameMaterialId?.toString();
    let genderIdList = data?.genderIdList?.toString();
    let availablity = data?.availablity?.toString();
    let search =
      `${frameShapeIdData(frameShapeId)}` +
      `${frameColorIdListData(frameColorIdList)}` +
      `${sizeIdData(sizeId)}` +
      `${brandIdData(brandId)}` +
      `${catagoriesIdData(productTypeId)}` +
      `${frameMaterialIdData(frameMaterialId)}` +
      `${genderIdListData(genderIdList)}` +
      `${availablityData(availablity)}`;

    let filter = search.trim();
    setQuery(filter.substring(0, filter.length - 1));
    getFilterdSelectedQuery(filter.substring(0, filter.length - 1));
  }, [selectedButton]);

  useEffect(() => {
    if (
      shapeCheckedData &&
      shapeCheckedData.frameShapeId &&
      shapeCheckedData.frameShapeId.length
    ) {
      let checkedObject = {};
      shapeCheckedData.frameShapeId.map((item) => {
        if (checkedObject[Object.keys(shapeCheckedData)]) {
          checkedObject[Object.keys(shapeCheckedData)].push(item.toString());
        } else {
          checkedObject[Object.keys(shapeCheckedData)] = [item.toString()];
        }
      });
      setCheckData(checkedObject);
      if (checkedObject?.frameShapeId?.length > 1) {
        setshapeAlll(true);
      }
      setQuery(
        frameShapeIdData(shapeCheckedData?.frameShapeIdData?.toString())
      );
    }
    if (
      materilaCheckedData &&
      materilaCheckedData.frameMaterialId &&
      materilaCheckedData.frameMaterialId.length
    ) {
      let checkedObject = {};
      materilaCheckedData.frameMaterialId.map((item) => {
        if (checkedObject[Object.keys(materilaCheckedData)]) {
          checkedObject[Object.keys(materilaCheckedData)].push(item.toString());
        } else {
          checkedObject[Object.keys(materilaCheckedData)] = [item.toString()];
        }
      });
      setCheckData(checkedObject);
      if (checkedObject?.frameMaterialId?.length > 1) {
        setMaterialAll(true);
      }
      setQuery(
        frameMaterialIdData(materilaCheckedData?.frameMaterialId?.toString())
      );
    }
    if (
      genderCheckedData &&
      genderCheckedData.genderIdList &&
      genderCheckedData.genderIdList.length
    ) {
      let genderObject = {};
      genderCheckedData.genderIdList.map((item) => {
        if (genderObject[Object.keys(genderCheckedData)]) {
          genderObject[Object.keys(genderCheckedData)].push(item.toString());
        } else {
          genderObject[Object.keys(genderCheckedData)] = [item.toString()];
        }
      });
      setCheckData(genderObject);
      setQuery(genderIdListData(genderCheckedData?.frameShapeId?.toString()));
    }
  }, [shapeCheckedData, materilaCheckedData, genderCheckedData]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    filterOptionData &&
      filterOptionData.filters.map((filter) => {
        switch (filter.type.length > 0 && filter.key.length > 0) {
          case "In" === filter.type && "sizeId" === filter.key:
            setProductSize({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "In" === filter.type && "frameMaterialId" === filter.key:
            setproductMaterial({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "In" === filter.type && "genderIdList" === filter.key:
            setProductGender({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "In" === filter.type && "frameShapeId" === filter.key:
            setProductShape({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "In" === filter.type && "frameColorIdList" === filter.key:
            setProductColor({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "In" === filter.type && "brandId" === filter.key:
            setProductBrand({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "In" === filter.type && "productTypeId" === filter.key:
            setProductCatagory({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          case "Between" === filter.type && "netPrice" === filter.key:
            setProductPrice({
              key: filter.key,
              name: filter.name,
              data: filter.data,
            });
            break;
          default:
        }
      });
  }, [filterOptionData]);

  const getPriceRangeData = (data) => {
    getPriceFiltered(data);
    let filterQuery = `netPrice_From=${data.min}&netPrice_To=${data.max}`;
    setPriceFilter(filterQuery);
    getFilterdSelectedQuery(`${query}&${filterQuery}`);
  };

  const frameShapeIdData = (frameShapeId) => {
    if (frameShapeId) {
      return `frameShapeId=${frameShapeId}&`;
    } else {
      return "";
    }
  };
  const frameColorIdListData = (frameColorIdList) => {
    if (frameColorIdList) {
      return `frameColorIdList=${frameColorIdList}&`;
    } else {
      return "";
    }
  };
  const sizeIdData = (sizeId) => {
    if (sizeId) {
      return `sizeId=${sizeId}&`;
    } else {
      return "";
    }
  };
  const brandIdData = (brandId) => {
    if (brandId) {
      return `brandId=${brandId}&`;
    } else {
      return "";
    }
  };
  const catagoriesIdData = (productTypeId) => {
    if (productTypeId) {
      return `productTypeId=${productTypeId}&`;
    } else {
      return "";
    }
  };
  const frameMaterialIdData = (frameMaterialId) => {
    if (frameMaterialId) {
      return `frameMaterialId=${frameMaterialId}&`;
    } else {
      return "";
    }
  };
  const genderIdListData = (genderIdList) => {
    if (genderIdList) {
      return `genderIdList=${genderIdList}&`;
    } else {
      return "";
    }
  };
  const priceFilterData = (priceString) => {
    if (priceString) {
      return `${priceFilter}&`;
    } else {
      return "";
    }
  };
  const availablityData = (availablity) => {
    if (availablity) {
      return `isAvailable=true&`;
    } else {
      return "";
    }
  };

  let queryData = checkData;

  const handleCheckBox = (e) => {
    if (!e.target.checked) {
      let arr = queryData[e.target.value];
      arr = arr && arr.length && arr.filter((item) => item !== e.target.name);
      queryData[e.target.value] = arr;
    } else {
      if (queryData[e.target.value]) {
        queryData[e.target.value].push(e.target.name);
      } else {
        queryData[e.target.value] = [e.target.name];
      }
    }

    getSelectedFilter(queryData);
    setCheckData(queryData);
    let frameShapeId =
      queryData && queryData.frameShapeId && queryData.frameShapeId.toString();
    let frameColorIdList =
      queryData &&
      queryData.frameColorIdList &&
      queryData.frameColorIdList.toString();
    let sizeId = queryData && queryData.sizeId && queryData.sizeId.toString();
    let brandId =
      queryData && queryData.brandId && queryData.brandId.toString();
    let productTypeId =
      queryData &&
      queryData.productTypeId &&
      queryData.productTypeId.toString();
    let frameMaterialId =
      queryData &&
      queryData.frameMaterialId &&
      queryData.frameMaterialId.toString();
    let genderIdList =
      queryData && queryData.genderIdList && queryData.genderIdList.toString();
    let availablity =
      queryData && queryData.availablity && queryData.availablity.toString();
    let search =
      `${frameShapeIdData(frameShapeId)}` +
      `${frameColorIdListData(frameColorIdList)}` +
      `${sizeIdData(sizeId)}` +
      `${brandIdData(brandId)}` +
      `${catagoriesIdData(productTypeId)}` +
      `${frameMaterialIdData(frameMaterialId)}` +
      `${genderIdListData(genderIdList)}` +
      `${availablityData(availablity)}` +
      `${priceFilterData(priceFilter)}`;

    let filter = search.trim();
    setQuery(filter.substring(0, filter.length - 1));
    getFilterdSelectedQuery(filter.substring(0, filter.length - 1));
  };

  const getFilter = (product, dataView) => {
    let data = product && product.data;
    if (filterId.genderIdList !== product.key) {
      data && getSortedArrayData(data);
    }

    return (
      data &&
      data.sort().map((item, index) => {
        return (
          <>
            <Form id={item.name}>
              <FormGroup
                check
                className={index > 4 && !dataView ? "d-none" : ""}
              >
                <Label check className="product-filter-option">
                  <Input
                    type="checkbox"
                    className="product-checkbox"
                    name={item.id}
                    value={product.key}
                    checked={
                      (filterId.frameMaterialId == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.frameMaterialId?.includes(
                          item.id.toString()
                        )) ||
                      (filterId.frameShapeId == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.frameShapeId?.includes(
                          item.id.toString()
                        )) ||
                      (filterId.genderIdList == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.genderIdList?.includes(
                          item.id.toString()
                        )) ||
                      (filterId.sizeId == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.sizeId?.includes(item.id.toString())) ||
                      (filterId.frameColorIdList == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.frameColorIdList?.includes(
                          item.id.toString()
                        )) ||
                      (filterId.brandId == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.brandId?.includes(item.id.toString())) ||
                      (filterId?.productTypeId == product.key &&
                        Object.keys(checkData).includes(
                          product.key.toString()
                        ) &&
                        checkData?.productTypeId?.includes(
                          item.id.toString()
                        )) ||
                      (filterId.availablity == product.key &&
                        Object.keys(checkData)?.includes(product.key) &&
                        checkData?.availablity?.includes(item.id))
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      handleCheckBox(e);
                    }}
                  />{" "}
                  {item.name.split(" ").map((item) => {
                    return (
                      item.charAt(0).toUpperCase() +
                      item.slice(1).toLowerCase() +
                      " "
                    );
                  })}
                </Label>
              </FormGroup>
            </Form>
          </>
        );
      })
    );
  };
  const isViewAllVisible = (viewAll) => {
    if (viewAll) {
      return t("CATALOG.VIEW_LESS");
    } else {
      return t("CATALOG.VIEW_ALL");
    }
  };
  return (
    <div className="product-filter-content">
      <PriceFilter
        priceFilterData={priceFilterDatas}
        productPrice={productPrice}
        getPriceRangeData={getPriceRangeData}
      />
      <p>{productSize.name}</p>
      {getFilter(productSize, sizeAll)}
      {productSize.data &&
      productSize.data.length > CATALOG.FILTER_DEFAULT_LENGTH ? (
        <Button
          onClick={() => {
            setSizeAll(!sizeAll);
          }}
        >
          {isViewAllVisible(sizeAll)}
        </Button>
      ) : (
        ""
      )}

      <p>{productMaterial.name}</p>
      {getFilter(productMaterial, materialAll)}
      {productMaterial.data &&
      productMaterial.data.length > CATALOG.FILTER_DEFAULT_LENGTH ? (
        <Button
          onClick={() => {
            setMaterialAll(!materialAll);
          }}
        >
          {isViewAllVisible(materialAll)}
        </Button>
      ) : (
        ""
      )}

      <p>{productGender.name}</p>
      {getFilter(productGender, genderAll)}
      {productGender.data &&
      productGender.data.length > CATALOG.FILTER_DEFAULT_LENGTH ? (
        <Button
          onClick={() => {
            setGenderAll(!genderAll);
          }}
        >
          {isViewAllVisible(genderAll)}
        </Button>
      ) : (
        ""
      )}

      <p>{productShape.name}</p>
      {getFilter(productShape, shapeAll)}

      {productShape.data &&
      productShape.data.length > CATALOG.FILTER_DEFAULT_LENGTH ? (
        <Button
          onClick={() => {
            setshapeAlll(!shapeAll);
          }}
        >
          {isViewAllVisible(shapeAll)}
        </Button>
      ) : (
        ""
      )}

      <p>{productColor.name}</p>
      {getFilter(productColor, colorAll)}
      {productColor.data &&
      productColor.data.length > CATALOG.FILTER_DEFAULT_LENGTH ? (
        <Button
          onClick={() => {
            setcolorAll(!colorAll);
          }}
        >
          {isViewAllVisible(colorAll)}
        </Button>
      ) : (
        ""
      )}

      <p>{productBrand.name}</p>
      {getFilter(productBrand, brandAll)}
      {productBrand.data &&
      productBrand.data.length > CATALOG.FILTER_DEFAULT_LENGTH ? (
        <Button
          onClick={() => {
            setBrandAll(!brandAll);
          }}
        >
          {isViewAllVisible(brandAll)}
        </Button>
      ) : (
        ""
      )}

      <p>{productCatagory.name}</p>
      {getFilter(productCatagory, categoriesAll)}
      {productCatagory.data &&
      productCatagory.data.length > CATALOG.FILTER_DEFAULT_LENGTH ? (
        <Button
          onClick={() => {
            setCategoriesAll(!categoriesAll);
          }}
        >
          {isViewAllVisible(categoriesAll)}
        </Button>
      ) : (
        ""
      )}

      <p>{IsAvailblity.name}</p>
      {getFilter(IsAvailblity, isAvailable)}
      {IsAvailblity.data &&
      IsAvailblity.data.length > CATALOG.FILTER_DEFAULT_LENGTH ? (
        <Button
          onClick={() => {
            setIsAvailable(!isAvailable);
          }}
        >
          {isViewAllVisible(isAvailable)}
        </Button>
      ) : (
        ""
      )}
    </div>
  );
}
const index = React.memo(filter);
export default index;
index.propTypes = {
  filterOptionData: PropTypes.object,
  getFilterdSelectedQuery: PropTypes.func,
  priceFilterDatas: PropTypes.object,
  materilaCheckedData: PropTypes.object,
  shapeCheckedData: PropTypes.object,
  genderCheckedData: PropTypes.object,
  clearFilterData: PropTypes.func,
  changeFilter: PropTypes.bool,
  getPriceFiltered: PropTypes.func,
  getSelectedFilter: PropTypes.any,
};
