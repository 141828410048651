import { PROFILE_CONST } from "./profileConst";

const UserProfile = (state = {}, action) => {
  switch (action.type) {
    case PROFILE_CONST.GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        isUserProfileRequesting: true,
        profileData: false,
        profileError: false,
      };
    case PROFILE_CONST.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isUserProfileRequesting: false,
        profileData: action.payload.response,
        profileError: false,
      };
    case PROFILE_CONST.GET_USER_PROFILE_FAILED:
      return {
        ...state,
        isUserProfileRequesting: false,
        profileData: false,
        profileError: action.payload.error ? false : action.payload.response,
      };

    /* Update User Profile */
    case PROFILE_CONST.UPDATE_USER_PROFILE_REQUEST:
      return {
        ...state,
        isUpdateUserProfileRequesting: true,
        updateUserProfileData: false,
        updateUserProfileError: false,
      };
    case PROFILE_CONST.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isUpdateUserProfileRequesting: false,
        updateUserProfileData: action.payload.response,
        updateUserProfileError: false,
      };
    case PROFILE_CONST.UPDATE_USER_PROFILE_FAILED:
      return {
        ...state,
        isUpdateUserProfileRequesting: false,
        updateUserProfileData: false,
        updateUserProfileError: action.payload.error
          ? false
          : action.payload.response,
      };

    case PROFILE_CONST.GET_COUNTRIES_REQUEST:
      return {
        ...state,
        isCountriesRequesting: true,
        countriesData: false,
        countriesError: false,
      };
    case PROFILE_CONST.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        isCountriesRequesting: false,
        countriesData: action.payload.response,
        countriesError: false,
      };
    case PROFILE_CONST.GET_COUNTRIES_FAILED:
      return {
        ...state,
        isCountriesRequesting: false,
        countriesData: false,
        countriesError: action.payload.error ? false : action.payload.response,
      };

    case PROFILE_CONST.GET_STATES_REQUEST:
      return {
        ...state,
        isStatesRequesting: true,
        statesData: false,
        statesError: false,
      };
    case PROFILE_CONST.GET_STATES_SUCCESS:
      return {
        ...state,
        isStatesRequesting: false,
        statesData: action.payload.response,
        statesError: false,
      };
    case PROFILE_CONST.GET_STATES_FAILED:
      return {
        ...state,
        isStatesRequesting: false,
        statesData: false,
        statesError: action.payload.error ? false : action.payload.response,
      };

    case PROFILE_CONST.GET_CITIES_REQUEST:
      return {
        ...state,
        isCitiesRequesting: true,
        citiesData: false,
        citiesError: false,
      };
    case PROFILE_CONST.GET_CITIES_SUCCESS:
      return {
        ...state,
        isCitiesRequesting: false,
        citiesData: action.payload.response,
        citiesError: false,
      };
    case PROFILE_CONST.GET_CITIES_FAILED:
      return {
        ...state,
        isCitiesRequesting: false,
        citiesData: false,
        citiesError: action.payload.error ? false : action.payload.response,
      };

    /* Change Password */
    case PROFILE_CONST.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isChangePasswordRequesting: true,
        changePasswordData: false,
        changePasswordError: false,
      };
    case PROFILE_CONST.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isChangePasswordRequesting: false,
        changePasswordData: action.payload.response,
        changePasswordError: false,
      };
    case PROFILE_CONST.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        isChangePasswordRequesting: false,
        changePasswordData: false,
        changePasswordError: action.payload.error
          ? false
          : action.payload.response,
      };

    case PROFILE_CONST.GET_RETAILERS_LISTS_REQUEST:
      return {
        ...state,
        isGetretailersListRequesting: true,
        isGetretailersListData: false,
        isGetretailersListError: false,
      };
    case PROFILE_CONST.GET_RETAILERS_LISTS_SUCCESS:
      return {
        ...state,
        isGetretailersListRequesting: false,
        isGetretailersListData: action.payload.response,
        isGetretailersListError: false,
      };
    case PROFILE_CONST.GET_RETAILERS_LISTS_FAILED:
      return {
        ...state,
        isGetretailersListRequesting: false,
        isGetretailersListData: false,
        isGetretailersListError: action.payload.error
          ? false
          : action.payload.response,
      };
    default:
      return { ...state };
  }
};

export default UserProfile;
