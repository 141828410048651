import { EXPRESS_CHECKOUT_CONST } from "./expressCheckoutConst";

const getAllProduct = (state = {}, action) => {
  switch (action.type) {
    case EXPRESS_CHECKOUT_CONST.EXPRESS_CHECKOUT_PRODUCT_LIST_REQUESTING:
      return {
        ...state,
        expressOrderAllProductRequesting: true,
        expressOrderAllProductData: false,
        expressOrderAllProductDataError: false,
      };
    case EXPRESS_CHECKOUT_CONST.EXPRESS_CHECKOU_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        expressOrderAllProductRequesting: false,
        expressOrderAllProductData: action.payload.response.data,
        expressOrderAllProductDataError: false,
      };
    case EXPRESS_CHECKOUT_CONST.EXPRESS_CHECKOUT_PRODUCT_LIST_FAILED:
      return {
        ...state,
        expressOrderAllProductRequesting: false,
        expressOrderAllProductData: false,
        expressOrderAllProductDataError: action.payload.error
          ? false
          : action.payload.response,
      };
    case EXPRESS_CHECKOUT_CONST.EXPRESS_CHECKOUT_MULTIPLE_PRODUCT_ID_REQUESTING:
      return {
        ...state,
        expressInventoryByMultipleProductsRequesting: true,
        expressInventoryByMultipleProductsData: false,
        expressInventoryByMultipleProductsDataError: false,
      };
    case EXPRESS_CHECKOUT_CONST.EXPRESS_CHECKOUT_MULTIPLE_PRODUCT_ID_SUCCESS:
      return {
        ...state,
        expressInventoryByMultipleProductsRequesting: false,
        expressInventoryByMultipleProductsData: action.payload.response.data,
        expressInventoryByMultipleProductsDataError: false,
      };
    case EXPRESS_CHECKOUT_CONST.EXPRESS_CHECKOUT_MULTIPLE_PRODUCT_ID_FAILED:
      return {
        ...state,
        expressInventoryByMultipleProductsRequesting: false,
        expressInventoryByMultipleProductsData: false,
        expressInventoryByMultipleProductsDataError: action.payload.error
          ? false
          : action.payload.response,
      };

    default:
      return { ...state };
  }
};

export default getAllProduct;
