import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-multi-lang";
import moment from "moment";
import { Row, Col, Button } from "reactstrap";
import { MY_ORDER } from "./../../helpers/Constants";
import Loader from "./../../common/Loader";
import RupeeIcon from "../../assets/Images/rupee_icon.png";
import { getOrderDetails } from "./../../redux/myOrders/orderActions";
import ViewModal from "./../../components/OrderStatus";

function ProductOrderedCard() {
  const dispatch = useDispatch();
  const t = useTranslation();
  const [orderData, setOrderData] = useState([]);
  const [itemCount, setItemsCount] = useState(MY_ORDER.DEFAULT_ORDER_COUNT);
  const [viewOrderModal, setViewOrderModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [orderDetailsHeadline, setOrderDetailsHeadline] = useState({});
  const salesExecutive = "salesexecutive";
  const nextProps = useSelector((state) => ({
    orderData: state.Order || null,
    UserProfileData: state.UserProfile.profileData?.data?.userProfile || null,
  }));
  const {
    isOrderData,
    isOrderRequesting,
    isOrderDetailsData,
    isOrderDetailsRequesting,
  } = nextProps.orderData;

  useEffect(() => {}, [viewOrderModal]);
  useEffect(() => {
    if (isOrderData && !isOrderRequesting) {
      setOrderData(isOrderData.data.orderList);
    }
  }, [isOrderData]);
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (isOrderDetailsData) {
      setOrderDetails(isOrderDetailsData.data);
    }
  }, [isOrderDetailsData]);

  const handleLoadMore = () => {
    setItemsCount(itemCount + MY_ORDER.DEFAULT_ORDER_COUNT);
  };

  const handleView = (
    orderId,
    orderDate,
    orderStatus,
    orderRefNo,
    orderItemTotal
  ) => {
    setOrderDetailsHeadline({
      orderId: orderRefNo,
      orderDate: orderDate,
      orderStatus: orderStatus,
      orderItemTotal: orderItemTotal,
    });
    setViewOrderModal(true);
    if (
      nextProps.UserProfileData?.roleName?.toLowerCase().replace(/ /g, "") ===
        salesExecutive &&
      localStorage.getItem("retailer")
    ) {
      // setSelectedQuantityData(reqData);
      const email = JSON.parse(localStorage.getItem("retailer"))?.Email;
      dispatch(getOrderDetails({ orderId, email }));
    } else {
      dispatch(getOrderDetails({ orderId }));
    }
  };

  const handleViewModel = (status) => {
    setViewOrderModal(status);
  };

  const myOrderList = () => {
    if (orderData !== undefined && orderData.length && !isOrderRequesting) {
      return orderData
        .slice(MY_ORDER.DEFAULT_SLICE_STARTING_COUNT, itemCount)
        .map((order) => {
          return (
            <>
              <Row className="myorder-card mb-4">
                <Col lg="6" xl="3" md="6">
                  <p className="myorder-card-item">
                    {t("PROFILE.ORDER_ID")} :
                    <span className="product-brand-name">
                      {" "}
                      {order.orderRefNo}
                    </span>
                  </p>
                  <p className="myorder-card-item mb-0">
                    {t("PROFILE.ORDER_DATE")} :
                    <span className="order-date">
                      {" "}
                      {moment(order.orderDate).format(" D MMM YYYY")}
                    </span>
                  </p>
                </Col>
                <Col lg="6" xl="3" md="6">
                  <p className="myorder-card-item">
                    {t("PROFILE.TOTAL_ITEMS")} :{" "}
                    <span className="ordered-card-item">
                      {" "}
                      {order.orderItemTotal}
                    </span>
                  </p>
                  <p className="myorder-card-item mb-0">
                    {t("PROFILE.ORDER_AMOUNT")} :
                    <span className="ordered-card-item">
                      {" "}
                      <img src={RupeeIcon} /> <span> </span>
                      {order.orderTotalAmt}{" "}
                    </span>
                  </p>
                </Col>
                <Col lg="6" xl="3" md="6" className="tab-mt">
                  <p className="myorder-card-item">
                    {t("PROFILE.ORDER_STATUS")} :
                    <span> {order.orderStatus}</span>
                  </p>
                </Col>
                <Col lg="6" xl="3" md="6">
                  <Button
                    className="product-price myorder-button"
                    onClick={() => {
                      handleView(
                        order.orderID,
                        order.orderDate,
                        order.orderStatus,
                        order.orderRefNo,
                        order.orderItemTotal
                      );
                    }}
                  >
                    {" "}
                    {t("PROFILE.VIEW")}
                  </Button>
                </Col>
              </Row>
            </>
          );
        });
    } else if (orderData === undefined) {
      return (
        <>
          <div className="no-orders-section">
            <h3 className="no-orders-msg">
              {t("ORDER.MY_ORDERS_ERROR_MESSAGE")}
            </h3>
          </div>
        </>
      );
    } else {
      return (
        <div className="custom-loader">
          <Loader width={50} height={50} />
        </div>
      );
    }
  };
  return (
    <div>
      <>
        {myOrderList()}
        {orderData && orderData.length > itemCount && !isOrderRequesting ? (
          <div className="myorder-loadmore-section">
            <Button className="myorder-loadmore" onClick={handleLoadMore}>
              {t("PROFILE.LOAD_MORE")}
            </Button>
          </div>
        ) : (
          ""
        )}
        <ViewModal
          viewOrderModal={viewOrderModal}
          handleViewModel={handleViewModel}
          orderDetails={orderDetails}
          isOrderDetailsRequesting={isOrderDetailsRequesting}
          orderDetailsHeadline={orderDetailsHeadline}
        />
      </>
    </div>
  );
}

export default ProductOrderedCard;
