import { MATERIAL_CONST } from "./categoryConst";

/**
 *  User Login actions
 */
export const getMaterials = () => ({
  type: MATERIAL_CONST.MATERIAL_REQUESTING,
  payload: null,
});

export const getMaterialsSuccess = (response) => ({
  type: MATERIAL_CONST.MATERIAL_SUCCESS,
  payload: response,
});

export const getMaterialsFailed = (error) => ({
  type: MATERIAL_CONST.MATERIAL_FAILED,
  payload: error,
});
