import { AXIOS_INSTANCE, BASE_URL } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { INVENTORY } from "./inventoryConst";
import {
  getInventorySuccess,
  getInventoryFailed,
  getInventoryByUserSuccess,
  getInventoryByUserFailed,
} from "./inventoryAction";
import { ProductClient } from "../apibenfranklin";
import { getToken, unAuthorizedRedirection } from "../../helpers/Utility";

const client = new ProductClient(`${BASE_URL}`, AXIOS_INSTANCE);

/**
 *  getInventory API requesting
 */
const getInventoryApiRequest = async (payload) => {
  let token = `Bearer ${getToken()}`;
  let response = {};
  try {
    const data = await client.getInventory(payload.productId, token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getInventory(action) {
  try {
    const apiResponse = yield call(getInventoryApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getInventorySuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getInventoryFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getInventoryFailed(errorData));
  }
}

/**
 *  getInventoryByUser API requesting
 */
const getInventoryByUserApiRequest = async () => {
  let token = `Bearer ${getToken()}`;
  let response = {};
  try {
    const data = await client.getInventoryByUserName(token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getInventoryByUser(action) {
  try {
    const apiResponse = yield call(
      getInventoryByUserApiRequest,
      action.payload
    );
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getInventoryByUserSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getInventoryByUserFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getInventoryByUserFailed(errorData));
  }
}

export function* watchInventory() {
  yield takeEvery(INVENTORY.GET_INVENTORY_REQUESTING, getInventory);
  yield takeEvery(
    INVENTORY.GET_INVENTORY_BY_USER_REQUESTING,
    getInventoryByUser
  );
}

function* getInventorySaga() {
  yield all([fork(watchInventory)]);
}

export default getInventorySaga;
