import { AXIOS_INSTANCE, BASE_URL } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { MY_ORDER_CONST } from "./orderConst";
import {
  getMyOrdersSuccess,
  getMyOrdersFailed,
  getOrderDetailsSuccess,
  getOrderDetailsFailed,
} from "./orderActions";
import { OrderClient } from "../apibenfranklin";
import { getToken, unAuthorizedRedirection } from "./../../helpers/Utility";

const client = new OrderClient(`${BASE_URL}`, AXIOS_INSTANCE);

/**
 *  My order Saga
 */
const getMyOrdersApiRequest = async (payload) => {
  let response = {};
  let token = `bearer  ${getToken()}`;
  try {
    const data = await client.getMyOrders(
      payload?.pageNumber || null,
      payload?.pageSize || null,
      payload?.email || null,
      token
    );
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getMyOrders(action) {
  try {
    const apiResponse = yield call(getMyOrdersApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getMyOrdersSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getMyOrdersFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getMyOrdersFailed(errorData));
  }
}

/**
 *  My order details saga
 */
const getOrderDetailsApiRequest = async (payload) => {
  let response = {};
  let token = `bearer  ${getToken()}`;
  try {
    const data = await client.getOrderDetails(
      payload.orderId,
      payload.email || null,
      token
    );
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getOrderDetails(action) {
  try {
    const apiResponse = yield call(getOrderDetailsApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getOrderDetailsSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getOrderDetailsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getOrderDetailsFailed(errorData));
  }
}

export function* watchLoginUser() {
  yield takeEvery(MY_ORDER_CONST.MY_ORDER_REQUESTING, getMyOrders);
  yield takeEvery(MY_ORDER_CONST.MY_ORDER_DETAILS_REQUESTING, getOrderDetails);
}

function* myOrderSaga() {
  yield all([fork(watchLoginUser)]);
}

export default myOrderSaga;
