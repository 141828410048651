import React from "react";
import "./BrandsPage.scss";
import "../../assets/CSS/preLoginStyles.scss";

const BrandImageComponent = (props) => {
  // eslint-disable-next-line react/prop-types
  const classes = props.isSmallImage ? "small_image" : "";
  return (
    <div className="brand_image">
      <img
        // eslint-disable-next-line react/prop-types
        src={props.image}
        // eslint-disable-next-line react/prop-types
        alt={props.name}
        className={classes}
        // eslint-disable-next-line react/prop-types
        style={{ height: `${props.height}`, width: "100%" }}
      />
    </div>
  );
};

export default BrandImageComponent;
