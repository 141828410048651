import "../../assets/CSS/preLoginStyles.scss";

import React from "react";
import leftArrow from "../../assets/Images/S-left-arrow.png";
import { useHistory } from "react-router-dom";

const CookiePolicyPage = () => {
  const history = useHistory();
  document.title = "Eyegear";
  return (
    <div className="section__padding policy__content__section">
      <div onClick={() => history.goBack()} className="back__section">
        <img src={leftArrow} alt="backButton" className="back_arrow" />
        <h3 className="policy__content__section_heading back__text">Back</h3>
      </div>
      <h3 className="policy__content__section_heading">Cookie Policy</h3>
      <p className="policy__content__section__para">
        A cookie is a small file saved by your web browser on your computer that
        can capture certain aspects of your engagement with our website. Cookies
        also enable us to compile data on site traffic and interactions to
        improve your experience on our website.
      </p>
      <p className="policy__content__section__para">
        Additionally, we may use trusted third-party services to track this data
        on our behalf. In such cases, we may arrange for a common identifier to
        be shared between the third party and us to ensure accuracy in the
        measurement of traffic and engagement. If you choose, you can change
        your cookie settings to accept or disable cookies. However, should you
        choose to disable them, some of the features that make the website more
        efficient may not run properly. You can also choose to be notified by
        your computer each time a cookie is sent. We do not sell, trade or in
        any way transfer your personal information to outside parties, nor do we
        include or offer third-party products on our website.
      </p>
    </div>
  );
};

export default CookiePolicyPage;
