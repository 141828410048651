import { Button, Col, Container, Row } from "reactstrap";
import React, { useEffect, useRef, useState } from "react";
import { getFilters, getSortType } from "./../../redux/filters/filterActions";
import { useDispatch, useSelector } from "react-redux";

import { CATALOG } from "../../helpers/Constants";
import CatalogHeader from "./CatalogHeader";
import { FaArrowUp } from "react-icons/fa";
import Filter from "./Filter";
import FilterModal from "../Products/Filter/FilterModal";
import Filterimage from "../../assets/Images/Filter.svg";
import Loader from "../../common/Loader";
import OrderPopUp from "../Products/OrderPopup";
import ProductBanner from "./ProductBanner";
import ProductCard from "./ProductCard";
import ProductPagination from "./Pagination";
import ProductWebFilter from "./ProductWebFIlter";
import SortModal from "../Products/ProductSort/SortModal";
import Sortimage from "../../assets/Images/Sort.svg";
import { getAll } from "../../redux/filters/filterActions";
import { getCartItems } from "../../redux/cart/cartActions";
import { getCartItemsCount } from "../../redux/cartCount/cartCountActions";
import { getToken } from "./../../helpers/Utility";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-multi-lang";

export default function Products() {
  // done by mani
  document.title = `Eyegear Products`;
  let history = useHistory();
  const breadCrumbsdata = [
    {
      name: "All Frames & Sunglasses",
      value: "/products",
    },
  ];
  const [openModal, setOpenModal] = useState(false);
  const [productCardData, setProductCardData] = useState();
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(14); //mani
  const [modalData, setModalData] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortTypes, setSortTypes] = useState();
  const [queryFilterdData, setQueryFilterdData] = useState("");
  const [selectedSortType, setSelectedSortType] = useState(
    "sortBy=releaseDate&orderBy=Desc"
  );
  const [priceFilterData, setPriceFilterData] = useState({});
  const [materilaCheckedData, setMaterilaCheckedData] = useState({});
  const [shapeCheckedData, setShapeCheckedData] = useState({});
  const [filterModal, setFilterModal] = useState(false);
  const [genderCheckedData, setGenderCheckedData] = useState({});
  const [filterCheckedData, setFilterCheckedData] = useState({});
  const [sortPopUp, setSortModal] = useState(false);
  const [sortModaSelectedIndex, setSortModaSelectedIndex] = useState(0);
  const [enableClear, setEnableClear] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [selectedButton, setSelectedButton] = useState({});
  const [filteredMewQuery, newFilteredNewQuery] = useState("");
  const [productWebFilterEnable, setProductWebFilterEnable] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);

  const t = useTranslation();
  const dispatch = useDispatch();
  const nextProps = useSelector((state) => ({
    ProductsData: state.FilterOption.getAllData || null,
    ProductsLoader: state.FilterOption.isGetAllRequesting,
    isFilterData: state.FilterOption,
    SortTypes: state.FilterOption.getSortTypeData || null,
    UserProfileData: state.UserProfile.profileData?.data?.userProfile || null,
  }));
  const salesExecutive = "salesexecutive";

  useEffect(() => {}, [filterModal]);
  const { filterOptionData } = nextProps.isFilterData;
  useEffect(() => {
    dispatch(getFilters());
    dispatch(getSortType());
  }, []);
  // done by mani
  document.addEventListener("visibilitychange", (event) => {
    event.preventDefault();
    if (!document.hidden) {
      let data = {
        pageNumber: 1,
      };
      const email = JSON.parse(localStorage.getItem("retailer"))?.Email;
      data.email = email;
      dispatch(getCartItemsCount());
      dispatch(getCartItems(data));
    }
    event.stopImmediatePropagation();
  });

  const isSecondRun = useRef(true);
  useEffect(() => {
    if (isSecondRun.current) {
      isSecondRun.current = false;
      return;
    }
    let data = {
      pageNumber: 1,
    };
    if (nextProps.UserProfileData) {
      if (
        nextProps.UserProfileData?.roleName?.toLowerCase().replace(/ /g, "") ===
          salesExecutive &&
        localStorage.getItem("retailer")
      ) {
        const email = JSON.parse(localStorage.getItem("retailer"))?.Email;
        data.email = email;
        dispatch(getCartItemsCount(email));
      } else {
        dispatch(getCartItemsCount());
      }
      dispatch(getCartItems(data));
    }
  }, [nextProps.UserProfileData]);

  let searchData = window.location.search.slice(
    1,
    window.location.search.length
  );
  //mani
  const firstAction = useRef(true);
  useEffect(() => {
    if (firstAction.current) {
      firstAction.current = false;
      return;
    } else {
      if (queryFilterdData) {
        history.push("/products");
        return;
      } else {
        history.push("/products");
        let paramsData = window.location.search.slice(
          1,
          window.location.search.length
        );
        let newParams = `${paramsData}&${selectedSortType}`;
        dispatch(
          getAll({
            search: newParams,
            pageNumber: pageNumber,
            pageSize: CATALOG.PAGINATION_SIZE,
          })
        );
      }
    }
  }, [selectedButton]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!getToken()) {
      history.push("/login");
    }

    let newParams = `${searchData}&${selectedSortType}`;
    dispatch(
      getAll({
        search: newParams,
        pageNumber: pageNumber,
        pageSize: CATALOG.PAGINATION_SIZE,
      })
    );

    if (searchData) {
      let url_string = window.location.href;
      let url = new URL(url_string);
      if (url.searchParams.get("frameMaterialId")) {
        let materialId = url.searchParams.get("frameMaterialId");
        let materilaCheckedBox = materialId.split(",").map(Number);
        setMaterilaCheckedData({
          frameMaterialId: materilaCheckedBox.map(String),
        });
        setEnableClear(true);
      }
      if (url.searchParams.get("frameShapeId")) {
        let shapeId = url.searchParams.get("frameShapeId");
        let shapeCheckedBox = shapeId.split(",").map(Number);
        setShapeCheckedData({ frameShapeId: shapeCheckedBox.map(String) });
        setEnableClear(true);
      }
      if (url.searchParams.get("genderIdList")) {
        let shapeId = url.searchParams.get("genderIdList");
        let genderCheckedBox = shapeId.split(",").map(Number);
        setGenderCheckedData({ genderIdList: genderCheckedBox.map(String) });
        setEnableClear(true);
      }
    }

    if (searchData) {
      let url_string = window.location.href;
      let url = new URL(url_string);
      let minPrice = url.searchParams.get("netPrice_From");
      let maxnPrice = url.searchParams.get("netPrice_To");
      setPriceFilterData({
        min: minPrice,
        max: maxnPrice,
      });
      if (minPrice && maxnPrice) {
        setEnableClear(true);
      }
    }
  }, []);

  const run = useRef(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (run.current) {
      run.current = false;
      return;
    }
    if (!getToken()) {
      history.push("/login");
    }
    if (searchData) {
      let newParams = `${searchData}&${selectedSortType}`;
      setShapeCheckedData({});
      setMaterilaCheckedData({});
      setGenderCheckedData({});
      let url_string = window.location.href;
      let url = new URL(url_string);
      if (url.searchParams.get("searchText")) {
        setPriceFilterData({});
        setEnableClear(true);
        setPageNumber(1);
      } else {
        setEnableClear(false);
      }
      dispatch(
        getAll({
          search: newParams,
          pageNumber: 1,
          pageSize: CATALOG.PAGINATION_SIZE,
        })
      );

      if (searchData) {
        let url_string = window.location.href;
        let url = new URL(url_string);
        if (url.searchParams.get("genderIdList")) {
          let shapeId = url.searchParams.get("genderIdList");
          let genderCheckedBox = shapeId.split(",").map(Number);
          setGenderCheckedData({ genderIdList: genderCheckedBox });
          setShapeCheckedData({});
          setMaterilaCheckedData({});
          setPriceFilterData({});
          setPageNumber(1);
        }
      }
    }
  }, [searchData]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    let paramsData = window.location.search.slice(
      1,
      window.location.search.length
    );

    let url_string = window.location.href;
    let url = new URL(url_string);
    if (!url.searchParams.get("searchText")) {
      paramsData = "";
    }
    let newParams = `${paramsData}&${searchData}&${queryFilterdData}&${selectedSortType}`;
    if (queryFilterdData) {
      newParams = `${paramsData}&${queryFilterdData}&${selectedSortType}`;
      dispatch(
        getAll({
          search: newParams,
          pageNumber: pageNumber,
          pageSize: CATALOG.PAGINATION_SIZE,
        })
      );
    }
    //mani
    else if (searchData) {
      dispatch(
        getAll({
          search: newParams,
          pageNumber: pageNumber,
          pageSize: CATALOG.PAGINATION_SIZE,
        })
      );
    } else {
      newParams = `${paramsData}&${selectedSortType}`;
      dispatch(
        getAll({
          search: newParams,
          pageNumber: pageNumber,
          pageSize: CATALOG.PAGINATION_SIZE,
        })
      );
    }
  }, [pageNumber, selectedSortType, filteredMewQuery]);

  useEffect(() => {
    if (
      nextProps.ProductsData &&
      nextProps.ProductsData.data &&
      nextProps.ProductsData.data.searchResults &&
      nextProps.ProductsData.data.searchResults.value
    ) {
      setPageCount(nextProps.ProductsData.data.searchResults["@odata.count"]);
      setProductCardData(nextProps.ProductsData.data.searchResults.value);
    }
  }, [nextProps.ProductsData]);

  const getFilterdSelectedQuery = (query, filterChecked) => {
    setFilterModal(false);
    setPageNumber(1);
    setStartIndex(0);
    setEndIndex(14); //mani
    setFilterCheckedData(filterChecked);
    setQueryFilterdData(query);
    let paramsData = window.location.search.slice(
      1,
      window.location.search.length
    );
    let url_string = window.location.href;
    let url = new URL(url_string);
    if (!url.searchParams.get("searchText")) {
      paramsData = "";
    }
    let newQuery = `${paramsData}&${query}&${selectedSortType}`;
    newFilteredNewQuery(newQuery);
    var str1 = query.replace(/[^\d.]/g, "");
    if (parseInt(str1) > 0 || query?.includes("isAvailable=true")) {
      setEnableClear(true);
    } else {
      setEnableClear(false);
    }
    // window.scrollTo(0, 0);
  };

  const handleOrderModal = (value) => {
    setOpenModal(value);
  };
  const arrowUp = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 600) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const handleModalData = (value) => {
    setModalData(value);
  };
  useEffect(() => {
    if (nextProps.SortTypes && nextProps.SortTypes.data) {
      setSortTypes(nextProps.SortTypes.data.sortTypes);
    }
  }, [nextProps.SortTypes]);
  const handlePagination = (value, startInd, endInd) => {
    setStartIndex(startInd);
    setEndIndex(endInd);
    setPageNumber(value);
  };
  const handleSelectedSortType = (value) => {
    setSelectedSortType(value);
  };
  const handleModalToggle = (value) => {
    setOpenModal(value);
  };

  const isproductCardData = () => {
    if (productCardData && productCardData.length > 0) {
      return (
        <>
          <ProductCard
            OrderModal={handleOrderModal}
            CardData={productCardData}
            modalData={handleModalData}
          />
          <ProductPagination
            pagination={handlePagination}
            pageCount={pageCount}
            pageNumber={pageNumber}
            startIndex={startIndex}
            endIndex={endIndex}
          />
        </>
      );
    } else {
      return (
        <>
          <div>
            <h3 className="no-record-msg mt-5">
              {t("CATALOG.NO_RECORD_FOUND")}
            </h3>
          </div>
        </>
      );
    }
  };
  const clearFilterData = () => {
    setFilterModal(false);
    setShapeCheckedData({});
    setMaterilaCheckedData({});
    setGenderCheckedData({});
    setFilterCheckedData({});
    setPriceFilterData({});
    setEnableClear(false);
    setPageNumber(1);
    setSelectedFilter({});
    setQueryFilterdData("");
    newFilteredNewQuery("");
    history.push("/products");
    dispatch(
      getAll({
        search: `${selectedSortType}`,
        pageNumber: pageNumber,
        pageSize: CATALOG.PAGINATION_SIZE,
      })
    );
  };

  const isFilterPopup = (status) => {
    setFilterModal(status);
  };

  const handleFilterModal = () => {
    setFilterModal(true);
  };

  const handleSortModal = () => {
    setSortModal(true);
  };

  const isSortPopUp = (status) => {
    setSortModal(status);
  };

  const getPriceFiltered = (params) => {
    setPriceFilterData(params);
    setPageNumber(1);
  };

  const getSortModalSelectedIndex = (index) => {
    setSortModaSelectedIndex(index);
  };

  /**
   * Filter option display in catalog header
   */

  const getSelectedFilter = (params) => {
    let data = JSON.parse(JSON.stringify(params));
    setSelectedFilter(data);
  };

  const getFilterButtonSelected = (params) => {
    let data = JSON.parse(JSON.stringify(params));
    setSelectedButton(data);
  };

  const productWebFilterEnableHandle = (status) => {
    setProductWebFilterEnable(status);
  };

  return (
    <div className={"product-page"}>
      <ProductWebFilter
        productWebFilterEnable={productWebFilterEnable}
        productWebFilterEnableHandle={productWebFilterEnableHandle}
        genderCheckedData={genderCheckedData}
        materilaCheckedData={materilaCheckedData}
        shapeCheckedData={shapeCheckedData}
        priceFilterDatas={priceFilterData}
        filterOptionData={filterOptionData}
        getFilterdSelectedQuery={getFilterdSelectedQuery}
        getPriceFiltered={getPriceFiltered}
        getSelectedFilter={getSelectedFilter}
        selectedButton={selectedButton}
        clearFilterData={clearFilterData}
      />
      <Container
        fluid={true}
        className="footer-section-container pb-4 container-fluid"
      >
        <Button
          className="filter-sort-button fliterbtn-1"
          onClick={handleFilterModal}
        >
          <img src={Filterimage} />
          {t("SORT.FILTER")}
        </Button>
        {filterModal ? (
          <FilterModal
            isFilterPopup={isFilterPopup}
            filterModal={filterModal}
            genderCheckedData={genderCheckedData}
            materilaCheckedData={materilaCheckedData}
            shapeCheckedData={shapeCheckedData}
            priceFilterDatas={priceFilterData}
            filterOptionData={filterOptionData}
            getFilterdSelectedQuery={getFilterdSelectedQuery}
            clearFilterData={clearFilterData}
            filterCheckedData={filterCheckedData}
            getPriceFiltered={getPriceFiltered}
          />
        ) : (
          ""
        )}
        <Button
          className="filter-sort-button fliterbtn-2"
          onClick={handleSortModal}
        >
          <img src={Sortimage} />
          {t("SORT.SORT_BY")}
        </Button>

        {sortPopUp ? (
          <SortModal
            sortPopUp={sortPopUp}
            sortTypesData={sortTypes}
            handleSelectedSortType={handleSelectedSortType}
            isSortPopUp={isSortPopUp}
            getSortModalSelectedIndex={getSortModalSelectedIndex}
            sortModaSelectedIndex={sortModaSelectedIndex}
          />
        ) : (
          ""
        )}
        <ProductBanner />
        <CatalogHeader
          SortTypeList={sortTypes}
          selectedSortType={handleSelectedSortType}
          defaultBreadCrumbsdata={breadCrumbsdata}
          genderCheckedData={genderCheckedData}
          materilaCheckedData={materilaCheckedData}
          shapeCheckedData={shapeCheckedData}
          priceFilterDatas={priceFilterData}
          enableClear={enableClear}
          filterOptionData={filterOptionData}
          selectedFilter={selectedFilter}
          clearFilterData={clearFilterData}
          getFilterButtonSelected={getFilterButtonSelected}
          productWebFilterEnableHandle={productWebFilterEnableHandle}
        />
        <Row className="mb-5 div-margint">
          <Col
            xl="3"
            lg="3"
            md="6"
            sm="3"
            className="product-left-filter d-none"
          >
            <div className="product-filter-header product-section">
              <p className="fliter-heading">{t("CATALOG.FILTER_BY")}</p>
              {enableClear ? (
                <span
                  className="clear-text"
                  onClick={() => {
                    clearFilterData();
                  }}
                >
                  {t("CATALOG.CLEAR")}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="product-left-filter-section product-section">
              <Filter
                genderCheckedData={genderCheckedData}
                materilaCheckedData={materilaCheckedData}
                shapeCheckedData={shapeCheckedData}
                priceFilterDatas={priceFilterData}
                filterOptionData={filterOptionData}
                getFilterdSelectedQuery={getFilterdSelectedQuery}
                getPriceFiltered={getPriceFiltered}
                getSelectedFilter={getSelectedFilter}
                selectedButton={selectedButton}
              />
            </div>
          </Col>
          <Col
            xl="12"
            lg="12"
            md="12"
            sm="12"
            className="product-right-card mt-2"
          >
            {nextProps.ProductsLoader ? (
              <div className="custom-loader">
                <Loader width="50" height="50" />
              </div>
            ) : (
              <>{isproductCardData()}</>
            )}
          </Col>
        </Row>
      </Container>
      {openModal && (
        <OrderPopUp
          isToggle={openModal}
          handleToggle={handleModalToggle}
          productModalData={modalData}
        />
      )}
      <div className="mobile-filter-sort">{/* <SortModal /> */}</div>
      {showTopBtn && <FaArrowUp className="Arrow-up-icon" onClick={arrowUp} />}
    </div>
  );
}
