export const EXPRESS_CHECKOUT_CONST = {
  EXPRESS_CHECKOUT_PRODUCT_LIST_REQUESTING:
    "EXPRESS_CHECKOUT_PRODUCT_LIST_REQUESTING",
  EXPRESS_CHECKOU_PRODUCT_LIST_SUCCESS: "EXPRESS_CHECKOU_PRODUCT_LIST_SUCCESS",
  EXPRESS_CHECKOUT_PRODUCT_LIST_FAILED: "EXPRESS_CHECKOUT_PRODUCT_LIST_FAILED",

  EXPRESS_CHECKOUT_MULTIPLE_PRODUCT_ID_REQUESTING:
    "EXPRESS_CHECKOUT_MULTIPLE_PRODUCT_ID_REQUESTING",
  EXPRESS_CHECKOUT_MULTIPLE_PRODUCT_ID_SUCCESS:
    "EXPRESS_CHECKOUT_MULTIPLE_PRODUCT_ID_SUCCESS",
  EXPRESS_CHECKOUT_MULTIPLE_PRODUCT_ID_FAILED:
    "EXPRESS_CHECKOUT_MULTIPLE_PRODUCT_ID_FAILED",
};
