import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button } from "reactstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-multi-lang";
// import { useHistory } from "react-router-dom";
// import RupeeIcon from "../../../assets/Images/rupee_icon.png";
import defaultImage from "../../../assets/Images/spec.png";
import canvasImage from "../../../assets/Images/canvasImage.png";
import LazyLoad from "react-lazyload";
import { getInventory } from "../../../redux/inventory/inventoryAction";
import { tabAlert } from "../../../common/alertNotifications";
import _ from "lodash";

function index(props) {
  const { OrderModal, CardData, modalData } = props;
  const t = useTranslation();
  const dispatch = useDispatch();
  // const history = useHistory();
  const [selectedColor, setSelectedColor] = useState({});
  const [showHoverImage, setHoverImage] = useState(false);
  const [isSalesExecutive, setIsSalesExecutive] = useState(false);
  const [prevCardData, setPrevCardData] = useState([]);

  const nextProps = useSelector((state) => ({
    addToCart: state.AddToCart || null,
    UserProfileData: state.UserProfile.profileData?.data?.userProfile || null,
  }));
  const salesExecutive = "salesexecutive";

  const { addToCartData } = nextProps.addToCart;
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (addToCartData) {
      if (addToCartData.data.message) {
        OrderModal(false);
      }
    }
  }, [addToCartData]);
  useEffect(() => {
    if (nextProps.UserProfileData) {
      if (
        nextProps.UserProfileData.roleName?.toLowerCase().replace(/ /g, "") ===
        salesExecutive
      ) {
        setIsSalesExecutive(true);
      } else {
        setIsSalesExecutive(false);
      }
    }
  }, [nextProps.UserProfileData]);

  const handleAddToOrder = (item) => {
    dispatch(getInventory({ productId: item.productId }));
    OrderModal(true);
    modalData(item);
  };

  // done by mani
  // const getCircularReplacer = () => {
  //   const seen = new WeakSet();
  //   return (key, value) => {
  //     if (typeof value === "object" && value !== null) {
  //       if (seen.has(value)) {
  //         return;
  //       }
  //       seen.add(value);
  //     }
  //     return value;
  //   };
  // };
  // const tabs = useRef([{}]);
  // let obj = [{}];
  //mani
  const handleProductView = (item) => {
    let proId = encodeURIComponent(item.productId);
    // let tab = {};
    let itemIdArr = [];
    let condition =
      JSON.parse(localStorage.getItem("firstView")) == null
        ? false
        : JSON.parse(localStorage.getItem("firstView"));

    if (condition) {
      itemIdArr = localStorage.getItem("itemIdArr").split(",");
      if (itemIdArr.includes(proId)) {
        tabAlert(`${item.productName}- tab already open.`);
        // obj = tabs.current.filter((value) => Object.keys(value).length !== 0);
        // obj = obj.filter((x) => {
        //   return x.id === proId;
        // });
        // obj[0].focus();
      } else {
        itemIdArr.push(proId);
        localStorage.setItem("itemIdArr", itemIdArr);
        window.open(
          `${window.location.origin}/product-details?productId=${proId}`
        );
        // tab = window.open(
        //   `${window.location.origin}/product-details?productId=${proId}`
        // );
        // tab.id = proId;
        // tabs.current.push(tab);
      }
    } else {
      localStorage.setItem("firstView", "true");
      itemIdArr.push(proId);
      localStorage.setItem("itemIdArr", itemIdArr);
      window.open(
        `${window.location.origin}/product-details?productId=${proId}`
      );
      // tab = window.open(
      //   `${window.location.origin}/product-details?productId=${proId}`
      // );
      // tab.id = proId;
      // tabs.current.push(tab);
      // localStorage.setItem("tabs", JSON.stringify(tabs, getCircularReplacer()));
      // winTab = JSON.parse(localStorage.getItem("tabs"));
    }
    // search: `${"productId"}=${proId}`,
    // history.push({
    //   pathname: "/product-details",
    //   search: `${"productId"}=${proId}`,
    // });
  };

  useEffect(() => {
    if (
      CardData.length &&
      CardData[0]?.productId !== prevCardData[0]?.productId
    ) {
      // mani
      CardData.map((data) => {
        let productColorList = JSON.parse(data.productColor);
        let indices = [];
        productColorList.filter((item, index) => {
          for (let i = 0; i < item.productImage.length; i++) {
            if (item.productImage[i].includes("No_Image")) {
              indices.push(index);
              break;
            }
          }
        });
        let random = 0;
        let con = true;
        // switch (true) {
        //   case indices.length == productColorList.length:
        //     random = 0;
        //     break;
        //   case indices.length == 0:
        //     random = Math.floor(Math.random() * productColorList.length);
        //     break;
        //   default:
        //     while (con) {
        //       random = Math.floor(Math.random() * productColorList.length);
        //       indices.includes(random) ? (con = true) : (con = false);
        //     }
        //     break;
        // }
        if (indices.length == productColorList.length) {
          random = 0;
        } else if (indices.length == 0) {
          random = Math.floor(Math.random() * productColorList.length);
        } else {
          while (con) {
            random = Math.floor(Math.random() * productColorList.length);
            indices.includes(random) ? (con = true) : (con = false);
          }
        }
        // const random = Math.floor(Math.random() * productColorList.length);
        handleSelectedColor(data.productId, random);
      });
    }
    setPrevCardData(CardData);
  }, [CardData]);

  let colorData = selectedColor;

  const handleSelectedColor = (cardId, index) => {
    if (colorData[cardId]) {
      colorData[cardId] = index;
    } else {
      colorData[cardId] = index;
    }
    setSelectedColor(JSON.parse(JSON.stringify(colorData)));
  };

  const checkSelectedColorsAvailable = (cardId) => {
    if (cardId in selectedColor) {
      return selectedColor[cardId];
    }
    return 0;
  };

  const showSecondImage = (data) => {
    setHoverImage(data);
  };

  const hideSecondImage = () => {
    setHoverImage(false);
  };
  // const openVTORTC = (id, variant, productId) => {
  //   let colorIndex =
  //     selectedColor && selectedColor[productId] ? selectedColor[productId] : 0;
  //   window.invokeKiksarRTE(id + "_" + variant[colorIndex]);
  // };

  return (
    <>
      <Row className="product-spacebet">
        {CardData && CardData.length > 0
          ? CardData.map((item) => {
              //mani
              let discounts = JSON.parse(
                item.discounts === undefined || item.discounts === null
                  ? 0
                  : item.discounts
              );
              //let discounts = JSON.parse(item.discounts);
              let availableDiscountList = _.filter(discounts, (discount) => {
                return (
                  discount.StateId ===
                  nextProps.UserProfileData?.stateId.toString()
                );
              });

              if (!availableDiscountList.length) {
                availableDiscountList = _.filter(discounts, (discount) => {
                  return discount.StateId === "";
                });
              }

              let availableDiscount = {};
              let tempDiscountValue = 0;
              availableDiscountList?.length &&
                availableDiscountList.map((discount) => {
                  if (
                    discount.UsePercentage.toString() === "1" &&
                    parseInt(discount?.DiscountPercentage) > 0
                  ) {
                    let discountValue =
                      (item.netPrice * discount.DiscountPercentage) / 100;
                    if (
                      parseInt(tempDiscountValue) < parseInt(discountValue) &&
                      parseInt(discountValue) < parseInt(item.netPrice) &&
                      (discount.PriceFrom
                        ? parseInt(discount.PriceFrom) <=
                          parseInt(item.netPrice)
                        : true) &&
                      (discount.PriceTo
                        ? parseInt(discount.PriceTo) >= parseInt(item.netPrice)
                        : true)
                    ) {
                      tempDiscountValue = discountValue;
                      availableDiscount = discount;
                    }
                  } else {
                    let discountValue = discount.DiscountAmount;
                    if (
                      parseInt(tempDiscountValue) < parseInt(discountValue) &&
                      parseInt(discountValue) < parseInt(item.netPrice) &&
                      (discount.PriceFrom
                        ? parseInt(discount.PriceFrom) <=
                          parseInt(item.netPrice)
                        : true) &&
                      (discount.PriceTo
                        ? parseInt(discount.PriceTo) >= parseInt(item.netPrice)
                        : true)
                    ) {
                      tempDiscountValue = discountValue;
                      availableDiscount = discount;
                    }
                  }
                });
              let productColorImages = JSON.parse(item.productColor);
              // const variant =
              //   item.productColor &&
              //   item.productColor.length > 0 &&
              //   productColorImages.map(
              //     (variant_data) => variant_data.colorVariant
              //   );

              return (
                <Col
                  lg="3"
                  md="3"
                  sm="3"
                  className="product-card mb-3"
                  key={item.productId}
                >
                  <div>
                    <div className="d-flex">
                      {/* <span className="product-try-on-parent">
                      <a
                        href="#"
                        onClick={() => openVTORTC(1)}
                        className="product-try-on"
                      >
                        Try On!
                      </a>
                    </span> */}
                      <span
                        className={
                          availableDiscount && item.productTypeId !== 4
                            ? availableDiscount?.UsePercentage?.toString() ===
                                "1" &&
                              parseInt(availableDiscount?.DiscountPercentage) >
                                0
                              ? "product-offer"
                              : availableDiscount?.UsePercentage?.toString() ===
                                  "0" &&
                                parseInt(availableDiscount?.DiscountAmount) > 0
                              ? "product-offer"
                              : "product-no-offer"
                            : "product-no-offer"
                        }
                      >
                        {availableDiscount && item.productTypeId !== 4
                          ? availableDiscount?.UsePercentage?.toString() ===
                              "1" &&
                            parseInt(availableDiscount?.DiscountPercentage) > 0
                            ? `${parseInt(
                                availableDiscount.DiscountPercentage
                              )}% off`
                            : availableDiscount?.UsePercentage?.toString() ===
                                "0" &&
                              parseInt(availableDiscount?.DiscountAmount) > 0
                            ? `₹${parseInt(
                                availableDiscount.DiscountAmount
                              )} off`
                            : ""
                          : ""}
                      </span>
                    </div>
                    <div className="productcrad-image-section">
                      <LazyLoad>
                        <object
                          onClick={() => handleProductView(item)}
                          onMouseOver={() => showSecondImage(item.productId)}
                          onMouseOut={() => hideSecondImage()}
                          data={
                            showHoverImage === item.productId
                              ? item.productColor &&
                                item.productColor.length > 0 &&
                                productColorImages[
                                  checkSelectedColorsAvailable(item.productId)
                                ].productImage.length > 1
                                ? productColorImages[
                                    checkSelectedColorsAvailable(item.productId)
                                  ].productImage[1]
                                : productColorImages[
                                    checkSelectedColorsAvailable(item.productId)
                                  ].productImage[0]
                              : (item.productColor &&
                                  item.productColor.length > 0 &&
                                  productColorImages[
                                    checkSelectedColorsAvailable(item.productId)
                                  ].productImage[0]) ||
                                defaultImage
                          }
                          className="frame-image p-2"
                        />
                      </LazyLoad>
                    </div>

                    <ul className="frame-colors-list mb-2">
                      {item.productTypeId === 4 ? (
                        <p className="product-card-color-name">
                          {item?.productColor?.length > 0 &&
                          productColorImages[
                            checkSelectedColorsAvailable(item.productId)
                          ].colorVariant
                            ? productColorImages[
                                checkSelectedColorsAvailable(item.productId)
                              ].colorVariant
                            : ""}
                        </p>
                      ) : (
                        <p className="product-card-color-name">
                          {item?.productColor?.length > 0 &&
                          productColorImages[
                            checkSelectedColorsAvailable(item.productId)
                          ].colorVariant
                            ? productColorImages[
                                checkSelectedColorsAvailable(item.productId)
                              ].colorVariant + " : "
                            : ""}
                          {item?.productColor?.length > 0 &&
                            productColorImages[
                              checkSelectedColorsAvailable(item.productId)
                            ].frameColor}
                          {productColorImages[
                            checkSelectedColorsAvailable(item.productId)
                          ].frameColor !==
                          productColorImages[
                            checkSelectedColorsAvailable(item.productId)
                          ].templeColor
                            ? "-" +
                              productColorImages[
                                checkSelectedColorsAvailable(item.productId)
                              ]?.templeColor
                            : ""}
                        </p>
                      )}
                      {item.productColor &&
                        item.productColor.length > 0 &&
                        productColorImages.map((color_data, index) => {
                          let frame_color = color_data.frameColorCode;
                          let templeColor = color_data.templeColorCode;
                          return (
                            <li
                              key={color_data.productColorId}
                              onClick={() =>
                                handleSelectedColor(item.productId, index)
                              }
                              style={
                                index ==
                                checkSelectedColorsAvailable(item.productId)
                                  ? {
                                      border:
                                        frame_color === "#FFFFFF"
                                          ? "2px solid #dad6d0"
                                          : `2px solid ${frame_color}`,
                                      padding: "1px",
                                      borderRadius: "50%",
                                    }
                                  : {}
                              }
                            >
                              {frame_color == "" ? (
                                <img
                                  src={canvasImage}
                                  width="auto"
                                  height="15"
                                />
                              ) : templeColor == "" && frame_color == "" ? (
                                <img
                                  src={canvasImage}
                                  width="auto"
                                  height="15"
                                />
                              ) : (
                                <Fragment>
                                  <span
                                    className="color-dot-first-half"
                                    style={{
                                      border:
                                        frame_color === "#FFFFFF"
                                          ? "2px solid #dad6d0"
                                          : "2px solid white",
                                      backgroundColor: `${frame_color}`,
                                    }}
                                  ></span>
                                  <span
                                    className="color-dot-second-half"
                                    style={{
                                      border:
                                        frame_color === "#FFFFFF"
                                          ? "2px solid #dad6d0"
                                          : "2px solid white",
                                      // border: "2px solid white",
                                      backgroundColor: `${
                                        frame_color != templeColor &&
                                        templeColor != ""
                                          ? templeColor
                                          : frame_color
                                      }`,
                                    }}
                                  ></span>
                                </Fragment>
                              )}
                            </li>
                          );
                        })}
                    </ul>
                    {/* )} */}
                    <div className="d-flex justify-content-center align-items-center mb-2">
                      <h5
                        onClick={() => handleProductView(item)}
                        className="card-heading"
                      >
                        {item.productName}
                      </h5>
                      <div className="product-price-text">
                        {/* <img src={RupeeIcon} /> */}
                        &#x20B9;
                        {item.netPrice.toFixed(2)}
                      </div>
                    </div>
                    {/* <div className="d-flex justify-content-center">
                    <p className="modal-code">CODE: {item.productCode}</p>
                    <div className="product-price-text">
                      <img src={RupeeIcon} />
                      {item.netPrice}
                    </div>
                  </div> */}
                    {/* <div className="product-card-overlay">
                    <div className="mt-5 product-buttons">
                      <Button
                        className="view-buuton mt-5"
                        onClick={() => handleProductView(item)}
                      >
                        {t("CATALOG.VIEW")}
                      </Button>
                      <Button
                        className="addcard-button mt-4"
                        onClick={() => handleAddToOrder(item)}
                      >
                        {t("CATALOG.ADD_TO_ORDER")}
                      </Button>
                    </div>
                  </div> */}
                  </div>
                  <div>
                    <div className="product-buttons">
                      <Button
                        className="view-buuton my-0"
                        onClick={() => handleProductView(item)}
                      >
                        {t("CATALOG.VIEW")}
                      </Button>
                      {isSalesExecutive && !localStorage.getItem("retailer") ? (
                        ""
                      ) : (
                        <Button
                          className="addcard-button my-0"
                          onClick={() => handleAddToOrder(item)}
                        >
                          {t("CATALOG.ORDER")}
                        </Button>
                      )}
                      {/* <Button
                        className="addcard-button my-0"
                        onClick={() =>
                          openVTORTC(item.modelName, variant, item.productId)
                        }
                      >
                        {t("CATALOG.TRY_ON")}
                      </Button> */}
                    </div>
                  </div>
                </Col>
              );
            })
          : ""}
      </Row>
    </>
  );
}

export default index;

index.propTypes = {
  OrderModal: PropTypes.func,
  CardData: PropTypes.object,
  modalData: PropTypes.func,
};
