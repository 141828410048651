import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import RupeeIcon from "../../../assets/Images/rupee_icon.png";
// import Delete from "../../../assets/Images/delete.svg";
import defaultImage from "../../../assets/Images/spec.png";
import ProductQuantity from "./productQuantity";
import DeleteModal from "../../../components/Deletepopup/index";
import _ from "lodash";

function ProductCard({
  productData,
  handleProductQuntity,
  getDeleteRequestedProductId,
  isUpdateQuantityRequesting,
  updateQuantity,
  setValidQuantity,
}) {
  const t = useTranslation();
  const [productInfo, setProductInfo] = useState();
  const [deleteCartId, setDeleteCartId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [quantityData, setQuantityData] = useState([]);
  const [productStockData, setProductStockData] = useState([]);
  const [quantityErrorData, setQuantityErrorData] = useState([]);
  const [maxLimitPerVariant, setMaxLimitPerVariant] = useState(0);

  const nextProps = useSelector((state) => ({
    UserProfileData: state.UserProfile.profileData?.data?.userProfile || null,
    availabileQuantity:
      state.GetInventory?.getInventoryByUserData?.data?.inventories || null,
    placedOrderData: state.PlaceOrder || null,
  }));
  const { isPlaceOrderRequesting } = nextProps.placedOrderData;
  useEffect(() => {
    if (
      nextProps.UserProfileData &&
      nextProps.UserProfileData.maxLimitPerVariant
    ) {
      setMaxLimitPerVariant(nextProps.UserProfileData.maxLimitPerVariant);
    }
  }, [nextProps.UserProfileData]);
  useEffect(() => {
    setProductInfo(productData);
  }, [productData]);
  useEffect(() => {
    setProductStockData(nextProps.availabileQuantity);
  }, [nextProps.availabileQuantity]);
  const handleDeleteItems = () => {
    setDeleteModal(true);
  };
  useEffect(() => {
    setQuantityData(updateQuantity);
  }, [updateQuantity]);

  const confirmDeleteProduct = (id) => {
    setDeleteCartId(id);
    getDeleteRequestedProductId(id);
    console.log("delete itesm id ", deleteCartId);
  };
  const handleError = (index, value) => {
    const presentindex = _.findIndex(quantityErrorData, {
      Id: index,
    });
    let temp = quantityErrorData;
    if (presentindex >= 0) {
      quantityErrorData[presentindex].Error = value;
    } else {
      temp.push({
        Id: index,
        Error: value,
      });
    }
    setQuantityErrorData(temp);
    const quantityError = _.find(quantityErrorData, {
      Error: true,
    });
    if (quantityError) {
      setValidQuantity(true);
    } else {
      setValidQuantity(false);
    }
  };
  return (
    <>
      <div className="d-none d-lg-block ">
        <Row className="purchase-product-heading">
          <Col lg="4"></Col>
          <Col lg="8">
            <Row className="text-center purchase-product-align-items">
              <Col lg="3">
                <p className="product-info-value-1">{t("CATALOG.COLOR")}</p>
              </Col>
              <Col lg="1" className="p-0">
                <p className="product-info-value-1">{t("CATALOG.SIZE")}</p>
              </Col>
              <Col lg="2">
                <p className="product-info-value-1">{t("CATALOG.PRICE")}</p>
              </Col>
              <Col lg="1" className="p-0">
                <p className="product-info-value-1">{t("CATALOG.DISCOUNT")}</p>
              </Col>
              <Col lg="3">
                <p className="product-info-value-1">{t("CATALOG.QUANTITY")}</p>
              </Col>
              <Col lg="2">
                <p className="product-info-value-1">
                  {t("CATALOG.TOTALPRICE")}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div>
        {productInfo?.map((item) => {
          let availabileQuantityData = _.find(productStockData, {
            colorVariant: item.colorVariant,
            productId: item.productID,
          });
          let availableStockQuantity =
            availabileQuantityData?.availableStockQuantity;
          return (
            <>
              <Row className="purchase-product mb-4 align-items-center">
                <Col lg="4">
                  <Row className="align-items-center">
                    <Col sm="12" md="12" lg="4" xs="12">
                      <div className="purchase-product-image">
                        <object data={item.image || defaultImage} />
                      </div>
                    </Col>
                    <Col lg="8" className="product-brand-align">
                      <span className="product-brand-name-1">
                        {item.productName}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col lg="8">
                  <Row className="text-center align-items-center">
                    <Col lg="3">
                      {item.productTypeID === 4 ? (
                        <p
                          className="product-info-value-1"
                          title={`${item.productColor}${
                            item.productColor !== item.productTempleColor &&
                            item.productTempleColor
                              ? "-" + item.productTempleColor
                              : ""
                          }`}
                        >
                          {item.colorVariant ? item.colorVariant : ""}
                        </p>
                      ) : (
                        <p
                          className="product-info-value-1"
                          title={`${item.productColor}${
                            item.productColor !== item.productTempleColor &&
                            item.productTempleColor
                              ? "-" + item.productTempleColor
                              : ""
                          }`}
                        >
                          {item.colorVariant ? item.colorVariant + " : " : ""}
                          {item.productColor}
                          {item.productColor !== item.productTempleColor &&
                          item.productTempleColor
                            ? "-" + item.productTempleColor
                            : ""}
                        </p>
                      )}
                    </Col>
                    <Col lg="1" className="p-0">
                      <p className="product-info-value-1">
                        {item.eyeSize && item.noseBridgeSize && item.armLength
                          ? `${item.eyeSize}-${item.noseBridgeSize}-${item.armLength}`
                          : "N/A"}
                      </p>
                    </Col>
                    <Col lg="2">
                      {item.isFree ? (
                        <p className="product-info-value-1">{"Free"}</p>
                      ) : (
                        <p className="product-info-value-1">
                          <img src={RupeeIcon} /> {item.productPrice}
                        </p>
                      )}
                    </Col>
                    <Col lg="1" className="p-0">
                      {item.isFree || item?.discount?.discountAmount <= 0 ? (
                        <p className="product-info-value-1">{"N/A"}</p>
                      ) : (
                        <p className="product-info-value-1">
                          <img src={RupeeIcon} />{" "}
                          {item?.discount?.discountAmount}
                          {<span className="discount-view-mobile"> Off</span>}
                        </p>
                      )}
                    </Col>
                    <Col lg="3">
                      <div className="direction-rtl product-quantity-position">
                        <ProductQuantity
                          quantityNumber={item.cartProductTotQuantity}
                          handleProductQuntity={handleProductQuntity}
                          cartProductDetailId={item.cartProductDetailId}
                          isUpdateQuantityRequesting={
                            isUpdateQuantityRequesting
                          }
                          costPriceWithoutGST={item.productPrice}
                          IGSTRate={item.IGSTRate}
                          CGSTRate={item.CGSTRate}
                          SGSTRate={item.SGSTRate}
                          totalPriceWithoutGST={item.cartProductTotPrice}
                          quantityData={quantityData}
                          index={item.cartProductDetailId}
                          availableStockQuantity={availableStockQuantity}
                          errorHandler={handleError}
                          maxLimitPerVariant={maxLimitPerVariant}
                          isPlaceOrderRequesting={isPlaceOrderRequesting}
                          isFree={item.isFree}
                        />
                      </div>
                    </Col>
                    <Col lg="2">
                      <label className="product-info-value-1 product-info-total-price-value">
                        <img src={RupeeIcon} /> {item.cartProductTotPrice}
                      </label>
                    </Col>

                    <div
                      onClick={() => {
                        handleDeleteItems();
                      }}
                    ></div>
                    {item.isFree ? (
                      ""
                    ) : (
                      <div>
                        <DeleteModal
                          deleteModal={deleteModal}
                          confirmDeleteProduct={confirmDeleteProduct}
                          cartId={item.cartProductDetailId}
                        />
                      </div>
                    )}
                  </Row>
                </Col>
              </Row>
            </>
          );
        })}
      </div>
    </>
  );
}

export default ProductCard;
ProductCard.propTypes = {
  productData: PropTypes.array,
  handleProductQuntity: PropTypes.func,
  getDeleteRequestedProductId: PropTypes.func,
  isUpdateQuantityRequesting: PropTypes.bool,
  updateQuantity: PropTypes.array,
  setValidQuantity: PropTypes.func,
};
