import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import BreadCrumbs from "../../../components/BreadCrumbs";
import ProductSort from "../ProductSort";
import PropTypes from "prop-types";
import { useTranslation } from "react-multi-lang";
import Filter from "../../../assets/Images/filter.png";
import Filterselect from "../../../assets/Images/filter-select.png";

function index(props) {
  const t = useTranslation();
  const [searchData, setSearchData] = useState();
  const {
    SortTypeList,
    selectedSortType,
    defaultBreadCrumbsdata,
    genderCheckedData,
    materilaCheckedData,
    enableClear,
    shapeCheckedData,
    filterOptionData,
    selectedFilter,
    clearFilterData,
    getFilterButtonSelected,
    productWebFilterEnableHandle,
  } = props;

  const [checkData, setCheckData] = useState({});
  const [selectedData, setSelectedData] = useState([]);

  useEffect(() => {
    setCheckData(selectedFilter);
  }, [selectedFilter]);

  useEffect(() => {
    if (
      shapeCheckedData &&
      shapeCheckedData.frameShapeId &&
      shapeCheckedData.frameShapeId.length
    ) {
      let checkedObject = {};
      shapeCheckedData.frameShapeId.map((item) => {
        if (checkedObject[Object.keys(shapeCheckedData)]) {
          checkedObject[Object.keys(shapeCheckedData)].push(item.toString());
        } else {
          checkedObject[Object.keys(shapeCheckedData)] = [item.toString()];
        }
      });
      let keyList = Object.keys(checkedObject);
      let slectedDataList = [];
      keyList?.map((key) => {
        filterOptionData?.filters?.map((data) => {
          if (data.key == key) {
            data?.data?.map((temp) => {
              checkedObject?.frameShapeId?.map((keyValue) => {
                if (keyValue == temp.id) {
                  slectedDataList.push({
                    name: temp.name,
                    id: temp.id,
                    key: "frameShapeId",
                  });
                }
              });
            });
          }
        });
      });
      setSelectedData(slectedDataList);
      setCheckData(checkedObject);
    }

    if (
      materilaCheckedData &&
      materilaCheckedData.frameMaterialId &&
      materilaCheckedData.frameMaterialId.length
    ) {
      let checkedObject = {};
      materilaCheckedData.frameMaterialId.map((item) => {
        if (checkedObject[Object.keys(materilaCheckedData)]) {
          checkedObject[Object.keys(materilaCheckedData)].push(item.toString());
        } else {
          checkedObject[Object.keys(materilaCheckedData)] = [item.toString()];
        }
      });
      let keyList = Object.keys(checkedObject);
      let slectedDataList = [];
      keyList?.map((key) => {
        filterOptionData?.filters?.map((data) => {
          if (data.key == key) {
            data?.data?.map((temp) => {
              checkedObject?.frameMaterialId?.map((keyValue) => {
                if (keyValue == temp.id) {
                  slectedDataList.push({
                    name: temp.name,
                    id: temp.id,
                    key: "frameMaterialId",
                  });
                }
              });
            });
          }
        });
      });
      setSelectedData(slectedDataList);
      setCheckData(checkedObject);
    }
    if (
      genderCheckedData &&
      genderCheckedData.genderIdList &&
      genderCheckedData.genderIdList.length
    ) {
      let genderObject = {};
      genderCheckedData.genderIdList.map((item) => {
        if (genderObject[Object.keys(genderCheckedData)]) {
          genderObject[Object.keys(genderCheckedData)].push(item.toString());
        } else {
          genderObject[Object.keys(genderCheckedData)] = [item.toString()];
        }
      });
      setCheckData(genderObject);
      let keyList = Object.keys(genderObject);
      let slectedDataList = [];
      keyList?.map((key) => {
        filterOptionData?.filters?.map((data) => {
          if (data.key == key) {
            data?.data?.map((temp) => {
              genderObject?.genderIdList?.map((keyValue) => {
                if (keyValue == temp.id) {
                  slectedDataList.push({
                    name: temp.name,
                    id: temp.id,
                    key: "genderIdList",
                  });
                }
              });
            });
          }
        });
      });
      setSelectedData(slectedDataList);
    }
  }, [
    shapeCheckedData,
    materilaCheckedData,
    genderCheckedData,
    filterOptionData,
  ]);

  const IsAvailblity = {
    key: "availablity",
    name: "Availability",
    type: "In",
    data: [
      {
        id: "1",
        name: "Don’t show out of stock items, Show available items only",
      },
    ],
  };

  useEffect(() => {
    if (filterOptionData) {
      filterOptionData?.filters.push(IsAvailblity);
    }
  }, [filterOptionData]);

  useEffect(() => {
    // setSelectedData([]);
    let keyList = Object.keys(selectedFilter);
    let slectedDataList = [];
    keyList?.map((key) => {
      filterOptionData?.filters?.map((data) => {
        if (data.key == key) {
          data?.data?.map((temp) => {
            if (
              key == "frameMaterialId" &&
              selectedFilter?.frameMaterialId?.length
            ) {
              selectedFilter?.frameMaterialId?.map((keyValue) => {
                if (keyValue == temp.id) {
                  slectedDataList.push({
                    name: temp.name,
                    id: temp.id,
                    key: "frameMaterialId",
                  });
                }
              });
            }
            if (key == "frameShapeId" && selectedFilter?.frameShapeId?.length) {
              selectedFilter?.frameShapeId?.map((keyValue) => {
                if (keyValue == temp.id) {
                  slectedDataList.push({
                    name: temp.name,
                    id: temp.id,
                    key: "frameShapeId",
                  });
                }
              });
            }
            if (key == "genderIdList" && selectedFilter?.genderIdList?.length) {
              selectedFilter?.genderIdList?.map((keyValue) => {
                if (keyValue == temp.id) {
                  slectedDataList.push({
                    name: temp.name,
                    id: temp.id,
                    key: "genderIdList",
                  });
                }
              });
            }
            if (key == "sizeId" && selectedFilter?.sizeId?.length) {
              selectedFilter?.sizeId?.map((keyValue) => {
                if (keyValue == temp.id) {
                  slectedDataList.push({
                    name: temp.name,
                    id: temp.id,
                    key: "sizeId",
                  });
                }
              });
            }
            if (
              key == "frameColorIdList" &&
              selectedFilter?.frameColorIdList?.length
            ) {
              selectedFilter?.frameColorIdList?.map((keyValue) => {
                if (keyValue == temp.id) {
                  slectedDataList.push({
                    name: temp.name,
                    id: temp.id,
                    key: "frameColorIdList",
                  });
                }
              });
            }
            if (key == "brandId" && selectedFilter?.brandId?.length) {
              selectedFilter?.brandId?.map((keyValue) => {
                if (keyValue == temp.id) {
                  slectedDataList.push({
                    name: temp.name,
                    id: temp.id,
                    key: "brandId",
                  });
                }
              });
            }
            if (
              key == "productTypeId" &&
              selectedFilter?.productTypeId?.length
            ) {
              selectedFilter?.productTypeId?.map((keyValue) => {
                if (keyValue == temp.id) {
                  slectedDataList.push({
                    name: temp.name,
                    id: temp.id,
                    key: "productTypeId",
                  });
                }
              });
            }
            if (key == "availablity" && selectedFilter?.availablity?.length) {
              selectedFilter?.availablity?.map((keyValue) => {
                if (keyValue == temp.id) {
                  slectedDataList.push({
                    name: temp.name,
                    id: temp.id,
                    key: "availablity",
                  });
                }
              });
            }
          });
        }
      });
    });
    let data = JSON.parse(JSON.stringify(slectedDataList));
    setSelectedData(data);
  }, [selectedFilter]);

  let tempSearch;
  let url_string = window.location.href;
  let url = new URL(url_string);
  if (url.searchParams.get("searchText")) {
    tempSearch = url.searchParams.get("searchText");
  }
  useEffect(() => {
    if (url.searchParams.get("searchText")) {
      setSearchData(url.searchParams.get("searchText"));
    } else {
      setSearchData("");
    }
  }, [tempSearch]);

  const handleSortTypeKey = (value) => {
    selectedSortType(value);
  };

  let queryData = checkData;
  const closeFilterOption = (key, id, name) => {
    let arr = JSON.parse(JSON.stringify(queryData[key]));
    arr = arr && arr.length && arr?.filter((item) => item != id);
    queryData[key] = JSON.parse(JSON.stringify(arr));
    setCheckData(JSON.parse(JSON.stringify(queryData)));
    getFilterButtonSelected(JSON.parse(JSON.stringify(queryData)));
    setSelectedData(selectedData.filter((data) => data.name != name));
  };

  const getSelectedItems = (data) => {
    let dataArray = [...data];
    return dataArray?.map((item) => {
      return (
        <span className="filtered-options" key={item.id + item.key}>
          {" "}
          {item.name}{" "}
          <span
            className="filtered-options-close"
            key={item.key + item.id}
            id={item.id + item.key}
            onClick={() => {
              closeFilterOption(item.key, item.id, item.name);
            }}
          >
            x
          </span>
        </span>
      );
    });
  };
  const handleModal = () => {
    productWebFilterEnableHandle(true);
  };
  const clearFilter = () => {
    clearFilterData();
  };

  return (
    <div className="pre-header-catalog">
      {/* {showFilterApplied()} */}
      <div className="footer-section-container pb-4 container-fluid">
        <Row className="mt-4">
          <Col xl="3" lg="4" sm="12" md="5" className="mt-2">
            <div>
              <BreadCrumbs defaultBreadCrumbsdata={defaultBreadCrumbsdata} />
            </div>
          </Col>
          <Col xl="5" lg="4" sm="9" md="4">
            <div>
              {searchData?.length ? (
                <p className="search-text">
                  {" "}
                  {t("CATALOG.SEARCH_TEXT")} {`"${searchData}"` || ""}
                </p>
              ) : (
                ""
              )}
            </div>
            <div>
              <ul>
                <li>{getSelectedItems(selectedData)}</li>
              </ul>
            </div>
          </Col>
          <Col lg="1" md="1" sm="1" className="filter-section-mp">
            <div className="clear-text-section">
              {enableClear ? (
                <span
                  className="clear-text clear-text-1"
                  onClick={() => {
                    clearFilter();
                  }}
                >
                  {t("CATALOG.CLEAR")}
                </span>
              ) : (
                ""
              )}
            </div>
            {enableClear ? (
              <div className="clear-text-section">
                <img
                  src={Filterselect}
                  height={40}
                  onClick={handleModal}
                  className="filter-icon-alignment"
                />
              </div>
            ) : (
              <div className="d-inline">
                <img
                  src={Filter}
                  height={40}
                  onClick={handleModal}
                  className="filter-icon-alignment float-right mx-2"
                />
              </div>
            )}
          </Col>
          {/* <span className="product-sort"> */}
          <Col lg="2" sm="2" md="2" className="product-sort">
            <ProductSort
              sortTypesData={SortTypeList}
              sortKey={handleSortTypeKey}
            />
          </Col>
          {/* </span> */}
        </Row>
      </div>
    </div>
  );
}

export default index;

index.propTypes = {
  SortTypeList: PropTypes.array,
  selectedSortType: PropTypes.func,
  defaultBreadCrumbsdata: PropTypes.object,
  materilaCheckedData: PropTypes.object,
  shapeCheckedData: PropTypes.object,
  genderCheckedData: PropTypes.object,
  filterOptionData: PropTypes.object,
  selectedFilter: PropTypes.object,
  enableClear: PropTypes.any,
  clearFilterData: PropTypes.any,
  getFilterButtonSelected: PropTypes.func,
  productWebFilterEnableHandle: PropTypes.func,
};
