import { BANNER_CONST } from "./bannerConst";

/**
 *  User banner actions
 */
export const getBanners = () => ({
  type: BANNER_CONST.BANNER_REQUEST,
  payload: null,
});

export const getBannersSuccess = (response) => ({
  type: BANNER_CONST.BANNER_SUCCESS,
  payload: response,
});

export const getBannersFailed = (error) => ({
  type: BANNER_CONST.BANNER_FAILED,
  payload: error,
});
