import { EXPRESS_CHECKOUT_CONST } from "./expressCheckoutConst";

export const getAllProduct = (payload) => ({
  type: EXPRESS_CHECKOUT_CONST.EXPRESS_CHECKOUT_PRODUCT_LIST_REQUESTING,
  payload: payload || null,
});

export const getAllProductSuccess = (response) => ({
  type: EXPRESS_CHECKOUT_CONST.EXPRESS_CHECKOU_PRODUCT_LIST_SUCCESS,
  payload: response,
});

export const getAllProductFailed = (error) => ({
  type: EXPRESS_CHECKOUT_CONST.EXPRESS_CHECKOUT_PRODUCT_LIST_FAILED,
  payload: error,
});

export const getInventoryByMultipleProducts = (payload) => ({
  type: EXPRESS_CHECKOUT_CONST.EXPRESS_CHECKOUT_MULTIPLE_PRODUCT_ID_REQUESTING,
  payload: payload || null,
});

export const getInventoryByMultipleProductIdSuccess = (response) => ({
  type: EXPRESS_CHECKOUT_CONST.EXPRESS_CHECKOUT_MULTIPLE_PRODUCT_ID_SUCCESS,
  payload: response,
});

export const getInventoryByMultipleProductIdFailed = (error) => ({
  type: EXPRESS_CHECKOUT_CONST.EXPRESS_CHECKOUT_MULTIPLE_PRODUCT_ID_FAILED,
  payload: error,
});
