import { BANNER } from "./../../../helpers/Constants";
import CarouselCaption from "../../../components/CarouselCaption";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";
import Slider from "../../../components/CarouselWrapper";

export default function Catalog({ catalogData }) {
  const buttonText = BANNER.CATALOG_BUTTON_TEXT;
  const data = {
    text: catalogData.length && catalogData[0].title,
    buttonText: buttonText,
    captionClassName: "catalogSectionTitle",
    buttonClassName: "catalogSectionButton",
  };
  return (
    <>
      <Container
        fluid={true}
        className="footer-section-container banner-section"
      >
        <Slider data={catalogData} />
        <div className="bannerText bannerText2 col-3">
          <CarouselCaption data={data} />
        </div>
      </Container>
    </>
  );
}

Catalog.propTypes = {
  catalogData: PropTypes.array,
};
