import { PROFILE_CONST } from "./profileConst";
/**
 *  Get user profile
 */

export const getUserProfile = (payload) => ({
  type: PROFILE_CONST.GET_USER_PROFILE_REQUEST,
  payload: payload || null,
});

export const getUserProfileSuccess = (response) => ({
  type: PROFILE_CONST.GET_USER_PROFILE_SUCCESS,
  payload: response,
});

export const getUserProfileFailed = (error) => ({
  type: PROFILE_CONST.GET_USER_PROFILE_FAILED,
  payload: error,
});

/**
 *  Get update user profile
 */

export const updateUserProfile = (payload) => ({
  type: PROFILE_CONST.UPDATE_USER_PROFILE_REQUEST,
  payload: payload || null,
});

export const updateUserProfileSuccess = (response) => ({
  type: PROFILE_CONST.UPDATE_USER_PROFILE_SUCCESS,
  payload: response,
});

export const updateUserProfileFailed = (error) => ({
  type: PROFILE_CONST.UPDATE_USER_PROFILE_FAILED,
  payload: error,
});

/**
 *  Get Countries
 */

export const getCountries = (payload) => ({
  type: PROFILE_CONST.GET_COUNTRIES_REQUEST,
  payload: payload || null,
});

export const getCountriesSuccess = (response) => ({
  type: PROFILE_CONST.GET_COUNTRIES_SUCCESS,
  payload: response,
});

export const getCountriesFailed = (error) => ({
  type: PROFILE_CONST.GET_COUNTRIES_FAILED,
  payload: error,
});

/**
 *  Get States
 */

export const getStates = (payload) => ({
  type: PROFILE_CONST.GET_STATES_REQUEST,
  payload: payload || null,
});

export const getStatesSuccess = (response) => ({
  type: PROFILE_CONST.GET_STATES_SUCCESS,
  payload: response,
});

export const getStatesFailed = (error) => ({
  type: PROFILE_CONST.GET_STATES_FAILED,
  payload: error,
});

/**
 *  Get Cities
 */

export const getCities = (payload) => ({
  type: PROFILE_CONST.GET_CITIES_REQUEST,
  payload: payload || null,
});

export const getCitiesSuccess = (response) => ({
  type: PROFILE_CONST.GET_CITIES_SUCCESS,
  payload: response,
});

export const getCitiesFailed = (error) => ({
  type: PROFILE_CONST.GET_CITIES_FAILED,
  payload: error,
});

/**
 *  Change Password
 */

export const changePasswordRequest = (payload) => ({
  type: PROFILE_CONST.CHANGE_PASSWORD_REQUEST,
  payload: payload || null,
});

export const changePasswordSuccess = (response) => ({
  type: PROFILE_CONST.CHANGE_PASSWORD_SUCCESS,
  payload: response,
});

export const changePasswordFailed = (error) => ({
  type: PROFILE_CONST.CHANGE_PASSWORD_FAILED,
  payload: error,
});

export const getRetailersListRequest = (payload) => ({
  type: PROFILE_CONST.GET_RETAILERS_LISTS_REQUEST,
  payload: payload || null,
});

export const getRetailersListSuccess = (response) => ({
  type: PROFILE_CONST.GET_RETAILERS_LISTS_SUCCESS,
  payload: response,
});

export const getRetailersListFailed = (error) => ({
  type: PROFILE_CONST.GET_RETAILERS_LISTS_FAILED,
  payload: error,
});
