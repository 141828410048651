import { AXIOS_INSTANCE, BASE_URL } from "../../api/apiEndPoint";

import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { SHAPES_CONST } from "./shapesConst";

import { getShapesSuccess, getShapesFailed } from "./shapesAction";
import { HomeClient } from "../apibenfranklin";
import { getToken, unAuthorizedRedirection } from "./../../helpers/Utility";

const client = new HomeClient(`${BASE_URL}`, AXIOS_INSTANCE);

/**
 *  Login
 */
const getShapesApiRequest = async () => {
  let response = {};
  let token = `bearer  ${getToken()}`;
  try {
    const data = await client.getFrameShapes(token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getShapes(action) {
  try {
    const apiResponse = yield call(getShapesApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getShapesSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getShapesFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getShapesFailed(errorData));
  }
}

export function* watchLoginUser() {
  yield takeEvery(SHAPES_CONST.SHAPES_CONST_REQUEST, getShapes);
}

function* shapesSaga() {
  yield all([fork(watchLoginUser)]);
}

export default shapesSaga;
