import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import PropTypes from "prop-types";

export default function BreadCrumbs(props) {
  const { defaultBreadCrumbsdata } = props;
  const breadCrumbList = [{ name: "Home", value: "/home" }];
  defaultBreadCrumbsdata.map((data) => {
    breadCrumbList.push(data);
  });
  return (
    <>
      <Breadcrumb>
        {breadCrumbList.map((item, index) => (
          <BreadcrumbItem key={index}>
            <a
              href={item.value}
              className={
                breadCrumbList.length - 1 === index
                  ? "bread-crumbs-active"
                  : "bread-crumbs"
              }
            >
              {item.name}
            </a>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </>
  );
}
BreadCrumbs.propTypes = {
  defaultBreadCrumbsdata: PropTypes.array,
};
