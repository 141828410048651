import { SHAPES_CONST } from "./shapesConst";

export const getShapesRequest = () => ({
  type: SHAPES_CONST.SHAPES_CONST_REQUEST,
  payload: null,
});

export const getShapesSuccess = (response) => ({
  type: SHAPES_CONST.SHAPES_CONST_SUCCESS,
  payload: response,
});

export const getShapesFailed = (error) => ({
  type: SHAPES_CONST.SHAPES_CONST_FAILED,
  payload: error,
});
