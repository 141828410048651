import { AXIOS_INSTANCE, BASE_URL } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery, throttle } from "redux-saga/effects";
import { FILTER_CONST } from "./filterConst";
import {
  getFiltersSuccess,
  getFiltersFailed,
  getAllSuccess,
  getAllFailed,
  getSortTypeSuccess,
  getSortTypeFailed,
  getProductByIdSuccess,
  getProductByIdFailed,
} from "./filterActions";
import { ProductClient } from "../apibenfranklin";
import { getToken, unAuthorizedRedirection } from "./../../helpers/Utility";

const client = new ProductClient(`${BASE_URL}`, AXIOS_INSTANCE);

/**
 *  getFilter API requesting
 */
const getFiltersApiRequest = async () => {
  let token = `Bearer ${getToken()}`;
  let response = {};
  try {
    const data = await client.getFilters(token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getFilters(action) {
  try {
    const apiResponse = yield call(getFiltersApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getFiltersSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getFiltersFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getFiltersFailed(errorData));
  }
}

/**
 *  getSortType API requesting
 */
const getSortTypeApiRequest = async () => {
  let token = `Bearer ${getToken()}`;
  let response = {};
  try {
    const data = await client.getSortTypes(token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getSortType(action) {
  try {
    const apiResponse = yield call(getSortTypeApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getSortTypeSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getSortTypeFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getSortTypeFailed(errorData));
  }
}

/**
 *  getAll API requesting
 */
const getAllApiRequest = async (payload) => {
  let token = `Bearer ${getToken()}`;
  let response = {};
  try {
    const data = await client.getAll(
      payload.search || null,
      payload.pageNumber || null,
      payload.pageSize || null,
      token
    );
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getAll(action) {
  try {
    const apiResponse = yield call(getAllApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getAllSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getAllFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllFailed(errorData));
  }
}

/**
 *  getProductByID API requesting
 */
const getProductByIdApiRequest = async (payload) => {
  let token = `Bearer ${getToken()}`;
  let response = {};
  try {
    const data = await client.get(payload.id || null, token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* get(action) {
  try {
    const apiResponse = yield call(getProductByIdApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getProductByIdSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getProductByIdFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getProductByIdFailed(errorData));
  }
}

export function* watchLoginUser() {
  yield takeEvery(FILTER_CONST.GET_FILTER_OPTION_REQUEST, getFilters);
  yield throttle(500, FILTER_CONST.GET_ALL_REQUESTING, getAll);
  yield takeEvery(FILTER_CONST.GET_SORT_TYPE_REQUESTING, getSortType);
  yield takeEvery(FILTER_CONST.GET_PRODUCT_BY_ID_REQUESTING, get);
}

function* filtersSaga() {
  yield all([fork(watchLoginUser)]);
}

export default filtersSaga;
