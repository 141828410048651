import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { useTranslation } from "react-multi-lang";
import PropTypes from "prop-types";

const SwitchUserSuccess = ({ isSwitched, successFullModal, currentUser }) => {
  const [modal, setModal] = useState(false);
  const t = useTranslation();
  useEffect(() => {
    setModal(successFullModal);
  }, [successFullModal]);
  const toggle = () => {
    isSwitched(!modal);
    setModal(!modal);
  };
  const closeModal = () => {
    setModal(false);
    isSwitched(false);
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody className="order-successfull p-5 pt-3">
          <h5 className="modal-product-name order-msg py-4">
            {t("PROFILE.SWITCHED_SUCCESSFULLY")}
            {`"${currentUser?.businessName}"`}
          </h5>
          <Button className="place-order-btn" onClick={closeModal}>
            {t("PROFILE.OK")}
          </Button>
        </ModalBody>
      </Modal>
    </div>
  );
};

SwitchUserSuccess.propTypes = {
  isSwitched: PropTypes.func,
  successFullModal: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default SwitchUserSuccess;
