import "./NewsRoomPage.scss";

import NewsDetailsImg from "../../assets/Images/news-details.jpg";
import React from "react";
import newsroombanner from "./../../assets/Images/news-roomdesktop.jpg";
import newsroommobile from "../../assets/Images/news-roommobile.jpg";
import { useScreenSize } from "../../hooks/useScreenSize";

const NewsRoomPage = () => {
  const screenSize = useScreenSize();
  document.title = "Eyegear";
  return (
    <>
      <div className="image-section">
        <img
          src={
            screenSize.currentScreenConfig.width > 767
              ? newsroombanner
              : newsroommobile
          }
          style={{ width: "100%" }}
          alt="newsroom banner"
          className="image"
        />
      </div>
      <section className="section__padding news__room">
        <div className="news__room__title-para">
          <h3>EyeGear News Room</h3>
          <p>
            Welcome to the Eyegear News Room. Here you will find the latest news
            on Eyegear Optics India Pvt ltd, our two brands Ben Franklin and
            Eyegear B2B. If you are a journalist and need more informations,
            please send us an email to{" "}
            <a
              href="mailto:corporate@eyegear.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              corporate@eyegear.com
            </a>
            . We would be happy to help.
          </p>
        </div>
        <div className="news__room__container">
          <div className="news-details">
            <div className="news-internal1">
              <img
                src={NewsDetailsImg}
                alt="news-detail"
                style={{ height: "200px" }}
              />
            </div>
            <div className="news-internal2">
              <dd>
                <a
                  href="https://www.financialexpress.com/industry/eyewear-brands-reaching-and-engaging-retailers-and-consumers/3163580/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Eyewear Brands: Reaching and engaging retailers and consumers
                </a>
              </dd>
              <sm>July 10, 2023</sm>
              <dl>
                <a
                  href="https://www.financialexpress.com/industry/eyewear-brands-reaching-and-engaging-retailers-and-consumers/3163580/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Raj Pyla in Financial Express
                </a>{" "}
              </dl>
              <strong>
                <a
                  href="https://www.financialexpress.com/industry/eyewear-brands-reaching-and-engaging-retailers-and-consumers/3163580/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Press Release
                </a>
              </strong>
            </div>
          </div>
          <div className="news-details">
            <div className="news-internal1">
              <img
                src={NewsDetailsImg}
                alt="news-detail"
                style={{ height: "200px" }}
              />
            </div>
            <div className="news-internal2">
              <dd>
                <a
                  href="https://www.indianretailer.com/article/retail-business/retail-trends/future-b2b-eyewear-distribution"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Future of Eyewear Industry in India : B2B Distribution
                </a>
              </dd>
              <sm>July 08, 2023 </sm>
              <dl>Raj Pyla in Indian Retailer</dl>
              <strong>
                <a
                  href="https://www.indianretailer.com/article/retail-business/retail-trends/future-b2b-eyewear-distribution"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Press Release
                </a>
              </strong>
            </div>
          </div>
        </div>
        <div className="news__room__container">
          <div className="news-details">
            <div className="news-internal1">
              <img
                src={NewsDetailsImg}
                alt="news-detail"
                style={{ height: "200px" }}
              />
            </div>
            <div className="news-internal2">
              <dd>
                <a
                  href="https://timesofindia.indiatimes.com/blogs/voices/the-importance-of-eye-health/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The importance of eye health
                </a>
              </dd>
              <sm>June 22, 2023</sm>
              <dl>
                <a
                  href="https://timesofindia.indiatimes.com/blogs/author/raj-pyla/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Raj Pyla
                </a>{" "}
                in Voices, Business, TOI
              </dl>
              <strong>
                <a
                  href="https://timesofindia.indiatimes.com/blogs/voices/the-importance-of-eye-health/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Press Release
                </a>
              </strong>
            </div>
          </div>
          <div className="news-details">
            <div className="news-internal1">
              <img
                src={NewsDetailsImg}
                alt="news-detail"
                style={{ height: "200px" }}
              />
            </div>
            <div className="news-internal2">
              <dd>
                <a
                  href="https://www.adgully.com/how-technology-is-proving-to-be-a-game-changer-for-the-eyewear-industry-133235.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  How technology is proving to be a game-changer for the eyewear
                  industry
                </a>
              </dd>
              <sm>June 21, 2023</sm>
              <dl>Raj Pyla in Adgully</dl>
              <strong>
                <a
                  href="https://www.adgully.com/how-technology-is-proving-to-be-a-game-changer-for-the-eyewear-industry-133235.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Press Release
                </a>
              </strong>
            </div>
          </div>
        </div>
        <div className="news__room__container">
          <div className="news-details">
            <div className="news-internal1">
              <img
                src={NewsDetailsImg}
                alt="news-detail"
                style={{ height: "200px" }}
              />
            </div>
            <div className="news-internal2">
              <dd>
                <a
                  href="https://mediabrief.com/exclusive-raj-pyla-eyegear-optics/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Exclusive | Raj Pyla, EyeGear Optics: Changing lifestyles,
                  growing awareness, and high disposable incomes drive India’s
                  eyewear industry growth
                </a>
              </dd>
              <sm>June 19, 2023</sm>
              <dl>Raj Pyla in MediaBrief</dl>
              <strong>
                <a
                  href="https://mediabrief.com/exclusive-raj-pyla-eyegear-optics/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Press Release
                </a>
              </strong>
            </div>
          </div>
          <div className="news-details">
            <div className="news-internal1">
              <img
                src={NewsDetailsImg}
                alt="news-detail"
                style={{ height: "200px" }}
              />
            </div>
            <div className="news-internal2">
              <dd>
                <a
                  href="http://pharmabiz.com/NewsDetails.aspx?aid=159525&sid=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dearth of qualified opticians with low-quality equipment and
                  eye testing facilities plague eye care industry
                </a>
              </dd>
              <sm>June 3, 2023</sm>
              <dl>Raj Pyla in Pharmabiz</dl>
              <strong>
                <a
                  href="http://pharmabiz.com/NewsDetails.aspx?aid=159525&sid=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Press Release
                </a>
              </strong>
            </div>
          </div>
        </div>
        <div className="news__room__container">
          <div className="news-details">
            <div className="news-internal1">
              <img
                src={NewsDetailsImg}
                alt="news-detail"
                style={{ height: "200px" }}
              />
            </div>
            <div className="news-internal2">
              <dd>
                <a
                  href="https://www.expresshealthcare.in/news/problems-faced-by-eye-hospitals-and-how-strategic-collaborations-with-eyewear-brands-can-help-bridge-the-gap/438849/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Problems faced by eye hospitals and how strategic
                  collaborations with eyewear brands can help bridge the gap
                </a>
              </dd>
              <sm>April 14, 2023</sm>
              <dl>Raj Pyla in Express Healthcare</dl>
              <strong>
                <a
                  href="https://www.expresshealthcare.in/news/problems-faced-by-eye-hospitals-and-how-strategic-collaborations-with-eyewear-brands-can-help-bridge-the-gap/438849/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Press Release
                </a>
              </strong>
            </div>
          </div>
          <div className="news-details">
            <div className="news-internal1">
              <img
                src={NewsDetailsImg}
                alt="news-detail"
                style={{ height: "200px" }}
              />
            </div>
            <div className="news-internal2">
              <dd>
                <a
                  href="http://pharmabiz.com/NewsDetails.aspx?aid=159525&sid=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  EyeGear Optics India: Efficiency, style, and creative
                  destruction
                </a>
              </dd>
              <sm>February 21, 2023</sm>
              <dl>Raj Pyla in MediaBrief</dl>
              <strong>
                <a
                  href="http://pharmabiz.com/NewsDetails.aspx?aid=159525&sid=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Press Release
                </a>
              </strong>
            </div>
          </div>
        </div>
        <div className="news__room__container">
          <div className="news-details">
            <div className="news-internal1">
              <img
                src={NewsDetailsImg}
                alt="news-detail"
                style={{ height: "200px" }}
              />
            </div>
            <div className="news-internal2">
              <dd>
                <a
                  href="https://www.bizzbuzz.news/bizz-talk/eyegear-optics-aims-to-achieve-a-revenue-of-rs1200-cr-by-2027-1197638"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  EyeGear Optics aims to achieve a revenue of Rs1,200 cr by 2027
                </a>
              </dd>
              <sm>February 12, 2023</sm>
              <dl>Raj Pyla in Bizz Buzz News</dl>
              <strong>
                <a
                  href="https://www.bizzbuzz.news/bizz-talk/eyegear-optics-aims-to-achieve-a-revenue-of-rs1200-cr-by-2027-1197638"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Press Release
                </a>
              </strong>
            </div>
          </div>
          <div className="news-details">
            <div className="news-internal1">
              <img
                src={NewsDetailsImg}
                alt="news-detail"
                style={{ height: "200px" }}
              />
            </div>
            <div className="news-internal2">
              <dd>
                <a
                  href="https://everythingexperiential.businessworld.in/article/Making-Experiential-Marketing-Work-For-Your-Brand/10-02-2023-465115/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Making Experiential Marketing work For your Brand
                </a>
              </dd>
              <sm>February 10, 2023</sm>
              <dl>Raj Pyla in Business World</dl>
              <strong>
                {" "}
                <a
                  href="https://everythingexperiential.businessworld.in/article/Making-Experiential-Marketing-Work-For-Your-Brand/10-02-2023-465115/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Press Release
                </a>
              </strong>
            </div>
          </div>
        </div>
        <div className="news__room__container">
          <div className="news-details">
            <div className="news-internal1">
              <img
                src={NewsDetailsImg}
                alt="news-detail"
                style={{ height: "200px" }}
              />
            </div>
            <div className="news-internal2">
              <dd>
                <a
                  href="https://www.financialexpress.com/brand-initiative/startup-reporter-acknowledges-business-icons-of-india-under-75-saal-azadi-ka-amrit-mahotsav/2942460/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Business Icons of India under “75 Saal Azadi Ka Amrit
                  Mahotsav”Business Icons of India under “75 Saal Azadi Ka Amrit
                  Mahotsav”
                </a>
              </dd>
              <sm>January 10, 2023</sm>
              <strong>
                <a
                  href="https://www.financialexpress.com/brand-initiative/startup-reporter-acknowledges-business-icons-of-india-under-75-saal-azadi-ka-amrit-mahotsav/2942460/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Press Release
                </a>
              </strong>
            </div>
          </div>
          <div className="news-details">
            <div className="news-internal1">
              <img
                src={NewsDetailsImg}
                alt="news-detail"
                style={{ height: "200px" }}
              />
            </div>
            <div className="news-internal2">
              <dd>
                <a
                  href="https://timesofindia.indiatimes.com/blogs/voices/the-year-gone-by-a-sneak-peek-at-what-to-expect-from-2023-within-the-indian-eyewear-sector/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The year gone by & a sneak peek at what to expect from 2023
                  within the Indian eyewear sector
                </a>
              </dd>
              <sm>December 31, 2022</sm>
              <dl>
                <a
                  href="https://timesofindia.indiatimes.com/blogs/author/raj-pyla/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Raj Pyla
                </a>{" "}
                in Voices, Business, TOI
              </dl>
              <strong>
                <a
                  href="https://timesofindia.indiatimes.com/blogs/voices/the-year-gone-by-a-sneak-peek-at-what-to-expect-from-2023-within-the-indian-eyewear-sector/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Press Release
                </a>
              </strong>
            </div>
          </div>
        </div>
        <div className="news__room__container">
          <div className="news-details">
            <div className="news-internal1">
              <img
                src={NewsDetailsImg}
                alt="news-detail"
                style={{ height: "200px" }}
              />
            </div>
            <div className="news-internal2">
              <dd>
                <a
                  href="https://www.ptinews.com/pti/India-s-leading-eyewear-company-EyeGear-Optics-projects-a-revenue-of-USD-150-million-per-annum-by-2027--catering--to-10--million-customers-every-year/59195.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  EyeGear Optics projects a revenue of USD 150 million per annum
                  by 2027, catering to 10+ million customers every year
                </a>
              </dd>
              <sm>December 27, 2022</sm>
              <dl>Raj Pyla in PTI</dl>
              <strong>
                <a
                  href="https://www.ptinews.com/pti/India-s-leading-eyewear-company-EyeGear-Optics-projects-a-revenue-of-USD-150-million-per-annum-by-2027--catering--to-10--million-customers-every-year/59195.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Press Release
                </a>
              </strong>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsRoomPage;
