import { PRICES_CONST } from "./pricesConst";

export const getPricesRequest = () => ({
  type: PRICES_CONST.PRICES_CONST_REQUEST,
  payload: null,
});

export const getPricesSuccess = (response) => ({
  type: PRICES_CONST.PRICES_CONST_SUCCESS,
  payload: response,
});

export const getPricesFailed = (error) => ({
  type: PRICES_CONST.PRICES_CONST_FAILED,
  payload: error,
});
