import { ADDRESS_DETAILS } from "./adressDetailsConst";

export const getAddressDetails = (payload) => ({
  type: ADDRESS_DETAILS.ADDRESS_DETAILS_REQUESTING,
  payload: payload || null,
});

export const getAddressDetailsSuccess = (response) => ({
  type: ADDRESS_DETAILS.ADDRESS_DETAILS_SUCCESS,
  payload: response,
});

export const getAddressDetailsFailed = (error) => ({
  type: ADDRESS_DETAILS.ADDRESS_DETAILS_FAILED,
  payload: error,
});
