import React, { useState, useEffect, useRef, Fragment } from "react";
import { Accordion } from "react-bootstrap";
import { Row, Col, Input, Button } from "reactstrap";
import Delete from "../../../../assets/Images/delete.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "../../../../redux/filters/filterActions";
import { getInventory } from "../../../../redux/inventory/inventoryAction";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import ExpressCheckoutQuantity from "./ExpressCheckoutQuantity";
import PropTypes from "prop-types";
import { useTranslation } from "react-multi-lang";
import _ from "lodash";
import ClipLoader from "react-spinners/ClipLoader";
import canvasImage from "../../../../assets/Images/canvasImage.png";

function ProductCollapse({ getAddToOrderItem, setButton }) {
  const dispatch = useDispatch();
  const t = useTranslation();
  const [inputEnable, setInputEnable] = useState(true);
  const [itemCode, setItemCode] = useState("");
  const [productDetails, setProductDetails] = useState([]);
  const [error, setError] = useState("");
  const [conirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState("");
  const [orderRequestList, setOrderRequestList] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [hideFilteredOption, setHideFilteredOption] = useState(false);
  const [data, setData] = useState([]);
  const [productId, setProductId] = useState("");
  const [errorData, setErrorData] = useState([]);
  const [availabilityDetails, setAvailabilityDetails] = useState([]);

  const nextProps = useSelector((state) => ({
    ProductsData: state.FilterOption || null,
    expressCheckoutAllProductData: state.getAllProduct || null,
    availabileQuantity:
      state.GetInventory?.getInventoryData?.data?.inventories || null,
    UserProfileData: state.UserProfile.profileData?.data?.userProfile || null,
  }));

  const { getAllData, isGetAllRequesting } = nextProps.ProductsData;
  const { expressOrderAllProductData } =
    nextProps.expressCheckoutAllProductData;

  useEffect(() => {
    if (searchData?.length) {
      let filteredOption = data?.filter((option) =>
        option.productName.toLowerCase().includes(searchData.toLowerCase())
      );
      setFilteredData(filteredOption);
      filteredOption.length
        ? setError("")
        : setError("Invalid Model Number. Please enter a valid Model Number");
    } else {
      setFilteredData([]);
    }
  }, [searchData]);

  useEffect(() => {
    if (expressOrderAllProductData) {
      setData(expressOrderAllProductData.searchResults.value);
    }
  }, [expressOrderAllProductData]);

  useEffect(() => {
    if (itemCode.length) {
      dispatch(getInventory({ productId: productId }));
      dispatch(
        getAll({
          search: `modelName=${itemCode}`,
          pageNumber: 1,
          pageSize: 12,
        })
      );
    }
  }, [itemCode]);

  const firstrun = useRef(true);
  let productList = productDetails;
  useEffect(() => {
    if (firstrun.current) {
      firstrun.current = false;
      return;
    }
    if (getAllData) {
      let modalNo = getAllData?.data?.searchResults?.value[0]?.modelName;
      getAllData?.data?.searchResults?.value.length == 0
        ? setError("Invalid Model Number. Please enter a valid Model Number")
        : "";
      if (productList.length) {
        if (!productList?.filter((data) => data.modelName == modalNo).length) {
          getAllData?.data?.searchResults?.value?.length
            ? productList.push(getAllData?.data?.searchResults?.value[0])
            : "";
          getAllData?.data?.searchResults?.value?.length
            ? setInputEnable(false)
            : "";
          getAllData?.data?.searchResults?.value?.length ? setError("") : "";
          getAllData?.data?.searchResults?.value?.length
            ? setFilteredData([])
            : "";
        } else {
          setError("Product already added. Please add different product");
        }
      } else {
        getAllData?.data?.searchResults?.value?.length
          ? productList.push(getAllData?.data?.searchResults?.value[0])
          : "";
        getAllData?.data?.searchResults?.value?.length
          ? setInputEnable(false)
          : "";
        getAllData?.data?.searchResults?.value?.length ? setError("") : "";
        getAllData?.data?.searchResults?.value?.length
          ? setFilteredData([])
          : "";
      }
      let newData = JSON.parse(JSON.stringify(productList));
      setProductDetails(newData);
    }
  }, [getAllData]);

  useEffect(() => {
    if (nextProps.availabileQuantity) {
      let tempAvailability = availabilityDetails;
      let availableIndex = -1;
      tempAvailability.forEach((item, index) => {
        if (item[0].productId == nextProps.availabileQuantity[0].productId) {
          availableIndex = index;
        }
      });
      if (availableIndex >= 0) {
        tempAvailability[availableIndex] = nextProps.availabileQuantity;
      } else {
        tempAvailability.push(nextProps.availabileQuantity);
      }
      setAvailabilityDetails(tempAvailability);
    }
  }, [nextProps.availabileQuantity]);

  useEffect(() => {
    getAddToOrderItem(orderRequestList);
  }, [orderRequestList]);

  const testDebounce = _.debounce((val) => {
    setSearchData(val);
  }, 1000);

  const getProductDetails = (e) => {
    e.stopPropagation();
    e.preventDefault();
    testDebounce(e.target.value);
    setHideFilteredOption(false);
    setError("");
  };

  const getColor = (productData) => {
    return JSON.parse(productData?.productColor).map((colorData, index) => {
      return (
        <li className="ml-2" key={`color${index}`}>
          <span className="product-color-dot">
            {colorData.frameColorCode == "" ? (
              <img src={canvasImage} width="auto" height="14.9" />
            ) : colorData.templeColorCode == "" &&
              colorData.frameColorCode == "" ? (
              <img src={canvasImage} width="auto" height="14.9" />
            ) : (
              <Fragment>
                <span
                  className="color-dot-express-first-half"
                  style={{
                    border:
                      colorData.frameColorCode === "#FFFFFF"
                        ? "2px solid #dad6d0"
                        : "2px solid white",
                    backgroundColor: `${colorData.frameColorCode}`,
                  }}
                ></span>
                <span
                  className="color-dot-express-second-half"
                  style={{
                    border:
                      colorData.frameColorCode === "#FFFFFF"
                        ? "2px solid #dad6d0"
                        : "2px solid white",
                    backgroundColor: `${
                      colorData.templeColorCode !== colorData.frameColorCode &&
                      colorData.templeColorCode != ""
                        ? colorData.templeColorCode
                        : colorData.frameColorCode
                    }`,
                  }}
                ></span>
              </Fragment>
            )}
          </span>
        </li>
      );
    });
  };

  const handleError = (productColorId, value) => {
    let tempErrorData = errorData;
    let errorIndex = _.findIndex(errorData, {
      productColorID: productColorId,
    });
    if (errorIndex >= 0) {
      tempErrorData[errorIndex].error = value;
    } else {
      tempErrorData.push({
        productColorID: productColorId,
        error: value,
      });
    }
    setErrorData(tempErrorData);

    let buttonError = _.find(errorData, {
      error: true,
    });
    if (buttonError) {
      setButton(true);
    } else {
      setButton(false);
    }
  };
  const handleQuantity = (e, colorVariant, productId) => {
    let temporayList = orderRequestList;
    let filterData = temporayList?.filter((data) => {
      if (data.ProductId == productId) {
        if (data.ColorVariant == colorVariant) {
          return;
        } else {
          return data;
        }
      } else {
        return data;
      }
    });
    temporayList = JSON.parse(JSON.stringify(filterData));
    temporayList.push({
      ProductId: productId,
      ColorVariant: colorVariant,
      Quantity: parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0,
    });
    let newData = JSON.parse(JSON.stringify(temporayList));
    setOrderRequestList(newData);
  };

  const renderColorList = (productData, availabileQuantity) => {
    let availabileColorList = JSON.parse(productData?.productColor).sort(
      (a, b) =>
        parseInt(a.colorVariant.substring(1)) >
        parseInt(b.colorVariant.substring(1))
          ? 1
          : parseInt(b.colorVariant.substring(1)) >
            parseInt(a.colorVariant.substring(1))
          ? -1
          : 0
    );
    let availabileProducts = availabileColorList.map((item) => {
      let availableProductQuantity = _.find(availabileQuantity, {
        colorVariant: item.colorVariant,
      });
      item.availableStockQuantity =
        availableProductQuantity?.availableStockQuantity;
      return item;
    });
    let unAvailableStock = availabileProducts.filter((item) => {
      return item.availableStockQuantity <= 0;
    });
    let totalVariants = JSON.parse(productData?.productColor).length;
    if (unAvailableStock.length == totalVariants) {
      return (
        <Row className="d-flex flex-row justify-content-center p-5">
          <span className="quantity-error-message">
            {t("PRODUCT_DETAILS.PRODUCT_UNAVAILABLE")}
          </span>
        </Row>
      );
    }
    return availabileProducts.map((colorData, index) => {
      let productQuantitity = _.find(orderRequestList, {
        ProductId: productData.productId,
        ColorVariant: colorData.colorVariant,
      });

      if (colorData?.availableStockQuantity > 0) {
        return (
          <li className="li-padding mt-2 d-flex" key={`color${index}`}>
            <ExpressCheckoutQuantity
              colorData={colorData}
              handleQuantity={handleQuantity}
              availabileQuantity={colorData?.availableStockQuantity}
              productId={productData.productId}
              handleError={handleError}
              quantity={productQuantitity?.Quantity}
              productTypeId={productData.productTypeId}
            />
          </li>
        );
      }
    });
  };

  const totalSelectedQuantity = (productId) => {
    let totlaQuantity = 0;
    orderRequestList
      ?.filter((productItems) => productItems.ProductId == productId)
      .map((data) => {
        totlaQuantity = parseInt(totlaQuantity) + parseInt(data.Quantity);
      });
    return totlaQuantity;
  };

  const productPage = (e, params) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(params);
  };

  const renderProductList = () => {
    return productDetails?.map((productData, index) => {
      let productAvailable = -1;
      availabilityDetails.forEach((item, indexId) => {
        if (item[0].productId == productData.productId) {
          productAvailable = indexId;
        }
      });
      let selectedProduct = availabilityDetails[productAvailable];
      let discounts = productData.discounts
        ? JSON.parse(productData.discounts)
        : productData.discounts;
      let availableDiscountList = _.filter(discounts, (discount) => {
        return (
          discount.StateId === nextProps.UserProfileData?.stateId.toString()
        );
      });

      if (!availableDiscountList.length) {
        availableDiscountList = _.filter(discounts, (discount) => {
          return discount.StateId === "";
        });
      }

      let availableDiscount = {};
      let tempDiscountValue = 0;
      availableDiscountList?.length &&
        availableDiscountList.map((discount) => {
          if (
            discount.UsePercentage.toString() === "1" &&
            parseInt(discount?.DiscountPercentage) > 0
          ) {
            let discountValue =
              (productData.netPrice * discount.DiscountPercentage) / 100;
            if (
              parseInt(tempDiscountValue) < parseInt(discountValue) &&
              parseInt(discountValue) < parseInt(productData.netPrice) &&
              (discount.PriceFrom
                ? parseInt(discount.PriceFrom) <= parseInt(productData.netPrice)
                : true) &&
              (discount.PriceTo
                ? parseInt(discount.PriceTo) >= parseInt(productData.netPrice)
                : true)
            ) {
              tempDiscountValue = discountValue;
              availableDiscount = discount;
            }
          } else {
            let discountValue = discount.DiscountAmount;
            if (
              parseInt(tempDiscountValue) < parseInt(discountValue) &&
              parseInt(discountValue) < parseInt(productData.netPrice) &&
              (discount.PriceFrom
                ? parseInt(discount.PriceFrom) <= parseInt(productData.netPrice)
                : true) &&
              (discount.PriceTo
                ? parseInt(discount.PriceTo) >= parseInt(productData.netPrice)
                : true)
            ) {
              tempDiscountValue = discountValue;
              availableDiscount = discount;
            }
          }
        });
      return (
        <Accordion.Item
          eventKey={`accordition${index}`}
          key={`accordition${index}`}
          className="mb-2"
        >
          <Accordion.Header>
            <Row className="remove-gutter-x">
              <Col sm="2" className="align-items-center">
                <span className="mr-1 fw-bold modal-name-express-checkout">
                  {productData?.modelName}
                </span>
              </Col>
              <>
                <Col sm="2" className="align-items-center">
                  <a
                    href="product-details?productId=50"
                    onClick={(e) =>
                      productPage(
                        e,
                        `/product-details?productId=${productData?.productId}`
                      )
                    }
                    className="product-link"
                  >
                    {productData?.productName}
                  </a>
                </Col>
                <Col sm="1" className="align-items-center fw-bold">
                  <span> &#8377; {productData?.netPrice.toFixed(2) || ""}</span>
                </Col>
                <Col sm="1" className="align-items-center fw-bold">
                  <span>
                    {availableDiscount && productData.productTypeId !== 4
                      ? availableDiscount?.UsePercentage?.toString() === "1" &&
                        parseInt(availableDiscount?.DiscountPercentage) > 0
                        ? `${parseInt(availableDiscount.DiscountPercentage)} %`
                        : availableDiscount?.UsePercentage?.toString() ===
                            "0" &&
                          parseInt(availableDiscount?.DiscountAmount) > 0
                        ? `̥₹ ${parseFloat(
                            availableDiscount.DiscountAmount
                          ).toFixed(2)}`
                        : "N/A"
                      : "N/A"}
                  </span>
                </Col>
                <Col sm="2" className="align-items-center">
                  <span className="color-allignment-span">
                    <ul className="d-flex color-allignment">
                      {getColor(productData)}
                    </ul>
                  </span>
                </Col>
                <Col sm="1" className="align-items-center">
                  <span className="fw-bold">
                    {productData.productTypeId === 4
                      ? "N/A"
                      : `${productData.eyeSize + "-"}` +
                        `${productData.noseBridgeSize + "-"}` +
                        `${productData.armLength}`}
                  </span>
                </Col>
                <Col sm="1" className="align-items-center fw-bold">
                  <span>
                    {productData.productTypeId === 4
                      ? "N/A"
                      : productData?.frameMaterialName || ""}{" "}
                  </span>
                </Col>
                <Col sm="1" className="align-items-center">
                  <span className="total-quantity">
                    {totalSelectedQuantity(productData?.productId) || 0}
                  </span>
                </Col>
                <Col sm="1" className="align-items-center">
                  <div className="product-delete-button-express-order">
                    <span
                      className="ml-auto"
                      onClick={(e) => {
                        deleteProduct(productData?.productId, e);
                      }}
                    >
                      <img src={Delete} height={15} width="auto" />
                    </span>
                  </div>
                </Col>
              </>
            </Row>
          </Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col sm="6">
                <div className="express-order-product-delete-button">
                  <img
                    src={productData?.productImageList[0]}
                    height={150}
                    width="auto"
                    className="margin-auto-right"
                  />
                </div>
              </Col>
              <Col sm="6">
                <ul className="align-left-input-express-checkout">
                  {renderColorList(productData, selectedProduct)}
                </ul>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      );
    });
  };

  const enableInput = () => {
    setInputEnable(true);
    setError("");
    setItemCode("");
    setProductId("");
  };

  const confirmationHandle = (deleteStatus, modalStatus, productId) => {
    setConfirmationModalOpen(modalStatus);
    if (deleteStatus) {
      setProductDetails(
        productDetails?.filter((data) => data.productId != productId)
      );
      let data = orderRequestList?.filter((data) => {
        if (data.ProductId != productId) {
          return data;
        }
      });
      setOrderRequestList(JSON.parse(JSON.stringify(data)));
    }
  };

  const deleteProduct = (productId, e) => {
    e.preventDefault();
    e.stopPropagation();
    setConfirmationModalOpen(true);
    setDeleteProductId(productId);
  };

  const selectedOption = (e, modelNumber, name, productId) => {
    e.preventDefault();
    e.stopPropagation();
    setHideFilteredOption(true);
    setSearchData(name);
    setItemCode(modelNumber);
    setProductId(productId);
  };

  const getFilteredList = () => {
    if (!filteredData.length) {
      return;
    }

    return (
      filteredData.length &&
      filteredData?.map((option) => {
        if (option?.isAvailable) {
          return (
            <>
              <li
                key={option.productId}
                id={option.modelName}
                onClick={(e) => {
                  selectedOption(
                    e,
                    option.modelName,
                    option.productName,
                    option.productId
                  );
                }}
              >
                {option.productName}
              </li>
            </>
          );
        } else {
          return;
        }
      })
    );
  };

  return (
    <div className="product-list-collspse-screen">
      {setConfirmationModalOpen ? (
        <ConfirmationModal
          confirmationId={deleteProductId}
          confirmationModalStatus={conirmationModalOpen}
          confirmationHandle={confirmationHandle}
        />
      ) : (
        ""
      )}
      <Accordion>
        {renderProductList()}
        {inputEnable ? (
          <Accordion.Item eventKey="0" className="mb-2">
            <Accordion.Header className="accordition-rotate" disabled="true">
              <Row className="remove-gutter-x">
                <Col sm="3" className="align-items-center">
                  <span className="input-proper-allignmen mr-1 d-flex">
                    {inputEnable ? (
                      <>
                        <Input
                          type="text"
                          className="item-code"
                          // value={searchData}
                          onChange={(e) => getProductDetails(e)}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          list="productList"
                          name="productList"
                          id="productList"
                          disabled={isGetAllRequesting}
                        />
                        {isGetAllRequesting ? (
                          <span className="input-loader-express-checkout">
                            <ClipLoader color="#14467c" size={15} />
                          </span>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      "Item Code"
                    )}
                  </span>
                </Col>

                <Col sm="7" className="align-items-center">
                  <span className="invalid-model-number">{error || ""}</span>
                </Col>
                <Col sm="2" className="align-items-center">
                  <span className="delete-icon-express-checkout">
                    <img
                      src={Delete}
                      onClick={() => {
                        setInputEnable(false);
                      }}
                      width={14}
                      height="auto"
                    />
                  </span>
                </Col>
              </Row>
            </Accordion.Header>
          </Accordion.Item>
        ) : (
          ""
        )}
      </Accordion>
      <Row>
        <Col sm="6">
          <span>
            {!hideFilteredOption ? (
              <ul className="express-checkout-filterd-option">
                {getFilteredList()}
              </ul>
            ) : (
              ""
            )}
          </span>
        </Col>
        <Col sm="6">
          <span>
            <Button
              className="primary add-more"
              onClick={(e) => {
                enableInput(e);
              }}
            >
              {t("EXPRESS_CHECKOUT.ADD_MORE")}
            </Button>
          </span>
        </Col>
      </Row>
    </div>
  );
}

export default ProductCollapse;
ProductCollapse.propTypes = {
  getAddToOrderItem: PropTypes.func,
  setButton: PropTypes.func,
};
