import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPricesRequest } from "../../redux/prices/pricesAction";
import { getShapesRequest } from "../../redux/shapes/shapesAction";
import { getMaterials } from "../../redux/category/categoryActions";
import { Container } from "reactstrap";
import Catalog from "../Category/Catalog";
import Price from "../Category/Price";
import Shape from "../Category/Shape";
import NewArrivals from "../Category/NewArrivals";
import Color from "../Category/Colors";
import Material from "../Category/Material";
import { getBanners } from "./../../redux/banner/bannerActions";
import Loader from "./../../common/Loader";
import { getToken } from "./../../helpers/Utility";
import { getCartItemsCount } from "../../redux/cartCount/cartCountActions";
import { getCartItems } from "../../redux/cart/cartActions";

function Landing() {
  //  done by mani
  document.title = `Eyegear Home`;
  let history = useHistory();
  const dispatch = useDispatch();
  const [catalog, setCatalog] = useState([]);
  const [materialBanner, setmaterialBannerData] = useState([]);
  const [colourBanner, setColourBannerData] = useState([]);
  const [frameBanner, setFrameBannerData] = useState([]);
  const [newArrivalBanner, setNewArrivalBannerData] = useState([]);
  const [priceRangeBanner, setPriceRangeBannerData] = useState([]);
  const [prices, setPrices] = useState([]);
  const [shapes, setShapes] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [key, setKey] = useState("");
  const [shapeKey, setShapeKey] = useState("");
  const [priceKey, setPriceKey] = useState("");
  const nextProps = useSelector((state) => ({
    bannerData: state.Banner,
    PricesData: state.Prices.pricesData,
    ShapesData: state.Shapes.shapesData,
    MaterialsData: state.Category.materialData,
    UserProfileData: state.UserProfile.profileData?.data?.userProfile || null,
  }));
  const salesExecutive = "salesexecutive";

  const { bannerData, isBannerRequesting } = nextProps.bannerData;

  useEffect(() => {
    if (!getToken()) {
      history.push("/login");
    }
    dispatch(getPricesRequest());
    dispatch(getShapesRequest());
    dispatch(getMaterials());
    dispatch(getBanners());
  }, []);

  useEffect(() => {
    let data = {
      pageNumber: 1,
    };
    if (nextProps.UserProfileData) {
      if (
        nextProps.UserProfileData?.roleName?.toLowerCase().replace(/ /g, "") ===
          salesExecutive &&
        localStorage.getItem("retailer")
      ) {
        const email = JSON.parse(localStorage.getItem("retailer"))?.Email;
        data.email = email;
        dispatch(getCartItemsCount(email));
      } else {
        dispatch(getCartItemsCount());
      }
      dispatch(getCartItems(data));
    }
  }, [nextProps.UserProfileData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (bannerData) {
      let catalogData = [];
      let materialBannerData = [];
      let colourBannerData = [];
      let frameBannerData = [];
      let newArrivalBannerData = [];
      let priceRangeBannerData = [];
      bannerData.data.banners.map((data) => {
        if (data.isHomeBanner) {
          catalogData.push({
            mobileImage: data?.mobileImage,
            id: data.bannerId,
            src: data.bannerImage,
            title: data.bannerTitle,
          });
        }
        if (data.isMaterialBanner) {
          materialBannerData.push({
            mobileImage: data?.mobileImage,
            id: data.bannerId,
            src: data.bannerImage,
            title: data.bannerTitle,
          });
        }
        if (data.isColourBanner) {
          colourBannerData.push({
            mobileImage: data?.mobileImage,
            id: data.bannerId,
            src: data.bannerImage,
            title: data.bannerTitle,
          });
        }
        if (data.isFrameBanner) {
          frameBannerData.push({
            mobileImage: data?.mobileImage,
            id: data.bannerId,
            src: data.bannerImage,
            title: data.bannerTitle,
          });
        }
        if (data.isNewArrivalBanner) {
          newArrivalBannerData.push({
            mobileImage: data?.mobileImage,
            id: data.bannerId,
            src: data.bannerImage,
            title: data.bannerTitle,
          });
        }
        if (data.isPriceRangeBanner) {
          priceRangeBannerData.push({
            mobileImage: data?.mobileImage,
            id: data.bannerId,
            src: data.bannerImage,
            title: data.bannerTitle,
          });
        }
      });
      setCatalog(catalogData);
      setmaterialBannerData(materialBannerData);
      setColourBannerData(colourBannerData);
      setFrameBannerData(frameBannerData);
      setNewArrivalBannerData(newArrivalBannerData);
      setPriceRangeBannerData(priceRangeBannerData);
    }
  }, [bannerData]);

  useEffect(() => {
    if (
      nextProps.PricesData &&
      nextProps.PricesData.data &&
      nextProps.PricesData.data.priceRangeList &&
      nextProps.PricesData.data.priceRangeList
    ) {
      setPrices(nextProps.PricesData.data.priceRangeList);
      setPriceKey(nextProps.PricesData.data.key);
    }
  }, [nextProps.PricesData]);
  useEffect(() => {
    if (
      nextProps.ShapesData &&
      nextProps.ShapesData.data &&
      nextProps.ShapesData.data.frameShapes
    ) {
      setShapes(nextProps.ShapesData.data.frameShapes);
      setShapeKey(nextProps.ShapesData.data.key);
    }
  }, [nextProps.ShapesData]);
  useEffect(() => {
    if (
      nextProps.MaterialsData &&
      nextProps.MaterialsData.data &&
      nextProps.MaterialsData.data.materials
    ) {
      setMaterials(nextProps.MaterialsData.data.materials);
      setKey(nextProps.MaterialsData.data.key);
    }
  }, [nextProps.MaterialsData]);

  return (
    <>
      {isBannerRequesting ||
      (nextProps.MaterialsData &&
        nextProps.MaterialsData.isMaterialRequesting) ||
      (nextProps.ShapesData && nextProps.ShapesData.isShapesRequesting) ||
      (nextProps.PricesData && nextProps.PricesData.isPricesRequesting) ? (
        <div className="custom-loader">
          <Loader width="50" height="50" />
        </div>
      ) : (
        <Container
          fluid={true}
          className="p-0"
          style={{ marginBottom: "6.5%" }}
        >
          <Catalog catalogData={catalog} />
          <Material
            materialBannerData={materialBanner}
            materialData={materials}
            materialKey={key}
          />
          <NewArrivals newArrivalBannerData={newArrivalBanner} />
          <Price
            priceRangeBannerData={priceRangeBanner}
            pricesData={prices}
            priceKey={priceKey}
          />
          <Color colorCarouselData={colourBanner} />
          <Shape
            frameBannerData={frameBanner}
            shapesData={shapes}
            shapeKey={shapeKey}
          />
        </Container>
      )}
    </>
  );
}

export default Landing;
