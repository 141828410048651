import { FILTER_CONST } from "./filterConst";

/**
 *  Get filter field data action
 */
export const getFilters = () => ({
  type: FILTER_CONST.GET_FILTER_OPTION_REQUEST,
  payload: null,
});

export const getFiltersSuccess = (response) => ({
  type: FILTER_CONST.GET_FILTER_OPTION_SUCCESS,
  payload: response,
});

export const getFiltersFailed = (error) => ({
  type: FILTER_CONST.GET_FILTER_OPTION_FAILED,
  payload: error,
});

/**
 *  Get product data
 */

export const getAll = (payload) => ({
  type: FILTER_CONST.GET_ALL_REQUESTING,
  payload: payload || null,
});

export const getAllSuccess = (response) => ({
  type: FILTER_CONST.GET_ALL_SUCCESS,
  payload: response,
});

export const getAllFailed = (error) => ({
  type: FILTER_CONST.GET_ALL_FAILED,
  payload: error,
});

/**
 *  Get product data by ID
 */

export const getProductById = (payload) => ({
  type: FILTER_CONST.GET_PRODUCT_BY_ID_REQUESTING,
  payload: payload || null,
});

export const getProductByIdSuccess = (response) => ({
  type: FILTER_CONST.GET_PRODUCT_BY_ID_SUCCESS,
  payload: response,
});

export const getProductByIdFailed = (error) => ({
  type: FILTER_CONST.GET_PRODUCT_BY_ID_FAILED,
  payload: error,
});

/**
 *  Get Sort Types
 */

export const getSortType = (payload) => ({
  type: FILTER_CONST.GET_SORT_TYPE_REQUESTING,
  payload: payload || null,
});

export const getSortTypeSuccess = (response) => ({
  type: FILTER_CONST.GET_SORT_TYPE_SUCCESS,
  payload: response,
});

export const getSortTypeFailed = (error) => ({
  type: FILTER_CONST.GET_SORT_TYPE_FAILED,
  payload: error,
});
