import { AXIOS_INSTANCE, BASE_URL } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { CONFIG } from "./configConst";
import { getConfigSuccess, getConfigFailed } from "./configActions";
import { ProductClient } from "../apibenfranklin";
import { getToken, unAuthorizedRedirection } from "./../../helpers/Utility";

const client = new ProductClient(`${BASE_URL}`, AXIOS_INSTANCE);

const getConfigApiRequest = async () => {
  let token = `Bearer ${getToken()}`;
  let response = {};
  try {
    const data = await client.getConfig(token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getConfig(action) {
  try {
    const apiResponse = yield call(getConfigApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getConfigSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getConfigFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getConfigFailed(errorData));
  }
}

export function* watchGetConfig() {
  yield takeEvery(CONFIG.CONFIG_AREQUESTING, getConfig);
}

function* getConfigSaga() {
  yield all([fork(watchGetConfig)]);
}

export default getConfigSaga;
