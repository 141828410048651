// @flow
import { all } from "redux-saga/effects";
import addItmesToCartSaga from "./addToCart/addToCartSaga";
import addressDetails from "./addressDetails/addressDetailsSaga";
import bannerSaga from "./banner/bannerSaga";
import cartSaga from "./cart/cartSaga";
import cartItemCountSaga from "./cartCount/cartCountSaga";
import categorySaga from "./category/categorySaga";
import filtersSaga from "./filters/filterSaga";
import loginSaga from "./login/loginSaga";
import getConfigSaga from "./config/configSaga";
import getPagesSaga from "./getPages/getPagesSaga";
import myOrderSaga from "./myOrders/orderSaga";
import placeOrderSaga from "./placeOrder/placeOrderSaga";
import pricesSaga from "./prices/pricesSaga";
import shapesSaga from "./shapes/shapesSaga";
import userProfileSaga from "./profile/profileSaga";
import usersSaga from "./users/usersSaga";
import expressCheckoutSaga from "../redux/expressCheckout/expressCheckoutSaga";
import getInventorySaga from "./inventory/inventorySaga";

export default function* rootSaga() {
  yield all([
    addressDetails(),
    addItmesToCartSaga(),
    bannerSaga(),
    cartItemCountSaga(),
    categorySaga(),
    cartSaga(),
    filtersSaga(),
    getPagesSaga(),
    getConfigSaga(),
    loginSaga(),
    myOrderSaga(),
    placeOrderSaga(),
    pricesSaga(),
    shapesSaga(),
    userProfileSaga(),
    usersSaga(),
    expressCheckoutSaga(),
    getInventorySaga(),
  ]);
}
