import { Col, Row } from "reactstrap";
import React, { useEffect, useRef, useState } from "react";
import { showError, showSuccess } from "../../../common/alertNotifications";
import { useDispatch, useSelector } from "react-redux";

import Button from "reactstrap-button-loader";
import ProductsCollapse from "../ProductList/ProductCollapse";
import { Prompt } from "react-router-dom";
import { addItemsToCart } from "../../../redux/addToCart/addToCartActions";
import { getInventoryByMultipleProducts } from "../../../redux/expressCheckout/expressCheckoutActions";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-multi-lang";

function ProductList() {
  const dispatch = useDispatch();
  const t = useTranslation();
  const history = useHistory();
  const [addToOrderData, setAddtoOrderData] = useState([]);
  const [leavePage, setLeavePage] = useState(true);
  const [buttonError, setButtonError] = useState(false);
  const getAddToOrderItem = (list) => {
    setAddtoOrderData(list);
  };
  const setButton = (state) => {
    setButtonError(state);
  };

  const nextProps = useSelector((state) => ({
    addToCart: state.AddToCart || null,
    expressInventoryByMultipleProductsData: state.getAllProduct || null,
  }));
  const { addToCartData, isAddToCartRequesting } = nextProps.addToCart;
  const {
    expressInventoryByMultipleProductsData,
    expressInventoryByMultipleProductsRequesting,
  } = nextProps.expressInventoryByMultipleProductsData;

  const isLeaving = async (params) => {
    await setLeavePage(params);
    history.push("/purchase-order");
  };

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (addToCartData) {
      if (addToCartData.data.message) {
        isLeaving(false);
        showSuccess(addToCartData.data.message, {
          position: "top-right",
        });
      }

      if (addToCartData.data.error) {
        showError(addToCartData.data.error.message);
      }
    }
  }, [addToCartData]);

  const totalAddToOrderQuantity = () => {
    let totlaQuantity = 0;
    addToOrderData?.map((data) => {
      totlaQuantity = parseInt(totlaQuantity) + parseInt(data.Quantity);
    });
    if (parseInt(totlaQuantity) > 0 && !buttonError) {
      return false;
    } else {
      return true;
    }
  };

  const handleAddToOrder = () => {
    //remove product with zeo Quantity
    let orderListProducts = addToOrderData?.filter((item) => item.Quantity > 0);
    let productIds = orderListProducts?.map((item) => {
      return item.ProductId;
    });
    let uniqueProductIds = [...new Set(productIds)];
    dispatch(getInventoryByMultipleProducts({ productIds: uniqueProductIds }));
  };

  useEffect(() => {
    if (
      expressInventoryByMultipleProductsData &&
      expressInventoryByMultipleProductsData.inventories
    ) {
      let data = addToOrderData;
      let reqData = data.filter((dt) => {
        return dt.Quantity !== 0 && !isNaN(dt.Quantity);
      });
      const temp = [];
      for (let i = 0; i <= reqData.length; i++) {
        for (
          let j = 0;
          j <= expressInventoryByMultipleProductsData.inventories?.length;
          j++
        ) {
          if (
            reqData[i]?.ColorVariant ==
              expressInventoryByMultipleProductsData.inventories[j]
                ?.colorVariant &&
            expressInventoryByMultipleProductsData.inventories[j]
              ?.colorVariant != undefined &&
            reqData[i]?.ProductId ==
              expressInventoryByMultipleProductsData.inventories[j]?.productId
          ) {
            if (
              reqData[i].Quantity <=
              expressInventoryByMultipleProductsData.inventories[j]
                .availableStockQuantity
            ) {
              temp.push(reqData[i]);
            }
          }
        }
      }
      if (temp.length > 0) {
        dispatch(addItemsToCart({ Products: reqData }));
      }
    }
  }, [expressInventoryByMultipleProductsData]);

  return (
    <>
      <div className="express-checkout-products-list">
        <Row className="heading-customization">
          <Col sm="2">
            <span className="product-model-number-express-checkout">
              <p className="material-code-heading fw-bold">
                {t("EXPRESS_CHECKOUT.MODAL_NUMBER")}
              </p>
            </span>
          </Col>
          <Col sm="2">
            <p className="material-name-heading fw-bold">
              {t("EXPRESS_CHECKOUT.ITEM_NAME")}
            </p>
          </Col>
          <Col sm="1">
            <p className="material-price-heading fw-bold">
              {t("EXPRESS_CHECKOUT.PRICE")}{" "}
            </p>
          </Col>
          <Col sm="1">
            <p className="material-discount-heading fw-bold">
              {t("EXPRESS_CHECKOUT.DISCOUNT")}{" "}
            </p>
          </Col>
          <Col sm="2">
            <p className="material-colors-heading fw-bold">
              {t("EXPRESS_CHECKOUT.AVAILABLE_COLORS")}
            </p>
          </Col>
          <Col sm="1">
            <p className="material-size-heading fw-bold">
              {" "}
              {t("EXPRESS_CHECKOUT.SIZE")}
            </p>
          </Col>
          <Col sm="1">
            <p className="material-material-heading fw-bold">
              {" "}
              {t("EXPRESS_CHECKOUT.MATERIAL")}
            </p>
          </Col>
          <Col sm="2">
            <p className="material-quantity-heading fw-bold">
              {" "}
              {t("EXPRESS_CHECKOUT.QUANTITY")}
            </p>
          </Col>
        </Row>
        <ProductsCollapse
          getAddToOrderItem={getAddToOrderItem}
          setButton={setButton}
        />
      </div>
      <div className="express-add-to-order">
        <Button
          type="button"
          disabled={totalAddToOrderQuantity()}
          loading={
            isAddToCartRequesting ||
            expressInventoryByMultipleProductsRequesting
          }
          className="modal-add-to-order-btn btn btn-secondary  express-add-to-orde-button"
          onClick={() => {
            handleAddToOrder();
          }}
        >
          {t("EXPRESS_CHECKOUT.ADD_TO_ORDER")}
        </Button>
      </div>
      {leavePage ? (
        <Prompt
          when={leavePage}
          message={t("EXPRESS_CHECKOUT.CONFIRMATION_MESSAGE")}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default ProductList;
