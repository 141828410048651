import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { useTranslation } from "react-multi-lang";
import PropTypes from "prop-types";
import Successimage from "../../assets/Images/success_image.png";
// import { useHistory } from "react-router-dom";

const SuccessPopup = ({ isSuccesfullyOrdered, succesfullModal }) => {
  // let history = useHistory();
  const t = useTranslation();
  const [modal, setModal] = useState(false);
  const toggle = () => {
    isSuccesfullyOrdered(!modal);
    setModal(!modal);
  };

  useEffect(() => {
    setModal(succesfullModal);
  }, [succesfullModal]);
  const closeModal = () => {
    setModal(false);
    isSuccesfullyOrdered(modal);
    // history.push("/my-orders");
  };
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className="">
        <ModalBody className="order-successfull p-5 pt-3">
          <img src={Successimage} className="successfull-image" />
          <h5 className="modal-product-name order-msg pt-4">
            {t("ORDER.ORDERED_PLACED_SUCCESFULLY")}
          </h5>
          <p className="product-info-label-2 py-2 px-5">
            {t("ORDER.ORDER_PLACED_DETAILS")}
          </p>
          <Button className="place-order-btn" onClick={closeModal}>
            {t("ORDER.OK")}
          </Button>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SuccessPopup;
SuccessPopup.propTypes = {
  isSuccesfullyOrdered: PropTypes.func,
  succesfullModal: PropTypes.bool,
};
