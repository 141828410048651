import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import {
  getUserProfile,
  updateUserProfile,
} from "../../redux/profile/profileAction";
import { isValidEmail, isValidMobileNumber } from "./../../helpers/Utility";
import { useDispatch, useSelector } from "react-redux";

import BreadCrumbs from "../../components/BreadCrumbs";
import Button from "reactstrap-button-loader";
import ChangePassword from "./ChangePassword";
import Loader from "../../common/Loader";
import MyInvoices from "../my_invoices/MyInvoices";
import ProductOrderedCard from "./ProductOrderedCard";
import SuccesModal from "../../components/SuccessfullOrdered";
import SwitchUser from "./SwitchUser";
import classnames from "classnames";
import defaultProfileImage from "../../assets/Images/profile-placeholder.png";
import { getMyOrders } from "../../redux/myOrders/orderActions";
import { getToken } from "./../../helpers/Utility";
import { showSuccess } from "./../../common/alertNotifications";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-multi-lang";

export default function Profile() {
  //  done by mani
  document.title = `Eyegear Account`;
  const t = useTranslation();
  const history = useHistory();
  const noData = "N/A";
  const [image, setImage] = useState();
  const [base64Image, setBase64Image] = useState("");
  const [formData, setFormData] = useState();
  const [activeTab, setActiveTab] = useState("1");
  const [nameRequired, setNameRequired] = useState(false);
  const [phoneNumberRequired, setPhoneNumberRequired] = useState(false);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState();
  const [alternateNumberRequired, setAlternateNumberRequired] = useState(false);
  const [alternateNumberErrorMessage, setAlternateNumberErrorMessage] =
    useState();
  const [emailRequired, setEmailRequired] = useState(false);
  const [editButtonClicked, setEditButtonClicked] = useState(false);
  const [allformData, setAllFormData] = useState(false);
  const [succesfullModal, setSuccesfullModal] = useState(false);
  const [currentRetailer, setCurrentRetailer] = useState({});
  const [retailerID, setRetailerID] = useState("");
  const [mobileNO, setMobileNO] = useState("");
  const [shopName, setShopName] = useState("");
  const [email, setEmail] = useState("");
  const salesExecutive = "salesexecutive";
  let breadCrumbsdata = [
    {
      name: "Account",
      value: "/profile",
    },
  ];
  const dispatch = useDispatch();
  const nextProps = useSelector((state) => ({
    UserProfileData: state.UserProfile.profileData || null,
    UserProfileLoader: state.UserProfile.isUserProfileRequesting || null,
    UserProfileSubmittedData: state.UserProfile.updateUserProfileData || null,
    UpdateUserProfileLoader: state.UserProfile.isUpdateUserProfileRequesting,
    placedOrderData: state.PlaceOrder || null,
  }));

  const { placeOrderData } = nextProps.placedOrderData;
  useEffect(() => {
    let tempSearch;
    let url_string = window.location.href;
    let url = new URL(url_string);

    if (url.searchParams.get("success")) {
      tempSearch = url.searchParams.get("success");
    }
    if (placeOrderData) {
      if (placeOrderData?.message == "Success" && tempSearch) {
        setSuccesfullModal(true);
      }
    }
  }, []);

  const toggle = (tab, e) => {
    e.preventDefault();
    if (tab === "1") {
      history.push("/profile");
    }
    if (tab === "2") {
      history.push("/profile?order=true");
    }
    if (tab === "3") {
      history.push("/profile?change-password=true");
    }
    if (tab === "4") {
      history.push("/profile?switch-user=true");
    }
    if (tab === "5") {
      history.push("/profile?my-invoices=true");
    }
  };

  let tempSearch;
  let url_string = window.location.href;
  let url = new URL(url_string);
  if (url.searchParams.get("order")) {
    tempSearch = url.searchParams.get("order");
  }
  if (url.searchParams.get("change-password")) {
    tempSearch = "change-password";
  }
  if (url.searchParams.get("switch-user")) {
    tempSearch = "switch-user";
  }
  if (url.searchParams.get("my-invoices")) {
    tempSearch = "my-invoices";
  }

  useEffect(() => {
    if (tempSearch === "change-password") {
      setActiveTab("3");
      return;
    }
    if (tempSearch === "my-invoices") {
      setActiveTab("5");
      return;
    }
    if (tempSearch === "switch-user") {
      setActiveTab("4");
      return;
    }
    if (tempSearch) {
      setActiveTab("2");
      return;
    }
    setActiveTab("1");
  }, [tempSearch]);

  useEffect(() => {
    if (!getToken()) {
      history.push("/login");
    }
    dispatch(getUserProfile());
    if (localStorage.getItem("retailer")) {
      const email = JSON.parse(localStorage.getItem("retailer"))?.Email;
      dispatch(getMyOrders({ email }));
    } else {
      dispatch(getMyOrders());
    }
  }, []);
  useEffect(() => {
    if (
      nextProps.UserProfileSubmittedData &&
      nextProps.UserProfileSubmittedData.data &&
      nextProps.UserProfileSubmittedData.data.status === 0
    ) {
      showSuccess("Profile Updated Successfully");
      setAllFormData(false);
      setEditButtonClicked(false);
      window.scrollTo(0, 0);
    }
  }, [nextProps.UserProfileSubmittedData]);

  useEffect(() => {
    let profileData = nextProps.UserProfileData;
    setRetailerID(profileData?.data?.userProfile?.retailerId);
    setMobileNO(profileData?.data?.userProfile?.phoneNumber);
    setEmail(profileData?.data?.userProfile?.email);
    setShopName(profileData?.data?.userProfile?.businessName);
    if (profileData && profileData.data && profileData.data.userProfile) {
      setFormData(profileData.data.userProfile);
      setImage(profileData.data.userProfile.profilePictureUrl);
      setBase64Image(profileData.data.userProfile.profilePictureUrl);
    }
  }, [nextProps.UserProfileData]);

  const changeCurrentRetailer = (Data) => {
    setCurrentRetailer(Data);
  };
  const handleEditProfile = () => {
    setEditButtonClicked(true);
  };
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    setAllFormData(true);
    setImage(URL.createObjectURL(file));
    const base64 = await convertBase64(file);
    console.log(base64);
  };
  const handleRemovePicture = () => {
    setImage(defaultProfileImage);
    setBase64Image("");
    setAllFormData(true);
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
        let bs6 = fileReader.result.split(",");
        setBase64Image(bs6[1]);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleName = (e) => {
    if (e.target.value !== "") {
      formData.name = e.target.value;
      setFormData({
        ...formData,
      });
      setNameRequired(false);
      setAllFormData(true);
    } else {
      setNameRequired(true);
      setAllFormData(false);
    }
  };

  const handlePhoneNumber = (e) => {
    if (e.target.value === "") {
      setPhoneNumberRequired(true);
      setAllFormData(false);
    } else {
      if (!isValidMobileNumber(e.target.value)) {
        setPhoneNumberRequired(true);
        setPhoneNumberErrorMessage(t("PROFILE.PHONE_NUMBER_ERROR_MESSAGE"));
        setAllFormData(false);
      } else if (e.target.value === formData.alternatePhoneNumber) {
        setPhoneNumberRequired(true);
        setPhoneNumberErrorMessage(
          t("PROFILE.ALTERNATE_PHONE_NUMBER_ERROR_MESSAGE")
        );
        setAllFormData(false);
      } else {
        formData.phoneNumber = e.target.value;
        setFormData({
          ...formData,
        });
        setPhoneNumberRequired(false);
        setAllFormData(true);
      }
    }
  };

  const handleAlternatePhoneNumber = (e) => {
    if (e.target.value === "") {
      formData.alternatePhoneNumber = e.target.value;
      setFormData({
        ...formData,
      });
      setAlternateNumberRequired(false);
      setAllFormData(true);
    } else {
      if (!isValidMobileNumber(e.target.value)) {
        setAlternateNumberRequired(true);
        setAlternateNumberErrorMessage(t("PROFILE.PHONE_NUMBER_ERROR_MESSAGE"));
        setAllFormData(false);
      } else if (e.target.value === formData.phoneNumber) {
        setAlternateNumberRequired(true);
        setAlternateNumberErrorMessage(
          t("PROFILE.ALTERNATE_PHONE_NUMBER_ERROR_MESSAGE")
        );
        setAllFormData(false);
      } else {
        formData.alternatePhoneNumber = e.target.value;
        setFormData({
          ...formData,
        });
        setAlternateNumberRequired(false);
        setAllFormData(true);
      }
    }
  };

  const handleEmail = (e) => {
    if (e.target.value === "" || !isValidEmail(e.target.value.trim())) {
      setEmailRequired(true);
      setAllFormData(false);
    } else {
      formData.email = e.target.value;
      setFormData({
        ...formData,
      });
      setEmailRequired(false);
      setAllFormData(true);
    }
  };

  const handleSubmit = () => {
    let reqFormData = {
      name: "string",
      phoneNumber: "string",
      alternatePhoneNumber: "string",
      email: "string",
      profilePicture: "",
    };
    reqFormData.name = formData.name;
    reqFormData.phoneNumber = formData.phoneNumber;
    reqFormData.alternatePhoneNumber = formData.alternatePhoneNumber;
    reqFormData.email = formData.email;
    reqFormData.profilePicture = base64Image;
    dispatch(updateUserProfile({ UpdateUserProfile: reqFormData }));
  };
  const isSuccesfullyOrdered = (status) => {
    setSuccesfullModal(status);
  };
  return (
    <>
      {succesfullModal ? (
        <SuccesModal
          isSuccesfullyOrdered={isSuccesfullyOrdered}
          succesfullModal={succesfullModal}
        />
      ) : (
        ""
      )}
      {nextProps.UserProfileLoader ? (
        <div className="custom-loader">
          <Loader width="50" height="50" />
        </div>
      ) : (
        <div className="profile-page">
          <Container fluid={true} className="profile-section-container pb-4">
            <div className="pt-4 profile-breadcrumb-mbv">
              <BreadCrumbs defaultBreadCrumbsdata={breadCrumbsdata} />
            </div>
            <div className="tab-label">
              <Nav tabs className="tab-mbv">
                <NavItem className="product-nav-item">
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={(e) => {
                      toggle("1", e);
                    }}
                  >
                    <label className="inDetail-info-label">
                      {t("PROFILE.MY_PROFILE")}
                    </label>
                    <hr
                      className={activeTab === "1" ? "hr-tag-active" : "hr-tag"}
                    />
                  </NavLink>
                </NavItem>
                {formData?.roleName?.toLowerCase().replace(/ /g, "") ===
                  salesExecutive &&
                !currentRetailer?.id &&
                !localStorage.getItem("retailer") ? (
                  ""
                ) : (
                  <NavItem className="product-nav-item">
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={(e) => {
                        toggle("2", e);
                      }}
                    >
                      <label className="inDetail-info-label">
                        {t("PROFILE.MY_ORDERS")}
                      </label>
                      <hr
                        className={
                          activeTab === "2" ? "hr-tag-active" : "hr-tag"
                        }
                      />
                    </NavLink>
                  </NavItem>
                )}
                <NavItem className="product-nav-item">
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={(e) => {
                      toggle("3", e);
                    }}
                  >
                    <label className="inDetail-info-label">
                      {t("PROFILE.CHANGE_PASSWORD")}
                    </label>
                    <hr
                      className={activeTab === "3" ? "hr-tag-active" : "hr-tag"}
                    />
                  </NavLink>
                </NavItem>
                <NavItem className="product-nav-item">
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={(e) => {
                      toggle("5", e);
                    }}
                  >
                    <label className="inDetail-info-label">My Invoices</label>
                    <hr
                      className={activeTab === "5" ? "hr-tag-active" : "hr-tag"}
                    />
                  </NavLink>
                </NavItem>
                {formData?.roleName?.toLowerCase().replace(/ /g, "") ===
                  salesExecutive && (
                  <NavItem className="product-nav-item">
                    <NavLink
                      className={classnames({ active: activeTab === "4" })}
                      onClick={(e) => {
                        toggle("4", e);
                      }}
                    >
                      <label className="inDetail-info-label">
                        {t("PROFILE.SWITCH_USER")}
                      </label>
                      <hr
                        className={
                          activeTab === "4" ? "hr-tag-active" : "hr-tag"
                        }
                      />
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  {/* <Container> */}
                  {activeTab === "1" ? (
                    <div className="profile_section">
                      <Row>
                        <Col
                          sm="12"
                          xl="4"
                          lg="12"
                          md="12"
                          xs="12"
                          className="myprofile-mbv"
                        >
                          <div className="myProfile_image">
                            <img
                              className="profile-image"
                              src={image || defaultProfileImage}
                            />
                          </div>
                          {editButtonClicked ? (
                            <>
                              <div className="picture-fields">
                                <input
                                  type="file"
                                  onChange={(e) => handleImageChange(e)}
                                  id="change-picture"
                                  style={{ display: "none" }}
                                />
                                <label
                                  htmlFor="change-picture"
                                  className="change-picture"
                                >
                                  Change Picture
                                </label>
                              </div>
                              <div className="picture-fields">
                                <label
                                  htmlFor="remove-picture"
                                  className="remove-picture"
                                  onClick={handleRemovePicture}
                                >
                                  Remove Picture
                                </label>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col sm="12" lg="12" md="12" xs="12" xl="8">
                          <Container>
                            <Row>
                              <Col md="8" lg="8" xs="12" sm="12">
                                <Form>
                                  <FormGroup>
                                    <Label className="input-label">
                                      {t("PROFILE.BUSINESS_NAME")}
                                    </Label>
                                    <Input
                                      className="input-value"
                                      defaultValue={
                                        formData &&
                                        (formData.businessName || noData)
                                      }
                                      onChange={(e) => {
                                        (formData.Business_Name =
                                          e.target.value),
                                          setFormData({
                                            ...formData,
                                          });
                                      }}
                                      disabled={true}
                                    />
                                  </FormGroup>
                                </Form>
                              </Col>
                              <Col
                                md="4"
                                lg="4"
                                xs="12"
                                sm="12"
                                className="profilee-option-mbv"
                              >
                                <FormGroup>
                                  <Label className="input-label">
                                    {t("PROFILE.CONTACT_PERSON")}
                                  </Label>
                                  <Input
                                    defaultValue={
                                      formData && (formData.name || noData)
                                    }
                                    className="input-value"
                                    onChange={handleName}
                                    disabled={!editButtonClicked}
                                    invalid={nameRequired}
                                  />
                                  {nameRequired ? (
                                    <p className="profile-input-form-error">
                                      {t(
                                        "PROFILE.CONTACT_PERSON_ERROR_MESSAGE"
                                      )}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row className="next_row">
                              <Col sm="12" md="6" lg="3" xs="12">
                                <FormGroup>
                                  <Label className="input-label">
                                    {t("PROFILE.CONTACT_NUMBER")}
                                  </Label>
                                  <Input
                                    defaultValue={
                                      formData &&
                                      (formData.phoneNumber || noData)
                                    }
                                    className="input-value"
                                    onChange={handlePhoneNumber}
                                    disabled={!editButtonClicked}
                                    invalid={phoneNumberRequired}
                                  />

                                  {phoneNumberRequired ? (
                                    <p className="profile-input-form-error">
                                      {phoneNumberErrorMessage}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </FormGroup>
                              </Col>
                              <Col
                                md="6"
                                lg="5"
                                sm="12"
                                xs="12"
                                className="profilee-option-mbv"
                              >
                                <FormGroup>
                                  <Label className="input-label">
                                    {t("PROFILE.ALTERNATE_NUMBER")}
                                  </Label>
                                  <Input
                                    defaultValue={
                                      formData &&
                                      (formData.alternatePhoneNumber || noData)
                                    }
                                    className="input-value"
                                    onChange={handleAlternatePhoneNumber}
                                    disabled={!editButtonClicked}
                                    invalid={alternateNumberRequired}
                                  />
                                  {alternateNumberRequired ? (
                                    <p className="profile-input-form-error">
                                      {" "}
                                      {alternateNumberErrorMessage}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </FormGroup>
                              </Col>
                              <Col sm="12" md="12" lg="4" xs="12">
                                <FormGroup className="column-class">
                                  <Label className="input-label">
                                    {t("PROFILE.EMAIL")}
                                  </Label>
                                  <Input
                                    defaultValue={
                                      formData && (formData.email || noData)
                                    }
                                    className="input-value"
                                    onChange={handleEmail}
                                    disabled={!editButtonClicked}
                                    invalid={emailRequired}
                                  />

                                  {emailRequired ? (
                                    <p className="profile-input-form-error">
                                      {t("PROFILE.EMAIL_ERROR_MESSAGE")}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row className="next_row">
                              <Col sm="12" md="12" lg="4" xs="12">
                                <FormGroup className="column-class">
                                  <Label className="input-label">
                                    {t("PROFILE.PAN")}
                                  </Label>
                                  <Input
                                    defaultValue={
                                      formData && (formData.pAN || noData)
                                    }
                                    className="input-value"
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm="12" md="12" lg="4" xs="12">
                                <FormGroup className="column-class">
                                  <Label className="input-label">
                                    {t("PROFILE.GST")}
                                  </Label>
                                  <Input
                                    defaultValue={
                                      formData && (formData.gSTIN || noData)
                                    }
                                    className="input-value"
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row className="next_row">
                              <Col sm="12" md="12" xs="12" lg="12">
                                <label className="input-label">
                                  {t("PROFILE.BILLING_ADDRESS")}
                                </label>
                                <Input
                                  defaultValue={
                                    formData && (formData.address || noData)
                                  }
                                  className="input-text-area-value"
                                  onChange={(e) => {
                                    (formData.Address_Line = e.target.value),
                                      setFormData({
                                        ...formData,
                                      });
                                  }}
                                  disabled={true}
                                  type="textarea"
                                />
                              </Col>
                            </Row>
                            <Row className="next_row">
                              <Col sm="12" md="4" lg="4" xs="12">
                                <label className="input-label">
                                  {t("PROFILE.CITY")}
                                </label>
                                <Input
                                  defaultValue={
                                    formData && (formData.city || noData)
                                  }
                                  className="input-value"
                                  onChange={(e) => {
                                    (formData.City = e.target.value),
                                      setFormData({
                                        ...formData,
                                      });
                                  }}
                                  disabled={true}
                                />
                              </Col>
                              <Col
                                sm="12"
                                md="4"
                                lg="4"
                                xs="12"
                                className="profilee-option-mbv"
                              >
                                <label className="input-label">
                                  {t("PROFILE.STATE")}
                                </label>
                                <Input
                                  defaultValue={
                                    formData && (formData.stateName || noData)
                                  }
                                  className="input-value"
                                  onChange={(e) => {
                                    (formData.State = e.target.value),
                                      setFormData({
                                        ...formData,
                                      });
                                  }}
                                  disabled={true}
                                />
                              </Col>
                              <Col
                                sm="12"
                                md="4"
                                lg="4"
                                xs="12"
                                className="profilee-option-mbv"
                              >
                                <label className="input-label">
                                  {t("PROFILE.COUNTRY")}
                                </label>
                                <Input
                                  defaultValue={
                                    formData && (formData.countryName || noData)
                                  }
                                  className="input-value"
                                  onChange={(e) => {
                                    (formData.Country = e.target.value),
                                      setFormData({
                                        ...formData,
                                      });
                                  }}
                                  disabled={true}
                                />
                              </Col>
                            </Row>
                            <Row className="next_row">
                              <Col sm="12" md="4" xs="12" lg="4">
                                <label className="input-label">
                                  {t("PROFILE.PINCODE")}
                                </label>
                                <Input
                                  defaultValue={
                                    formData && (formData.pinCode || noData)
                                  }
                                  className="input-value"
                                  onChange={(e) => {
                                    (formData.Pincode = e.target.value),
                                      setFormData({
                                        ...formData,
                                      });
                                  }}
                                  disabled={true}
                                />
                              </Col>
                            </Row>
                            <Row className="next_row">
                              <Col sm="12" md="12" xs="12" lg="12">
                                <label className="input-label">
                                  {t("PROFILE.SHIPPING_ADDRESS")}
                                </label>
                                <Input
                                  defaultValue={
                                    formData &&
                                    formData.shippingAddressResponse &&
                                    (formData.shippingAddressResponse.address ||
                                      noData)
                                  }
                                  className="input-text-area-value"
                                  onChange={(e) => {
                                    (formData.Address_Line = e.target.value),
                                      setFormData({
                                        ...formData,
                                      });
                                  }}
                                  disabled={true}
                                  type="textarea"
                                />
                              </Col>
                            </Row>
                            <Row className="next_row">
                              <Col sm="12" md="4" lg="4" xs="12">
                                <label className="input-label">
                                  {t("PROFILE.CITY")}
                                </label>
                                <Input
                                  defaultValue={
                                    formData &&
                                    formData.shippingAddressResponse &&
                                    (formData.shippingAddressResponse.city ||
                                      noData)
                                  }
                                  className="input-value"
                                  onChange={(e) => {
                                    (formData.City = e.target.value),
                                      setFormData({
                                        ...formData,
                                      });
                                  }}
                                  disabled={true}
                                />
                              </Col>
                              <Col
                                sm="12"
                                md="4"
                                lg="4"
                                xs="12"
                                className="profilee-option-mbv"
                              >
                                <label className="input-label">
                                  {t("PROFILE.STATE")}
                                </label>
                                <Input
                                  defaultValue={
                                    formData &&
                                    formData.shippingAddressResponse &&
                                    (formData.shippingAddressResponse
                                      .stateName ||
                                      noData)
                                  }
                                  className="input-value"
                                  onChange={(e) => {
                                    (formData.State = e.target.value),
                                      setFormData({
                                        ...formData,
                                      });
                                  }}
                                  disabled={true}
                                />
                              </Col>
                              <Col
                                sm="12"
                                md="4"
                                lg="4"
                                xs="12"
                                className="profilee-option-mbv"
                              >
                                <label className="input-label">
                                  {t("PROFILE.COUNTRY")}
                                </label>
                                <Input
                                  defaultValue={
                                    formData &&
                                    formData.shippingAddressResponse &&
                                    (formData.shippingAddressResponse
                                      .countryName ||
                                      noData)
                                  }
                                  className="input-value"
                                  onChange={(e) => {
                                    (formData.Country = e.target.value),
                                      setFormData({
                                        ...formData,
                                      });
                                  }}
                                  disabled={true}
                                />
                              </Col>
                            </Row>
                            <Row className="next_row">
                              <Col sm="12" md="4" xs="12" lg="4">
                                <label className="input-label">
                                  {t("PROFILE.PINCODE")}
                                </label>
                                <Input
                                  defaultValue={
                                    formData &&
                                    formData.shippingAddressResponse &&
                                    (formData.shippingAddressResponse.pin ||
                                      noData)
                                  }
                                  className="input-value"
                                  onChange={(e) => {
                                    (formData.Pincode = e.target.value),
                                      setFormData({
                                        ...formData,
                                      });
                                  }}
                                  disabled={true}
                                />
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>
                      <div className="edit-profile">
                        {!editButtonClicked ? (
                          <Button
                            color="primary"
                            className="edit-profile-btn d-none"
                            onClick={handleEditProfile}
                          >
                            Edit Profile
                          </Button>
                        ) : (
                          ""
                        )}
                        {editButtonClicked ? (
                          <Button
                            loading={nextProps.UpdateUserProfileLoader || false}
                            color="primary"
                            type="submit"
                            className="edit-profile-btn"
                            onClick={handleSubmit}
                            disabled={allformData ? false : true}
                          >
                            Submit
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </TabPane>
                <TabPane tabId="2">
                  {activeTab === "2" ? (
                    <Row className="tab-row">
                      <ProductOrderedCard />
                    </Row>
                  ) : (
                    ""
                  )}
                </TabPane>
                <TabPane tabId="3">
                  {activeTab === "3" ? (
                    // <Row className="tab-row">
                    <ChangePassword />
                  ) : (
                    // </Row>
                    ""
                  )}
                </TabPane>
                <TabPane tabId="4">
                  {activeTab === "4" ? (
                    // <Row className="tab-row">
                    <SwitchUser changeCurrentRetailer={changeCurrentRetailer} />
                  ) : (
                    // </Row>
                    ""
                  )}
                </TabPane>
                <TabPane tabId="5">
                  {activeTab === "5" ? (
                    // <Row className="tab-row">
                    <MyInvoices
                      RetailerID={retailerID}
                      MobileNO={mobileNO}
                      ShopName={shopName}
                      Email={email}
                    />
                  ) : (
                    // </Row>
                    ""
                  )}
                </TabPane>
              </TabContent>
            </div>
          </Container>
        </div>
      )}
    </>
  );
}
