import { AXIOS_INSTANCE, BASE_URL } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { PLACE_ORDER } from "./placeOrderConst";

import { placeOrderSuccess, placeOrderFailed } from "./placeOrderActions";
import { OrderClient } from "../apibenfranklin";
import { getToken, unAuthorizedRedirection } from "./../../helpers/Utility";

const client = new OrderClient(`${BASE_URL}`, AXIOS_INSTANCE);

/**
 *  Placed order
 */
const placeOrderApiRequest = async (payload) => {
  let token = `Bearer ${getToken()}`;
  let response = {};
  try {
    const data = await client.placeOrder(payload, token);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* placeOrder(action) {
  try {
    const apiResponse = yield call(placeOrderApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(placeOrderSuccess(responseData));
    } else {
      unAuthorizedRedirection(apiResponse);
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(placeOrderFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(placeOrderFailed(errorData));
  }
}

export function* watchplaceOrder() {
  yield takeEvery(PLACE_ORDER.PLACE_ORDERR_EQUESTING, placeOrder);
}

function* placeOrderSaga() {
  yield all([fork(watchplaceOrder)]);
}

export default placeOrderSaga;
