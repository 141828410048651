import "./Blog.scss";

import { Link, useHistory, useParams } from "react-router-dom";
import React, { useEffect } from "react";

import { BiArrowBack } from "react-icons/bi";
import { FiChevronsRight } from "react-icons/fi";
import { blogData } from "./blogData";

const Blog = () => {
  const { slug } = useParams();
  const history = useHistory();
  const blog = blogData.find((blog) => blog.slug === slug);

  if (!blog) {
    return (
      <div className="blog-not-found-page section__padding">
        <h2>404 Page Not Found!</h2>
        <p>Page your looking for is not yet created or in underconstruction!</p>
        <Link to="/homepage">Go to Home</Link>
      </div>
    );
  }

  const titleVal = slug
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  document.title = titleVal;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="section__padding blog-container">
      <div onClick={() => history.goBack()} className="backBtn">
        <BiArrowBack size={22} color="#14467B" />
        <span>Back</span>
      </div>
      <div className="image-title-container">
        <img src={blog.mainImage} alt={blog.title} aria-label={blog.title} />
        <div className="title-container">
          {blog.title.map((title, i) => (
            <div key={i}>
              <h4>{title.title1}</h4>
              <h4>{title.title2}</h4>
            </div>
          ))}
        </div>
      </div>
      <div className="shortDescription">
        {blog.shortDesc.map((shortDescription) => (
          <div key={shortDescription.id}>
            <strong>
              {shortDescription.shortDescTitle1
                ? shortDescription.shortDescTitle1
                : ""}
            </strong>
            <p>{shortDescription.shortDesc1}</p>
            <strong>
              {shortDescription.shortDescTitle2
                ? shortDescription.shortDescTitle2
                : ""}
            </strong>
            <p>
              {shortDescription.shortDesc2 ? shortDescription.shortDesc2 : ""}
            </p>
          </div>
        ))}
      </div>
      <div className="paragraph-section">
        {blog.paragraph ? (
          <>
            {blog.paragraph.map((para) => (
              <div
                key={para.id}
                className="paragraph-container"
                style={{ backgroundColor: para.paraBg }}
              >
                <div className="paraimg-container">
                  <img src={para.paraImg} alt={para.paraTitle} />
                </div>
                <div className="para-desc">
                  <strong>{para.paraTitle}</strong>
                  <p>{para.paraDesc}</p>
                </div>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
      <div className="categories-image-container">
        <div
          className={`${
            blog.categoryImages
              ? "categories-section"
              : "categories-section empty-img-section"
          }`}
        >
          {blog.categories1Title ? <h3>{blog.categories1Title}</h3> : ""}
          {blog.categories1.map((category) => (
            <div key={category.id} className="individual-category">
              <span>
                {category.id}. {category.heading}
              </span>
              <p>{category.desc}</p>
              {category.subCategories ? (
                <>
                  {category.subCategories.map((subCategories) => (
                    <div
                      key={subCategories.id}
                      className="sub-categories-section"
                    >
                      <strong>
                        {subCategories.topHeading && (
                          <span style={{ paddingRight: 6 }}>
                            <FiChevronsRight size={18} />
                          </span>
                        )}

                        {subCategories.topHeading}
                      </strong>
                      <p>
                        {!subCategories.topHeading && subCategories.subData && (
                          <span style={{ paddingRight: 6 }}>
                            <FiChevronsRight size={20} />
                          </span>
                        )}
                        {subCategories.subData}
                      </p>
                    </div>
                  ))}
                </>
              ) : (
                ""
              )}
            </div>
          ))}
          {blog.subCategory ? (
            <>
              {blog.subCategory.map((subCategory) => (
                <div
                  key={subCategory.subCategoryTitle}
                  className="subCategory-section"
                >
                  <div className="subCategory-desc">
                    <h3>{subCategory.subCategoryTitle}</h3>
                    <p>{subCategory.subCategoryDesc}</p>
                  </div>
                  {subCategory.subCategoryData.map((subData) => (
                    <div key={subData.id} className="subData">
                      <strong>{subData.subCategoryDataDesc}</strong>
                    </div>
                  ))}
                </div>
              ))}
            </>
          ) : (
            ""
          )}
        </div>
        <div
          className={`${blog.categoryImages ? "image-section" : "displayNone"}`}
        >
          {blog.categoryImages ? (
            <>
              {blog.categoryImages.map((categoryImg) => (
                <div key={categoryImg.id} className="image-container">
                  <img src={categoryImg.categoryImg} alt={categoryImg.id} />
                </div>
              ))}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        className={`${blog.paragraph2 ? "paragraph2-section" : "displayNone"}`}
      >
        {blog.paragraph2 ? (
          <>
            {blog.paragraph2.map((para) => (
              <div
                key={para.id}
                className={`paragraph2-container ${
                  !blog.paragraph2 ? "displayNone" : ""
                }`}
                style={{ backgroundColor: para.paraBg }}
              >
                <div className="para2img-container">
                  <img src={para.paraImg} alt={para.paraTitle} />
                </div>
                <div className="para2-desc">
                  <strong>{para.paraTitle}</strong>
                  <p>{para.paraDesc}</p>
                </div>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
      <div className="detailed-section">
        {blog.detailedSection ? (
          <>
            {blog.detailedSection.map((details) => (
              <div
                key={details.id}
                className={`details-container ${
                  !blog.detailedSection ? "displayNone" : ""
                }`}
              >
                <strong>{details.detailedTitle}</strong>
                <p>{details.detailedDesc}</p>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
      <div
        className={`${blog.paragraph3 ? "paragraph2-section" : "displayNone"}`}
      >
        {blog.paragraph3 ? (
          <>
            {blog.paragraph3.map((para) => (
              <div
                key={para.id}
                className={`paragraph2-container ${
                  !blog.paragraph3 ? "displayNone" : ""
                }`}
                style={{ backgroundColor: para.paraBg }}
              >
                <div className="para2img-container">
                  <img src={para.paraImg} alt={para.paraTitle} />
                </div>
                <div className="para2-desc">
                  <strong>{para.paraTitle}</strong>
                  <p>{para.paraDesc}</p>
                </div>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
      <div className="detailed-section">
        {blog.detailedSection1 ? (
          <>
            {blog.detailedSection1.map((details) => (
              <div
                key={details.id}
                className={`details-container ${
                  !blog.detailedSection ? "displayNone" : ""
                }`}
              >
                <strong>{details.detailedTitle}</strong>
                <p>{details.detailedDesc}</p>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
      <div
        className={`${blog.paragraph4 ? "paragraph2-section" : "displayNone"}`}
      >
        {blog.paragraph4 ? (
          <>
            {blog.paragraph4.map((para) => (
              <div
                key={para.id}
                className={`paragraph2-container ${
                  !blog.paragraph4 ? "displayNone" : ""
                }`}
                style={{ backgroundColor: para.paraBg }}
              >
                <div className="para2img-container">
                  <img src={para.paraImg} alt={para.paraTitle} />
                </div>
                <div className="para2-desc">
                  <strong>{para.paraTitle}</strong>
                  <p>{para.paraDesc}</p>
                </div>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
      <div className="detailed-section">
        {blog.detailedSection2 ? (
          <>
            {blog.detailedSection2.map((details) => (
              <div
                key={details.id}
                className={`details-container ${
                  !blog.detailedSection2 ? "displayNone" : ""
                }`}
              >
                <strong>{details.detailedTitle}</strong>
                <p>{details.detailedDesc}</p>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
      <div
        className={`${blog.paragraph5 ? "paragraph2-section" : "displayNone"}`}
      >
        {blog.paragraph5 ? (
          <>
            {blog.paragraph5.map((para) => (
              <div
                key={para.id}
                className={`paragraph2-container ${
                  !blog.paragraph5 ? "displayNone" : ""
                }`}
                style={{ backgroundColor: para.paraBg }}
              >
                <div className="para2img-container">
                  <img src={para.paraImg} alt={para.paraTitle} />
                </div>
                <div className="para2-desc">
                  <strong>{para.paraTitle}</strong>
                  <p>{para.paraDesc}</p>
                </div>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
      <div
        className="detailed-section"
        style={
          !blog.detailedSection3 ? { display: "none" } : { display: "grid" }
        }
      >
        {blog.detailedSection3 ? (
          <>
            {blog.detailedSection3.map((details) => (
              <div
                key={details.id}
                className={`details-container ${
                  !blog.detailedSection3 ? "displayNone" : ""
                }`}
              >
                <strong>{details.detailedTitle}</strong>
                <p>{details.detailedDesc}</p>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
      <div
        className={`${blog.paragraph6 ? "paragraph2-section" : "displayNone"}`}
      >
        {blog.paragraph6 ? (
          <>
            {blog.paragraph6.map((para) => (
              <div
                key={para.id}
                className={`paragraph2-container ${
                  !blog.paragraph6 ? "displayNone" : ""
                }`}
                style={{ backgroundColor: para.paraBg }}
              >
                <div className="para2img-container">
                  <img src={para.paraImg} alt={para.paraTitle} />
                </div>
                <div className="para2-desc">
                  <strong>{para.paraTitle}</strong>
                  <p>{para.paraDesc}</p>
                </div>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
      <div
        className="detailed-section"
        style={
          !blog.detailedSection4 ? { display: "none" } : { display: "grid" }
        }
      >
        {blog.detailedSection4 ? (
          <>
            {blog.detailedSection4.map((details) => (
              <div
                key={details.id}
                className={`details-container ${
                  !blog.detailedSection4 ? "displayNone" : ""
                }`}
              >
                <strong>{details.detailedTitle}</strong>
                <p>{details.detailedDesc}</p>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
      {blog.categories2 || blog.categories2Title ? (
        <div
          className={`categories-image-container ${
            !blog.categories2 ? "displayNone" : ""
          }`}
        >
          <div
            className={
              blog.categoryImages2
                ? "categories-section"
                : "categories-section empty-img-section"
            }
            style={
              blog.categoryImages2
                ? { marginBottom: 0 }
                : { marginBottom: "2rem" }
            }
          >
            {blog.categories2Title ? <h3>{blog.categories2Title}</h3> : ""}
            {blog.categories2Desc ? <p>{blog.categories2Desc}</p> : ""}
            {blog.categories2.map((category) => (
              <div key={category.id} className="individual-category">
                <span>
                  {category.id}. {category.heading}
                </span>
                <p>{category.desc}</p>
              </div>
            ))}
          </div>
          <div
            className="image-section"
            style={
              !blog.categoryImages2 ? { display: "none" } : { display: "block" }
            }
          >
            {blog.categoryImages2 ? (
              <>
                {blog.categoryImages2.map((categoryImg) => (
                  <div
                    key={categoryImg.id}
                    className={`image-container ${
                      !blog.categoryImages2 ? "displayNone" : ""
                    }`}
                  >
                    <img src={categoryImg.categoryImg} alt={categoryImg.id} />
                  </div>
                ))}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {blog.categories3 || blog.categories3Title ? (
        <div
          className={`categories-image-container ${
            !blog.categories2 ? "displayNone" : ""
          }`}
        >
          <div
            className={
              blog.categoryImages3
                ? "categories-section"
                : "categories-section empty-img-section"
            }
            style={
              blog.categoryImages3
                ? { marginBottom: 0 }
                : { marginBottom: "2rem" }
            }
          >
            {blog.categories3Title ? <h3>{blog.categories3Title}</h3> : ""}
            {blog.categories3Desc ? <p>{blog.categories3Desc}</p> : ""}
            {blog.categories3.map((category) => (
              <div key={category.id} className="individual-category">
                <span>
                  {category.id}. {category.heading}
                </span>
                <p>{category.desc}</p>
              </div>
            ))}
          </div>
          <div
            className="image-section"
            style={
              !blog.categoryImages3 ? { display: "none" } : { display: "block" }
            }
          >
            {blog.categoryImages3 ? (
              <>
                {blog.categoryImages3.map((categoryImg) => (
                  <div
                    key={categoryImg.id}
                    className={`image-container ${
                      !blog.categoryImages3 ? "displayNone" : ""
                    }`}
                  >
                    <img src={categoryImg.categoryImg} alt={categoryImg.id} />
                  </div>
                ))}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="conclude-section">
        <p>{blog.conclude}</p>
      </div>
    </section>
  );
};

export default Blog;
