import axios from "axios";
// import { getToken } from "./../helpers/Utility";
// getToken
// creating global instance for the axios to call apis

export const AXIOS_INSTANCE = axios.create({
  headers: { Accept: "application/json" },
});

AXIOS_INSTANCE.defaults.headers["Content-Type"] = "application/json";
// if (
//     localStorage.getItem("authToken") !== null &&
//     localStorage.getItem("authToken") !== undefined
//   ) {
//     const token = localStorage.getItem("authToken");
//     AXIOS_INSTANCE.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//   }
// if (getToken()) {
//   AXIOS_INSTANCE.defaults.headers.common[
//     "Authorization"
//   ] = `Bearer ${getToken()}`;
// }

export const BASE_URL = `${process.env.REACT_APP_SERVER_URL}`;
