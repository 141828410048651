export const CART_CONST = {
  GET_CART_ITEMS_REQUEST: "GET_CART_ITEMS_REQUEST",
  GET_CART_ITEMS_SUCCESS: "GET_CART_ITEMS_SUCCESS",
  GET_CART_ITEMS_FAILED: "GET_CART_ITEMS_FAILED",
  DELETE_CART_ITEMS_REQUEST: "DELETE_CART_ITEMS_REQUEST",
  DELETE_CART_ITEMS_SUCCESS: "DELETE_CART_ITEMS_SUCCESS",
  DELETE_CART_ITEMS_FAILED: "DELETE_CART_ITEMS_FAILED",
  UPDATE_QUANTITY_REQUEST: "UPDATE_QUANTITY_REQUEST",
  UPDATE_QUANTITY_SUCCESS: "UPDATE_QUANTITY_SUCCESS",
  UPDATE_QUANTITY_FAILED: "UPDATE_QUANTITY_FAILED",
  CLEAR_CART_ITEMS_REQUEST: "CLEAR_CART_ITEMS_REQUEST",
  CLEAR_CART_ITEMS_SUCCESS: "CLEAR_CART_ITEMS_SUCCESS",
  CLEAR_CART_ITEMS_FAILED: "CLEAR_CART_ITEMS_FAILED",
};
