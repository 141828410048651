import React from "react";
import "./Button.scss";
import PropTypes from "prop-types";

const ButtonMailTo = ({ title, receiver }) => {
  const mailToText = `mailto:${receiver}`;
  return (
    <div>
      <button
        onClick={() => (window.location = `${mailToText}`)}
        className="button__container"
      >
        {title}
      </button>
    </div>
  );
};

export default ButtonMailTo;

ButtonMailTo.propTypes = {
  title: PropTypes.string,
  receiver: PropTypes.string,
};
