export const PROFILE_CONST = {
  GET_USER_PROFILE_REQUEST: "GET_USER_PROFILE_REQUEST",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
  GET_USER_PROFILE_FAILED: "GET_USER_PROFILE_FAILED",
  GET_COUNTRIES_REQUEST: "GET_COUNTRIES_REQUEST",
  GET_COUNTRIES_SUCCESS: "GET_COUNTRIES_SUCCESS",
  GET_COUNTRIES_FAILED: "GET_COUNTRIES_FAILED",
  GET_STATES_REQUEST: "GET_STATES_REQUEST",
  GET_STATES_SUCCESS: "GET_STATES_SUCCESS",
  GET_STATES_FAILED: "GET_STATES_FAILED",
  GET_CITIES_REQUEST: "GET_CITIES_REQUEST",
  GET_CITIES_SUCCESS: "GET_CITIES_SUCCESS",
  GET_CITIES_FAILED: "GET_CITIES_FAILED",
  UPDATE_USER_PROFILE_REQUEST: "UPDATE_USER_PROFILE_REQUEST",
  UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
  UPDATE_USER_PROFILE_FAILED: "UPDATE_USER_PROFILE_FAILED",
  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",
  GET_RETAILERS_LISTS_REQUEST: "GET_RETAILERS_LISTS_REQUEST",
  GET_RETAILERS_LISTS_SUCCESS: "GET_RETAILERS_LISTS_SUCCESS",
  GET_RETAILERS_LISTS_FAILED: "GET_RETAILERS_LISTS_FAILED",
};
