import React, { useState, useRef, useEffect } from "react";
import CaretIcon from "../../assets/Images/dropdown_icon.png";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { getFilters } from "./../../redux/filters/filterActions";

const FramesDropdown = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [genderList, setGnderList] = useState([]);
  const nextProps = useSelector((state) => ({
    isFilterData: state.FilterOption || null,
  }));
  const { filterOptionData } = nextProps.isFilterData;
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    dispatch(getFilters());
  }, []);
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (filterOptionData) {
      filterOptionData?.filters?.map((filter) => {
        if (
          filter.type.length > 0 &&
          filter.key.length > 0 &&
          "In" === filter.type &&
          "genderIdList" === filter.key
        ) {
          setGnderList({
            key: filter.key,
            name: filter.name,
            data: filter.data,
          });
        }
      });
    }
  }, [filterOptionData]);

  const isdropdownItems = () => {
    return genderList?.data?.map((item) => {
      return (
        <>
          <DropdownItem
            key={item.id}
            className="profile-dropdown-item frames-item-mbv"
            onClick={() => {
              props?.handleToggle ? props?.handleToggle() : "";
              history.push(`/products?genderIdList=${item.id}`);
            }}
          >
            {item.name}
          </DropdownItem>
        </>
      );
    });
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className="profile-dropdown"
    >
      <DropdownToggle tag="span" aria-expanded={dropdownOpen}>
        {props.label}
        <span className="angleDownIcon">
          <img src={CaretIcon} className="caretIcon" />
        </span>
      </DropdownToggle>
      <DropdownMenu className="frame-dropdown-menu">
        {isdropdownItems()}
      </DropdownMenu>
    </Dropdown>
  );
};

export default FramesDropdown;

FramesDropdown.propTypes = {
  label: PropTypes.string,
  handleToggle: PropTypes.func,
};
