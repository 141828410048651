export const LOGIN = {
  PASSWORD_MINIMUM_LENGTH: 5,
  PASSWORD_MAXIMUM_LENGTH: 20,
  CAROUSEL_INTERVAL: 5000,
};

export const BANNER = {
  CAROUSEL_INTERVAL: 5000,
  CATALOG_BUTTON_TEXT: "Explore Catalog",
  COLOR_BUTTON_TEXT: "Explore Catalog",
  NEW_ARRIVAL_BUTTON_TEXT: "NEW ARRIVALS",
};

export const CATALOG = {
  FILTER_DEFAULT_LENGTH: 5,
  PRICE_FILTER_MAXIMUM_PRICE: 10000,
  PAGINATION_SIZE: 27, //mani
  PRICE_FILTER_STEP: 1,
};

export const PURCHASE_ORDER = {
  DEFAUILT_PAGE_SIZE: 5,
  DEFAULT_PAGE_NUMBER: 1,
};

export const MESSAGES = {
  ADDED_TO_CART: "Item has been added in cart successfully",
  REMOVED_FROM_CART: "Item has been deleted from cart successfully",
};

export const CART = {
  COLOR_CODE: "#14467c",
  WHITE_BACKGROUND: "#FFFFFF",
};
export const PRODUCTINFO = {
  MIN_CART_SIZE: 1,
  MAX_CART_SIZE: 100,
};
export const PAGINATION = {
  DEFAULT_PAGE_SIZE: 27, //mani
  DEFAULT_END_INDEX: 14, //mani
};

export const MY_ORDER = {
  DEFAULT_ORDER_COUNT: 10,
  DEFAULT_SLICE_STARTING_COUNT: 0,
};

export const PAGES_TYPE = {
  YEAR: "FooterAllRightReservedYear",
};
