export const windowTabSave = (payload) => {
  return {
    type: "SAVE",
    payload: payload,
  };
};

export const windowTabReset = () => {
  return {
    type: "RESET",
  };
};
export const windowStatus = () => {
  return {
    type: "status",
  };
};
