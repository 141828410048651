import { ADD_TO_CART } from "./addToCartConst";

const AddToCart = (state = {}, action) => {
  switch (action.type) {
    case ADD_TO_CART.ADD_TO_CART_REQUESTING:
      return {
        ...state,
        isAddToCartRequesting: true,
        addToCartData: false,
        addToCartDataError: false,
      };
    case ADD_TO_CART.ADD_TO_CART_SUCCESS:
      return {
        ...state,
        isAddToCartRequesting: false,
        addToCartData: action.payload.response,
        addToCartDataError: false,
      };
    case ADD_TO_CART.ADD_TO_CART_FAILED:
      return {
        ...state,
        isAddToCartRequesting: false,
        addToCartData: false,
        addToCartDataError: action.payload.error
          ? false
          : action.payload.response,
      };

    default:
      return { ...state };
  }
};

export default AddToCart;
