import { GET_CARD_ITEMS_COUNT } from "./cartCountConst";

const GetCartItemsCount = (state = {}, action) => {
  switch (action.type) {
    case GET_CARD_ITEMS_COUNT.GET_CARD_ITEMS_COUNT_REQUESTING:
      return {
        ...state,
        isCartItemsCountRequesting: true,
        cartItemsCountData: false,
        cartItemsCountDataError: false,
      };
    case GET_CARD_ITEMS_COUNT.GET_CARD_ITEMS_COUNT_SUCCESS:
      return {
        ...state,
        isCartItemsCountRequesting: false,
        cartItemsCountData: action.payload.response.data,
        cartItemsCountDataError: false,
      };
    case GET_CARD_ITEMS_COUNT.GET_CARD_ITEMS_COUNT_FAILED:
      return {
        ...state,
        isCartItemsCountRequesting: false,
        cartItemsCountData: false,
        cartItemsCountDataError: action.payload.error
          ? false
          : action.payload.response,
      };

    default:
      return { ...state };
  }
};

export default GetCartItemsCount;
