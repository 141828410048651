import { CONFIG } from "./configConst";

const ConfigData = (state = {}, action) => {
  switch (action.type) {
    case CONFIG.CONFIG_AREQUESTING:
      return {
        ...state,
        isConfigRequesting: true,
        configData: false,
        configDataError: false,
      };
    case CONFIG.CONFIG_SUCCESS:
      return {
        ...state,
        isConfigRequesting: false,
        configData: action.payload.response,
        configDataError: false,
      };
    case CONFIG.CONFIG_FAILED:
      return {
        ...state,
        isConfigRequesting: false,
        configData: false,
        configDataError: action.payload.error ? false : action.payload.response,
      };

    default:
      return { ...state };
  }
};

export default ConfigData;
