import { BANNER_CONST } from "./bannerConst";

const Banner = (state = {}, action) => {
  switch (action.type) {
    case BANNER_CONST.BANNER_REQUEST:
      return {
        ...state,
        isBannerRequesting: true,
        bannerData: false,
        bannerDataError: false,
      };
    case BANNER_CONST.BANNER_SUCCESS:
      return {
        ...state,
        isBannerRequesting: false,
        bannerData: action.payload.response,
        bannerDataError: false,
      };
    case BANNER_CONST.BANNER_FAILED:
      return {
        ...state,
        isBannerRequesting: false,
        bannerData: false,
        bannerDataError: action.payload.error ? false : action.payload.response,
      };
    default:
      return { ...state };
  }
};

export default Banner;
