import React, { useState, useEffect, Fragment } from "react";
import canvasImage from "../../../../../assets/Images/canvasImage.png";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";

function ExpressCheckoutQuantity({
  colorData,
  handleQuantity,
  availabileQuantity,
  productId,
  handleError,
  quantity,
  productTypeId,
}) {
  const [quantityError, setQuantityError] = useState(false);
  const [limitError, setLimitError] = useState(false);
  const [error, setError] = useState(false);
  const [variantAvailability, setVariantAvailability] = useState(0);

  const t = useTranslation();
  const nextProps = useSelector((state) => ({
    usersProfile: state.UserProfile.profileData?.data?.userProfile || null,
    addToCart: state.AddToCart || null,
  }));
  const { isAddToCartRequesting } = nextProps.addToCart;
  useEffect(() => {
    setVariantAvailability(availabileQuantity);
  }, availabileQuantity);

  function handleInputQuantity(e, colorVariant, productId, productColorID) {
    if (e.target.value > availabileQuantity) {
      setQuantityError(true);
    } else {
      setQuantityError(false);
    }

    if (e.target.value > nextProps?.usersProfile?.maxLimitPerVariant) {
      setLimitError(true);
    } else {
      setLimitError(false);
    }

    if (
      e.target.value > availabileQuantity ||
      e.target.value > nextProps?.usersProfile?.maxLimitPerVariant
    ) {
      handleError(productColorID, true);
      setError(true);
    } else {
      handleError(productColorID, false);
      setError(false);
    }
    handleQuantity(e, colorVariant, productId);
  }
  return (
    <Fragment>
      <div className="d-flex express-checkout-color-width">
        <span className="product-color-express-checkout">
          {colorData.frameColorCode == "" ? (
            <img src={canvasImage} width="auto" height="18.5" />
          ) : colorData.templeColorCode == "" &&
            colorData.frameColorCode == "" ? (
            <img src={canvasImage} width="auto" height="18.5" />
          ) : (
            <Fragment>
              <span
                className="color-dot-first-half-product top-0"
                style={{
                  border:
                    colorData.frameColorCode === "#FFFFFF"
                      ? "2px solid #dad6d0"
                      : "2px solid white",
                  backgroundColor: `${colorData.frameColorCode}`,
                }}
              ></span>
              <span
                className="color-dot-second-half-product top-0"
                style={{
                  border:
                    colorData.frameColorCode === "#FFFFFF"
                      ? "2px solid #dad6d0"
                      : "2px solid white",
                  backgroundColor: `${
                    colorData.templeColorCode !== colorData.frameColorCode &&
                    colorData.templeColorCode != ""
                      ? colorData.templeColorCode
                      : colorData.frameColorCode
                  }`,
                }}
              ></span>
            </Fragment>
          )}
        </span>
        {productTypeId === 4 ? (
          <label className="color-label-update">
            {colorData.colorVariant ? colorData.colorVariant : ""}
          </label>
        ) : (
          <label className="color-label-update">
            {colorData.colorVariant ? colorData.colorVariant + " : " : ""}
            {colorData.frameColor}
            {colorData.frameColor !== colorData.templeColor
              ? "-" + colorData.templeColor
              : ""}
          </label>
        )}
      </div>
      <div className="d-flex justify-content-end express-checkout-input-width">
        {limitError || quantityError ? (
          limitError ? (
            <span className="quantity-error-message exprees-checkout-error-width">
              {t("ORDER.COLOR_QUANTITY_LIMIT_ERROR_MESSAGE")}
            </span>
          ) : (
            <span className="quantity-error-message exprees-checkout-error-width">
              {`Only ${variantAvailability} available in stock!`}
            </span>
          )
        ) : (
          ""
        )}
        <Input
          type="number"
          id={colorData.frameColorID}
          onChange={(e) =>
            handleInputQuantity(
              e,
              colorData.colorVariant,
              productId,
              colorData.productColorId
            )
          }
          className="form-allignment-right disabled-product-quantity-number"
          invalid={error}
          value={quantity ? quantity : ""}
          disabled={isAddToCartRequesting}
        />
      </div>
    </Fragment>
  );
}

export default ExpressCheckoutQuantity;
ExpressCheckoutQuantity.propTypes = {
  colorData: PropTypes.object,
  handleQuantity: PropTypes.func,
  availabileQuantity: PropTypes.object,
  productId: PropTypes.string,
  handleError: PropTypes.func,
  quantity: PropTypes.number,
  productTypeId: PropTypes.number,
};
