import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { PAGINATION } from "../../../helpers/Constants";
import PropTypes from "prop-types";

function ProductPagination(props) {
  const { pagination, pageCount, startIndex, endIndex, pageNumber } = props;

  const handlePageNumber = (page_number) => {
    window.scrollTo(0, 0);
    // mani
    if (page_number === "prev") {
      pagination(startIndex, startIndex - 14, endIndex - 14);
    } else if (page_number === "next") {
      pagination(endIndex + 1, startIndex + 14, endIndex + 14);
    } else {
      pagination(page_number, startIndex, endIndex);
    }
  };

  const catalogPageNumbers = [];
  let pageNumberCount = pageCount / PAGINATION.DEFAULT_PAGE_SIZE;
  if (pageCount % PAGINATION.DEFAULT_PAGE_SIZE !== 0) {
    pageNumberCount = pageNumberCount + 1;
  }

  for (var i = 1; i <= pageNumberCount; i++) {
    catalogPageNumbers.push(i);
  }
  return (
    <>
      <Pagination
        className="product-pagination"
        aria-label="Page navigation example"
      >
        {catalogPageNumbers.length > PAGINATION.DEFAULT_END_INDEX &&
        startIndex !== 0 ? (
          <PaginationItem>
            <PaginationLink
              previous
              href="javascript:void(0);"
              id="prev"
              onClick={() => handlePageNumber("prev")}
            />
          </PaginationItem>
        ) : (
          ""
        )}
        {pageCount >= PAGINATION.DEFAULT_PAGE_SIZE
          ? catalogPageNumbers.slice(startIndex, endIndex).map((data) => (
              <PaginationItem key={data} active={pageNumber === data}>
                <PaginationLink
                  href="javascript:void(0);"
                  onClick={() => handlePageNumber(data)}
                >
                  {data}
                </PaginationLink>
              </PaginationItem>
            ))
          : ""}
        {catalogPageNumbers.length > PAGINATION.DEFAULT_END_INDEX &&
        endIndex < catalogPageNumbers.length &&
        endIndex - 1 != catalogPageNumbers.length ? (
          <PaginationItem>
            <PaginationLink
              next
              href="javascript:void(0);"
              id="next"
              onClick={() => handlePageNumber("next")}
            />
          </PaginationItem>
        ) : (
          ""
        )}
      </Pagination>
    </>
  );
}

export default ProductPagination;

ProductPagination.propTypes = {
  pagination: PropTypes.func,
  startIndex: PropTypes.number,
  endIndex: PropTypes.number,
  pageCount: PropTypes.number,
  pageNumber: PropTypes.number,
};
