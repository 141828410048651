const loginTabReducer = (state = {}, action) => {
  console.log(state);
  switch (action.type) {
    case "SAVE":
      return (state = action.payload);
    case "RESET":
      return (state = {});
    default:
      return state;
  }
};
export default loginTabReducer;
