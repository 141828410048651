import AboutPage from "./pages/about/AboutPage";
import BrandsPage from "./pages/brands/BrandsPage";
import CareersPage from "./pages/careers/CareersPage";
import NewsRoomPage from "./pages/newsRoom/NewsRoomPage";
import BlogsPage from "./pages/Blogs/BlogsPage";
import Blog from "./pages/Blogs/Blog";
import Catalog from "./pages/Category/Catalog";
import Catalogfilter from "./pages/CatalogFilter";
import Color from "./pages/Category/Colors";
import { ContactPage } from "./pages/contact/ContactPage";
import CookiePolicyPage from "./pages/cookie_policy/CookiePolicyPage";
import ExpressCheckout from "../src/pages/ExpressCheckout";
import Home from "./pages/Category";
import { HomePage } from "./pages/home/HomePage";
import Login from "./pages/Login";
import NewArrivals from "./pages/Category/NewArrivals";
import OrderStatus from "./components/OrderStatus";
import PrivacyPolicyPage from "./pages/privacy_policy/PrivacyPolicyPage";
import ProductDetails from "./pages/ProductDetails";
import ProductOrder from "./pages/ProductOrder";
import Products from "./pages/Products";
import Profile from "./pages/Profile";
import ReactSlider from "../src/pages/Login/LoginForm";
import Resetpassword from "./pages/Login/resetPassword";
import Support from "./components/Support";

//import Landing from "./pages/Category";
var routes = [
  {
    path: "/homepage",
    name: "HOMEPAGE",
    component: HomePage,
  },
  {
    path: "/contact",
    name: "CONTACT",
    component: ContactPage,
  },
  {
    path: "/careers",
    name: "CARRERS",
    component: CareersPage,
  },
  {
    path: "/news-room",
    name: "NEWS_ROOM",
    component: NewsRoomPage,
  },
  {
    path: "/blogs",
    name: "BLOGS",
    component: BlogsPage,
  },
  {
    path: "/blog/:slug",
    name: "BLOG",
    component: Blog,
  },
  {
    path: "/about",
    name: "ABOUTPAGE",
    component: AboutPage,
  },
  {
    path: "/brands",
    name: "BRANDSPAGE",
    component: BrandsPage,
  },
  {
    path: "/privacy-policy",
    name: "PRIVACYPOLICY",
    component: PrivacyPolicyPage,
  },
  {
    path: "/cookie-policy",
    name: "COOKIEPOLICYPAGE",
    component: CookiePolicyPage,
  },
  {
    path: "/login",
    name: "LOGIN",
    component: Login,
  },
  {
    path: "/resetpassword",
    name: "RESETPASSWORD",
    component: Resetpassword,
  },
  {
    path: "/products",
    name: "PRODUCTS",
    component: Products,
  },
  {
    path: "/product-details",
    name: "PRODUCT_DETAILS",
    component: ProductDetails,
  },
  {
    path: "/home",
    name: "HOME",
    component: Home,
  },
  {
    path: "/profile",
    name: "PROFILE",
    component: Profile,
  },
  {
    path: "/my-orders",
    name: "MY_ORDERS",
    component: Profile,
  },
  {
    path: "/catalog-filter",
    name: "CATALOG_FILTER",
    component: Catalogfilter,
  },
  {
    path: "/catalog",
    name: "Catalog",
    component: Catalog,
  },
  {
    path: "/catalog",
    name: "Color",
    component: Color,
  },
  {
    path: "/catalog",
    name: "NewArrivals",
    component: NewArrivals,
  },
  {
    path: "/purchase-order",
    name: "order",
    component: ProductOrder,
  },
  {
    path: "/view-order",
    name: "order",
    component: OrderStatus,
  },
  {
    path: "/support",
    name: "support",
    component: Support,
  },
  {
    path: "/slider",
    name: "slider",
    component: ReactSlider,
  },
  {
    path: "/express-checkout",
    name: "Express Checkout",
    component: ExpressCheckout,
  },
];
export default routes;
