// import React from "react";
// import Banner from "../../../assets/Images/productbanner.png";
// import { useTranslation } from "react-multi-lang";

function ProductBanner() {
  // const t = useTranslation();
  return (
    <>
      {/* <div className="product-banner">
        <img src={Banner} alt="Responsive image"></img>
        <div className="bannerText1 col-5">
          <h3 className="catalogSectionTitle-1">{t("CATALOG.BANNER_TEXT")}</h3>
        </div>
      </div> */}
    </>
  );
}
export default ProductBanner;
