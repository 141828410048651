import "./Header.scss";
import "../../assets/CSS/preLoginStyles.scss";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  windowStatus,
  windowTabReset,
  windowTabSave,
} from "../../redux/loginTabDuplicate/loginTabAction";

import Logo from "../../assets/Images/S-logo.png";
import close from "../../assets/Images/close.png";
import hamburgerMenu from "../../assets/Images/hamburger.svg";
import { useHistory } from "react-router-dom";
import { useScreenSize } from "../../hooks/useScreenSize";

//import { handleLoginDuplicate } from "../../common/alertNotifications";

const HeaderComponent = () => {
  const screenSize = useScreenSize();
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const windowTab = useSelector((state) => state.loginTabReducer);
  const dispatch = useDispatch();

  document.addEventListener("visibilitychange", (event) => {
    if (document.visibilityState != "hidden") {
      event.preventDefault();
      let value = window.localStorage.getItem("isTab");
      value === null ? "true" : "false";
      if (value === "false") {
        dispatch(windowTabReset());
      }
      dispatch(windowStatus());
      event.stopImmediatePropagation();
    }
  });

  const onSideMenuItemClick = (path, event) => {
    setIsMenuOpen(false);
    if (path == "/login") {
      event.preventDefault();
      //handleLoginDuplicate("login");
      if (Object.keys(windowTab).length > 0) {
        windowTab.focus();
      } else {
        window.localStorage.setItem("isTab", "true");
        let windowtab = window.open(`${window.location.origin}/login`);
        dispatch(windowTabSave(windowtab));
      }
    } else {
      history.push(path);
    }
  };

  if (
    window.location.pathname !== "/blogs" &&
    !window.location.pathname.includes("/blog")
  ) {
    localStorage.setItem("visibleBlogs", 12);
  }

  window.addEventListener("beforeunload", function (e) {
    e.preventDefault();
    localStorage.removeItem("isTab");
    e.stopImmediatePropagation();
  });

  return (
    <div className="header_block just-space-btw align-center">
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          history.push("/homepage");
        }}
      >
        <img src={Logo} className="header_logo" alt="Logo" />
      </a>
      {screenSize.currentScreenConfig.width > 767 ? (
        <ul className="header_list disp-flex">
          <li onClick={() => history.push("/about")}>About Eyegear</li>
          {/* <li onClick={() => navigate("/brands")}>Eyegear Platform</li> */}
          <li onClick={() => history.push("/careers")}>Careers</li>
          <li onClick={() => history.push("/news-room")}>News Room</li>
          <li>
            <a
              href="#"
              style={{ textDecoration: "none", color: "white" }}
              onClick={(event) => {
                event.preventDefault();
                //handleLoginDuplicate("login");
                if (Object.keys(windowTab).length > 0) {
                  windowTab.focus();
                } else {
                  window.localStorage.setItem("isTab", "true");
                  let windowtab = window.open(
                    `${window.location.origin}/login`
                  );
                  dispatch(windowTabSave(windowtab));
                }
              }}
              target="_blank"
            >
              Partner Log-in
            </a>{" "}
          </li>
          <li
            onClick={() => {
              history.push("/contact");
            }}
          >
            Contact Us
          </li>
        </ul>
      ) : (
        <img
          src={hamburgerMenu}
          alt="Menu"
          className="header__icon"
          onClick={() => setIsMenuOpen(true)}
        />
      )}
      {isMenuOpen ? (
        <div className="side__menu__block">
          <img
            src={close}
            className="header__icon close__icon"
            alt="closeIcon"
            onClick={() => setIsMenuOpen(false)}
          />
          <ul className="side__menu">
            <li onClick={() => onSideMenuItemClick("/about")}>About Eyegear</li>
            {/* <li onClick={() => onSideMenuItemClick("/brands")}>
              Eyegear Platform
            </li> */}
            <li onClick={() => onSideMenuItemClick("/careers")}>Careers</li>
            <li onClick={() => onSideMenuItemClick("/news-room")}>News Room</li>
            <li onClick={(event) => onSideMenuItemClick("/login", event)}>
              {" "}
              Partner Log-in
            </li>
            <li
              onClick={() => {
                onSideMenuItemClick("/contact");
              }}
            >
              Contact Us
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default HeaderComponent;
