import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "reactstrap-button-loader";
import { useTranslation } from "react-multi-lang";
import PropTypes from "prop-types";
const ClearCartModal = ({
  confirmClearCart,
  ClearCartModal,
  isClearCartItemsRequesting,
}) => {
  const [modal, setModal] = useState(false);
  const t = useTranslation();
  useEffect(() => {
    setModal(setModal);
  }, [ClearCartModal]);
  const toggle = () => setModal(!modal);
  const okTodelete = () => {
    confirmClearCart();
    setModal(!modal);
  };

  const cancelDelete = () => {
    setModal(!modal);
  };

  return (
    <div>
      <div>
        <Button
          loading={isClearCartItemsRequesting}
          className="clear-cart-button"
          onClick={toggle}
          disabled={isClearCartItemsRequesting}
        >
          {t("ORDER.CLEAR_CART")}
        </Button>
      </div>
      <Modal isOpen={modal} toggle={toggle} className="">
        <ModalHeader
          toggle={toggle}
          className="p-0"
          style={{ border: "none" }}
        />
        <ModalBody className="order-delete">
          <h5>{t("ORDER.CLEAR_CART_WARNING_TEXT")}</h5>
        </ModalBody>
        <ModalFooter style={{ border: "none" }}>
          <Button
            className="ok-button"
            onClick={() => {
              okTodelete();
            }}
          >
            {t("PRODUCT_DETAILS.OK")}
          </Button>{" "}
          <Button className="cancel-button" onClick={() => cancelDelete()}>
            {t("PRODUCT_DETAILS.CANCEL")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ClearCartModal;

ClearCartModal.propTypes = {
  confirmClearCart: PropTypes.any,
  ClearCartModal: PropTypes.bool,
  isClearCartItemsRequesting: PropTypes.bool,
};
