import React, { useState, useEffect } from "react";
import Slider from "../../../components/CarouselWrapper";
import { t } from "react-multi-lang";
import { Container, Row, Col } from "reactstrap";
import CarouselHeader from "../../../components/CategoryHeader";
import Frames from "../../../components/Frames";
import PropTypes from "prop-types";
// import { trackWindowScroll } from "react-lazy-load-image-component";

function Shape(props) {
  const { shapesData, frameBannerData, shapeKey } = props;
  const [shape, setShape] = useState();
  const [shapeViewAll, setShapeViewAll] = useState("");
  const data = {
    title: t("HOME.SHAPE_TEXT"),
    view_all: t("HOME.VIEW_ALL"),
  };
  useEffect(() => {
    let shapeViewAllData = [];
    if (shapesData !== undefined) {
      shapesData.map((item) => {
        shapeViewAllData.push(item.frameTypeId.toString());
      });
    }
    setShapeViewAll(`frameShapeId=${shapeViewAllData.toString()}`);

    let frameShape = [];
    if (shapesData !== undefined) {
      const shapeResult = shapesData.filter(
        (item) => item.displayOnHome === true
      );
      shapeResult.map((item) => {
        frameShape.push({
          frameId: item.frameTypeId,
          frameName: item.description,
          frameImages: item.frameShapeImages,
        });
      });
    }
    setShape(frameShape);
  }, [shapesData]);

  const handleImage = (item, index) => {
    return item && item.length > 0 && (item[index]?.frameUrl || "");
  };

  return (
    <div>
      <Container
        fluid={true}
        className="footer-section-container material-section d-none"
      >
        <CarouselHeader data={data} viewAll={shapeViewAll} />
        <Row>
          <Col xl="6" lg="12" md="12">
            <Slider data={frameBannerData} className="catalog-section-3" />
          </Col>
          <Col xs="6" sm="6" md="12" xl="6" lg="12" className="ta-section">
            <Row className="shape-section">
              {shape && shape.length > 0
                ? shape.map((item) => (
                    <Col
                      xs="6"
                      sm="6"
                      md="6"
                      className="mb-section"
                      key={item.frameId}
                    >
                      <Frames
                        label={item.frameName || ""}
                        imageOne={handleImage(item.frameImages, 0)}
                        imageTwo={handleImage(item.frameImages, 1)}
                        frameKey={shapeKey}
                        id={item.frameId}
                      />
                    </Col>
                  ))
                : ""}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Shape;
Shape.propTypes = {
  frameBannerData: PropTypes.array,
  shapesData: PropTypes.object,
  shapeKey: PropTypes.string,
};
