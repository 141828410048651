import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  // CarouselIndicators,
} from "reactstrap";
import PropTypes from "prop-types";
import { BANNER } from "./../../helpers/Constants";
import { isTabViewVisible } from "./../../helpers/Utility";

const Slider = (props) => {
  const { data, className } = props;
  const class_name = className ? className : "catalog-section-1";
  let carouselData = [];
  data &&
    data.map((bannerData) => {
      carouselData.push({
        id: bannerData.bannerId ? bannerData.bannerId : bannerData.id,
        src: bannerData.bannerImage ? bannerData.bannerImage : bannerData.src,
        mobileViwImage: bannerData?.mobileImage,
      });
    });
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === carouselData.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? carouselData.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  /**
   * Later on we can include this feature
   */

  // const goToIndex = (newIndex) => {
  //   if (animating) return;
  //   setActiveIndex(newIndex);
  // };

  const isMobileView = (mobileViewImage) => {
    if (isTabViewVisible() && mobileViewImage?.length) {
      return true;
    } else false;
  };

  const slides = carouselData.map((item) => {
    return (
      <CarouselItem
        // className="login-background catalog-section-1 catalog-section-2"
        className={class_name}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        {isMobileView(item.mobileViwImage) ? (
          <object data={item.mobileViwImage} className="image-carousel" />
        ) : (
          <object
            data={item.src}
            className="image-carousel"
            alt={item.altText}
          />
        )}
      </CarouselItem>
    );
  });

  return (
    <div>
      {carouselData.length > 0 ? (
        <Carousel
          ride="carousel"
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          interval={carouselData.length > 1 ? BANNER.CAROUSEL_INTERVAL : 0}
        >
          {/* <CarouselIndicators
        className="indicators-carousel"
        items={carouselData}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      /> */}
          {slides}
          <CarouselControl direction="" onClickHandler={previous} />
          <CarouselControl direction="" onClickHandler={next} />
        </Carousel>
      ) : (
        ""
      )}
    </div>
  );
};

export default Slider;
Slider.propTypes = {
  data: PropTypes.any,
  className: PropTypes.string,
};
