import { CONFIG } from "./configConst";

export const getConfig = (payload) => ({
  type: CONFIG.CONFIG_AREQUESTING,
  payload: payload || null,
});

export const getConfigSuccess = (response) => ({
  type: CONFIG.CONFIG_SUCCESS,
  payload: response,
});

export const getConfigFailed = (error) => ({
  type: CONFIG.CONFIG_FAILED,
  payload: error,
});
