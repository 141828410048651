import { ADDRESS_DETAILS } from "./adressDetailsConst";

const AddressDetails = (state = {}, action) => {
  switch (action.type) {
    case ADDRESS_DETAILS.ADDRESS_DETAILS_REQUESTING:
      return {
        ...state,
        isAddressDetailsRequesting: true,
        addressDetailsData: false,
        addressDetailsDataError: false,
      };
    case ADDRESS_DETAILS.ADDRESS_DETAILS_SUCCESS:
      return {
        ...state,
        isAddressDetailsRequesting: false,
        addressDetailsData: action.payload.response,
        addressDetailsDataError: false,
      };
    case ADDRESS_DETAILS.ADDRESS_DETAILS_FAILED:
      return {
        ...state,
        isAddressDetailsRequesting: false,
        addressDetailsData: false,
        addressDetailsDataError: action.payload.error
          ? false
          : action.payload.response,
      };

    default:
      return { ...state };
  }
};

export default AddressDetails;
